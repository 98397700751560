(function () {
    'use strict';

    angular.module('modules.cookie_banner.components').component('cookieBannerCookieOverview', {
        templateUrl: 'app/modules/cookie-banner/components/cookie-overview/cookie-overview.html',
        controller: CookieBannerCookieOverviewController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            cookies: '<',
        },
    });

    CookieBannerCookieOverviewController.$inject = [
        'ng2CookieOverviewService',
        'COOKIE_CATEGORIES',
        'COOKIE_SOURCE',
        'COOKIE_FIELDS',
        'COOKIE_BANNER_I18N',
        'LOCALE_LANGUAGE_MAP',
        'gettextCatalog',
        '$q',
        '$timeout',
        'Lodash',
        '$transitions',
        '$element',
        'TRAFFIC_LIGHT_COLORS',
        'ng2CookieModelService',
        'ng2DomainService',
        'ng2CoreDomainService',
        'ng2SessionService',
        'ng2CookieFieldsLocalizeService',
        'ng2MonModalService',
        'ng2DemoHasAccessService',
        'ng2CookieCategoriesLocalizeService',
        'ng2ActiveFeatureService',
    ];

    function CookieBannerCookieOverviewController (
        ng2CookieOverviewService,
        COOKIE_CATEGORIES,
        COOKIE_SOURCE,
        COOKIE_FIELDS,
        COOKIE_BANNER_I18N,
        LOCALE_LANGUAGE_MAP,
        gettextCatalog,
        $q,
        $timeout,
        Lodash,
        $transitions,
        $element,
        TRAFFIC_LIGHT_COLORS,
        ng2CookieModelService,
        ng2DomainService,
        ng2CoreDomainService,
        ng2SessionService,
        ng2CookieFieldsLocalizeService,
        ng2MonModalService,
        ng2DemoHasAccessService,
        ng2CookieCategoriesLocalizeService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;

        vm.search = '';
        vm.sortedInCookies = [];
        vm.sortedInCookiesWithLanguageMap = [];
        vm.perPage = 10;
        vm.currentPageNumber = 1;
        vm.showFilters = true;
        vm.isFiltersOpen = false;
        vm.restoredCookies = [];
        var transListenerOff;
        var COOKIE_PROPS = {
            NAME: 'name',
            DOMAIN: 'domain',
            PATH: 'path',
        };
        // can just add more properties to validate against here
        var cookieValidationProps = [COOKIE_PROPS.NAME, COOKIE_PROPS.DOMAIN, COOKIE_PROPS.PATH];
        var availableLanguages = Object.values(COOKIE_BANNER_I18N);

        vm.onPageSizeChange = onPageSizeChange;
        vm.onFiltersChange = onFiltersChange;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.$onDestroy = onDestroy;
        vm.addNewCookieEntry = addNewCookieEntry;
        vm.removeCookieEntry = removeCookieEntry;
        vm.cookieEntryTouched = cookieEntryTouched;
        vm.onPageChange = onPageChange;
        vm.submit = submit;
        vm.onSearch = onSearch;
        vm.onDemandScan = onDemandScan;
        vm.openScanDetails = openScanDetails;
        vm.getDomainCrawlStatus = getDomainCrawlStatus;
        vm.resolveAbsenceWarning = resolveAbsenceWarning;
        vm.demoAccountScanWarning = demoAccountScanWarning;
        vm.addLanguage = addLanguage;
        vm.removeLanguage = removeLanguage;
        vm.onLanguageChange = onLanguageChange;
        vm.cookieSourceConstant = COOKIE_SOURCE;
        vm.trafficLightConstant = TRAFFIC_LIGHT_COLORS;
        vm.trafficLights = [];

        vm.languageOptions = [];
        vm.filledLanguages = [];

        var debouncedAdjustDropdownsWidth = Lodash.debounce(adjustDropdownsWidth, 300);

        function activate () {
            vm.showReminder = false;
            vm.languageOptions = availableLanguages.map(function (key) {
                var languageLabel = LOCALE_LANGUAGE_MAP[key];
                return {
                    name: languageLabel ? languageLabel : key,
                    value: key,
                };
            });
            vm.isDemoAccount = ng2DemoHasAccessService.isDemoConsentManager();
            vm.cookieCategories = [
                {
                    name: ng2CookieCategoriesLocalizeService[COOKIE_CATEGORIES.essential],
                    value: COOKIE_CATEGORIES.essential,
                },
                {
                    name: ng2CookieCategoriesLocalizeService[COOKIE_CATEGORIES.basic],
                    value: COOKIE_CATEGORIES.basic,
                },
                {
                    name: ng2CookieCategoriesLocalizeService[COOKIE_CATEGORIES.marketing],
                    value: COOKIE_CATEGORIES.marketing,
                },
                {
                    name: ng2CookieCategoriesLocalizeService[COOKIE_CATEGORIES.analytics],
                    value: COOKIE_CATEGORIES.analytics,
                },
                {
                    name: ng2CookieCategoriesLocalizeService[COOKIE_CATEGORIES.personalisation],
                    value: COOKIE_CATEGORIES.personalisation,
                },
            ];
            vm.cookiesInstance = filterDuplicates(vm.cookies);
            vm.cookiesInstanceLanguageMap = getCookiesInstanceLanguageMap();
            vm.originCookies = angular.copy(vm.cookiesInstance);
            vm.hasOnDemandScans = ng2ActiveFeatureService.isFeatureActivePure('on_demand_scans');
            vm.hasCrawlStatus = ng2CoreDomainService.hasCrawlStatus();

            initFilters();
            initTransitionListener();

            $timeout(function () {
                var scrollableSection = $element.find('.middle_section')[0];
                if (scrollableSection) {
                    scrollableSection.addEventListener(
                        'scroll',
                        Lodash.throttle(function () {
                            var shift = -scrollableSection.scrollLeft + 'px';
                            Array.prototype.forEach.call(scrollableSection.querySelectorAll('form-field-select'), function (el) {
                                el.style.setProperty('margin-left', shift);
                            });
                        }, 50),
                    );
                    hackForFixingDropdownBehavior(scrollableSection);
                }
            });

            window.addEventListener('resize', debouncedAdjustDropdownsWidth);
        }

        function onChanges (changes) {
            if (changes.cookies) {
                var filteredCookies = filterDuplicates(vm.cookies);
                vm.cookiesInstance = angular.copy(filteredCookies);
                vm.cookiesInstanceLanguageMap = getCookiesInstanceLanguageMap();
                vm.originCookies = angular.copy(vm.cookiesInstance);
                vm.toBeUpdated = [];
                vm.toBeRemoved = [];
                updateFilteredCookies();

                vm.filledLanguages = getFilledLanguages(vm.cookies);

                if (vm.filledLanguages.length === 0 && vm.languageOptions.length > 0) {
                    vm.filledLanguages.push(vm.languageOptions[0]);
                }

                if (vm.filledLanguages.length > 0) {
                    vm.selectedLanguage = vm.filledLanguages[0].value;
                }
            }
        }

        function onDestroy () {
            if (typeof transListenerOff === 'function') {
                transListenerOff();
            }
            window.removeEventListener('resize', debouncedAdjustDropdownsWidth);
        }

        function initTransitionListener () {
            transListenerOff = $transitions.onBefore({}, function (transition) {
                if (!vm.toBeUpdated.length && !vm.toBeRemoved.length && vm.originCookies.length === vm.cookiesInstance.length) {
                    // if there are no changes to the form, proceed with transition
                    return true;
                }

                var params = {
                    transition: transition,
                    callback: submit,
                    options: {
                        form: vm.cookieOverviewForm,
                        listenerRef: transListenerOff,
                    },
                };

                if (!vm.isDemoAccount) {
                    ng2CookieOverviewService.confirmSavePopup(params);
                    return false;
                }
                return true;
            });
        }

        // TODO table-filters components from frontend-components and ASC should fix this issue with resetting with x
        // TODO https://zube.io/monsido/monsido/c/27891
        function onFiltersChange (changes) {
            // initializing filters again because closing the filters with x, does not properly reset
            if (!changes.category.length) {
                initFilters();
            }

            if (changes.category) {
                updateFilteredCookies();
            }
        }

        function initFilters () {
            vm.availableFilters = {
                category: vm.cookieCategories,
            };
            vm.filters = {
                category: [],
            };
        }

        function removeCookieEntry (id, cookieIndex) {
            var index;
            if (id === undefined && cookieIndex !== undefined) {
                // calculating index when the cookies are not saved (they don't have an id yet)
                index = vm.perPage * (vm.currentPageNumber - 1) + cookieIndex;
            } else {
                // when cookies have an id
                index = Lodash.findIndex(vm.cookiesInstance, function (cookie) {
                    return cookie.id === id;
                });
            }
            if (vm.cookiesInstance[index].id != null) {
                vm.toBeRemoved.push(angular.copy(vm.cookiesInstance[index]));
            }

            vm.toBeUpdated = vm.toBeUpdated.filter(function (cookie) {
                return id !== cookie.id;
            });

            vm.cookiesInstance.splice(index, 1);
            vm.cookiesInstanceLanguageMap = getCookiesInstanceLanguageMap();
            vm.originCookies = angular.copy(vm.cookiesInstance);

            if (vm.sortedInCookies.length <= 1 && vm.currentPageNumber > 1) {
                vm.currentPageNumber -= 1;
            }

            vm.cookieOverviewForm.$setDirty();
            updateFilteredCookies();
        }

        function onPageChange (page) {
            vm.currentPageNumber = Number(page);
            updateFilteredCookies();
        }

        function addNewCookieEntry () {
            var cookieOptions = {};
            cookieOptions[COOKIE_FIELDS.name] = '';
            cookieOptions[COOKIE_FIELDS.domain] = '';
            cookieOptions[COOKIE_FIELDS.platform] = '';
            cookieOptions[COOKIE_FIELDS.source] = COOKIE_SOURCE.manual;
            cookieOptions[COOKIE_FIELDS.type] = vm.cookieCategories[0].value;

            cookieOptions[COOKIE_FIELDS.expirations] = [];
            cookieOptions[COOKIE_FIELDS.descriptions] = [];
            vm.filledLanguages.forEach(function (languageOption) {
                cookieOptions[COOKIE_FIELDS.expirations].push({ language: languageOption.value, text: '' });
                cookieOptions[COOKIE_FIELDS.descriptions].push({ language: languageOption.value, text: '' });
            });

            vm.cookiesInstance.push(ng2CookieModelService.createCookie(cookieOptions));

            var total = vm.cookiesInstance.length;

            if (total >= vm.perPage) {
                vm.currentPageNumber = total % vm.perPage !== 0 ? Math.floor(total / vm.perPage + 1) : total / vm.perPage;
            }

            updateFilteredCookies();
        }

        function cookieEntryTouched (cookie, indexInSortedCookies) {
            var originCookie = vm.originCookies.find(function (o) {
                return o.id === cookie.id;
            });

            var languageArrays = getLanguageArrays(vm.sortedInCookiesWithLanguageMap[indexInSortedCookies]);
            vm.sortedInCookies[indexInSortedCookies][COOKIE_FIELDS.expirations] = languageArrays[COOKIE_FIELDS.expirations];
            vm.sortedInCookies[indexInSortedCookies][COOKIE_FIELDS.descriptions] = languageArrays[COOKIE_FIELDS.descriptions];

            cookie[COOKIE_FIELDS.expirations] = languageArrays[COOKIE_FIELDS.expirations];
            cookie[COOKIE_FIELDS.descriptions] = languageArrays[COOKIE_FIELDS.descriptions];

            vm.trafficLights[indexInSortedCookies] = getTrafficLightData(cookie);

            // Check if the cookie is existed from the beginning
            if (!originCookie) {
                return;
            }
            var existedInToBeUpdatedIndex = vm.toBeUpdated.findIndex(function (existed) {
                return existed.id === cookie.id;
            });
            if (existedInToBeUpdatedIndex > -1) {
                // Remove the cookie from toBeUpdated array if its value is changed back to its original
                if (angular.equals(cookie, originCookie)) {
                    vm.toBeUpdated.splice(existedInToBeUpdatedIndex, 1);
                }
            } else {
                // Add the cookie to toBeUpdated array if its value has been changed
                if (!angular.equals(cookie, originCookie)) {
                    vm.toBeUpdated.push(cookie);
                }
            }

            if (vm.toBeUpdated.length) {
                vm.cookieOverviewForm.$setDirty();
            }
        }

        function submit () {
            var toBeCreated = [];
            for (var i = 0; i < vm.cookiesInstance.length; i++) {
                var cookie = vm.cookiesInstance[i];
                if (!cookie.id) {
                    toBeCreated.push(cookie);
                }
            }

            var firstErroredEntry = getInvalidId(toBeCreated.concat(vm.toBeUpdated));
            var errorIndex = vm.cookiesInstance.indexOf(firstErroredEntry);

            if (errorIndex > -1) {
                vm.currentPageNumber = Math.ceil(errorIndex / vm.perPage);
                updateFilteredCookies().then(function () {
                    $timeout(reportFirstErroredInputValidity);
                });
                return;
            }

            var chain = $q.when();
            var promises = [
                function () {
                    return ng2CookieOverviewService.removeMultipleCookies(vm.toBeRemoved, vm.domain.id);
                },
                function () {
                    return ng2CookieOverviewService.updateMultipleCookies(vm.toBeUpdated, vm.domain.id);
                },
                function () {
                    return ng2CookieOverviewService.createMultipleCookies(toBeCreated, vm.domain.id);
                },
            ];

            vm.saving = true;

            promises.forEach(function (promise) {
                chain = chain.then(function () {
                    return promise().then(
                        function (res) {
                            return res;
                        },
                        function (error) {
                            return $q.reject(error);
                        },
                    );
                }, function (error) {
                    return $q.reject(error);
                });
            });

            return chain.then(
                function (result) {
                    // Since the promises are chained now, the result will always be created cookies due
                    // to the order of DELETE - PATCH - POST
                    var createdCookies = result || [];
                    vm.toBeUpdated = [];
                    vm.toBeRemoved = [];
                    vm.cookiesInstance = getConcatedCreatedCookies(vm.cookiesInstance, createdCookies);
                    vm.originCookies = getConcatedCreatedCookies(vm.originCookies, createdCookies);
                    vm.cookieOverviewForm.$setPristine();
                    updateFilteredCookies();
                    vm.saving = false;
                    ng2CookieOverviewService.successToast();
                },
                function (error) {
                    if (error && error.data && error.data.message) {
                        ng2CookieOverviewService.errorToast(error.data.message);
                    }
                    vm.saving = false;
                },
            );
        }

        function getConcatedCreatedCookies (existingCookies, createdCookies) {
            return existingCookies
                .filter(function (entry) {
                    // removing created cookies since we concat their version received from server (with id)
                    return entry.id != null;
                })
                .concat(angular.copy(createdCookies));
        }

        function onSearch (search) {
            vm.search = search;
            updateFilteredCookies();
        }

        function resolveAbsenceWarning (cookie, cookieIndex) {
            ng2MonModalService.customDialog({
                title: gettextCatalog.getString('Cookie not found'),
                message: gettextCatalog.getString(
                    'It looks like the previously set up cookie was not found in this scan. It might be that the cookie was removed or our scan just couldn’t detect it.',
                ),
                buttons: [
                    {
                        label: gettextCatalog.getString('Keep'),
                        className: 'btn btn-secondary',
                        callback: function () {
                            cookie.source = COOKIE_SOURCE.manual;
                            cookieEntryTouched(cookie, cookieIndex);
                        },
                        value: 'leave',
                    },
                    {
                        label: gettextCatalog.getString('Delete'),
                        className: 'btn btn-danger',
                        callback: function () {
                            removeCookieEntry(cookie.id, cookieIndex);
                        },
                        value: 'save',
                    },
                ],
            });
        }

        async function addLanguage ($event) {
            vm.showReminder = true;
            vm.filledLanguages.push($event);
            vm.selectedLanguage = vm.filledLanguages[vm.filledLanguages.length - 1].value;

            await replaceEmptyCookiesFieldsWithRestoredData();

            vm.cookiesInstanceLanguageMap = getCookiesInstanceLanguageMap();

            updateAllCookieEntriesLanguage();
        }

        async function replaceEmptyCookiesFieldsWithRestoredData () {
            if (!vm.restoredCookies?.length) {
                vm.restoredCookies = await ng2CookieOverviewService.getCookiesFromCentralDb(vm.selectedLanguage);
            }

            vm.cookiesInstance.forEach(function (cookie) {
                const sourceCookie = vm.restoredCookies.find(it => it.name.trim().toLowerCase() === cookie.name?.trim().toLowerCase());
                if (!sourceCookie) {
                    return;
                }

                if (!cookie.descriptions.find(it => it.language === vm.selectedLanguage)) {
                    const sourceDescriptions = sourceCookie.descriptions.filter(it => it.language === vm.selectedLanguage);
                    cookie.descriptions = [...cookie.descriptions, ...sourceDescriptions];
                }

                if (!cookie.expirations.find(it => it.language === vm.selectedLanguage)) {
                    const sourceExpirations = sourceCookie.expirations.filter(it => it.language === vm.selectedLanguage);
                    cookie.expirations = [...cookie.expirations, ...sourceExpirations];
                }

                updateFilteredCookies();
            });
        }

        function removeLanguage ($event) {
            ng2MonModalService.customDialog({
                title: gettextCatalog.getString('Delete language - ' + $event.name),
                message: gettextCatalog.getString(

                    'Doing this will result in removing the translations in the selected language from both your Cookie Overview and the actual banner itself.<br></br><strong>You will need to add the descriptions again when you re-add the selected language.</strong>',
                ),
                buttons: [
                    {
                        label: gettextCatalog.getString('Cancel'),
                        className: 'btn btn-secondary',
                        value: 'cancel',
                    },
                    {
                        label: gettextCatalog.getString('Delete'),
                        className: 'btn btn-danger',
                        callback: function () {
                            vm.showReminder = true;
                            vm.filledLanguages = vm.filledLanguages.filter(function (lang) {
                                return lang.value !== $event.value;
                            });
                            vm.selectedLanguage = vm.filledLanguages[0].value;
                            vm.cookiesInstanceLanguageMap = getCookiesInstanceLanguageMap();
                            updateAllCookieEntriesLanguage();
                        },
                        value: 'delete',
                    },
                ],
            });
        }

        function onLanguageChange () {
            updateFilteredCookies();
        }

        // PROTECTED

        function getInvalidId (items) {
            var i = 0;
            var len = items.length;
            var item;

            for (i; i < len; i += 1) {
                item = items[i];

                if (
                    ['name'].some(function (k) {
                        return !item[k] || !item[k].trim();
                    })
                ) {
                    return item;
                }
            }
            return undefined;
        }

        /**
         * Filtering cookie overview duplicates based on the following acceptance criteria:
         * If there are 2 or more cookies with the same "name", "domain", and "path" (yes, we do not have "path" right now,
         * but we may have it later), then the cookie with "source" === "manual" should be output.
         * If there is more then 1 with the source "manual" (or no "manual" at all), then the latest should be shown
         *
         * @param inputArr
         * @returns {*[]}
         */
        function filterDuplicates (inputArr) {
            var result = [];
            if (inputArr && inputArr.length) {
                var inputCopy = angular.copy(inputArr);
                var sourceIsNotEmpty = inputCopy.length;

                while (sourceIsNotEmpty) {
                    var cookie = inputCopy.shift();

                    var iIndex = getIndexOfDuplicateCookie(inputCopy, cookie);

                    if (iIndex !== -1) {
                        var duplicateSourceCookie = inputCopy.splice(iIndex, 1)[0];

                        if (
                            cookie.source === duplicateSourceCookie.source ||
                            (duplicateSourceCookie.source === COOKIE_SOURCE.manual && cookie.source !== COOKIE_SOURCE.manual)
                        ) {
                            cookie = duplicateSourceCookie;
                        }
                    }

                    var dIndex = getIndexOfDuplicateCookie(result, cookie);

                    if (dIndex !== -1) {
                        var duplicateDestCookie = result.splice(dIndex, 1)[0];

                        if (duplicateDestCookie.source === COOKIE_SOURCE.manual && cookie.source !== COOKIE_SOURCE.manual) {
                            cookie = duplicateDestCookie;
                        }
                    }

                    result.push(cookie);
                    sourceIsNotEmpty = inputCopy.length;
                }
            }
            return result;
        }

        function getIndexOfDuplicateCookie (array, item) {
            return Lodash.findIndex(array, function (elem) {
                return isEqual(elem, item);
            });
        }

        /**
         * Comparing if cookies {value} and {other} are equal considering the properties in {cookieValidationProps}
         *
         * @param value
         * @param other
         * @returns {*}
         */
        function isEqual (value, other) {
            return Lodash.isEqual(Lodash.pick(value, cookieValidationProps), Lodash.pick(other, cookieValidationProps));
        }

        function applySearch (cookies, searchTerm) {
            return cookies.filter(function (c) {
                var domain = (c.domain || '').toLowerCase();
                var name = (c.name || '').toLowerCase();
                var type = (c.type || '').toLowerCase();

                var description = '';
                var expires = '';

                var descriptionWithSelectedLanguage = c.descriptions.find(function (desc) {
                    return desc.language === vm.selectedLanguage;
                });

                var expiresWithSelectedLanguage = c.expirations.find(function (exp) {
                    return exp.language === vm.selectedLanguage;
                });

                if (descriptionWithSelectedLanguage) {
                    description = (descriptionWithSelectedLanguage.text || '').toLowerCase();
                }

                if (expiresWithSelectedLanguage) {
                    expires = (expiresWithSelectedLanguage.text || '').toLowerCase();
                }

                return [domain, description, name, type, expires].some(function (text) {
                    return text && text.indexOf(searchTerm) > -1;
                });
            });
        }

        function applySorting (cookies, categories) {
            return cookies.filter(function (cookie) {
                return categories.some(function (cat) {
                    return cat.value === cookie.type;
                });
            });
        }

        function setPaginatorProperties (cookies, currentIndex) {
            var total = cookies.length;

            var result = cookies.slice(currentIndex * vm.perPage, currentIndex * vm.perPage + vm.perPage);
            result.perPage = vm.perPage;
            result.total = total;
            result.currentPage = vm.currentPageNumber;

            return result;
        }

        function updateFilteredCookies () {
            var defer = $q.defer();
            $timeout(function () {
                if (!Array.isArray(vm.cookiesInstance)) {
                    return;
                }

                // shallow copy
                var updatedCookies = vm.cookiesInstance.slice();
                var hasSearch = Boolean(vm.search.length);
                var hasCategories = Boolean(vm.filters && vm.filters.category && vm.filters.category.length);

                if (hasSearch) {
                    updatedCookies = applySearch(updatedCookies, vm.search);
                }

                if (hasCategories) {
                    updatedCookies = applySorting(updatedCookies, vm.filters.category);
                }

                vm.sortedInCookies = setPaginatorProperties(updatedCookies, vm.currentPageNumber - 1);
                vm.sortedInCookiesWithLanguageMap = [];
                vm.trafficLights = [];

                for (var i = 0; i < vm.sortedInCookies.length; i++) {
                    var cookie = vm.sortedInCookies[i];
                    vm.sortedInCookiesWithLanguageMap.push(getLanguageMap(cookie));
                    vm.trafficLights.push(getTrafficLightData(cookie));
                }

                $timeout(function () {
                    debouncedAdjustDropdownsWidth();
                    defer.resolve();
                });
            });
            return defer.promise;
        }

        function onPageSizeChange (perPage) {
            vm.perPage = Number(perPage);
            updateFilteredCookies();
        }

        function adjustDropdownsWidth () {
            var scrollableSection = $element.find('.middle_section')[0];
            if (scrollableSection) {
                var cellWidth;
                Array.prototype.forEach.call(scrollableSection.querySelectorAll('form-field-select'), function (el) {
                    var parentElement = el.parentElement;
                    if (parentElement) {
                        if (!cellWidth) {
                            cellWidth = window.getComputedStyle(parentElement).width;
                        }
                        el.style.setProperty('width', cellWidth);
                        el.style.setProperty('max-width', cellWidth);
                    }
                });
                scrollableSection.scrollTo(null, 0);
            }
        }

        function getTrafficLightData (cookie) {
            var description = getSelectedLanguageFieldText(cookie, 'descriptions');
            var expire = getSelectedLanguageFieldText(cookie, 'expirations');
            var emptyFields = [];

            [COOKIE_FIELDS.type, COOKIE_FIELDS.name, COOKIE_FIELDS.domain].forEach(function (key) {
                var value = cookie[key];
                if (typeof value !== 'string' || value.trim() === '') {
                    emptyFields.push(key);
                }
            });

            if (cookie[COOKIE_FIELDS.type] === COOKIE_CATEGORIES.unclassified && emptyFields.indexOf(COOKIE_FIELDS.type) < 0) {
                emptyFields.push(COOKIE_FIELDS.type);
            }

            if (description.trim() === '') {
                emptyFields.push(COOKIE_FIELDS.description);
            }

            if (expire.trim() === '') {
                emptyFields.push(COOKIE_FIELDS.expires);
            }

            var emptyFieldName = emptyFields.map(function (key) {
                return ng2CookieFieldsLocalizeService.getField(key);
            });
            var color = TRAFFIC_LIGHT_COLORS.green;
            var tooltip = gettextCatalog.getString('Complete');

            if (cookie.source === COOKIE_SOURCE.scanned_now_absent) {
                color = TRAFFIC_LIGHT_COLORS.yellow;
                tooltip = gettextCatalog.getString('Action required. Click to resolve issue.');
            } else if (emptyFieldName.length) {
                color = TRAFFIC_LIGHT_COLORS.red;
                tooltip = gettextCatalog.getString('Cookie not published. Missing:') + ' ' + emptyFieldName.join(', ') + '.';
            }

            return {
                color: color,
                tooltip: tooltip,
            };
        }

        function getLanguageMap (cookie) {
            var expirations = cookie[COOKIE_FIELDS.expirations] || [];
            var descriptions = cookie[COOKIE_FIELDS.descriptions] || [];
            var expirationMap = {};
            var descriptionMap = {};
            var availableLanguages = vm.filledLanguages.map(function (langOption) {
                return langOption.value;
            });

            expirations.forEach(function (expire) {
                if (availableLanguages.indexOf(expire.language) > -1) {
                    expirationMap[expire.language] = expire.text;
                }
            });

            descriptions.forEach(function (desc) {
                if (availableLanguages.indexOf(desc.language) > -1) {
                    descriptionMap[desc.language] = desc.text;
                }
            });

            vm.filledLanguages.forEach(function (languageOption) {
                if (expirationMap[languageOption.value] === undefined) {
                    expirationMap[languageOption.value] = '';
                }
                if (descriptionMap[languageOption.value] === undefined) {
                    descriptionMap[languageOption.value] = '';
                }
            });

            return {
                expirationMap: expirationMap,
                descriptionMap: descriptionMap,
            };
        }

        function getLanguageArrays (languageMap) {
            var expirationMap = languageMap.expirationMap;
            var descriptionMap = languageMap.descriptionMap;
            var result = {};
            result[COOKIE_FIELDS.expirations] = [];
            result[COOKIE_FIELDS.descriptions] = [];
            var availableLanguages = vm.filledLanguages.map(function (langOption) {
                return langOption.value;
            });

            if (expirationMap) {
                result[COOKIE_FIELDS.expirations] = Object.keys(expirationMap)
                    .filter(function (language) {
                        return availableLanguages.indexOf(language) > -1;
                    })
                    .map(function (language) {
                        return {
                            language: language,
                            text: expirationMap[language],
                        };
                    });
            }

            if (descriptionMap) {
                result[COOKIE_FIELDS.descriptions] = Object.keys(descriptionMap)
                    .filter(function (language) {
                        return availableLanguages.indexOf(language) > -1;
                    })
                    .map(function (language) {
                        return {
                            language: language,
                            text: descriptionMap[language],
                        };
                    });
            }

            return result;
        }

        function onDemandScan () {
            var domainId = vm.domain.id || ng2SessionService.getDomainId();
            const params = { full_cookie_scan: true };
            ng2DomainService.rescan(domainId, params).then(function () {
                if (ng2SessionService.domain !== null && domainId === ng2SessionService.domain.id) {
                    ng2SessionService.domain.crawl_status = {};
                    ng2SessionService.domain.crawl_status.progress = 0;
                    vm.domain = ng2SessionService.domain;
                }
            }, angular.noop);
        }

        function openScanDetails () {
            ng2CoreDomainService.openScanDetails();
        }

        function getDomainCrawlStatus () {
            return ng2CoreDomainService.getDomainCrawlStatus();
        }

        function demoAccountScanWarning () {
            var params = {
                title: gettextCatalog.getString('On-demand scan unavailable'),
                message: gettextCatalog.getString('This feature is not available on a demo account.'),
                buttons: [
                    {
                        label: gettextCatalog.getString('OK'),
                        className: 'btn btn-secondary',
                        value: 'cancel',
                    },
                ],
            };
            ng2MonModalService.customDialog(params);
        }

        // TODO: Remove the hack when select is updated - https://zube.io/monsido/monsido/c/28708
        function hackForFixingDropdownBehavior (scrollableSection) {
            Array.prototype.forEach.call(scrollableSection.querySelectorAll('form-field-select'), function (select) {
                var label = select.querySelector('label.selector-input');
                var input;
                var dropdown;

                if (label) {
                    input = label.querySelector('input');
                    if (input) {
                        input.addEventListener('mousedown', function (e) {
                            e.stopImmediatePropagation();
                            e.preventDefault();
                        });

                        input.addEventListener('mouseup', function (e) {
                            e.stopImmediatePropagation();
                            e.preventDefault();
                            dropdown = select.querySelector('.selector-container.open');

                            if (!dropdown && label) {
                                input.blur();
                                label.click();
                            }
                        });
                    }
                }
            });
        }

        function reportFirstErroredInputValidity () {
            var errorInput = $element.find('input[aria-invalid="true"]')[0];

            if (errorInput && typeof errorInput.reportValidity === 'function') {
                errorInput.reportValidity();
            }
        }

        /** Returns an array of languages that exist in the input cookies list
         */
        function getFilledLanguages (cookies) {
            if (!cookies) {
                return [];
            }

            var resultMap = {};

            cookies.forEach(function (cookie) {
                var descriptions = cookie.descriptions || [];
                var expirations = cookie.expirations || [];

                descriptions.forEach(function (description) {
                    if (!resultMap[description.language]) {
                        resultMap[description.language] = true;
                    }
                });

                expirations.forEach(function (expiration) {
                    if (!resultMap[expiration.language]) {
                        resultMap[expiration.language] = true;
                    }
                });
            });

            return Object.keys(resultMap).map(function (key) {
                var languageLabel = LOCALE_LANGUAGE_MAP[key];
                return {
                    name: languageLabel ? languageLabel : key,
                    value: key,
                };
            });
        }

        function getCookiesInstanceLanguageMap () {


            return vm.cookiesInstance.map(function (cookie) {
                return getLanguageMap(cookie);
            });
        }

        function updateAllCookieEntriesLanguage () {
            vm.cookiesInstance.forEach(function (cookie, index) {
                var originCookie = vm.originCookies.find(function (o) {
                    return o.id === cookie.id;
                });

                if (!originCookie) {
                    return;
                }

                var languageArrays = getLanguageArrays(vm.cookiesInstanceLanguageMap[index]);
                vm.cookiesInstance[index][COOKIE_FIELDS.expirations] = languageArrays[COOKIE_FIELDS.expirations];
                vm.cookiesInstance[index][COOKIE_FIELDS.descriptions] = languageArrays[COOKIE_FIELDS.descriptions];

                var existedInToBeUpdatedIndex = vm.toBeUpdated.findIndex(function (existed) {
                    return existed.id === cookie.id;
                });

                if (existedInToBeUpdatedIndex > -1) {
                    // Remove the cookie from toBeUpdated array if its value is changed back to its original
                    if (angular.equals(cookie, originCookie)) {
                        vm.toBeUpdated.splice(existedInToBeUpdatedIndex, 1);
                    }
                } else {
                    // Add the cookie to toBeUpdated array if its value has been changed
                    if (!angular.equals(cookie, originCookie)) {
                        vm.toBeUpdated.push(cookie);
                    }
                }

                if (vm.toBeUpdated.length) {
                    vm.cookieOverviewForm.$setDirty();
                }
            });
        }

        function getSelectedLanguageFieldText (cookie, fieldName) {
            if (!fieldName || !cookie[fieldName] || !cookie[fieldName].length) {
                return '';
            }

            var match = cookie[fieldName].find(function (entry) {
                return entry.language === vm.selectedLanguage;
            });

            if (match) {
                return match.text;
            }

            return '';
        }
    }
})();
