import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyRules', {
        templateUrl: 'app/forms/global-policy/steps/rules/rules.html',
        controller: PolicyRulesController,
        controllerAs: 'vm',
        bindings: {
            policy: '=',
            boxTab: '=',
            containerForm: '=',
        },
    });

    PolicyRulesController.$inject = [
        'ng2PolicyFormHelperService',
        '$scope',
        'FORM_CONSTANT',
        'ng2MonEventsService',
        '$element',
        '$window',
        '$timeout',
        'gettextCatalog',
    ];
    /* @ngInject */
    function PolicyRulesController (
        ng2PolicyFormHelperService,
        $scope,
        FORM_CONSTANT,
        ng2MonEventsService,
        $element,
        $window,
        $timeout,
        gettextCatalog,
    ) {
        var vm = this;
        var listenDNDRuleCandidate;
        var listenConfirmDNDRuleCandidate;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.editRule = editRule;
        vm.deleteRule = deleteRule;
        vm.findIndexOfElement = findIndexOfElement;
        vm.ruleDropped = ruleDropped;
        vm.getOperatorTranslation = getOperatorTranslation;
        vm.isRuleTypetext = ng2PolicyFormHelperService.isRuleTypeText;
        vm.onElementSelect = onElementSelect;
        vm.onAddRuleToPolicyClick = onAddRuleToPolicyClick;
        vm.hideElementsDropdown = hideElementsDropdown;
        vm.dndRuleCandidate = undefined;
        vm.onDropdownMenuItemSelect = onDropdownMenuItemSelect;

        var RULE_POLICY_DROPDOWN_MENU_WIDTH = 210;
        var RULE_POLICY_DROPDOWN_MENU_HEIGHT = 275;
        var RULE_POLICY_DROPDOWN_MENU_OFFSET = 10;

        function activate () {
            vm.policy.rules_operator = vm.policy.rules_operator || 'or';
            vm.initialized = false;
            vm.showElementsDropdown = false;
            vm.policyRulesMenuStyleObj = {};

            setupListeners();
            setupWatchers();
            vm.tooltip = gettextCatalog.getString('Click here or drag a rule to this area');
        }

        function setupListeners () {
            vm.hoverEventId = ng2MonEventsService.addListener('dropZoneHoverFalse', function () {
                vm.hoveredRule = false;
            });

            vm.unHoverEventId = ng2MonEventsService.addListener('dropZoneHoverTrue', function () {
                vm.hoveredRule = true;
            });

            listenDNDRuleCandidate = ng2MonEventsService.addListener('addDNDRuleCandidate', function (candidate) {
                vm.dndRuleCandidate = candidate;

                if (candidate) {
                    $timeout(function () {
                        ng2MonEventsService.run('scrollToDropzone');
                    });
                }
            });

            listenConfirmDNDRuleCandidate = ng2MonEventsService.addListener('confirmDNDRuleCandidate', function () {
                if (vm.dndRuleCandidate) {
                    vm.policy.rules.push({ type: vm.dndRuleCandidate.type });
                    ruleDropped(vm.dndRuleCandidate, 'copy');
                }
            });
        }

        function setupWatchers () {
            $scope.$watch('vm.policy.subject', function () {
                getElements();
            });
        }

        function editRule (rule, index) {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY_DIALOG_RULE,
                data: { rule: rule },
            };
            const callback = function (editedRule) {
                if (angular.isObject(editedRule)) {
                    vm.policy.rules[index] = editedRule;
                    vm.containerForm.$setDirty();
                } else {
                    if (!rule.hasOwnProperty('name') || rule.name === '' || rule.name === null || rule.name === undefined) {
                        // Delete rule if no name is defined
                        deleteRule(index, true);
                    }
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function ruleDropped (item, dropEffect) {
            var index = 0; // The documentation is lying, the index seems to be a counter for the array instead, so we search manually instead
            for (var i = 0; i < vm.policy.rules.length; i++) {
                if (vm.policy.rules[i].type === item.type && vm.policy.rules[i].name === item.name) {
                    index = i;
                }
            }

            if (dropEffect === 'copy') {
                editRule(item, index);
                return item;
            }
            vm.dndRuleCandidate = undefined;
        }

        function deleteRule (index, skipSetFormDirty) {
            vm.policy.rules.splice(index, 1);
            if (!skipSetFormDirty) {
                vm.containerForm.$setDirty();
            }
        }

        function getOperatorTranslation (rule) {
            ng2PolicyFormHelperService.setRuleType(rule.type);
            var operator = ng2PolicyFormHelperService.getOptionsByRuleType().find(function (operator) {
                return operator.type === rule.operator;
            });

            return angular.isUndefined(operator) ? '' : operator.translation;
        }

        function getElements () {
            ng2PolicyFormHelperService.setPolicySubject(vm.policy.subject);
            vm.elements = ng2PolicyFormHelperService.getSubjectTypeOptions();
            vm.monMenuItems = [];
            for (var i = 0; i < vm.elements.length; i++) {
                vm.monMenuItems.push({
                    icons: vm.elements[i].rawIcons,
                    text: vm.elements[i].translation,
                    id: i,
                });
            }
            vm.policy.rules = vm.policy.rules.filter(function (rule) {
                return findIndexOfElement(rule.type) > -1;
            });
        }

        function findIndexOfElement (ruleType) {
            var result = -1;
            for (var i = 0; i < vm.elements.length; i++) {
                if (vm.elements[i].type === ruleType) {
                    result = i;
                    break;
                }
            }
            return result;
        }

        function onAddRuleToPolicyClick ($event) {
            if (vm.showElementsDropdown) {
                return;
            }
            vm.boxTab = 'rule';
            vm.policyRulesMenuStyleObj = calculateRuleMenuPosition($event.originalEvent);
            vm.showElementsDropdown = true;
        }

        function hideElementsDropdown () {
            vm.showElementsDropdown = false;
        }

        function onElementSelect (data) {
            hideElementsDropdown();
            if (!data) {
                return;
            }
            var element = vm.elements[data.id];
            if (element) {
                vm.policy.rules.push({ type: element.type });
                ruleDropped({ type: element.type }, 'copy');
            }
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.hoverEventId);
            ng2MonEventsService.remove(vm.unHoverEventId);
            ng2MonEventsService.remove(listenDNDRuleCandidate);
            ng2MonEventsService.remove(listenConfirmDNDRuleCandidate);
        }

        function calculateRuleMenuPosition (nativeEvent) {
            if (!$element[0]) {
                return {};
            }
            var el = $element[0].querySelector('#rulePolicyMenu');
            if (!el) {
                return {};
            }
            var rect = el.getBoundingClientRect();
            var left = nativeEvent.clientX - rect.left + RULE_POLICY_DROPDOWN_MENU_OFFSET;
            var top = nativeEvent.clientY - rect.top + RULE_POLICY_DROPDOWN_MENU_OFFSET;
            if (nativeEvent.clientX + RULE_POLICY_DROPDOWN_MENU_WIDTH + RULE_POLICY_DROPDOWN_MENU_OFFSET > $window.innerWidth) {
                left = rect.width - RULE_POLICY_DROPDOWN_MENU_WIDTH;
            }
            if (nativeEvent.clientY + RULE_POLICY_DROPDOWN_MENU_HEIGHT > $window.innerHeight) {
                top = top - RULE_POLICY_DROPDOWN_MENU_OFFSET * 2 - RULE_POLICY_DROPDOWN_MENU_HEIGHT;
            }
            return {
                left: left + 'px',
                top: top + 'px',
                width: RULE_POLICY_DROPDOWN_MENU_WIDTH + 'px',
                height: RULE_POLICY_DROPDOWN_MENU_HEIGHT + 'px',
            };
        }

        function onDropdownMenuItemSelect ($event) {
            vm.hideElementsDropdown();
            vm.onElementSelect($event.data);
        }
    }
})();
