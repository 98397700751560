import { PageFixType } from '@client/app/services/api/pagefix-repo/page-fix-repo.service';


type PageFixModel = { word: string };

export class PageFixMisspellingUtil {
    model?: PageFixModel;
    pageFix?: PageFixType;
    misspelling: string | string[] = '';
    correctSpelling = '';

    constructor () {}

    setModel (model: PageFixModel): void {
        this.model = model;
    }

    setPageFix (pageFix): void {
        this.pageFix = pageFix;
    }

    getPageFix (): PageFixType | undefined {
        return this.pageFix;
    }

    setupSettings (): string | void {
        if (this.pageFix) {
            this.misspelling = this.pageFix.custom_selector || '';
            this.correctSpelling = this.pageFix.change.value || '';
        } else if (this.model) {
            this.misspelling = this.model.word || '';
        }
    }

    getMisspelling (): string | string[] {
        return this.misspelling;
    }

    getCorrectSpelling (): string {
        return this.correctSpelling;
    }
}
