import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActiveDialog, PromptOptions } from '@monsido/angular-shared-components';

export class TempUser {
    email: string;
    first_name: string;
    last_name: string;
    id?: number;

    constructor (user: TempUser) {
        this.email = user.email;
        this.first_name = user.first_name;
        this.last_name = user.last_name;
        if (user.id) {
            this.id = user.id;
        }
    }
}

@Component({
    selector: 'mon-schedule-report-admin-non-users',
    templateUrl: './schedule-report-admin-non-users.component.html',
    styleUrls: ['./schedule-report-admin-non-users.component.scss'],
})
export class ScheduleReportAdminNonUsersComponent {
    @ViewChild('tempUserForm', { static: false }) tempUserForm?: NgForm;
    selectedTempUsers: TempUser[] = [];
    firstName = '';
    lastName = '';
    email = '';
    promptOptions: PromptOptions = {
        parentSelector: '#common-dialog-wrapper',
    };

    constructor (private activeDialog: ActiveDialog) {}

    selectTempUser (): void {
        if (this.tempUserForm?.valid) {
            this.selectedTempUsers.push(new TempUser({
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
            }));
            this.resetForm();
        }
    }

    removeTempUser (index: number): void {
        this.selectedTempUsers.splice(index, 1);
    }

    close (): void {
        this.activeDialog.close({ users: this.selectedTempUsers });
    }


    private resetForm (): void {
        this.tempUserForm?.resetForm();
    }
}
