import { Component, Input, OnInit } from '@angular/core';
import { ActiveDialog, PromptOptions } from '@monsido/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { DialogOptionsAdapter } from '@monsido/core/modules/dialog-container/dialog-container.component';
import { SessionService } from '@monsido/core/session/session.service';
import { DomainGroupMembersEntity, DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { User } from '@monsido/modules/models/api/user';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ScheduleReportAdminNonUsersComponent, TempUser } from '../schedule-report-admin-non-users/schedule-report-admin-non-users.component';
import { UserRepoService } from '@client/app/services/api/user-repo/user-repo.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Domain } from '@monsido/modules/models/api/domain';
import { DomainRepoService } from '@client/app/services/api/domain-repo/domain-repo.service';
import { DomainGroup } from '@monsido/modules/models/api/domain-group';

@Component({
    selector: 'mon-schedule-report-admin-users',
    templateUrl: './schedule-report-admin-users.component.html',
    styleUrls: ['./schedule-report-admin-users.component.scss'],
})
export class ScheduleReportAdminUsersComponent implements OnInit {
    @Input() users: User[] = [];
    @Input() entityId: number | undefined = undefined;
    @Input() domainType: string | undefined = undefined;
    @Input() domainId: number | undefined = undefined;

    domain?: Domain;
    currentUser?: User;
    domainGroup?: DomainGroup;
    userList: MonTableCollection<User> = [];
    allUsers: Array<User[ 'user' ]> = [];
    selectedUsers: Record<number, boolean> = {};
    disabledUsers: Record<number, boolean> = {};
    selectedTempUsers: TempUser[] = [];
    loading = false;
    promptOptions: PromptOptions = {
        parentSelector: '#common-dialog-wrapper',
    };

    usersPage = 1;
    usersPageSize = 10;
    usersSearchTerm = '';

    constructor (
        private userRepoService: UserRepoService,
        private sessionService: SessionService,
        private eventsService: MonEventService,
        private activeDialog: ActiveDialog,
        private domainRepoService: DomainRepoService,
    ) {}

    async ngOnInit (): Promise<void> {
        if (this.sessionService.me) {
            this.currentUser = this.sessionService.me;
        }

        this.selectedTempUsers = this.users?.filter(user => user.user === null || user.user === undefined) || [];
        this.allUsers = this.users?.map(user => {
            if (user.user) {
                return user.user;
            }
        });
        if (this.domainType === 'Domain') {
            this.domain = await this.domainRepoService.get(Number(this.entityId));
        }

        if (this.domainType !== 'Domain') {
            this.domainGroup = await this.domainRepoService.getDomainGroup(Number(this.domainId), Number(this.entityId));
        }
        this.getUsers();
        this.setupUsers();
    }

    async onUsersSearch (searchTerm: string): Promise<void> {
        this.usersSearchTerm = searchTerm;
        this.usersPage = 1;
        this.getUsers();
    }

    async onUsersPageChange (page: number): Promise<void> {
        this.usersPage = page;
        this.getUsers();
    }

    async onUsersPageSizeChange (pageSize: number): Promise<void> {
        this.usersPage = 1;
        this.usersPageSize = pageSize;
        this.getUsers();

    }

    setupUsers ():void {
        let domainUsers: Array<DomainGroupMembersEntity | DomainUsersEntity>;

        if (this.domainType === 'Domain' && this.domain?.domain_users) {
            domainUsers = this.domain?.domain_users;
        } else {
            domainUsers = <DomainGroupMembersEntity[]> this.domainGroup?.domain_group_members;
        }
        if (Array.isArray(domainUsers)) {
            for (const domainUser of domainUsers) {
                this.disabledUsers[domainUser.user_id] = domainUser.visible;
            }
        }

        this.users.forEach(user => {
            if (user.user?.id) {
                this.selectedUsers[user.user.id] = true;
            }
        });
    }

    getUsers (): void {
        this.loading = true;

        this.userRepoService.getAll({
            page: this.usersPage,
            page_size: this.usersPageSize,
            search: this.usersSearchTerm,
        }).then(usersCollection => {
            const { total, currentPage, perPage } = usersCollection;

            this.userList = usersCollection;

            usersCollection.forEach(user => {
                if (this.allUsers.indexOf(user) === -1) {
                    this.allUsers.push(user);
                }
            });

            this.userList.total = total;
            this.userList.currentPage = currentPage;
            this.userList.perPage = perPage;
        })
            .finally(() => this.loading = false);


    }

    removeTempUser (index: number): void {
        this.selectedTempUsers.splice(index, 1);
    }

    openNonUserDialog (): void {
        const params: DialogOptionsAdapter<ScheduleReportAdminNonUsersComponent> = {
            component: ScheduleReportAdminNonUsersComponent,
            dialogOptions: {
                size: 'md',
                cb: (result: Record<string, TempUser>): void => {
                    if (result?.users) {
                        this.selectedTempUsers = this.selectedTempUsers.concat(result.users);
                    }
                },
            },
        };

        this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

    close (): void {
        const users: Array<User | TempUser> = [];
        for (const userId in this.selectedUsers) {
            if (this.selectedUsers[userId]) {
                const foundUser = this.allUsers.find(user => user?.id === Number(userId)) || null;
                if (foundUser) {
                    users.push(new User({
                        email: foundUser.email,
                        first_name: foundUser.first_name,
                        last_name: foundUser.last_name,
                        user: foundUser as Partial<User>,
                    } as User));
                }
            }
        }
        this.selectedTempUsers.forEach(user => users.push(new User(user as User)));
        this.activeDialog.close({ users: users });
    }
}
