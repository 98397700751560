import { Injectable } from '@angular/core';

import { DatePipe, DecimalPipe } from '@angular/common';
import { HistoryHelperService } from '@client/app/blocks/helpers/history.helper.service';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class ExporterAllParserService {
    constructor (
        private historyHelperService: HistoryHelperService,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
    ) {}

    private getHeadlines (): string[] {
        return [
            this.translateService.getString('Crawled at'),
            this.translateService.getString('Pages with Accessibility Issues'),
            this.translateService.getString('Pages with SEO Opportunities'),
            this.translateService.getString('Pages with QA Issues'),
            this.translateService.getString('Pages with Policy Issues'),
            this.translateService.getString('Pages crawled'),
            this.translateService.getString('Documents crawled'),
            this.translateService.getString('Changes since previous crawl'),
        ];
    }

    parse (data: any[]): string[][] {
        const csv: string[][] = [];
        const issuesStack = ['page_count', 'documents_count'];
        csv.push(this.getHeadlines());

        for (const item of data) {
            const entry: (string | number)[] = [
                this.datePipe.transform(item.post_processing_done_at || item.date, 'MMM d, y, h:mm a') || '',
                item.pages_with_accessibility_errors_count,
                item.pages_with_seo_errors_count,
                item.pages_with_notifications_count,
                item.pages_with_policy_matches_count,
                item.page_count,
                item.documents_count,
                `${this.getCaret(item.id, data, issuesStack)} ${this.formatPercentage(
                    this.historyHelperService.getChangePercentageValue(item.id, data, issuesStack) / 100,
                )}`,
            ];
            csv.push(entry.map(String));
        }
        return csv;
    }

    private getCaret (id: number, data: any[], issuesStack: string[]): string {
        const value = this.historyHelperService.getChangeValue(id, data, issuesStack);
        if (value > 0) {
            return String.fromCharCode(94);
        } else if (value < 0) {
            return 'ˇ';
        }
        return '';
    }

    private formatPercentage (value: number): string {
        return this.decimalPipe.transform(value, '1.0-2') + '%';
    }
}
