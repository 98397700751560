import { NgModule } from '@angular/core';
import { DialogModule, StorageModule } from '@monsido/angular-shared-components';
import { SessionModule } from '@monsido/core/session/session.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { MonTranslationModule } from '@monsido/core/translation/translation.module';
import { DialogContainerModule } from './modules/dialog-container/dialog-container.module';
import { ParamModule } from './param/param.module';

@NgModule({
    providers: [],
    imports: [StorageModule, SessionModule, PipesModule, MonTranslationModule, DialogModule, DialogContainerModule, ParamModule],
    exports: [StorageModule, SessionModule, PipesModule, MonTranslationModule, DialogModule, DialogContainerModule, ParamModule],
    declarations: [],
})
export class CoreModule {}
