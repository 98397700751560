import { Component, OnDestroy, OnInit } from '@angular/core';
import { SortType } from '@monsido/angular-shared-components';
import { SessionService } from '@monsido/core/session/session.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { ParamService } from '@monsido/core/param/param.service';
import { MonTableCollection } from 'ng2/models/table-collection.interface';
import { TransitionService } from '@uirouter/core';
import { cloneDeep, isEqual } from 'lodash';
import { Domain } from '@monsido/modules/models/api/domain';
import { StatisticsEventsRepoService } from './services/statistics-events/statistics-events.repo.service';
import { StatisticsEventsAction, StatisticsEventsCategoryOrName } from '@monsido/modules/statistics/models/statistics-events.type';
import { STATISTICS } from '@monsido/modules/statistics/statistics.constant';
import { DEFAULTS } from '@monsido/ng2/core/constants/defaults.constant';
import { TranslateService } from '@client/app/services/translate/translate.service';

enum EventTab {
    CATEGORIES = 0,
    ACTIONS = 1,
    NAMES = 2,
}

type StatisticsEvents = StatisticsEventsCategoryOrName | StatisticsEventsAction;
type Header = {
    columnName: string;
    field: string;
};

@Component({
    selector: 'mon-statistics-events',
    templateUrl: './statistics-events.component.html',
    styleUrls: ['./statistics-events.component.scss'],
})
export class StatisticsEventsComponent implements OnInit, OnDestroy {
    loading: boolean = false;
    tabs: string[] = [];
    selectedTab: string = '';
    selectedTabIndex$: BehaviorSubject<number>;
    selectedDateRange$: BehaviorSubject<Record<string, moment.Moment>> = new BehaviorSubject({});
    getEventsParams$: BehaviorSubject<{
        page: number,
        limit: number,
        sortBy: string,
        sortDirection: SortType
    }>;
    onDestroy$: Subject<null> = new Subject();
    selectedDateRange: Record<string, moment.Moment> = {};
    search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    transitionUnsubscribe: unknown;
    rawEvents: MonTableCollection<StatisticsEvents> = [];
    headers: Header[][] = [];
    eventsMap?: Map<string, StatisticsEvents[]>;
    domain: Domain | null = null;

    defaultTabIndex: number;
    readonly EventTab = EventTab;

    monHref: string = 'https://help.monsido.com/en/articles/6499385-how-to-set-up-event-tracking';

    dateFormat = DEFAULTS.DATE_FORMAT;

    exports = [
        {
            name: this.translateService.getString('Event categories'),
            data: {
                category: 'event_categories',
                category_ref: '',
            },
        },
        {
            name: this.translateService.getString('Event actions'),
            data: {
                category: 'event_actions',
                category_ref: '',
            },
        },
        {
            name: this.translateService.getString('Event names'),
            data: {
                category: 'event_names',
                category_ref: '',
            },
        },
    ];

    constructor (
        private translateService: TranslateService,
        private statisticsEventsRepo: StatisticsEventsRepoService,
        private sessionService: SessionService,
        private paramService: ParamService,
        private transitionService: TransitionService,
    ) {
        this.tabs[EventTab.CATEGORIES] = this.translateService.getString('Categories');
        this.tabs[EventTab.ACTIONS] = this.translateService.getString('Actions');
        this.tabs[EventTab.NAMES] = this.translateService.getString('Names');

        const categoryHeader = {
            columnName: this.translateService.getString('Category'),
            field: 'event_category',
        };
        const actionHeader = {
            columnName: this.translateService.getString('Action'),
            field: 'event_action',
        };
        const nameHeader = {
            columnName: this.translateService.getString('Name'),
            field: 'event_name',
        };
        const eventCountHeader = {
            columnName: this.translateService.getString('Events'),
            field: 'event_count',
        };

        this.headers[EventTab.CATEGORIES] = [categoryHeader, actionHeader, eventCountHeader];
        this.headers[EventTab.ACTIONS] = [actionHeader, nameHeader, eventCountHeader];
        this.headers[EventTab.NAMES] = [nameHeader, actionHeader, eventCountHeader];

        const { tab, from, to, page, limit, search } = this.paramService.getParams();
        const { sortDirection } = this.paramService.getParams();
        this.defaultTabIndex = tab && !isNaN(Number(tab)) ? Number(tab) : EventTab.CATEGORIES;
        this.search$.next(search);

        this.selectedTabIndex$ = new BehaviorSubject(this.defaultTabIndex);

        const start = moment(from, STATISTICS.DATE_FORMAT);
        const end = moment(to, STATISTICS.DATE_FORMAT);
        const today = moment();
        if (start.isValid() && end.isValid() && start <= today && end <= today) {
            this.selectedDateRange$.next({
                startDate: start,
                endDate: end,
            });
        } else {
            this.selectedDateRange$.next({
                startDate: moment(),
                endDate: moment(),
            });
        }

        this.getEventsParams$ = new BehaviorSubject({
            page: Number(page),
            limit: Number(limit),
            sortBy: '',
            sortDirection: sortDirection as SortType,
        });

    }

    ngOnInit (): void {
        this.domain = this.sessionService.domain;
        combineLatest([this.selectedTabIndex$, this.search$, this.getEventsParams$, this.selectedDateRange$]).pipe(
            distinctUntilChanged(isEqual),
            tap(([selectedTabIndex]) => {
                if (this.selectedTab !== this.tabs[selectedTabIndex]) {
                    this.selectedTab = this.tabs[selectedTabIndex];
                }
            }),
            tap(() => this.clearEvents),
            debounceTime(100),
            tap(() => {
                this.getEvents();
                this.updateURLParams();
            }),
            takeUntil(this.onDestroy$),
        )
            .subscribe();
        this.transitionUnsubscribe = this.transitionService.onSuccess(
            { retained: 'base.customer.domain.statistics.pages.events' },
            (transition) => {
                const { search, tab } = transition.targetState().params();
                this.search$.next(search);
                this.selectedTabIndex$.next(Number(tab));
                this.getEventsParams$.next({
                    ...this.getEventsParams$.value,
                    sortBy: this.headers[tab][0].field,
                });
                this.defaultTabIndex = Number(tab);
            },
        );
    }

    ngOnDestroy (): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        if (this.transitionUnsubscribe && typeof this.transitionUnsubscribe === 'function') {
            this.transitionUnsubscribe();
        }
    }

    onTabSelected (tabIndex: number): void {
        this.getEventsParams$.value.page = 1;
        this.getEventsParams$.value.sortBy = this.headers[tabIndex][0].field;
        this.selectedTabIndex$.next(tabIndex);
    }

    onSelectedDateRange (date: Record<string, moment.Moment>): void {
        if (date.startDate !== undefined && date.endDate !== undefined) {
            this.selectedDateRange$.next(cloneDeep(date));
            this.setExportCategoryRef(date.startDate.format(STATISTICS.DATE_FORMAT), date.endDate.format(STATISTICS.DATE_FORMAT));
        }
    }

    clearEvents (): void {
        this.rawEvents = [];
    }

    async getEvents (): Promise<void> {
        this.loading = true;
        const domainId = this.sessionService.getDomainId(this.sessionService.domain as unknown as Record<string, string | number>);
        let events: StatisticsEvents[] = [];
        const { page, limit, sortBy, sortDirection } = this.getEventsParams$.value;

        const params: Record<string, string | number> = {
            page,
            limit,
            from: this.selectedDateRange$.value.startDate?.format(STATISTICS.DATE_FORMAT),
            to: this.selectedDateRange$.value.endDate?.format(STATISTICS.DATE_FORMAT),
            search: this.search$.value,
        };

        if (sortBy) {
            params.sort_by = sortBy;
        }

        if (sortDirection) {
            params.sort_dir = sortDirection;
        }

        this.setExportCategoryRef(
            this.selectedDateRange$.value.startDate?.format(STATISTICS.DATE_FORMAT),
            this.selectedDateRange$.value.endDate?.format(STATISTICS.DATE_FORMAT),
        );

        if (domainId != null) {
            switch (this.selectedTabIndex$.value) {
                case EventTab.CATEGORIES:
                    events = await this.statisticsEventsRepo.getEventsCategories(domainId, params);
                    this.eventsMap = this.createEventsMap(events, this.headers[EventTab.CATEGORIES][0].field);
                    break;
                case EventTab.ACTIONS:
                    events = await this.statisticsEventsRepo.getEventsActions(domainId, params);
                    this.eventsMap = this.createEventsMap(events, this.headers[EventTab.ACTIONS][0].field);
                    break;
                case EventTab.NAMES:
                    events = await this.statisticsEventsRepo.getEventsNames(domainId, params);
                    this.eventsMap = this.createEventsMap(events, this.headers[EventTab.NAMES][0].field);
                    break;
            }
        }

        const sortDir = sortDirection === 'asc' ? 1 : -1;
        if (Array.isArray(events) && events.length > 0) {
            const eventsMapArray = Array.from(this.eventsMap ? this.eventsMap.entries() : []);

            if (sortBy === this.headers[this.selectedTabIndex$.value][0].field) {
                eventsMapArray.sort((a, b) => {
                    return a[0] < b[0] ? sortDir * -1 : sortDir;
                });
            }

            this.rawEvents = events;
            this.rawEvents.total = events.length;
        }

        this.loading = false;
    }

    onPageChange (page: number): void {
        this.getEventsParams$.next({
            ...this.getEventsParams$.value,
            page,
        });
    }

    onPageSizeChange (pageSize: number): void {
        this.getEventsParams$.next({
            ...this.getEventsParams$.value,
            page: 1,
            limit: pageSize,
        });
    }

    onSortEvents (sortPayload: Record<string, string>): void {
        const { by, direction } = sortPayload;
        this.getEventsParams$.next({
            ...this.getEventsParams$.value,
            page: 1,
            sortBy: by,
            sortDirection: direction as SortType,
        });
    }

    private createEventsMap (events: StatisticsEvents[] = [], fieldName: string): Map<string, StatisticsEvents[]> {
        const eventsMapByFieldName = new Map();
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const existedInMap = eventsMapByFieldName.get(event[fieldName]);
            if (existedInMap) {
                existedInMap.push(event);
            } else {
                eventsMapByFieldName.set(event[fieldName], [event]);
            }
        }
        return eventsMapByFieldName;
    }

    private updateURLParams (): void {
        this.paramService.setParams({
            tab: this.selectedTabIndex$.value,
            limit: this.getEventsParams$.value.limit,
            page: this.getEventsParams$.value.page,
            sortBy: this.getEventsParams$.value.sortBy,
            sortDirection: this.getEventsParams$.value.sortDirection,
            from: this.selectedDateRange$.value.startDate?.format(STATISTICS.DATE_FORMAT),
            to: this.selectedDateRange$.value.endDate?.format(STATISTICS.DATE_FORMAT),
            search: this.search$.value,
        });
    }

    private setExportCategoryRef (start: string, end: string): void {
        this.exports.forEach(eventsExport =>
            eventsExport.data.category_ref = `${start},${end}`,
        );
    }
}
