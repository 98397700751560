import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { POLICY_FORM_HELPER_DB } from '@client/app/core/constants/policy-form-helper-db';


@Injectable({
    providedIn: 'root',
})
export class PolicyFormHelperService {
    private policySubject: string = '';
    private ruleType: string = '';
    private optionsBySubject = POLICY_FORM_HELPER_DB.SUBJECT_OPTIONS;
    private optionsByRuleType = POLICY_FORM_HELPER_DB.RULE_TYPE_OPTIONS;
    private icons = POLICY_FORM_HELPER_DB.ICONS;
    private rawIcons = POLICY_FORM_HELPER_DB.RAW_ICONS;
    private translations = POLICY_FORM_HELPER_DB.TRANSLATIONS;
    private subEntries = POLICY_FORM_HELPER_DB.RULE_TYPE_SUB_ENTRIES;

    constructor (private translateService: TranslateService) {}

    setPolicySubject (subject: string): string {
        this.policySubject = subject;
        return this.policySubject;
    }

    getSubjectTypeOptions (): any[] {
        const types = this.optionsBySubject[this.policySubject];
        if (types) {
            return types.map((type) => this.getSubjectTypeOption(type));
        } else {
            return [];
        }
    }

    getSubjectTypeOption (type: string): any {
        return {
            type: type,
            icons: this.icons.SUBJECT_OPTIONS[type],
            rawIcons: this.rawIcons.SUBJECT_OPTIONS[type],
            translation: this.translateService.getString(this.translations.SUBJECT_OPTIONS.NAME[type]),
            description: this.translateService.getString(this.translations.SUBJECT_OPTIONS.DESCRIPTION[type]),
        };
    }

    setRuleType (type: string): string {
        this.ruleType = type;
        return this.ruleType;
    }

    getOptionsByRuleType (): any[] {
        return this.optionsByRuleType[this.ruleType].map((type) => ({
            type: type,
            translation: this.translateService.getString(this.translations.RULE_TYPE_OPTIONS[type]),
        }));
    }

    getOptionsByRuleSize (): any[] {
        return [
            {
                name: this.translateService.getString('KB'),
                value: 'kb',
            },
            {
                name: this.translateService.getString('MB'),
                value: 'mb',
            },
        ];
    }

    getMenuDropdownOptions (source: () => any[], getLabel: (option: any) => string, handler: (option: any) => void): any[] {
        return source().map((option) => ({
            ...option,
            action: () => handler(option),
            label: getLabel(option),
        }));
    }

    getMenuDropdownOptionsByRuleType (handler: (option: any) => void): any[] {
        return this.getMenuDropdownOptions(this.getOptionsByRuleType.bind(this), (option) => option.translation, handler);
    }

    getMenuDropdownOptionsByRuleSize (handler: (option: any) => void): any[] {
        return this.getMenuDropdownOptions(this.getOptionsByRuleSize.bind(this), (option) => option.name, handler);
    }

    getRuleSubEntries (): any {
        return {
            allTxt: this.translateService.getString(this.translations.RULE_TYPE_SUB_ENTRIES.ALL[this.ruleType] || 'Select all'),
            labelTxt: this.translateService.getString(this.translations.RULE_TYPE_SUB_ENTRIES.LABEL[this.ruleType] || 'Type'),
            options: (this.subEntries[this.ruleType] || []).map((entry) => {
                entry.name = this.translateService.getString(entry.name);
                return entry;
            }),
        };
    }

    isRuleTypeText (ruleType: string): boolean {
        const ruleTypes = [
            'page_html',
            'page_text',
            'page_title',
            'header_text',
            'page_url',
            'image_text',
            'link_text',
            'link_url',
        ];
        return ruleTypes.includes(ruleType);
    }

    getSubjectTypeHelpBlock (ruleType: string): string {
        const helpBlock = this.translations.RULE_TYPE_HELP_BLOCKS[ruleType];
        if (typeof helpBlock === 'string') {
            return this.translateService.getString(helpBlock);
        }
        return '';
    }
}
