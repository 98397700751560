(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceOpportunitiesService
     * @description Page Performance Opportunity Category Service
     */
    angular
        .module('modules.page-performance.page-details')
        .service('PagePerformanceOpportunitiesService', PagePerformanceOpportunitiesService);

    PagePerformanceOpportunitiesService.$inject = [
        'PAGE_PERFORMANCE_CATEGORY',
        'ng2PagePerformancePageDetailsService',
    ];

    /* @ngInject*/
    function PagePerformanceOpportunitiesService (
        PAGE_PERFORMANCE_CATEGORY,
        ng2PagePerformancePageDetailsService,
    ) {
        return {
            getAudits: getAudits,
        };

        /**
         * @memberOf PagePerformanceDiagnosticsService
         * @desc Get Audits Collection
         * @return {Array}
         */
        function getAudits () {
            const report = getReport();
            if (report) {
                const auditRefIds = ng2PagePerformancePageDetailsService.getUnassignedAuditIds(report.categories[PAGE_PERFORMANCE_CATEGORY.PERFORMANCE].auditRefs);
                return Object.values(report.audits).filter((audit) => {
                    return audit.details && audit.details.type === 'opportunity' && auditRefIds.indexOf(audit.id) > -1;
                });
            }

            return [];
        }

        /**
         * @memberOf PagePerformanceDiagnosticsService
         * @desc Get the entire provided Report of a Page
         */
        function getReport () {
            return ng2PagePerformancePageDetailsService.getReport();
        }
    }
})();
