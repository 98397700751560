import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { Page } from '@monsido/modules/page-details/models';
import { QualityRepoSummaryType } from './quality-repo-summary.type';

type SummaryGetAllType = {
    page?: number;
    page_size?: number;
    group_id?: number;
    qa_errors?: boolean;
    sort_by?: string;
    sort_dir?: string;
};

@Injectable({
    providedIn: 'root',
})
export class QualityRepoService {

    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) { }

    getAll (params: SummaryGetAllType): Promise<QualityRepoSummaryType> {
        params = params || {};
        const groupId = this.sessionService.getGroupId(params);
        if (groupId != null) {
            params.group_id = groupId;
        }
        return this.apiService.get('domains/' + this.sessionService.getDomainId(params) + '/quality/pages', params);
    }

    getSummary (params: { domainId?: number, group_id?: number }): Promise<CollectionInterface<Page>> {
        params = params || {};
        const groupId = this.sessionService.getGroupId(params);
        if (groupId != null) {
            params.group_id = groupId;
        }
        return this.apiService.get('domains/' + this.sessionService.getDomainId(params) + '/quality/summary', params);
    }
}
