import { Injectable } from '@angular/core';
import { VIRTUAL_URLS } from '@client/app/modules/business-intelligence/constants/vitrual-urls';


@Injectable({
    providedIn: 'root',
})
export class VirtualUrlService {
    constructor () {}

    get (url: string, params: any): string {
        const virUrl = VIRTUAL_URLS[url];
        if (!virUrl) {
            throw new Error(`Virtual url is missing: ${url}`);
        }
        const newUrl = virUrl.url;
        if (newUrl) {
            return newUrl + this.getSubUrl(url, params);
        }
        return '';
    }

    private getSubUrl (url: string, params: any): string {
        return url === 'pageDetails' ? `${params.data.page.url} (${params.data.page.title})` : '';
    }

    getRef (params: any): string | undefined {
        if (params.data && params.data.ref) {
            return params.data.ref;
        }
        if (params.ref) {
            return params.ref;
        }
        return undefined;
    }

    getParams (): void {}

    getTitle (): void {}
}
