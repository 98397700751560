import { Injectable } from '@angular/core';

import { TranslateService } from '@client/app/services/translate/translate.service';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import { SessionService } from '@monsido/core/session/session.service';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityFiltersService {
    constructor (
        private translateService: TranslateService,
        private activeFeatureService: ActiveFeatureService,
        private sessionService: SessionService,
    ) {}

    getResponsibilityTranslation (): string {
        return this.translateService.getString('Responsibility');
    }

    getTypes (): Array<{ name: string; value: string }> {
        const types = [
            {
                name: this.translateService.getString('Error'),
                value: 'error',
            },
            {
                name: this.translateService.getString('Warning'),
                value: 'warning',
            },
            {
                name: this.translateService.getString('Review'),
                value: 'review',
            },
        ];
        if (this.canUseMobileGuidelines()) {
            types.push({
                name: this.translateService.getString('Mobile only'),
                value: 'mobile',
            });
        }
        return types;
    }

    getResponsibilities (): Array<{ name: string; value: string }> {
        return [
            {
                name: this.translateService.getString('Visual Design'),
                value: 'Visual Design',
            },
            {
                name: this.translateService.getString('UX Design'),
                value: 'UX Design',
            },
            {
                name: this.translateService.getString('Content Authoring'),
                value: 'Content Authoring',
            },
            {
                name: this.translateService.getString('Front-end Development'),
                value: 'Front-end Development',
            },
        ];
    }

    canUseMobileGuidelines (): boolean | undefined {
        return (
            this.activeFeatureService.isFeatureActive('mobile_guidelines') &&
            this.sessionService.domain?.features?.accessibility?.includes('mobile')
        );
    }
}
