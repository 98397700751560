import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ActionRunService } from '@client/app/modules/business-intelligence/run/action.service';
import { BiService } from '@client/app/modules/business-intelligence/services/bi.service';
import { VirtualUrlService } from '@client/app/modules/business-intelligence/services/virtual-urls.service';


export function init_app (actionRunService: ActionRunService) {
    return () => actionRunService.initializeApp();
}

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        VirtualUrlService,
        BiService, {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [ActionRunService],
            multi: true,
        }],
})


export class MonBiModule {}
