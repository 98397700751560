import { PAGE_FIX_SELECTOR_QUERIES } from '@client/app/modules/page-fix/constants/page-fix-selector-queries';
import { PageFixModelHelper } from '@client/app/modules/page-fix/services/model-helper/page-fix-model-helper';
import { PAGE_FIX_CHANGE_TYPES } from '@client/app/modules/page-fix/constants/page-fix-change-types';

export class PageFixBrokenLinkService {
    private model: any = {};
    private pageFix: any = {};
    private selector: string = '';
    private fixableId: number | null = null;

    constructor () {
    }

    setModel (model: any): void {
        this.model = model;
    }

    setPageFix (pageFix: any): void {
        this.pageFix = pageFix;
    }

    getPageFix (): any {
        return this.pageFix;
    }

    setFixableId (id: number): void {
        this.fixableId = id;
    }

    getFixableId (): number | null {
        return this.fixableId;
    }

    getSelector (): string {
        return this.selector;
    }

    parseQuerySelector (originalUrls: { url: string }[]): void {
        const collection = originalUrls.map((originalUrl) => this.getQuerySelector(originalUrl.url));
        this.selector = `${PAGE_FIX_SELECTOR_QUERIES.query_selector};${JSON.stringify(collection)}`;
    }

    getAttribute (): string {
        return this.isImageType() ? 'src' : 'href';
    }

    getChangeType (): string {
        return this.isImageType() ? `${PAGE_FIX_CHANGE_TYPES.attribute}:href` : `${PAGE_FIX_CHANGE_TYPES.attribute}:src`;
    }

    getLinkReplacement (): string {
        return this.pageFix ? this.pageFix.change.value : '';
    }

    private getQuerySelector (url: string): string {
        return this.isImageType()
            ? PageFixModelHelper.getSelectorValue(`<img src="${url}"/>`)
            : PageFixModelHelper.getSelectorValue(`<a href="${url}"></a>`);
    }

    private isImageType (): boolean {
        if (this.pageFix && Object.keys(this.pageFix).length > 0) {
            return this.pageFix.change.attribute === 'src';
        } else {
            let link = this.model;
            if (this.model.link) {
                link = this.model.link;
            }
            return link.type === 'image';
        }
    }
}
