import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Label } from '@monsido/angular-shared-components';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';

type LabelRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class LabelRepo extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }


    getLabels (params: LabelRepoRequestType = {}): Promise<Label[]> {
        params.page_size = params.page_size || 0;
        params = this.setGroupId(params);
        return this.apiService.get('labels', params);
    }

    createLabel (params: LabelRepoRequestType): Promise<void> {
        return this.apiService.post('labels', params);
    }

    deleteLabel (params: LabelRepoRequestType): Promise<void> {
        return this.apiService.delete(`labels/${params.label_id}`);
    }

    updateLabel (params: LabelRepoRequestType): Promise<void> {
        return this.apiService.patch(`labels/${params.label_id}`, params);
    }

    getDomainGroupsUsingLabel (labelId: number): Promise<Label[]> {
        return this.apiService.get(`labels/${labelId}/domains_with_blocking_groups`);
    }
}
