import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { DatePipe } from '@angular/common';
import { QAReadabilityService } from '@client/app/blocks/service/qa-readability/qareadability.service';

@Injectable({
    providedIn: 'root',
})
export class ExporterReadabilityParserService {
    constructor (
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private qaReadabilityService: QAReadabilityService,
    ) {}

    private getHeadlines (readabilityLevels: string[], readabilityTest: string): string[] {
        const headlines = [
            this.translateService.getString('Crawled at'),
            this.translateService.getString('Pages Crawled'),
        ];

        readabilityLevels.forEach(level => {
            headlines.push(this.qaReadabilityService.getTranslationScore(level, readabilityTest));
        });

        return headlines;
    }

    parse (data: any[]): string[][] {
        if (!data?.length) {
            return [];
        }

        const csv: string[][] = [];
        const readabilityTest = data[0].readability_test;
        const readabilityLevels = this.qaReadabilityService.getReadabilityLevels(readabilityTest);

        csv.push(this.getHeadlines(readabilityLevels, readabilityTest));

        for (const item of data) {
            const entry: (string | number)[] = [
                this.datePipe.transform(item.post_processing_done_at || item.date, 'MMM d, y, h:mm a') || '',
                (item.page_count + item.documents_count),
            ];

            for (const level of readabilityLevels) {
                entry.push(item.readability_distribution[level] || 0);
            }

            csv.push(entry.map(String));
        }

        return csv;
    }
}
