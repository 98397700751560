import { Injectable, inject } from '@angular/core';
import { PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY } from '../../constants/page-fix-accessibility-check-difficulity';
import { TranslateService } from '@client/app/services/translate/translate.service';

type DifficultyType = typeof PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY[keyof typeof PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY];

@Injectable({
    providedIn: 'root',
})
export class PageFixAccessibilityDifficultyService {
    private translateService = inject(TranslateService);
    private difficulties = [
        {
            name: this.translateService.getString('Easy'),
            value: PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.EASY,
            weight: 0,
        },
        {
            name: this.translateService.getString('Medium'),
            value: PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.MEDIUM,
            weight: 10,
        },
        {
            name: this.translateService.getString('Hard'),
            value: PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.HARD,
            weight: 20,
        },
    ];

    constructor () { }

    getDifficulty (difficultyKeyName: DifficultyType): { name: string, value: DifficultyType, weight: number } {
        return this.difficulties.find(difficulty => difficulty.value === difficultyKeyName) || this.difficulties[0];
    }
}
