(function () {
    'use strict';
    angular.module('app.components').component('tableDictionaryAll', {
        templateUrl: 'app/components/dictionary/all/all.html',
        controller: DictionaryController,
        controllerAs: 'vm',
        bindings: {
            showConfirm: '<?',
            selected: '=',
            callback: '&?',
            showIgnore: '<?',
            pageId: '<?',
        },
    });

    DictionaryController.$inject = [
        'ng2SpellingRepoService',
        '$q',
        'ng2LoadingService',
        'spellService',
        'gettextCatalog',
        'ng2MonUtilsService',
    ];

    /* @ngInject */
    function DictionaryController (
        ng2SpellingRepoService,
        $q,
        ng2LoadingService,
        spellService,
        gettextCatalog,
        ng2MonUtilsService,
    ) {
        var vm = this;
        vm.dropdownId = 'tableDictionaryAllDropdown-' + ng2MonUtilsService.getRandomString();

        vm.addToDictionary = addToDictionary;
        vm.addToAllDictionaries = addToAllDictionaries;
        vm.ignoreMisspellings = ignoreMisspellings;
        vm.confirmMisspelling = confirmMisspelling;
        vm.clearSelected = clearSelected;
        vm.ignoreMisspelling = ignoreMisspelling;
        vm.markMisspellingsAsFixed = markMisspellingsAsFixed;
        vm.markPageMisspellingsAsFixed = markPageMisspellingsAsFixed;

        vm.$onInit = activate;

        function activate () {
            vm.showConfirm = vm.showConfirm || false;
            vm.showIgnore = vm.showIgnore || false;
        }

        function clearSelected () {
            vm.selected = [];
        }

        function addToDictionary () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Adding word to dictionary'));
            angular.forEach(vm.selected, function (value) {
                chain = chain.then(function () {
                    return add({
                        word: value.word,
                        language: value.language,
                        classification: 'dictionary',
                    });
                }, angular.noop);
            });

            waitForDone(chain);
        }

        function markMisspellingsAsFixed () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Marking as fixed'));
            angular.forEach(vm.selected, function (value) {
                chain = chain.then(function () {
                    return spellService.destroySpellingError(value.id);
                }, angular.noop);
            });

            waitForDone(chain);
        }

        function markPageMisspellingsAsFixed () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Marking as fixed'));
            angular.forEach(vm.selected, function (value) {
                chain = chain.then(function () {
                    return spellService.destroyPageSpellingError(vm.pageId, value.id);
                }, angular.noop);
            });

            waitForDone(chain);
        }

        function ignoreMisspelling () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Adding words to ignored list'));
            if (vm.pageId) {
                angular.forEach(vm.selected, function (value) {
                    chain = chain.then(function () {
                        return ignoreMisspellingForPage(value.id);
                    }, angular.noop);
                });
            }

            waitForDone(chain);
        }

        function ignoreMisspellingForPage (errorId) {
            return spellService.updatePageIgnoreSpellingError(vm.pageId, errorId);
        }

        function addToAllDictionaries () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Adding words to dictionary'));
            angular.forEach(vm.selected, function (value) {
                chain = chain.then(function () {
                    return add({
                        word: value.word,
                        classification: 'dictionary',
                    });
                });
            });

            waitForDone(chain);
        }

        function ignoreMisspellings () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Adding words to ignored list'));

            angular.forEach(vm.selected, function (value) {
                chain = chain.then(function () {
                    return add({
                        word: value.word,
                        classification: 'ignore',
                    });
                });
            });

            waitForDone(chain);
        }

        function confirmMisspelling () {
            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait - Confirming misspelling'));

            angular.forEach(vm.selected, function (value) {
                chain = chain.then(function () {
                    return spellService.confirmWord(value.id, {});
                }, angular.noop);
            });

            waitForDone(chain);
        }

        function waitForDone (promise) {
            promise
                .then(function () {
                    if (vm.callback) {
                        vm.callback();
                    }
                }, angular.noop)
                .finally(function () {
                    vm.dialog.close();
                });
        }

        function add (params) {
            return ng2SpellingRepoService.addToDictionary(params);
        }
    }
})();
