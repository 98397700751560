import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { StateService, TransitionHookFn, UIRouterGlobals } from '@uirouter/core';
import { MonPromptService } from '@monsido/angular-shared-components';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MonNotificationsService } from '@monsido/core/services/notifications/mon-notifications.service';
import { DebugLogService } from '@monsido/core/services/debug-log/debug-log.service';
import { SessionService } from '@monsido/core/session/session.service';
import { debounce } from 'lodash';
import { InterceptorHelperService } from './interceptor-helper.service';
import { generateRandomString } from '@monsido/ng2/shared/utils';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { RequestAuxiliaryService } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';
import { environment } from '@client/environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private allreadyDialog: boolean = false;
    private resetNotifications: () => void;

    constructor (
        private ng2MonEventsService: MonEventService,
        private monPromptService: MonPromptService,
        private monNotificationsService: MonNotificationsService,
        private debugLogService: DebugLogService,
        private sessionService: SessionService,
        private stateService: StateService,
        private translateService: TranslateService,
        private uiRouterGlobals: UIRouterGlobals,
        private interceptorHelper: InterceptorHelperService,
        private requestAuxiliaryService: RequestAuxiliaryService,
    ) {
        this.resetNotifications = debounce(() => {
            this.monNotificationsService.reset();
        }, 1000);
    }

    intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let requestParams = request.params;

        const groupId = request.params.get('group_id');
        if (groupId === 'undefined') {
            requestParams = requestParams.delete('group_id');

            if (environment.env === 'development') {
                // eslint-disable-next-line no-console
                console.trace('group_id is undefined:', groupId);
            }
        }

        let ri = '';

        if (request.method === 'GET') {
            let search: string | null = requestParams.get('search');
            if (typeof search === 'string' && search.length === 0) {
                requestParams = requestParams.delete('search');
                search = null;
            }

            if (!this.requestAuxiliaryService.hasNoParseSearch(request.context)) {
                if (search) {
                    if (!(search[0] === '%') && !(search[search.length - 1] === '%')) {
                        // Don't set Percentage if the user already did
                        requestParams = requestParams.append('search', '%' + search + '%');
                    }
                }
            }
        }

        if (requestParams !== request.params) {
            request = request.clone({
                params: requestParams,
            });
        }

        if (!request.url.endsWith('.html')) {
            const requestTime = new Date();
            ri = generateRandomString();
            this.debugLogService.add(Object.assign(request, {
                ri,
                t: `${requestTime.toUTCString()}, ms: ${requestTime.getMilliseconds()}`,
            }), 'request');
        }

        return next.handle(request).pipe(
            tap((response: HttpResponse<unknown>) => {
                let url = '';
                if (response && response.type === HttpEventType.Response) {
                    url = typeof response.url === 'string' ? response.url : '';
                    if (['GET', 'POST', 'PATCH', 'DELETE'].includes(request.method)) {

                        const urlPath = url && String(url).split('/')
                            .pop() || '';

                        const isBIResponse = this.interceptorHelper.isBIResponse(urlPath);
                        const isNotificationResponse = this.interceptorHelper.isNotificationResponse(urlPath);
                        const isLogoutResponse = this.interceptorHelper.isLogoutResponse(urlPath);

                        if (!isBIResponse && !isNotificationResponse && !isLogoutResponse) {
                            this.resetNotifications();
                        }
                    }
                }
                if (!url.endsWith('.html')) {
                    const responseTime = new Date();
                    this.debugLogService.add(Object.assign(response, {
                        ri,
                        t: `${responseTime.toUTCString()}, ms: ${responseTime.getMilliseconds()}`,
                    }), 'response');
                }
            }),
            catchError((error: HttpErrorResponse) => {

                if (this.interceptorHelper.isBusinessIntelligenceError(request)) {
                    this.debugLogService.add(error, 'Business Intelligence responseError');
                    return EMPTY;
                }

                if (this.interceptorHelper.isNewReportPage404Error(error)) {
                    // API returns 404 for a newly added page. That is fine, the error will be handled by the page component.
                    return throwError(error);
                }

                if (this.interceptorHelper.isStatistics404Error(error)) {
                    this.debugLogService.add(error, 'Statistics 404 responseError');
                    return throwError(error);
                }

                switch (error.status) {
                    case 422:
                    case 400:
                        if (error.headers && !this.requestAuxiliaryService.hasNoGlobal(request.context)) {
                            let messages: string = '';
                            let params: string = '';
                            let errorMessage = error.message || '';

                            if (error) {
                                if ((request.body as Record<string, string>)?.auth_user_password) {
                                    this.monPromptService.alert(this.translateService.getString('Current password is incorrect please try again'));
                                    break;
                                } else {
                                    const errors = error.error?.errors;
                                    if (errors) {
                                        Object.entries(errors).forEach((entry) => {
                                            const key = entry[0];
                                            const value = entry[1];

                                            if (Array.isArray(value)) {
                                                value.forEach((valueParams) => {
                                                    if (params !== '') {
                                                        params += ', ';
                                                    }
                                                    params += valueParams;
                                                });
                                            } else {
                                                params = value as string;
                                            }

                                            const keyAsString = String(key).replace(/\[|\]/g, '');
                                            messages += keyAsString + ': ' + params + '\n';
                                        });
                                        errorMessage = '';
                                    }
                                }
                            }
                            if (!this.allreadyDialog) {
                                this.allreadyDialog = true;
                                this.monPromptService.alert(errorMessage + messages).finally(() => {
                                    this.allreadyDialog = false;
                                });

                            }
                        }
                        break;
                    case 401:
                        this.navigate401or403(error, request);
                        break;
                    case 403:
                        if (
                            !this.interceptorHelper.skipRedirectOn403(error) &&
                            !this.requestAuxiliaryService.hasNoRedirectOn403(request.context)
                        ) {
                            this.navigate401or403(error, request);
                            return EMPTY;
                        }
                        break;
                    case 404:
                        if (error) {
                            if (window.location.href.includes('issueOverlay=true') && this.interceptorHelper.isURLBelongsToIssueView(error.url || '')) {
                                return EMPTY;
                            }
                        }
                        this.defaultHandler(error, this.requestAuxiliaryService.hasNoGlobal(request.context));
                        break;
                    case 423:
                        if (!this.allreadyDialog) {
                            this.allreadyDialog = true;
                            this.monPromptService
                                .alert(this.translateService.getString('Account is under maintenance') +
                                '\n' +
                                this.translateService.getString('Please try again later'))
                                .then(() => {}, () => {})
                                .finally(() => {
                                    this.allreadyDialog = false;
                                });
                        }
                        break;
                    case 429:
                        break;
                    case 502:
                        if (!this.allreadyDialog) {
                            this.allreadyDialog = true;
                            this.monPromptService
                                .alert(
                                    this.translateService.getString('It seems like we are experience some issues with the page you are looking at.') +
                                    '\n' +
                                        this.translateService.getString('The Acquia Optimize team is notified and are working on the issue.') +
                                        '\n' +
                                        this.translateService.getString('Please contact the support if this issue keeps happening'),
                                )
                                .then(
                                    () => throwError(error),
                                    () => throwError(error),
                                )
                                .finally(() => {
                                    this.allreadyDialog = false;
                                });
                        }

                        break;
                    case 0:
                    case -1:
                        if (!this.allreadyDialog && error.statusText !== 'abort') {
                            this.allreadyDialog = true;
                            let box: Promise<string | void> | undefined;

                            if (window.navigator.onLine === false) {
                                this.allreadyDialog = true;
                                box = this.monPromptService
                                    .alert(
                                        this.translateService.getString('It seems like your computer is disconnected from the internet.') +
                                        '\n\n' +
                                        this.translateService.getString('Please refresh the browser when your computer is online again.'),
                                    )
                                    .then(
                                        () => {},
                                        () => {},
                                    );
                            } else {
                                // The notification request is polling in the background,
                                // it is ok if we do not inform users about its failure
                                if (!request.url.endsWith('api/notifications')) {
                                    box = this.monPromptService.alert(this.translateService.getString('An error has occurred - please try again'));
                                }
                            }

                            if (box) {
                                box.finally(() => {
                                    this.allreadyDialog = false;
                                });

                            }
                        }

                        break;
                    default:
                        this.defaultHandler(error, this.requestAuxiliaryService.hasNoGlobal(request.context));
                        break;
                }
                return throwError(error);
            }),
        );
    }

    private defaultHandler (error: HttpErrorResponse, noGlobal?: boolean): void {
        if (error.headers && !noGlobal) {
            if (error.error && error.error.message) {
                if (!this.allreadyDialog) {
                    this.allreadyDialog = true;
                    this.monPromptService.alert(error.error.message).then(
                        () => throwError(error),
                        () => throwError(error),
                    )
                        .finally(() => {
                            this.allreadyDialog = false;
                        });
                }
            } else if (error.message) {
                if (!this.allreadyDialog) {
                    this.allreadyDialog = true;
                    this.monPromptService.alert(error.message).then(
                        () => throwError(error),
                        () => throwError(error),
                    )
                        .finally(() => {
                            this.allreadyDialog = false;
                        });
                }
            }
        }
    }

    private async navigate401or403 (responseObject: HttpErrorResponse, request: HttpRequest<unknown>): Promise<void> {
        if (!responseObject || responseObject.message === 'Account not found') {
            this.onTransitionFinished(() => {
                this.stateService.transitionTo('base.dashboard');
            });
            return;
        }

        let pathname = '';

        try {
            pathname = new URL(request.url).pathname;
        } catch (_e) {}

        let customerId: number | null;

        if (/\/api\/domains\/[\d]+$/.test(pathname)) {
            customerId = this.sessionService.customer && this.sessionService.customer.id;
            if (customerId) {
                this.onTransitionFinished(() => {
                    this.stateService.transitionTo('base.customer.dashboard', { customerId });
                });
                return;
            }

            this.onTransitionFinished(() => {
                this.stateService.transitionTo('base.dashboard');
            });
            return;
        }

        await this.ng2MonEventsService.run('refreshDomain');

        const domainId = this.sessionService.domain && this.sessionService.domain.id;
        customerId = this.sessionService.customer && this.sessionService.customer.id;


        if (domainId && customerId) {
            this.onTransitionFinished(() => {
                this.stateService.transitionTo('base.customer.domain.dashboard', { domainId, customerId });
            });
            return;
        }

        if (customerId) {
            this.onTransitionFinished(() => {
                this.stateService.transitionTo('base.customer.dashboard', { customerId });
            });
            return;
        }

        this.onTransitionFinished(() => {
            this.stateService.transitionTo('base.dashboard');
        });
        return;
    }


    private onTransitionFinished (callback: TransitionHookFn): void {
        if (this.uiRouterGlobals.transition) {
            this.uiRouterGlobals.transition.onFinish({}, callback);
        } else {
            (callback as () => void)();
        }
    }
}
