import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Agreement } from '@monsido/modules/models/api/agreement';

import { LocalStorageService } from '@monsido/angular-shared-components';
import { CoreAgreementService } from '@monsido/core/session/services/agreement/agreement.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { HttpLegacyService } from '@monsido/http/http-legacy-service/http-legacy.service';
import { MonHttpClientService } from '@monsido/http/http-client.service';

@Injectable()
export class LoaderService {
    constructor (
        private monEventService: MonEventService,
        private ng2SessionService: SessionService,
        private localStorage: LocalStorageService,
        private coreAgreementService: CoreAgreementService,
        private httpLegacyService: HttpLegacyService,
        private monHttpClientService: MonHttpClientService,
    ) {}

    initializeApp (): Promise<unknown> {
        return new Promise<unknown>((resolve: (value: unknown) => void) => {
            this.monEventService.addListener('exitCustomer', (index) => {
                this.coreAgreementService.clearAgreement();
                return index;
            });
            this.monEventService.addListener('exitDomain', (index) => index);
            this.monEventService.addListener('afterCustomer', (index) => index);
            this.monEventService.addListener('logout', (index) => {
                this.httpLegacyService.cancelRequests();
                this.monHttpClientService.cancelRequests();
                return index;
            });
            this.monEventService.addListener('clearAgreement', (index) => {
                this.localStorage.setItem('roles', [].toString());
                return index;
            });
            this.monEventService.addListener('refreshDomain', (index) => index);
            this.monEventService.addListener('afterLoadUser', (index) => index);

            this.monEventService.addListener('setAgreement', (agreement: Agreement, index) => {
                const roles: string[] = [];
                this.ng2SessionService.roles = roles;
                this.localStorage.setItem('roles', roles.toString());
                roles.push(agreement.account.type.toLowerCase());
                if (agreement.admin) {
                    roles.push(agreement.account.type.toLowerCase() + '_admin');
                }
                this.ng2SessionService.roles = roles;
                this.localStorage.setItem('roles', roles.toString());
                return index;
            });
            return resolve(null);
        });
    }
}
