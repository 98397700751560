import { Injectable } from '@angular/core';

import { find } from 'lodash';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { SessionService } from '@monsido/core/session/session.service';
import { POLICY_FORM_HELPER_DB } from '@client/app/core/constants/policy-form-helper-db';

@Injectable({
    providedIn: 'root',
})
export class PolicyHelperService {
    private tags: { [key: string]: string };

    constructor (
        private translateService: TranslateService,
        private sessionService: SessionService,
    ) {
        this.tags = {
            accessibility: this.translateService.getString('Accessibility'),
            qa: this.translateService.getString('Quality Assurance'),
            seo: this.translateService.getString('SEO (Search Engine Optimization)'),
        };
    }

    getTag (tag: string): string {
        return this.tags[tag] || this.translateService.getString('NA');
    }

    getType (policy: any): string | null {
        let type: string | null = null;

        switch (policy.mode) {
            case 'violation':
                type = 'unwanted';
                break;
            case 'search':
                type = 'search';
                break;
            case 'required':
                type = 'required';
                break;
        }
        return type;
    }

    getTooltipMessageForType (policy: any): string | null {
        let message: string | null = null;
        switch (policy.mode) {
            case 'violation':
                message = this.translateService.getString('Unwanted content');
                break;
            case 'search':
                message = this.translateService.getString('Matches');
                break;
            case 'required':
                message = this.translateService.getString('Required content');
                break;
        }
        return message;
    }

    getTargetDomain (targets: any[]): any | null {
        const domain = this.sessionService.domain;
        const domainGroup = this.sessionService.domainGroup;

        if (domainGroup !== null) {
            const domainGroupTarget = find(targets, { type: 'DomainGroup', id: domainGroup.id });
            if (domainGroupTarget) {
                return domainGroupTarget;
            }
        }

        if (domain !== null) {
            return find(targets, { type: 'Domain', id: domain.id });
        }

        return null;
    }

    isMatchLinkable (type: 'link_url' | 'file_size' | 'image_size'): boolean {
        return POLICY_FORM_HELPER_DB.RULE_TYPE_LINKS.indexOf(type) !== -1;
    }

    isMatchAnImage (type: 'image_size'): boolean {
        return POLICY_FORM_HELPER_DB.RULE_TYPE_IMAGES.indexOf(type) !== -1;
    }
}
