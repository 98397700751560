import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceivedReportsComponent } from './received-reports.component';
import { ReceivedReportsTableModule } from '../../received-reports-table/received-reports-table.module';
import { MonEventModule } from '@monsido/services/mon-event/mon-event.module';
import { AngularSharedComponentsModule, LayoutModule } from '@monsido/angular-shared-components';
import { MonTranslationModule } from '@monsido/core/translation/translation.module';
import { IconsModule } from '@monsido/ng2/layout/icons/icons.module';

@NgModule({
    declarations: [
        ReceivedReportsComponent,
    ],
    imports: [
        CommonModule,
        ReceivedReportsTableModule,
        MonEventModule,
        AngularSharedComponentsModule,
        MonTranslationModule,
        IconsModule,
        LayoutModule,
    ],
    exports: [
        ReceivedReportsComponent,
    ],
})
export class ReceivedReportsModule {}
