(function () {
    'use strict';

    angular.module('app.components.documentDetails').component('documentDetailsSectionAccessibility', {
        templateUrl: 'app/components/documentdetails/details/accessibility/accessibility.html',
        controller: SectionAccessibilityController,
        controllerAs: 'vm',
        bindings: {
            document: '<',
            page: '<',
            callback: '&',
            updateDocument: '&',
        },
    });

    SectionAccessibilityController.$inject = [
        'ng2PageClarityService',
        'Lodash',
        'PDFService',
        'ng2MonModalService',
        'gettextCatalog',
        'ng2SessionService',
    ];
    /* @ngInject */
    function SectionAccessibilityController (PageClarityService, Lodash, PDFService, ng2MonModalService, gettextCatalog, ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onTabChange = onTabChange;
        vm.selectCheck = selectCheck;
        vm.hasErrors = hasErrors;
        vm.requestClarification = requestClarification;
        vm.hasClarityQueuedAt = hasClarityQueuedAt;

        function activate () {
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export Page Clarity Report'),
                    data: {
                        category: 'page_clarity',
                        category_ref: vm.page.id,
                    },
                },
            ];
            vm.domain = ng2SessionService.domain;
            vm.checks = [];
            vm.check = null;
            vm.checkErrors = [];
            vm.tab = null;
            getPage();
            setHeadline();
        }

        function setHeadline () {
            var wcag2_1 = gettextCatalog.getString('WCAG 2.1 Accessibility Compliance');
            var wcag2_0 = gettextCatalog.getString('WCAG 2.0 Accessibility Compliance');
            if (!vm.document.standard) {
                if (vm.domain.features.clarity_guideline === 'WCAG2_1') {
                    vm.headline = wcag2_1;
                } else {
                    vm.headline = wcag2_0;
                }
            } else {
                if (vm.document.standard === 'WCAG2_1') {
                    vm.headline = wcag2_1;
                } else {
                    vm.headline = wcag2_0;
                }
            }
        }

        function getPage () {
            vm.progress = PageClarityService.getAllChecks(vm.document.id).then(function (checks) {
                vm.checks = Lodash.sortBy(checks, ['errors_count']).reverse();
                selectCheck(vm.check);
            }, angular.noop);
        }

        function getErrors () {
            if (vm.check === null || isNaN(parseInt(vm.check.id))) {
                vm.checkErrors = [];
                return;
            }
            vm.loadingErrors = true;
            var params = {
                page_size: 0,
                result_type: vm.tab,
            };
            PageClarityService.getCheckErrors(vm.document.id, vm.check.id, params)
                .then(function (errors) {
                    vm.checkErrors = errors;
                }, angular.noop)
                .finally(function () {
                    vm.loadingErrors = false;
                });
        }

        function onTabChange (tab) {
            vm.tab = tab;
            getErrors();
        }

        function selectCheck (check) {
            if (check === null) {
                vm.check = Lodash.head(vm.checks) || null;
            } else {
                vm.check = Lodash.find(vm.checks, { id: check.id }) || null;
            }
            getErrors();
        }

        function hasErrors (check) {
            if (check) {
                return check.errors_count > 0;
            } else {
                return false;
            }
        }

        function requestClarification () {
            var translateParams = {
                name: '<strong>' + vm.document.title || vm.document.url + '</strong>',
            };
            var translation = gettextCatalog.getString(
                'Are you sure you want to the request ADA clarification for {{name}}?',
                translateParams,
            );
            ng2MonModalService.confirm(translation).then(function () {
                PDFService.createClarity(vm.document).then(function (document) {
                    vm.updateDocument({ document: document });
                    getPage();
                }, angular.noop);
            }, angular.noop);
        }

        function hasClarityQueuedAt () {
            return vm.document.hasOwnProperty('clarity_queued_at');
        }
    }
})();
