import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.export').factory('exporter', exporter);

    exporter.$inject = [
        'exporterQaParser',
        'exporterSeoParser',
        'exporterPolicyParser',
        'exporterAccessibilityParser',
        'ng2ExporterAllParserService',
        'exporterAuditLogParser',
        'ng2ExporterReadabilityParserService',
        'ng2ExporterDataPrivacyParserService',
        'ng2ExporterPerformanceParserService',
        'exporterPerformanceAuditsParser',
        'exporterPerformanceAuditsDetailsParser',
        'monDialog',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function exporter (
        exporterQaParser,
        exporterSeoParser,
        exporterPolicyParser,
        exporterAccessibilityParser,
        ng2ExporterAllParserService,
        exporterAuditLogParser,
        ng2ExporterReadabilityParserService,
        ng2ExporterDataPrivacyParserService,
        ng2ExporterPerformanceParserService,
        exporterPerformanceAuditsParser,
        exporterPerformanceAuditsDetailsParser,
        monDialog,
        ng2MonEventsService,
    ) {
        var service = {
            parseHistory: parseHistory,
            parseAuditLog: parseAuditLog,
            cancelAuditLog: cancelAuditLog,
            parsePerformanceAudits: parsePerformanceAudits,
            parsePerformanceAuditsDetails: parsePerformanceAuditsDetails,
            connectToCSVExporter: connectToCSVExporter,
            requestExportFromModule: requestExportFromModule,
            disconnectFromCSVExporter: disconnectFromCSVExporter,
        };

        var connectedModules = {};

        return service;

        // ///////////////

        function parseHistory (module, data) {
            switch (module) {
                case 'qa':
                    return exporterQaParser.parse(data);
                case 'seo':
                    return exporterSeoParser.parse(data);
                case 'policy':
                    return exporterPolicyParser.parse(data);
                case 'accessibility':
                    return exporterAccessibilityParser.parse(data);
                case 'readability':
                    return ng2ExporterReadabilityParserService.parse(data);
                case 'data-privacy':
                    return ng2ExporterDataPrivacyParserService.parse(data);
                case 'performance':
                    return ng2ExporterPerformanceParserService.parse(data);
                case 'all':
                    return ng2ExporterAllParserService.parse(data);
            }
            return [];
        }

        async function parseAuditLog (params, options) {
            var dialogParams = {
                body: 'csvExportAuditLogDialog',
                size: 'sm',
                classes: 'fade-animation middle center',
                data: { total: options.total, params: params },
            };
            const dialogId = await ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params: dialogParams });
            return exporterAuditLogParser.getData(params, []).finally(function () {
                if (Array.isArray(dialogId)) {
                    monDialog.close(dialogId[0]);
                } else {
                    monDialog.close(dialogId);
                }
            });
        }

        function cancelAuditLog () {
            exporterAuditLogParser.stopGetData();
        }

        function parsePerformanceAudits (data) {
            return exporterPerformanceAuditsParser.parse(data);
        }

        function parsePerformanceAuditsDetails (data) {
            return exporterPerformanceAuditsDetailsParser.parse(data);
        }

        function connectToCSVExporter (moduleName, callbackReturningExportData) {
            connectedModules[moduleName] = callbackReturningExportData;
        }

        function disconnectFromCSVExporter (moduleName) {
            delete connectedModules[moduleName];
        }

        function requestExportFromModule (moduleName) {
            var callbackReturningExportData = connectedModules[moduleName];

            if (typeof callbackReturningExportData === 'function') {
                return parseHistory(moduleName, callbackReturningExportData());
            }
        }
    }
})();
