import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.page-performance.pages.admin').component('adminPagePerformanceProfiles', {
        templateUrl: 'app/modules/page-performance/pages/admin/profiles/profiles.html',
        controller: PagePerformanceProfilesController,
        controllerAs: 'vm',
    });

    PagePerformanceProfilesController.$inject = [
        '$controller',
        'ng2MonModalService',
        'ng2ToastService',
        'ng2PagePerformanceProfileService',
        'gettextCatalog',
        'ng2PagePerformanceService',
        'ng2MonEventsService',
        'PagePerformanceProfileObsService',
        'FORM_CONSTANT',
        'uibModalOpenerService',
    ];
    /* @ngInject */
    function PagePerformanceProfilesController (
        $controller,
        ng2MonModalService,
        toastService,
        ng2PagePerformanceProfileService,
        gettextCatalog,
        ng2PagePerformanceService,
        ng2MonEventsService,
        PagePerformanceProfileObsService,
        FORM_CONSTANT,
        uibModalOpenerService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openProfile = openProfile;
        vm.getAvailableProfiles = getAvailableProfiles;
        vm.confirmArchive = confirmArchive;
        vm.confirmRestore = confirmRestore;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.promise = null;
            vm.profiles = [];
            vm.totalActive = 0;
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Active'),
                    value: 'active',
                },
                {
                    name: gettextCatalog.getString('Archived'),
                    value: 'archived',
                },
            ];
            vm.activeTab = 'active';
            vm.page = 1;
            // On init, the full list of created profiles hasn't been loaded yet
            vm.showHeading = false;
            getPage();
        }

        function getPage (isArchived) {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                archived: isArchived || vm.activeTab === 'archived',
            };

            vm.promise = ng2PagePerformanceProfileService.getAll(params).then(function (profiles) {
                vm.showHeading = true;
                vm.profiles = profiles;
                updateDropdowns();
                if (!isArchived) {
                    vm.totalActive = vm.profiles.total;
                }
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const profile of vm.profiles) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faEdit',
                        action: ()=>openProfile(profile),
                        shouldShow: !profile.archived_at,
                    },
                    {
                        label: gettextCatalog.getString('Archive'),
                        leftIcon: 'faArchive',
                        action: ()=>confirmArchive(profile),
                        shouldShow: !profile.archived_at,
                    },
                    {
                        label: gettextCatalog.getString('Restore'),
                        leftIcon: 'faBox',
                        action: ()=>confirmRestore(profile),
                        shouldShow: Boolean(profile.archived_at),
                    },
                ];

                profile.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: item.action,
                    };
                });
            }
        }

        function openProfile (profile) {
            if (getAvailableProfiles() || profile) {
                var params = {
                    body: FORM_CONSTANT.FORM_PAGE_PERFORMANCE_PROFILE,
                    size: 'md',
                    data: {
                        profile: profile,
                    },
                };
                const callback = function (newProfile) {
                    if (typeof newProfile !== 'undefined') {
                        getPage();
                    }
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
            } else {
                ng2PagePerformanceService.getReachedMaximumProfileMessageDialog()
                    .then(() => {
                        requestMoreProfiles();
                    });
            }
        }

        function requestMoreProfiles () {
            ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-confirmed' });
            vm.saving = true;
            ng2PagePerformanceService.requestMoreProfiles(gettextCatalog.getString('Contact me about more performance profiles'))
                .then(function () {
                    toastService.success(gettextCatalog.getString('Our team has been notified of your request. We will soon be in touch.'));
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }

        function confirmArchive (profile) {
            uibModalOpenerService
                .open({
                    component: 'formPagePerformanceConfirmArchive',
                    size: 'lg',
                    resolve: {
                        profile: function () {
                            return profile;
                        },
                    },
                })
                .result.then(function () {
                    vm.activeTab = 'archived';
                    vm.page = 1;
                    getPage();
                    getPage(true);
                }, angular.noop);
        }

        function confirmRestore (profile) {
            if (getAvailableProfiles() > 0) {
                ng2MonModalService
                    .confirm({
                        title: gettextCatalog.getString('Restore profile?'),
                        message: gettextCatalog.getString(
                            'Restoring this profile <strong>({{profileName}})</strong> will make it available in your modules.',
                            { profileName: profile.name },
                        ),
                    })
                    .then(function () {
                        restoreProfile(profile);
                    }, angular.noop);
            } else {
                ng2MonModalService
                    .confirm({
                        message: gettextCatalog.getString(
                            '<p class=\'warning\'>Acquia Optimize is unable to restore the Profile.</p><p>You have reached ' +
                                'the limit of the amount of profiles allowed on your account ' +
                                '({{maxProfiles}} profiles). Please contact our sales team to add more profiles to your plan or archive some profiles before restoring this one.</p>',
                            { maxProfiles: ng2PagePerformanceService.getMaxProfileCount() },
                        ),
                    })
                    .then(function () {
                        restoreProfile(profile);
                    }, angular.noop);
            }
        }

        function getAvailableProfiles () {
            if (Array.isArray(vm.profiles)) {
                return ng2PagePerformanceService.getMaxProfileCount() - (vm.totalActive || 0);
            }

            return 0;
        }

        function restoreProfile (profile) {
            profile.archive = false;
            ng2PagePerformanceProfileService.archive(profile).then(function () {
                PagePerformanceProfileObsService.addProfile(profile);
                vm.activeTab = 'active';
                vm.page = 1;
                getPage();
            }, angular.noop);
        }
    }
})();
