import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkComponent } from './link.component';
import { IconsModule } from '@monsido/angular-shared-components';

@NgModule({
    declarations: [LinkComponent],
    imports: [
        CommonModule,
        IconsModule,
    ],
    exports: [
        LinkComponent,
    ],
})
export class LinkModule { }
