import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import { FormDomainScriptGuideService } from '@client/app/forms/domain-script-guide/form-domain-script-guide.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { DOMAIN_SCRIPT_GUIDE_EVENTS } from '@client/app/forms/constants/domain-script-guide';

@Injectable({
    providedIn: 'root',
})
export class FormDomainScriptGuideHeatmapsService {
    private showSetting = false;
    readonly settingChangeObs = new BehaviorSubject<void>(undefined);

    constructor (
        private activeFeatureService: ActiveFeatureService,
        private formDomainScriptGuideService: FormDomainScriptGuideService,
        private monEventService: MonEventService,
    ) {}

    getValue (): boolean {
        return this.showSetting;
    }

    setValue (value: boolean, manualEdit: boolean): void {
        this.showSetting = this.hasAccessToFeature() && value;
        this.updateSettings(manualEdit);
        this.settingChangeObs.next();
    }

    hasAccessToFeature (): boolean {
        return this.activeFeatureService.isFeatureActive(
            'heatmaps',
            this.formDomainScriptGuideService.getDomain() ?? undefined,
        );
    }

    private updateSettings (manualEdit: boolean): void {
        this.monEventService.run(DOMAIN_SCRIPT_GUIDE_EVENTS.FORM_UNSAVED_UPDATE);
        this.formDomainScriptGuideService.setScriptSettingBlock(
            'heatmap',
            {
                enabled: this.showSetting,
            },
            manualEdit,
        );
    }
}
