(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardQa', {
        templateUrl: 'app/pages/domain/charts/qa/qa.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            historyData: '=',
        },
    });

    DashboardController.$inject = [
        'ng2QualityRepoService',
        '$filter',
        'ng2SessionService',
        'gettextCatalog',
        'ng2ChangesHistoryService',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DashboardController (ng2QualityRepoService, $filter, ng2SessionService, gettextCatalog, ng2ChangesHistoryService, ng2ActiveFeatureService) {
        var vm = this;
        vm.historyCenterParameters = {};
        vm.$onInit = activate;

        function activate () {
            vm.historyCenterParameters = ng2ChangesHistoryService.getHistoryCenterParameters('qa');
            vm.isOnlyQASummary = ng2ActiveFeatureService.isFeatureActive('qa_summary');
            vm.loading = true;
            vm.domain = ng2SessionService.domain;
            vm.latestCrawl = getLatestHistory();
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            setupDoughnutChart();
        }

        function getLatestHistory () {
            if (vm.historyData.length > 0) {
                return vm.historyData.sort(function (a, b) {
                    // Get the latest history id
                    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                })[0];
            } else {
                return {};
            }
        }

        function setupDoughnutChart () {
            vm.doughnutLabels = [[''], ['']];
            vm.doughnutColors = ['#4dc0bd', '#DCDCDC'];
            vm.total = 0;
            vm.totalPages = 0;
            vm.promise = ng2QualityRepoService.getSummary({}).then(function (data) {
                vm.total_pages_count = data.pages_with_quality_notifications + data.pages_without_quality_notifications;
                vm.total_documents_count = data.documents_with_quality_notifications + data.documents_without_quality_notifications;
                vm.totalPages = vm.total_pages_count + vm.total_documents_count;

                vm.pages_count = data.pages_with_quality_notifications;
                vm.documents_count = data.documents_with_quality_notifications;

                vm.total = vm.pages_count + vm.documents_count;
                vm.percentage = (vm.total / vm.totalPages) * 100;

                vm.doughnutData = [vm.totalPages - vm.total, vm.total];

                vm.broken_links = data.broken_links;
                vm.broken_images = data.broken_images;
                vm.misspellings = data.confirmed_misspellings;

                vm.doughnutOptions = {
                    centerText: {
                        percentageLabel: $filter('monNumber')((100 - vm.percentage) / 100, '0.[00] %'),
                        totalLabel: gettextCatalog.getString('overall compliance'),
                        subLabel: gettextCatalog.getString('achieved'),
                        totalFontSize: 150,
                        subFontSize: 150,
                    },
                    cutoutPercentage: 80,
                    tooltips: {
                        enabled: false,
                    },
                    hover: {
                        mode: null,
                    },
                };

                vm.latestQaErrors = data.broken_links + data.broken_images + data.confirmed_misspellings;
                vm.loading = false;
            }, angular.noop);
        }

    }
})();
