import { Injectable, inject } from '@angular/core';
import { COOKIE_CATEGORIES } from '@monsido/core/constants/cookie-categories.constant';
import { COOKIE_SOURCE } from '@monsido/core/constants/cookie-source.constant';
import { MonLogService } from '@monsido/services/mon-log-service/mon-log.service';
import { CookieEntity } from '@client/app/modules/cookie-banner/interfaces/cookie.interface';
import { CookieModelType } from '@client/app/modules/cookie-banner/components/cookie-overview/cookie-model.type';

type CookieTypesType = typeof COOKIE_CATEGORIES[keyof typeof COOKIE_CATEGORIES];
type CookieSourcesType = typeof COOKIE_SOURCE[keyof typeof COOKIE_SOURCE];

@Injectable({
    providedIn: 'root',
})
export class CookieModelService {
    readonly cookieTypes: CookieTypesType[] = [
        COOKIE_CATEGORIES.analytics,
        COOKIE_CATEGORIES.basic,
        COOKIE_CATEGORIES.essential,
        COOKIE_CATEGORIES.marketing,
        COOKIE_CATEGORIES.personalisation,
        COOKIE_CATEGORIES.unclassified,
    ] as const;

    readonly cookieSources: CookieSourcesType[] = [
        COOKIE_SOURCE.manual,
        COOKIE_SOURCE.scanned,
        COOKIE_SOURCE.scanned_now_absent,
    ] as const;

    readonly atCookieModelService = ' at CookieModelService!';

    monLogService = inject(MonLogService);

    createCookie (options: CookieEntity): CookieModelType | null {
        if (!options) {
            this.monLogService.warn('Cannot create cookie' + this.atCookieModelService);
            return null;
        }
        const cookieModel: CookieModelType = {
            type: this.getCookieType(options.type as CookieTypesType),
            source: this.getCookieSource(options.source as CookieSourcesType),
        };

        if (options.id) {
            cookieModel.id = options.id;
        }
        if (options.name != null) {
            cookieModel.name = String(options.name);
        } else {
            this.monLogService.warn('Missing cookie name' + this.atCookieModelService);
        }

        if (options.domain != null) {
            cookieModel.domain = String(options.domain);
        } else {
            this.monLogService.warn('Missing cookie domain' + this.atCookieModelService);
        }

        if (options.platform != null) {
            cookieModel.platform = String(options.platform);
        }

        if (options.company != null) {
            cookieModel.company = String(options.company);
        }
        if (options.path != null) {
            cookieModel.path = String(options.path);
        }

        if (Array.isArray(options.descriptions) && options.descriptions.length > 0) {
            cookieModel.descriptions = options.descriptions;
            if (cookieModel.descriptions.length === 0) {
                delete cookieModel.descriptions;
            } else {
                // Remove deprecated field from model
                delete cookieModel.description;
            }
        }
        if (Array.isArray(options.expirations) && options.expirations.length > 0) {
            cookieModel.expirations = options.expirations;
            if (cookieModel.expirations && cookieModel.expirations.length === 0) {
                delete cookieModel.expirations;
            } else {
                // Remove deprecated field from model
                delete cookieModel.expires;
            }
        }

        return cookieModel;
    }

    private getCookieType (type: CookieTypesType): CookieTypesType {
        if (this.cookieTypes.includes(type)) {
            return type;
        } else {
            this.monLogService.warn('Wrong cookie type' + this.atCookieModelService);
            return COOKIE_CATEGORIES.unclassified;
        }
    }

    private getCookieSource (source: CookieSourcesType): CookieSourcesType {
        if (source && this.cookieSources.includes(source)) {
            return source;
        } else {
            this.monLogService.warn('Wrong cookie source' + this.atCookieModelService);
            return COOKIE_SOURCE.manual;
        }
    }

}
