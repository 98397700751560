import { default as guidelinesData } from './guidelines-data.json';

(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableAccessibility', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/accessibility/accessibility.html',
        controller: AccessibilityController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
            domainGroup: '<',
        },
    });

    AccessibilityController.$inject = [
        '$filter',
        'complianceService',
        'ng2AccessibilityGuidelineVersionService',
        'ng2ActiveFeatureService',
        'exporter',
        'historyPrepareService',
        'ng2MonUtilsService',
        'ng2ChangesHistoryHelperService',
    ];
    /* @ngInject */
    function AccessibilityController (
        $filter,
        complianceService,
        ng2AccessibilityGuidelineVersionService,
        ng2ActiveFeatureService,
        exporter,
        historyPrepareService,
        ng2MonUtilsService,
        ng2ChangesHistoryHelperService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.$onChanges = update;
        vm.historyOutput = [];
        vm.totalCount = 100;
        vm.allowVerticalAverage = false;

        function activate () {
            exporter.connectToCSVExporter('accessibility', function () {
                return vm.historyOutput;
            });
        }

        function onDestroy () {
            exporter.disconnectFromCSVExporter('accessibility');
        }

        function update (changes) {
            vm.crawlAttribute = 'accessiblity_issues_count';
            vm.guidelines = guidelinesData;

            if (changes.history) {
                prepareHistoryOutput(vm.history).then(function (result) {
                    vm.historyOutput = result;
                }, angular.noop);
            }
            vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
            vm.historyOutput = historyPrepareService.accessibility(vm.history);
        }

        function prepareHistoryOutput (history) {
            return ng2AccessibilityGuidelineVersionService.getVersions(history).then(function (versionChanges) {
                var result = [];
                var len = history.length;
                var story;
                var entry;
                for (var i = 0; i < len; i += 1) {
                    story = history[i];
                    entry = {
                        id: story.id,
                        post_processing_done_at: story.post_processing_done_at,
                        date: story.date,
                        page_count: $filter('monNumber')(story.page_count),
                        error: getError(story),
                        changePercentageValue: $filter('monNumber')(
                            ng2ChangesHistoryHelperService.getChangePercentageValue(story.id, vm.history, [vm.crawlAttribute], 'accessibility') / 100,
                            '0.[00]%',
                        ),
                        hasCompliance: hasCompliance(story),
                        changeValueColorClass: ng2ChangesHistoryHelperService.getChangeValueColorClass(story.id, vm.history, [vm.crawlAttribute], 'accessibility'),
                        changeValueIconClass: ng2ChangesHistoryHelperService.getChangeValueIconClass(story.id, vm.history, [vm.crawlAttribute], 'accessibility'),
                        accessibility_checks: story.accessibility_checks,
                        accessiblity_issues_count: $filter('monNumber')(story.accessiblity_issues_count),
                    };
                    var totalCount = vm.totalCount || 1;
                    entry.compliancePercentage = ng2MonUtilsService.withinHundred(1 - entry.error / totalCount) * 100;

                    if (vm.allowVerticalAverage && story.vertical_scores && entry.hasCompliance) {
                        var comparisonToAverage = Math.round(entry.compliancePercentage - story.vertical_scores.accessibility) / 100;
                        entry.comparisonToAverage = $filter('monNumber')(comparisonToAverage, '0.[00]%');
                        entry.comparisonToAverageStyle = ng2MonUtilsService.getTextStyleByNumberValue(comparisonToAverage);
                    }

                    for (var j = 0; j < versionChanges.length; j++) {
                        var versionChange = versionChanges[j];
                        if (story.post_processing_done_at === versionChange.time) {
                            entry.versionChange = versionChange;
                            break;
                        }
                    }
                    result.push(entry);
                }

                return result;
            }, angular.noop);
        }

        function hasCompliance (history) {
            return getError(history) !== null;
        }

        function getError (history) {
            var complianceAr = complianceService.accessibilityHistoryCompliance(history);
            return Array.isArray(complianceAr) ? complianceAr[1] : null;
        }
    }
})();
