import { get } from 'lodash';

(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceScoreIndicators', {
        templateUrl: 'app/modules/page-performance/components/score-indicators/score-indicators.html',
        controller: PagePerformanceScoreIndicatorsController,
        controllerAs: 'vm',
        bindings: {
            audits: '<',
            goToSection: '&',
        },
    });

    PagePerformanceScoreIndicatorsController.$inject = ['ng2PagePerformanceChartsService'];
    /* @ngInject */
    function PagePerformanceScoreIndicatorsController (ng2PagePerformanceChartsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getFailedColor = getFailedColor;
        vm.getAverageColor = getAverageColor;
        vm.getPassedColor = getPassedColor;
        function activate () {}

        function getFailedColor () {
            ng2PagePerformanceChartsService.getFailedColor();
        }

        function getAverageColor () {
            ng2PagePerformanceChartsService.getAverageColor();
        }

        function getPassedColor () {
            ng2PagePerformanceChartsService.getPassedColor();
        }

    }
})();
