import { Injectable } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { SessionService } from '@monsido/core/session/session.service';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { Page } from '@monsido/modules/page-details/models';

@Injectable({
    providedIn: 'root',
})
export class CheckForDuplicatesService {
    constructor (
        private monEventService: MonEventService,
        private sessionService: SessionService,
    ) {}

    async checkForDuplicatePages (pages: CollectionInterface<Page>): Promise<void | void[]> {
        const duplicates = this.checkForDuplicateIds(pages);
        if (duplicates.length) {
            return this.monEventService.run(
                'newException',
                'Duplicate Page IDs ' +
                duplicates.join(', ') +
                ' on page ' +
                pages.currentPage +
                ' with page size ' +
                pages.perPage +
                ' found on domain ' +
                this.sessionService.getDomainId(),
                'error',
            );
        }
    }

    private checkForDuplicateIds (collection: CollectionInterface<Page>): number[] {
        if (!Array.isArray(collection)) {
            return [];
        }

        const uniquePageIds = new Set();
        const duplicates: number[] = [];
        const len = collection.length;

        for (let i = 0; i < len; i++) {
            if (uniquePageIds.has(collection[i].id)) {
                duplicates.push(collection[i].id);
            } else {
                uniquePageIds.add(collection[i].id);
            }
        }

        return duplicates;
    }
}
