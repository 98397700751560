import { Inject, Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore   is necessary here because it is complaining about importing from json
import translations from './translations.json';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { LanguageType } from '@monsido_services/services/mon-translate/dist/translate.interface';
import {
    TranslateServiceInjectionToken,
    TranslateServiceInterface,
} from '@monsido/angular-shared-components';

@Injectable()
export class MonTranslationService {
    constructor (
        private translationService: TranslateService,
        @Inject(TranslateServiceInjectionToken)
        private ascTranslationService: TranslateServiceInterface,
        private ng2SessionService: SessionService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    initializeApp (): Promise<unknown> {
        return new Promise<unknown>((resolve: (value?: unknown) => void) => {
            this.ng2SessionService.meObs.subscribe({
                next: user => {
                    const lang = user && user.locale ? user.locale : this.translationService.baseLanguage;
                    this.setLanguage(lang as LanguageType);
                },
            });
            return resolve(null);
        });
    }

    private setLanguage (lang: LanguageType): void {
        this.translationService.setCurrentLanguage(lang);
        this.translationService.setStrings(lang, translations[lang]);
        this.ascTranslationService.setCurrentLanguage(lang);
        this.ascTranslationService.setStrings(lang, translations[lang]);
        this.document.documentElement.lang = lang;
    }
}
