(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormBlinkOptions', {
        templateUrl: 'app/modules/page-fix/forms/blink/options/options.html',
        controller: PageFixFormSkipToContentOptionsController,
        controllerAs: 'vm',
        bindings: {
            monPageFix: '<?',
            monPageFixId: '<',
            monPageFixSelector: '<',
            monType: '<',
            monSelected: '<',
            monOrder: '<',
        },
    });

    PageFixFormSkipToContentOptionsController.$inject = [
        'PAGE_FIX_SELECTOR_QUERIES',
        'PAGE_FIX_FIXABLE_TYPES',
        'PAGE_FIX_CHANGE_TYPES',
        'ng2MonEventsService',
        '$element',
        'PAGE_FIX_EVENT',
        'ng2PageFixFormBlinkOptionService',
        'Lodash',
        '$timeout',
    ];

    /* @ngInject */
    function PageFixFormSkipToContentOptionsController (
        PAGE_FIX_SELECTOR_QUERIES,
        PAGE_FIX_FIXABLE_TYPES,
        PAGE_FIX_CHANGE_TYPES,
        ng2MonEventsService,
        $element,
        PAGE_FIX_EVENT,
        ng2PageFixFormBlinkOptionService,
        Lodash,
        $timeout,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getElementText = getElementText;
        vm.updatePreview = updatePreview;
        vm.setReplacementSetting = setReplacementSetting;

        function activate () {
            vm.elementOptions = ng2PageFixFormBlinkOptionService.getElementOptions();
            vm.replacementRegex = ng2PageFixFormBlinkOptionService.getElementRegex();
            vm.elementReplacement = 'em';
            vm.customElement = '';
            vm.replacementSetting = 0;
            vm.requestPreparePayloadId = 0;
            vm.previewText = '';

            ng2MonEventsService.run(PAGE_FIX_EVENT.REGISTER_VIEW, $element[0], {
                type: vm.monType,
                order: vm.monOrder,
                selected: vm.monSelected,
            });

            if (vm.monPageFix) {
                var changeValue = vm.monPageFix.change.value;
                if (Lodash.find(vm.elementOptions, { value: changeValue })) {
                    vm.elementReplacement = changeValue;
                } else {
                    vm.customElement = changeValue;
                    vm.replacementSetting = 1;
                }
            }

            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.UNREGISTER_VIEW, $element[0]);
            ng2MonEventsService.remove(vm.requestPreparePayloadId);
        }

        function getElementText () {
            if (vm.replacementSetting === 0) {
                return vm.elementReplacement;
            }

            return vm.customElement;
        }

        function updatePreview () {
            $timeout(function () {
                var elementName = getElementText();
                var valid = ng2PageFixFormBlinkOptionService.isElementNameValid(elementName);
                ng2MonEventsService.run(PAGE_FIX_EVENT.CUSTOM_VALIDATION, valid);
                setPreview(elementName);
            });
        }

        function setReplacementSetting (setting) {
            vm.replacementSetting = setting;
            updatePreview();
        }

        // PROTECTED

        function setPreview (elementName) {
            vm.previewText = ng2PageFixFormBlinkOptionService.getElementPreview(elementName);
        }

        function setupListeners () {
            vm.requestPreparePayloadId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.REQUEST_PREPARE_PAYLOAD, function () {
                var payload = {
                    change: {
                        attribute: 'blink',
                        check_id: vm.monPageFixId,
                        value: getElementText(),
                    },
                };
                if (!vm.pageFix) {
                    payload.selector = PAGE_FIX_SELECTOR_QUERIES.query_selector + ';' + vm.monPageFixSelector;
                    payload.fixable_type = PAGE_FIX_FIXABLE_TYPES.accessibility_check;
                    payload.fixable_id = vm.monPageFixId;
                    payload.change_type = PAGE_FIX_CHANGE_TYPES.element + ':blink';
                }
                ng2MonEventsService.run(PAGE_FIX_EVENT.REQUEST_SUBMIT, payload);
            });
        }
    }
})();
