import { Injectable } from '@angular/core';
import {
    PageFixFormHtmlElementOptionsService,
} from '@client/app/modules/page-fix/services/form/html-element-options.service';
import {
    AccessibilityAutofixService,
} from '@client/app/modules/page-fix/services/accessibility-auto-fix/accessibility-auto-fix.service';

@Injectable({
    providedIn: 'root',
})
export class PageFixFormBlinkOptionService {
    private elementOptions: any[];

    constructor (
        private pageFixFormHtmlElementOptionsService: PageFixFormHtmlElementOptionsService,
        private accessibilityAutofixService: AccessibilityAutofixService,
    ) {
        this.elementOptions = this.pageFixFormHtmlElementOptionsService.getOptions();
    }

    getElementOptions (): any[] {
        return this.elementOptions;
    }

    getElementRegex (): RegExp {
        return this.accessibilityAutofixService.getElementRegex();
    }

    getElementPreview (elementName: string): string {
        return this.accessibilityAutofixService.getElementPreview(elementName);
    }

    isElementNameValid (elementName: string): boolean {
        return this.accessibilityAutofixService.isElementNameValid(elementName);
    }
}
