import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@monsido/angular-shared-components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ExportButtonComponent } from './export-button.component';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbTooltipModule,
        IconsModule,
        TranslateModule,
    ],
    exports: [ExportButtonComponent],
    declarations: [ExportButtonComponent],
})
export class ExportButtonModule {}
