import { Injectable } from '@angular/core';
import { PagePerformanceResultType } from '@client/app/services/api/page-performance/page-performance-results-repo/page-performance-results-repo.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { PAGE_PERFORMANCE_VERSION } from '@monsido/ng2/core/constants/performance-version.constant';

type VersionsType = typeof PAGE_PERFORMANCE_VERSION[keyof typeof PAGE_PERFORMANCE_VERSION];
type VersionInfoType = { id: string } & VersionsType;

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceVersionService {

    constructor (private translateService: TranslateService) { }

    getVersionInfo (version: string): VersionInfoType | null {
        const versionInfo = PAGE_PERFORMANCE_VERSION[version];
        if (versionInfo) {
            return {
                id: version,
                label: this.translateService.getString('[[label]]', { label: versionInfo.label }),
                link: versionInfo.link,
                metrics: versionInfo.metrics,
                avgMetrics: versionInfo.avgMetrics,
            };
        }
        return null;
    }

    getEntriesWithVersionChanged (series: PagePerformanceResultType[], versionFieldName: string): PagePerformanceResultType[] {
        const result: PagePerformanceResultType[] = [];
        let previousEntry;
        for (let i = 0; i < series.length; i++) {
            const entry = series[i];
            if (i > 0) {
                previousEntry = series[i - 1];
                if (entry[versionFieldName] !== previousEntry[versionFieldName]) {
                    result.push(entry);
                }
            }
        }

        return result;
    }

    getSeriesByVersion (inputSeries: PagePerformanceResultType[], versionFieldName: string):
    { series: { [version: string]: PagePerformanceResultType[] }, versions: (VersionInfoType | null)[] } {
        const seriesByVersion = {};
        const versions: string[] = [];
        for (let i = 0; i < inputSeries.length; i++) {
            const entry = inputSeries[i];
            const version = entry[versionFieldName];
            if (!seriesByVersion[version]) {
                seriesByVersion[version] = [];
                versions.push(version);
            }
            seriesByVersion[version].push(entry);
        }
        return {
            series: seriesByVersion,
            versions: versions
                .map( (version: string) => {
                    return this.getVersionInfo(version);
                })
                .reverse(),
        };
    }
}
