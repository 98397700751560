import { Injectable } from '@angular/core';
import { CacheService } from '@client/app/modules/cache/cache.service';
import { UserRepoRequestType, UserRepoService } from '@client/app/services/api/user-repo/user-repo.service';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { User } from '@monsido/ng2/modules/models/api/user';
import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';
import { cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor (
        private userRepoService: UserRepoService,
        private cacheService: CacheService,
    ) { }

    create (user: Partial<User>): Promise<void | User> {
        return this.userRepoService.create(user).then(async (returnUser) => {
            if (returnUser) {
                const users = await this.tryGetUsersFromCache();
                delete returnUser.domain_users;
                const updatedUsers = {
                    ...users,
                    [returnUser.id]: returnUser,
                };
                this.cacheService.set('users', updatedUsers);
            }
            return returnUser;
        }, () => {});
    }

    async update (user: UserRepoRequestType): Promise<User> {
        const returnUser = await this.userRepoService.update(user);
        if (!returnUser) {
            throw Error('No user');
        }

        const users = await this.tryGetUsersFromCache();
        delete returnUser.domain_users;
        const updatedUsers = {
            ...users,
            [returnUser.id]: returnUser,
        };
        this.cacheService.set('users', updatedUsers);
        return returnUser;
    }

    async get (userId: number, byPassCache: boolean): Promise<User> {
        return new Promise(async (resolve, reject) => {
            let user: User | undefined;
            if (byPassCache !== true) {
                const users = await this.tryGetUsersFromCache();
                if (users) {
                    user = users[String(userId)];
                }
            }
            if (user === undefined && userId !== undefined) {
                this.userRepoService.get(userId, {}, contextTokens.NO_GLOBAL).then(
                    async (returnUser) => {
                        const tempUser = cloneDeep(returnUser);
                        const users = await this.tryGetUsersFromCache();
                        delete returnUser.domain_users;
                        const updatedUsers = {
                            ...users,
                            [returnUser.id]: returnUser,
                        };
                        this.cacheService.set('users', updatedUsers);
                        resolve(tempUser);
                    },
                    function () {
                        reject(new Error('Can\'t find the user'));
                    },
                );
            } else {
                resolve(user as User);
            }
        });
    }

    getAll (params?: UserRepoRequestType): Promise<CollectionInterface<User>> {
        return this.userRepoService.getAll(params);
    }

    destroy (user: Partial<User>): Promise<void> {
        return this.userRepoService.destroy(user);
    }

    async tryGetUsersFromCache (params = { page: 1, page_size: 50 }): Promise<Record<number, User>> {
        let users = this.cacheService.get<Record<number, User>>('users');
        if (!users) {
            const fetchedUsers = [];
            users = await this.getUsers(params, fetchedUsers);
        }
        return users;
    }

    async getUsers (params, fetchedUsers: User[]): Promise<Record<number, User>> {
        const users = await this.getAll(params);
        fetchedUsers = [...fetchedUsers, ...users];
        if (users.length === params.page_size) {
            params.page++;
            return this.getUsers(params, fetchedUsers);
        }
        const mappedUsers = fetchedUsers.map((user) => {
            delete user.domain_users;
            return user;
        });
        const cacheUsers = mappedUsers.reduce((array, user) => ({ ...array, [user.id]: user }), {} as Record<number, User>);
        this.cacheService.set('users', cacheUsers);
        return cacheUsers;
    }
}
