(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideLegacy', {
        templateUrl: 'app/forms/domain-script-guide/legacy/legacy.html',
        controller: DomainScriptGuideController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            pageAssistModel: '<',
        },
    });

    DomainScriptGuideController.$inject = [
        'ng2FormDomainScriptGuideHeatmapsService',
        'ng2FormDomainScriptGuidePageFixService',
        'FormDomainScriptGuideStatisticsService',
        'ng2FormDomainScriptGuidePageAssistService',
    ];
    /* @ngInject */
    function DomainScriptGuideController (
        ng2FormDomainScriptGuideHeatmapsService,
        ng2FormDomainScriptGuidePageFixService,
        FormDomainScriptGuideStatisticsService,
        ng2FormDomainScriptGuidePageAssistService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getExternalScripts = getExternalScripts;
        vm.getVariables = getVariables;
        vm.updatePageAssistSettings = updatePageAssistSettings;

        function activate () {
            vm.id = 'script' + Math.floor(Math.random() * 100);
        }

        function updatePageAssistSettings (settings) {
            vm.pageAssistModel.legacySettings = settings;
        }

        function getVariables () {
            var scripts = tab() + '_monsido.push([\'_setDomainToken\', \'' + vm.domain.token + '\']);\n';
            scripts +=
                tab(2) +
                '_monsido.push([\'_withStatistics\', \'' +
                stringify(FormDomainScriptGuideStatisticsService.getValue()) +
                '\']);';

            if (
                ng2FormDomainScriptGuidePageAssistService.getAccessToFeature(vm.domain) &&
                vm.pageAssistModel.legacySettings.enabled
            ) {
                if (vm.pageAssistModel.legacySettings.theme) {
                    scripts +=
                        '\n' +
                        tab() +
                        'var _monsido_page_assist_theme = ' +
                        stringify(vm.pageAssistModel.legacySettings.theme) +
                        ';';
                }
                if (vm.pageAssistModel.legacySettings.direction) {
                    scripts +=
                        '\n' +
                        tab() +
                        'var _monsido_page_assist_direction = ' +
                        stringify(vm.pageAssistModel.legacySettings.direction) +
                        ';';
                }
                scripts +=
                    '\n' +
                    tab() +
                    'var _monsido_skip_to_pageassist = ' +
                    stringify(vm.pageAssistModel.legacySettings.skip_to) +
                    ';';
            }
            return scripts;
        }

        function getExternalScripts () {
            var result = '';

            if (
                ng2FormDomainScriptGuidePageAssistService.getAccessToFeature(vm.domain) &&
                vm.pageAssistModel.legacySettings.enabled
            ) {
                result +=
                    '&lt;script async src="//cdn.monsido.com/page-assist/mon-page-assist.js"&gt;&lt;/script&gt;\n';
            }

            if (ng2FormDomainScriptGuidePageFixService.getValue()) {
                result +=
                    '&lt;script async type="text/javascript" src="//pagecorrect.monsido.com/v1/page-correct.js"&gt;&lt;/script&gt;\n';
            }

            if (ng2FormDomainScriptGuideHeatmapsService.getValue()) {
                result +=
                    '&lt;script async type="text/javascript" src="//heatmaps.monsido.com/v1/heatmaps.js"&gt;&lt;/script&gt;\n';
            }

            return result;
        }

        function stringify (value) {
            return JSON.stringify(value);
        }

        function tab (times) {
            times = times || 1;
            var result = '';
            for (var i = 0; i < times; i++) {
                result = result + '&nbsp;&nbsp;&nbsp;&nbsp;';
            }
            return result;
        }
    }
})();
