import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartTextOptionsInterface } from '@monsido/angular-shared-components';
import MonColors from '@monsido/colors/dist/es6/colors';
import numeral from 'numeral';

@Component({
    selector: 'mon-vertical-score',
    templateUrl: './mon-vertical-score.component.html',
    styleUrls: ['./mon-vertical-score.component.css'],
})
export class MonVerticalScoreComponent implements OnChanges {
    @Input() monSize: number = 90;
    @Input() monChartValue: number = -1;
    @Input() monTitle: string = '';
    @Input() monTooltipText: string = '';
    @Input() monLabels: Array<string> = [];

    public chartData: ChartDataset<'doughnut'> = {
        data: [],
    };
    public options: ChartTextOptionsInterface = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context): string => context.label || '',
                    title: () => '',
                },
                enabled: false,
            },
        },
        centerText: {
            percentageLabel: '',
        },
    };
    public colors: string[] = [MonColors['secondary-8'], MonColors['secondary-6']];

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.monChartValue && this.monChartValue) {
            const restValue = 100 - this.monChartValue;
            this.chartData.data = [this.monChartValue, restValue];
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.options.centerText!.percentageLabel = numeral(this.monChartValue / 100).format('0.[00] %');
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.options.plugins!.tooltip!.enabled = this.monLabels?.length > 0;
            this.setColor();
        }
    }

    private setColor (): void {
        let color = 'secondary-7';

        if (this.monChartValue > 66) {
            color = 'branding-5';
        } else if (this.monChartValue > 33) {
            color = 'secondary-8';
        }

        this.colors[0] = MonColors[color];
    }
}
