import { BehaviorSubject } from 'rxjs';

(function () {
    'use strict';
    angular.module('pages.global.changesHistory').service('ChangesHistoryDashboardService', ChangesHistoryDashboardService);

    ChangesHistoryDashboardService.$inject = [
        'ng2MonEventsService',
        'ng2SEOLineChartFactory',
        'AccessibilityLineChartService',
        'ng2QALineChartService',
        'ng2PolicyLineChartService',
        'ChangeHistoryLineChartService',
        'ng2ReadabilityLineChartService',
        'ng2DataPrivacyLineChartService',
        'PerformanceDomainHistoryLineChartService',
        'rx',
        'gettextCatalog',
        'Lodash',
        'ng2DateTimeService',
        '$q',
        'CHART_COLORS_CONSTANT',
        '$filter',
        'historyPrepareService',
        'ng2ChangesHistoryService',
    ];
    /* @ngInject*/
    function ChangesHistoryDashboardService (
        ng2MonEventsService,
        ng2SEOLineChartFactory,
        AccessibilityLineChartService,
        ng2QALineChartService,
        ng2PolicyLineChartService,
        ChangeHistoryLineChartService,
        ng2ReadabilityLineChartService,
        ng2DataPrivacyLineChartService,
        PerformanceDomainHistoryLineChartService,
        rx,
        gettextCatalog,
        Lodash,
        ng2DateTimeService,
        $q,
        CHART_COLORS_CONSTANT,
        $filter,
        historyPrepareService,
        ng2ChangesHistoryService,
    ) {
        const vm = this;
        const historyBehaviorSubject = new BehaviorSubject([]);

        vm.history = [];
        vm.groupOptions = [];
        vm.lineChartService = null;
        vm.domain = null;
        vm.domain = null;
        vm.activeModule = 'all';
        vm.groupOption = null;
        vm.date = {
            startDate: '',
            endDate: '',
        };
        var modulesScoresLabelTexts = {
            seo: gettextCatalog.getString('SEO score'),
            qa: gettextCatalog.getString('Quality Assurance score'),
            accessibility: gettextCatalog.getString('Accessibility score'),
        };
        var verticalScoresLabelTexts = {
            seo: gettextCatalog.getString('Industry average score'),
            qa: gettextCatalog.getString('Industry average score'),
            accessibility: gettextCatalog.getString('Industry average score'),
        };
        var scoresChartLineProperties = {
            yAxisID: 'y-axis-0',
            xAxisID: 'x-axis-0',
            type: 'line',
            fill: false,
            lineTension: 0,
            radius: 0,
            borderWidth: 1,
            pointRadius: 3,
            pointHitRadius: 34,
        };
        var scoresChartOptions = {
            layout: {
                padding: {
                    bottom: 3,
                    top: 3,
                },
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    fontSize: 11,
                    boxWidth: 10,
                },
            },
            scales: {
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0') + '% ';
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Score'),
                            fontSize: 11,
                        },
                    },
                ],
                xAxes: [
                    {
                        id: 'x-axis-0',
                        type: 'time',
                        gridLines: {
                            offsetGridLines: true,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            maxTicksLimit: 30,
                        },
                    },
                ],
            },
            tooltips: {
                intersect: false,
                mode: 'x-axis',
                callbacks: {
                    label: function (tooltipItem, data) {
                        return (
                            data.datasets[tooltipItem.datasetIndex].label +
                            ': ' +
                            $filter('monNumber')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y / 100, '0.[00]%')
                        );
                    },
                    title: function (tooltipItem) {
                        return ng2DateTimeService.format(tooltipItem[0].xLabel, 'MMM DD');
                    },
                },
            },
        };

        var service = {
            getHistory: getHistory,
            getOverride: getOverride,
            getSeries: getSeries,
            getColors: getColors,
            getLabels: getLabels,
            getData: getData,
            getOptions: getOptions,
            getGroupOptions: getGroupOptions,
            setGroupOption: setGroupOption,
            getDefaultGroupOption: getDefaultGroupOption,

            getScoresChartSeries: getScoresChartSeries,
            getScoresChartColors: getScoresChartColors,
            getScoresChartOverride: getScoresChartOverride,
            getScoresChartOptions: getScoresChartOptions,
            getProcessedScoresData: getProcessedScoresData,
            getVerticalScores: getVerticalScores,

            chartChangeObs: new rx.BehaviorSubject(null),
            history$: historyBehaviorSubject.asObservable(),
            pagesStack$: ng2ChangesHistoryService.pagesStackBehaviorSubject.asObservable(),
        };

        function init () {
            setupGroupOptions();
            ng2MonEventsService.addListener('hcSetHistory', function (history) {
                setHistory(history);
            });

            ng2MonEventsService.addListener('hcSetDate', function (date) {
                setDate(date);
            });

            ng2MonEventsService.addListener('hcSetDomain', function (domain) {
                setDomain(domain);
            });

            ng2MonEventsService.addListener('hcSetModule', function (module) {
                setModule(module);
            });

            return service;
        }

        return init();

        function getGroupOptions () {
            return Lodash.filter(vm.groupOptions, { belongsTo: vm.activeModule });
        }

        function getDefaultGroupOption () {
            var groupOptions = getGroupOptions();
            if (groupOptions.length > 0) {
                var groupOption = Lodash.find(groupOptions, { value: vm.groupOption });
                if (typeof groupOption !== 'undefined') {
                    return groupOption.value;
                }

                return groupOptions[0].value;
            }
        }

        function getHistory () {
            return vm.history;
        }

        function getOverride () {
            if (!vm.lineChartService) {
                return {};
            }
            switch (vm.activeModule) {
                case 'performance':
                    return vm.lineChartService.getOverride(vm.history);
                default:
                    return vm.lineChartService.getOverride();
            }
        }

        function getSeries () {
            if (!vm.lineChartService) {
                return [];
            }
            switch (vm.activeModule) {
                case 'performance':
                    return vm.lineChartService.getSeries(vm.history);
                default:
                    return vm.lineChartService.getSeries();
            }
        }

        function getColors () {
            if (!vm.lineChartService) {
                return [];
            }
            switch (vm.activeModule) {
                case 'performance':
                    return vm.lineChartService.getColors(vm.history);
                default:
                    return vm.lineChartService.getColors();
            }
        }

        function getLabels () {
            if (!vm.lineChartService || !ng2DateTimeService.isValid(vm.date.startDate) || !ng2DateTimeService.isValid(vm.date.endDate)) {
                return [];
            }

            var labels = [
                {
                    date: vm.date.startDate.startOf('day'),
                },
                {
                    date: vm.date.endDate.endOf('day'),
                },
            ];

            return vm.lineChartService.getLabels(labels);
        }

        function getData () {
            if (!vm.lineChartService) {
                return [];
            }
            return vm.lineChartService.getData(vm.history);
        }

        function getOptions () {
            var deferred = $q.defer();
            if (!vm.lineChartService) {
                deferred.resolve({});
            } else {
                switch (vm.activeModule) {
                    case 'accessibility':
                        vm.lineChartService.getOption(null, vm.history).then(function (options) {
                            deferred.resolve(options);
                        }, angular.noop);
                        break;
                    default:
                        deferred.resolve(vm.lineChartService.getOption(vm.history));
                        break;
                }
            }
            return deferred.promise;
        }

        function setGroupOption (group) {
            if (group !== vm.groupOption) {
                vm.groupOption = group;
                setupChartData().then(function () {
                    onChangeChart();
                }, angular.noop);
            }
        }

        // PROTECTED

        function setHistory (history) {
            vm.history = history;
            historyBehaviorSubject.next(history);
            vm.currentBrowserService = vm.history[0]?.js_rendering_service;
            const PUPPETEER_V20_UPDATE_TIME = new Date('2023-09-07T02:00:00+02:00').getTime();
            for (let i = 0; i < vm.history.length; i++) {
                const entry = vm.history[i];
                if (i > 0) {
                    const currentEntryDate = new Date(entry.date).getTime();
                    const prevEntryDate = new Date(vm.history[i - 1].date).getTime();
                    const comparison = currentEntryDate >= PUPPETEER_V20_UPDATE_TIME &&
                        PUPPETEER_V20_UPDATE_TIME > prevEntryDate;
                    if (comparison) {
                        entry.isScanWithV20 = {
                            infoLink: 'https://help.monsido.com/en/articles/8288291-puppeteer-20-upgrade',
                        };
                    }
                }
                if (vm.currentBrowserService !== entry.js_rendering_service) {
                    vm.currentBrowserService = entry.js_rendering_service;
                    entry.browserServiceChanged = {
                        value: true,
                        infoLink: 'https://help.monsido.com/en/articles/6341586-visual-indication-when-your-domain-is-being-scanned-with-new-browser-service',
                    };
                }
            }
            setupChartData().then(function () {
                onChangeChart();
            }, angular.noop);
        }

        function setDate (date) {
            vm.date = date;
        }

        function setDomain (domain) {
            vm.domain = domain;
            setHistory([]);
        }

        function setModule (module) {
            vm.activeModule = module;
            setupChartData().then(function () {
                onChangeChart();
            }, angular.noop);
        }

        function setupChartData () {
            var deferred = $q.defer();
            switch (vm.activeModule) {
                case 'seo':
                    vm.lineChartService = ng2SEOLineChartFactory.createSEOLineChart();
                    break;
                case 'accessibility':
                    vm.lineChartService = new AccessibilityLineChartService(null, vm.groupOption);
                    break;
                case 'qa':
                    vm.lineChartService = ng2QALineChartService.createQaLineChartService();
                    break;
                case 'policy':
                    vm.lineChartService = ng2PolicyLineChartService.createPolicyLineChartService();
                    break;
                case 'readability':
                    vm.lineChartService = ng2ReadabilityLineChartService.createReadabilityLineChartService(vm.domain);
                    break;
                case 'data-privacy':
                    vm.lineChartService = ng2DataPrivacyLineChartService.createPrivacyLineChartService();
                    break;
                case 'performance':
                    vm.lineChartService = new PerformanceDomainHistoryLineChartService();
                    break;
                default:
                    vm.lineChartService = new ChangeHistoryLineChartService(vm.domain);
                    break;
            }
            deferred.resolve();
            return deferred.promise;
        }

        function onChangeChart () {
            service.chartChangeObs.onNext(null);
        }

        function setupGroupOptions () {
            vm.groupOptions = [
                {
                    name: gettextCatalog.getString('Issues'),
                    value: 'issues',
                    belongsTo: 'accessibility',
                },
                {
                    name: gettextCatalog.getString('Checks'),
                    value: null,
                    belongsTo: 'accessibility',
                },
            ];
        }

        function getScoresChartSeries (selectedModule) {
            return [modulesScoresLabelTexts[selectedModule], verticalScoresLabelTexts[selectedModule]];
        }

        function getScoresChartColors () {
            return [CHART_COLORS_CONSTANT.LIGHT_BLUE, CHART_COLORS_CONSTANT.PEACH];
        }

        function getScoresChartOverride () {
            return [scoresChartLineProperties, scoresChartLineProperties];
        }

        function getScoresChartOptions () {
            return scoresChartOptions;
        }

        function getProcessedScoresData (selectedModule, history) {
            var processedHistory = historyPrepareService[selectedModule](history);
            return processedHistory.map(function (item) {
                return {
                    x: item.post_processing_done_at,
                    y: item.compliancePercentage,
                };
            });
        }

        function getVerticalScores (selectedModule, history) {
            return history.map(function (item) {
                return {
                    x: item.post_processing_done_at,
                    y: item.vertical_scores && item.vertical_scores[selectedModule],
                };
            });
        }
    }
})();
