import { NgModule } from '@angular/core';
import { DomainScriptModule } from 'app/forms/domain-script-guide/domain-script.module';
import { ToolsHelperModule } from '../../../app/modules/tools/tools-helper.module';

@NgModule({
    imports: [
        DomainScriptModule,
        ToolsHelperModule,
    ],
    declarations: [],
    providers: [],
})
export class PagesAdminModule {}
