(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PerformancePagesService
     * @description Service for the Performance Pages component
     */
    angular.module('modules.page-performance').factory('PerformancePagesService', PerformancePagesService);

    PerformancePagesService.$inject = [
        'Lodash',
        'gettextCatalog',
        'ng2MonEventsService',
        'ng2PagePerformanceCategoryService',
        'PAGE_PERFORMANCE_CATEGORY',
        'PAGE_PERFORMANCE_AUDIT_ID',
        'ng2PagePerformancePageDetailsService',
        'ng2SessionService',
        'ng2PagePerformanceService',
        'PagePerformanceProfileObsService',
    ];

    function PerformancePagesService (
        Lodash,
        gettextCatalog,
        ng2MonEventsService,
        ng2PagePerformanceCategoryService,
        PAGE_PERFORMANCE_CATEGORY,
        PAGE_PERFORMANCE_AUDIT_ID,
        ng2PagePerformancePageDetailsService,
        ng2SessionService,
        ng2PagePerformanceService,
        PagePerformanceProfileObsService,
    ) {
        var vm = this;
        vm.assignedProfiles = [];
        vm.profiles = {};

        function init () {
            PagePerformanceProfileObsService.profilesObs.subscribe(function (profiles) {
                vm.profiles = {};
                profiles.map(function (profile) {
                    vm.profiles[profile.id] = profile;
                });
            });
            ng2MonEventsService.addListener('pagePerformanceUpdateAssignedProfile', function (page, profile) {
                var index = Lodash.findIndex(vm.assignedProfiles, function (assignedProfile) {
                    return (
                        assignedProfile.page &&
                        page.id === assignedProfile.page.id &&
                        getDefaultProfileIdFromAssignedProfile(assignedProfile) === profile.id
                    );
                });
                if (index > -1) {
                    var report = ng2PagePerformancePageDetailsService.getReport();
                    var seoCategory = ng2PagePerformanceCategoryService.getCategory(PAGE_PERFORMANCE_CATEGORY.SEO);
                    var performanceCategory = ng2PagePerformanceCategoryService.getCategory(PAGE_PERFORMANCE_CATEGORY.PERFORMANCE);
                    var bestPracticeCategory = ng2PagePerformanceCategoryService.getCategory(PAGE_PERFORMANCE_CATEGORY.BEST_PRACTICE);
                    var audits = ng2PagePerformanceCategoryService.getAuditsFromCategory(PAGE_PERFORMANCE_CATEGORY.PERFORMANCE);
                    var screenshotThumbnailsAudit = Lodash.find(audits, {
                        id: PAGE_PERFORMANCE_AUDIT_ID.FINAL_SCREENSHOT,
                    });
                    var metrics = Lodash.find(audits, { id: PAGE_PERFORMANCE_AUDIT_ID.METRICS });
                    var metricsItemDetail = metrics.details.items[0];

                    // NB! Consider using a less static way of assigning key values directly on a Assigned Profile
                    vm.assignedProfiles[index].screenshot = screenshotThumbnailsAudit.details.data;
                    vm.assignedProfiles[index].scores = {
                        profile_id: profile.id,
                        scanned_at: report.fetchTime,
                        performance: performanceCategory.score,
                        seo: seoCategory.score,
                        best_practices: bestPracticeCategory.score,
                        first_contentful_paint: metricsItemDetail.firstContentfulPaint,
                        first_meaningful_paint: metricsItemDetail.firstMeaningfulPaint,
                        time_to_interactive: metricsItemDetail.interactive,
                    };
                }
            });

            return {
                getProfiles: getProfiles,
                setAssignedProfiles: setAssignedProfiles,
                getProfileDeviceName: getProfileDeviceName,
                getProfileLocation: getProfileLocation,
                getProfileCountryCode: getProfileCountryCode,
                getProfileConnectionName: getProfileConnectionName,
                getAssignedProfiles: getAssignedProfiles,
                canRescan: canRescan,
                getMaxPageCount: getMaxPageCount,
            };
        }

        return init();

        /**
         * @memberOf PerformancePagesService
         * @desc Get Profiles collection
         */
        function getProfiles () {
            return vm.profiles;
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Set the Assigned Profiles Collection
         */
        function setAssignedProfiles (assignedProfiles) {
            vm.assignedProfiles = assignedProfiles;
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Get filtered Assigned Profiles Collection
         * @return {Array}
         */
        function getAssignedProfiles () {
            return vm.assignedProfiles;
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Get Name of the Default related Profile on the Assigned Profile
         * @param assignedProfile - Assigned Profile Object
         * @return {String}
         */
        function getProfileDeviceName (assignedProfile) {
            var profileId = getDefaultProfileIdFromAssignedProfile(assignedProfile);
            var profile = Lodash.find(vm.profiles, { id: profileId });
            if (profile) {
                return profile.getDeviceName();
            }

            return gettextCatalog.getString('Device not found');
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Get Location of the Default related Profile on the Assigned Profile
         * @param assignedProfile - Assigned Profile Object
         * @return {String}
         */
        function getProfileLocation (assignedProfile) {
            var profileId = getDefaultProfileIdFromAssignedProfile(assignedProfile);
            var profile = Lodash.find(vm.profiles, { id: profileId });
            if (profile) {
                return profile.getCountry() + ': ' + profile.getCity();
            }

            return gettextCatalog.getString('Location not found');
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Get Country Code of the Default related Profile on the Assigned Profile
         * @param assignedProfile - Assigned Profile Object
         * @return {String}
         */
        function getProfileCountryCode (assignedProfile) {
            var profileId = getDefaultProfileIdFromAssignedProfile(assignedProfile);
            var profile = Lodash.find(vm.profiles, { id: profileId });
            if (profile) {
                return profile.getCountryCode();
            }

            return '';
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Get Connection Name of the Default related Profile on the Assigned Profile
         * @param assignedProfile - Assigned Profile Object
         * @return {String}
         */
        function getProfileConnectionName (assignedProfile) {
            var profileId = getDefaultProfileIdFromAssignedProfile(assignedProfile);
            var profile = Lodash.find(vm.profiles, { id: profileId });
            if (profile) {
                return profile.getConnectionName();
            }

            return gettextCatalog.getString('Network not found');
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Check if the Customer is allowed to rescan a page on demand
         */
        function canRescan () {
            return Boolean(ng2SessionService.customer !== null && ng2SessionService.customer.plan_traits.performance_tracking_on_demand_scan);
        }

        /**
         * @memberOf PerformancePagesService
         * @desc Get the number of maximum allowed pages for this account
         */
        function getMaxPageCount () {
            return ng2PagePerformanceService.getMaxPageCount();
        }

        // PROTECTED

        /**
         * @memberOf PerformancePagesService
         * @desc Get the Default ID from the Assigned Profile
         * @param assignedProfile - Assigned Profile Object
         * @return {Number}
         */
        function getDefaultProfileIdFromAssignedProfile (assignedProfile) {
            return assignedProfile.default_profile_id || assignedProfile.profile_ids[0];
        }
    }
})();
