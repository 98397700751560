(function () {
    'use strict';

    angular.module('services.entities.user').run([
        'ng2UserService',
        'ng2CacheService',
        'ng2MonEventsService',
        'ng2SessionService',
        function (ng2UserService, ng2CacheService, ng2MonEventsService, ng2SessionService) {
            ng2MonEventsService.addListener('afterCustomer', async () => {
                if (ng2SessionService.agreement.admin) {
                    const fetchedUsers = [];
                    ng2CacheService.setConfig({ maxAge: 3600000 });
                    return ng2UserService.tryGetUsersFromCache({ page_size: 50, page: 1 }, fetchedUsers);
                }
            });
        },
    ]);
})();
