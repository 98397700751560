import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'mon-spelling-classification',
    standalone: true,
    templateUrl: './classification.component.html',
    imports: [CommonModule],
})
export class ClassificationComponent implements OnInit {
    @Input() type: string = '';
    @Input() word: string = '';
    classification: string = '';

    ngOnInit (): void {
        this.activate();
    }

    private activate (): void {
        if (this.type === 'potential') {
            this.classification = 'text-potential-misspelling';
        }
        if (this.type === 'confirmed') {
            this.classification = 'text-confirmed-misspelling';
        }
    }
}
