import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ACCESSIBILITY_HELP_CENTER_IMPACT } from '@client/app/modules/accessibility/constants/impact';


@Injectable({
    providedIn: 'root',
})
export class AccessibilityImpactService {
    constructor (private translateService: TranslateService) {}

    getTranslation (impactKey: string): string {
        const tag = ACCESSIBILITY_HELP_CENTER_IMPACT[impactKey];
        if (tag) {
            return this.translateService.getString(tag.translation);
        }
        let result = impactKey.replace(/_/g, ' ');
        result = result[0].toUpperCase() + result.substring(1);
        return result;
    }

    getClass (impactKey: string): string {
        const tag = ACCESSIBILITY_HELP_CENTER_IMPACT[impactKey];
        if (tag && tag.styleClass.length > 0) {
            return tag.styleClass;
        }
        return 'label-info';
    }
}
