import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { DatePipe, DecimalPipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ExporterDataPrivacyParserService {
    constructor (
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private translateService: TranslateService,
    ) {}

    private getHeadlines (): string[] {
        return [
            this.translateService.getString('Crawled at'),
            this.translateService.getString('Pages Crawled'),
            this.translateService.getString('Domain compliance'),
            this.translateService.getString('High severity'),
            this.translateService.getString('Medium severity'),
            this.translateService.getString('Low severity'),
        ];
    }

    parse (data: any[]): string[][] {
        if (!data?.length) {
            return [];
        }

        const csv: string[][] = [];
        csv.push(this.getHeadlines());

        for (const item of data) {
            const entry: (string | number)[] = [
                this.datePipe.transform(item.post_processing_done_at || item.date, 'MMM d, y, h:mm a') || '',
                item.page_count,
                this.formatCompliance(item.data_protection_pages_with_violations_count, item.page_count),
                item.data_protection_high_priority_page_violations_count || 0,
                item.data_protection_medium_priority_page_violations_count || 0,
                item.data_protection_low_priority_page_violations_count || 0,
            ];

            csv.push(entry.map(String));
        }

        return csv;
    }

    private formatCompliance (violations: number, total: number): string {
        const compliancePercentage = (1 - violations / total) * 100;
        return this.decimalPipe.transform(compliancePercentage, '1.0-2') + '%';
    }
}
