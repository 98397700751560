/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { ScoresService } from './scores/scores.service';
import { AngularSharedComponentsModule } from '@monsido/angular-shared-components';
@NgModule({
    declarations: [],
    imports: [],
    exports: [AngularSharedComponentsModule],
    providers: [
        ScoresService,
    ],
})
export class MonScoreServicesModule {}
