import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportCenterDashboardComponent } from './report-center-dashboard.component';
import { LayoutModule, AngularSharedComponentsModule } from '@monsido/angular-shared-components';
import { IconsModule } from '@layout/icons/icons.module';
import { LinkModule } from '../../../../shared/components/link/link.module';
import { ReportModule } from '@monsido/modules/report-center/components/dashboard/report/report.module';
import { ReportListModule } from '@monsido/modules/report-center/components/dashboard/report-list/report-list.module';
import { ScheduledReportModule } from '../../components/scheduled-report/scheduled-report.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ReceivedReportsModule } from '../../components/dashboard/received-reports/received-reports.module';
import { ReceivedReportsContainerModule } from '../../components/dialogs/received-reports-container/received-reports-container.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [ReportCenterDashboardComponent],
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        IconsModule,
        AngularSharedComponentsModule,
        LinkModule,
        ReportModule,
        ReportListModule,
        ScheduledReportModule,
        PipesModule,
        ReceivedReportsModule,
        ReceivedReportsContainerModule,
    ],
    exports: [
        ReportCenterDashboardComponent,
    ],
})
export class ReportCenterDashboardModule {}
