import { PAGE_PERFORMANCE_AUDIT_ID } from './page-performance-audit-id';
import { PAGE_PERFORMANCE_METRICS } from './page-performance-metrics';

const { FCP, LCP, TBT, CLS } = PAGE_PERFORMANCE_METRICS;
export const PAGE_PERFORMANCE_AUDIT_RELATIONS = {
    FCP: FCP,
    LCP: LCP,
    TBT: TBT,
    CLS: CLS,
    audits: {
        [FCP]: [
            PAGE_PERFORMANCE_AUDIT_ID.SERVER_RESPONCE_TIME,
            PAGE_PERFORMANCE_AUDIT_ID.RENDER_BLOCKING_RESOURCES,
            PAGE_PERFORMANCE_AUDIT_ID.REDIRECTS,
            PAGE_PERFORMANCE_AUDIT_ID.CRITICAL_REQUEST_CHAINS,
            PAGE_PERFORMANCE_AUDIT_ID.USES_TEXT_COMPRESSION,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRECONNECT,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRELOAD,
            PAGE_PERFORMANCE_AUDIT_ID.FONT_DISPLAY,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_CSS,
            PAGE_PERFORMANCE_AUDIT_ID.UNUSED_CSS_RULES,
            PAGE_PERFORMANCE_AUDIT_ID.BF_CACHE,
        ],
        [LCP]: [
            PAGE_PERFORMANCE_AUDIT_ID.SERVER_RESPONCE_TIME,
            PAGE_PERFORMANCE_AUDIT_ID.RENDER_BLOCKING_RESOURCES,
            PAGE_PERFORMANCE_AUDIT_ID.REDIRECTS,
            PAGE_PERFORMANCE_AUDIT_ID.CRITICAL_REQUEST_CHAINS,
            PAGE_PERFORMANCE_AUDIT_ID.USES_TEXT_COMPRESSION,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRECONNECT,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRELOAD,
            PAGE_PERFORMANCE_AUDIT_ID.FONT_DISPLAY,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_CSS,
            PAGE_PERFORMANCE_AUDIT_ID.UNUSED_CSS_RULES,
            PAGE_PERFORMANCE_AUDIT_ID.LARGEST_CONTENTFUL_PAINT_ELEMENT,
            PAGE_PERFORMANCE_AUDIT_ID.UNUSED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.EFFICIENT_ANIMATED_CONTENT,
            PAGE_PERFORMANCE_AUDIT_ID.TOTAL_BYTE_WEIGHT,
            PAGE_PERFORMANCE_AUDIT_ID.PRIORITIZE_LCP,
        ],
        [TBT]: [
            PAGE_PERFORMANCE_AUDIT_ID.LONG_TASKS,
            PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_SUMMARY,
            PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_FACADES,
            PAGE_PERFORMANCE_AUDIT_ID.BOOTUP_TIME,
            PAGE_PERFORMANCE_AUDIT_ID.MAINTHREAD_WORK_BREAKDOWN,
            PAGE_PERFORMANCE_AUDIT_ID.DOM_SIZE,
            PAGE_PERFORMANCE_AUDIT_ID.DUPLICATED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.LEGACY_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.VIEWPORT,
        ],
        [CLS]: [
            PAGE_PERFORMANCE_AUDIT_ID.LAYOUT_SHIFT_ELEMENTS,
            PAGE_PERFORMANCE_AUDIT_ID.NON_COMPOSITED_ANIMATIONS,
            PAGE_PERFORMANCE_AUDIT_ID.UNSIZED_IMAGES,
        ],
    },
    metrics: {
        [PAGE_PERFORMANCE_AUDIT_ID.SERVER_RESPONCE_TIME]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.RENDER_BLOCKING_RESOURCES]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.REDIRECTS]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.CRITICAL_REQUEST_CHAINS]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.USES_TEXT_COMPRESSION]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRECONNECT]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRELOAD]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.FONT_DISPLAY]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_JAVASCRIPT]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_CSS]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.UNUSED_CSS_RULES]: [FCP, LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.LARGEST_CONTENTFUL_PAINT_ELEMENT]: [LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.UNUSED_JAVASCRIPT]: [LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.EFFICIENT_ANIMATED_CONTENT]: [LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.TOTAL_BYTE_WEIGHT]: [LCP],
        [PAGE_PERFORMANCE_AUDIT_ID.LONG_TASKS]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_SUMMARY]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_FACADES]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.BOOTUP_TIME]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.MAINTHREAD_WORK_BREAKDOWN]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.DOM_SIZE]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.DUPLICATED_JAVASCRIPT]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.LEGACY_JAVASCRIPT]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.VIEWPORT]: [TBT],
        [PAGE_PERFORMANCE_AUDIT_ID.LAYOUT_SHIFT_ELEMENTS]: [CLS],
        [PAGE_PERFORMANCE_AUDIT_ID.NON_COMPOSITED_ANIMATIONS]: [CLS],
        [PAGE_PERFORMANCE_AUDIT_ID.UNSIZED_IMAGES]: [CLS],
        [PAGE_PERFORMANCE_AUDIT_ID.BF_CACHE]: [FCP],
        [PAGE_PERFORMANCE_AUDIT_ID.PRIORITIZE_LCP]: [LCP],
    },
} as const;
