import { Injectable } from '@angular/core';
import { ChartFactory } from '@client/app/blocks/service/charts/base-chart/chart-factory';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { ChartsDataHelper } from '@client/app/blocks/helpers/charts/charts-data-helper';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { QAReadabilityService } from '@client/app/blocks/service/qa-readability/qareadability.service';

@Injectable({
    providedIn: 'root',
})
export class ReadabilityLineChartService {
    private readabilityLineChartFactory: any;
    private readabilityTest: any;

    constructor (
        private translateService: TranslateService,
        private qaReadabilityService: QAReadabilityService,
    ) {}

    createReadabilityLineChartService (domain: any): any {
        this.setReadabilityTest(domain.features.readabilityTest);
        const readabilityLineChartFactory = new ChartFactory(new LineChart());
        readabilityLineChartFactory.setOptions([
            this.translateService.getString('Issues'),
            this.translateService.getString('Content'),
        ]);
        readabilityLineChartFactory.setColors(
            this.qaReadabilityService.getColorPallete(),
        );
        readabilityLineChartFactory.setOverrides([
            this.qaReadabilityService.getReadabilityLevels(this.readabilityTest)
                .length,
        ]);
        readabilityLineChartFactory.setSeries(
            this.qaReadabilityService
                .getReadabilityLevels(this.readabilityTest)
                .map((level: any) => {
                    return this.qaReadabilityService.getTranslationScore(
                        level,
                        this.readabilityTest,
                    );
                }),
        );
        readabilityLineChartFactory.getData = this.getData.bind(this);
        return readabilityLineChartFactory;
    }

    private getData (crawls: any[]): any[] {
        if (!crawls || crawls.length === 0) {
            return [];
        }
        const helper = new ChartsDataHelper('time');
        const readabilityLevels = this.qaReadabilityService.getReadabilityLevels(
            this.readabilityTest,
        );

        return readabilityLevels.map((level: any) => {
            return crawls.map((crawl: any) => {
                let ReadabilityDistributionScore = 0;
                if (crawl.readability_distribution) {
                    ReadabilityDistributionScore = crawl.readability_distribution[level];
                }
                return helper.setData(
                    {
                        result: ReadabilityDistributionScore,
                        date: crawl.date,
                    },
                    'result',
                );
            });
        });
    }

    private setReadabilityTest (test: any): void {
        this.readabilityTest = test;
    }
}
