import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { IconsModule } from '@monsido/angular-shared-components';
import { BotLoadingSpinnerComponent } from '../bot-loading-spinner/bot-loading-spinner.component';

@Component({
    selector: 'mon-bot-greetings',
    standalone: true,
    imports: [
        IconsModule,
        BotLoadingSpinnerComponent,
    ],
    templateUrl: './bot-greetings.component.html',
    styleUrl: './bot-greetings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotGreetingsComponent {
    hasUserAgreement = input<boolean>(false);
    userAuthenticated = input<boolean>(false);
    userAgreed = output<boolean>();
    showLoader = signal(false);

    constructor () {
        this.userAgreed.subscribe((value: boolean) => {
            this.showLoader.set(value);
        });
        effect(() => {
            this.showLoader.set(this.hasUserAgreement() && !this.userAuthenticated());
        }, { allowSignalWrites: true });
    }
}
