/*
    DEPRECATED!
    If you need to use this filter outside of a template, use ComplianceAccessibilityService.filterAccessibilityChecksByResultTypes instead.
*/

(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name accessibilityChecksByResultTypes
     * @description Filter accessibility checks according to their result type(s)
     */

    angular
        .module('filters.monsido')
        .filter('accessibilityChecksByResultTypes', AccessibilityChecksByResultTypesFilter);

    AccessibilityChecksByResultTypesFilter.$inject = ['ng2ComplianceAccessibilityService'];

    /* @ngInject */
    function AccessibilityChecksByResultTypesFilter (ng2ComplianceAccessibilityService) {
        return filter;

        // //////////

        /**
         * @param {Array} checks
         * @param {Object} params {
         *      only_errors: {Boolean}
         *      abbreviations: {Array[string]}
         *      result_types: {Array[string]} Current options are error, warning, review
         * }
         * @return {Array}
         */
        function filter (checks, params) {
            return ng2ComplianceAccessibilityService.filterAccessibilityChecksByResultTypes(checks, params);
        }
    }
})();
