import { Injectable } from '@angular/core';
import { PageClarityRepoService } from '@client/app/services/api/page-clarity/page-clarity-repo.service';
import { DemoHasAccessService } from '@client/app/modules/demodata/services/demo-has-access.service';
import {
    DemoPageClarityRepoService,
} from '@client/app/modules/demodata/endpoints/pages/demo-page-clarity-repo/demo-page-clarity-repo.service';


@Injectable({
    providedIn: 'root',
})
export class PageClarityService {
    constructor (
        private pageClarityRepoService: PageClarityRepoService,
        private demoHasAccessService: DemoHasAccessService,
        private demoPageClarityRepoService: DemoPageClarityRepoService,
    ) {}

    /**
   * Get Checks collection for a single PDF document
   * @param documentId - The document id
   * @param params - The parameters to inject to the get request
   * @returns Promise containing checks collection
   */
    getAllChecks (documentId: number, params: any) {
        if (this.demoHasAccessService.canAccessAccessibilityPDF()) {
            return this.demoPageClarityRepoService.getAllChecks(documentId);
        }
        return this.pageClarityRepoService.getAllChecks(documentId);
    }

    /**
   * Get Errors collection of a single Accessibility Check
   * @param documentId - The document id
   * @param checkId - The Accessibility Check ID
   * @param params - The parameters to inject to the get request
   * @returns Promise containing the error collection
   */
    getCheckErrors (documentId: number, checkId: number, params: any) {
        if (this.demoHasAccessService.canAccessAccessibilityPDF()) {
            return this.demoPageClarityRepoService.getCheckErrors(documentId, checkId);
        }
        return this.pageClarityRepoService.getCheckErrors(documentId, checkId, params);
    }

    /**
   * Update the error of an Accessibility Check
   * @param pageId - The page id
   * @param checkId - The Accessibility check id
   * @param errorId - The ID of the error in the database
   * @param data - The data object to add to the request
   * @param params - The query parameters
   * @returns Promise containing the updated error
   */
    updateCheckError (pageId: number, checkId: number, errorId: number, data: Record<string, unknown>, params: any) {
        if (this.demoHasAccessService.canAccessAccessibilityPDF()) {
            return this.demoPageClarityRepoService.updateCheckError(params);
        }
        return this.pageClarityRepoService.updateCheckError(pageId, checkId, errorId, params);
    }
}
