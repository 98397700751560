import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { COOKIE_CATEGORIES } from '@monsido/ng2/core/constants/cookie-categories.constant';

@Injectable({
    providedIn: 'root',
})
export class CookieCategoriesLocalizeService {
    private translateService = inject(TranslateService);
    public readonly [COOKIE_CATEGORIES.analytics]: string = this.translateService.getString('Statistics');
    public readonly [COOKIE_CATEGORIES.basic]: string = this.translateService.getString('Basic');
    public readonly [COOKIE_CATEGORIES.essential]: string = this.translateService.getString('Essential');
    public readonly [COOKIE_CATEGORIES.marketing]: string = this.translateService.getString('Marketing');
    public readonly [COOKIE_CATEGORIES.personalisation]: string = this.translateService.getString('Preferences');

    constructor () {}
}
