import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { Domain } from '@monsido/ng2/modules/models/api/domain';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityAuxService {

    private guidelines = {
        QUALWEB: ['QUALWEB-A', 'QUALWEB-AA'],
        WCAG2: ['WCAG2-A', 'WCAG2-AA', 'WCAG2-AAA'],
        WCAG21: ['WCAG21-A', 'WCAG21-AA', 'WCAG21-AAA', 'WCAG21-A_MOBILE', 'WCAG21-AA_MOBILE', 'WCAG21-AAA_MOBILE'],
        WCAG22: ['WCAG22-A', 'WCAG22-AA', 'WCAG22-AAA', 'WCAG22-A_MOBILE', 'WCAG22-AA_MOBILE', 'WCAG22-AAA_MOBILE'],
    };

    constructor (
        private sessionService: SessionService,
        private translateService: TranslateService,
    ) { }


    hasGuideline (guideline: string, domain?: Domain): boolean {

        if (typeof guideline !== 'string') {
            return false;
        }

        guideline = guideline.toUpperCase();
        const conformanceLevels = this.guidelines[guideline];
        const selectedDomain = domain || this.sessionService.domain;

        if (typeof conformanceLevels === 'undefined') {
            return false;
        }

        if (selectedDomain === null || typeof selectedDomain === 'undefined') {
            return false;
        }

        if (typeof selectedDomain.features.accessibility !== 'string') {
            return false;
        }

        let accessibility = selectedDomain.features.accessibility;

        const indexOfVersion = accessibility.search(/_v(\d+)?/);
        /* Makes accessibility guidelines independent of version for displaying */
        if (indexOfVersion > -1) {
            accessibility = accessibility.slice(0, indexOfVersion);
        }

        return conformanceLevels.includes(accessibility.toUpperCase());
    }

    getSubHeaderDescription (totalPages: number, type: 'ignored' | 'failed'): string {
        let guideline;
        if (this.hasGuideline('wcag2')) {
            guideline = 'WCAG 2.0';
        } else if (this.hasGuideline('wcag21')) {
            guideline = 'WCAG 2.1';
        } else if (this.hasGuideline('wcag22')) {
            guideline = 'WCAG 2.2';
        } else if (this.hasGuideline('qualweb')) {
            guideline = 'QUALWEB';
        }
        const scope = {
            guideline: guideline,
        };

        if (type === 'failed') {
            return this.translateService.getPlural(
                Number(totalPages),
                `1 page with one or more failing checks ${guideline}`,
                `${totalPages} pages with failing checks ${guideline}`,
                scope,
                '',
            );
        }

        if (type === 'ignored') {
            return this.translateService.getPlural(
                Number(totalPages),
                `1 page with one or more ignored checks ${guideline}`,
                `${totalPages} pages with ignored checks ${guideline}`,
                scope,
                '',
            );
        }

        return '';
    }

    getAccessibilityOptions (guideline: string = ''): { name: string; value: string; } [] {
        let options: {
            name: string;
            value: string;
        }[] = [];
        const levelA = { name: this.translateService.getString('Level A'), value: 'A' };
        const levelAA = { name: this.translateService.getString('Level AA'), value: 'AA' };
        const levelAAA = { name: this.translateService.getString('Level AAA'), value: 'AAA' };

        const guidelineWoVersion = guideline.split('_v')[0];

        switch (guidelineWoVersion) {
            case 'QUALWEB-A':
            case 'WCAG2-A':
            case 'WCAG21-A':
            case 'WCAG21-A_mobile':
            case 'WCAG22-A':
            case 'WCAG22-A_mobile':
                options = [levelA];
                break;

            case 'QUALWEB-AA':
            case 'WCAG2-AA':
            case 'WCAG21-AA':
            case 'WCAG21-AA_mobile':
            case 'WCAG22-AA':
            case 'WCAG22-AA_mobile':
                options = [levelA, levelAA];
                break;

            case 'WCAG2-AAA':
            case 'WCAG21-AAA':
            case 'WCAG21-AAA_mobile':
            case 'WCAG22-AAA':
            case 'WCAG22-AAA_mobile':
                options = [levelA, levelAA, levelAAA];
                break;
        }
        return options;
    }
}
