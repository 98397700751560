import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatomoService } from '@client/app/modules/tracking/services/matomo.service';
import { TrackingRunService } from '@client/app/modules/tracking/run/tracking.service';

export function init_app (trackingRunService: TrackingRunService) {
    return () => trackingRunService.initializeApp();
}

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [MatomoService, {
        provide: APP_INITIALIZER,
        useFactory: init_app,
        deps: [TrackingRunService],
        multi: true,
    }],
})

export class TrackingModule {}
