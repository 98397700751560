import { Injectable, OnInit } from '@angular/core';
import PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG from '@client/app/modules/page-fix/constants/page-fix-accessibility-check-config.json';
import { TranslateService } from '@monsido/angular-shared-components';

@Injectable({
    providedIn: 'root',
})
export class PageFixAccessibilityConfigService implements OnInit {
    config = Object.assign({}, PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG);
    descriptionTranslation: Record<string, string> = {};
    checkImgIds = [1, 2, 3, 4, 5, 6, 7, 11, 16, 178];

    constructor (private translateService: TranslateService) {}
    ngOnInit (): void {
        Object.keys(this.config).forEach(key => {
            const description = this.config[key].check_description;
            if (description) {
                this.descriptionTranslation[key] = this.translateService.getString(description);
            }
        });
    }

    getConfig (): typeof PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG {
        return this.config;
    }

    isCheckImg (check): boolean {
        return this.checkImgIds.indexOf(check.id) > -1;
    }

    isPageFixImg (pageFix): boolean {
        return this.checkImgIds.indexOf(pageFix.change.check_id) > -1;
    }

    getDescription (check): string {
        return this.descriptionTranslation[check.id];
    }
}
