import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { COOKIE_FIELDS } from '@monsido/core/constants/cookie-fields.constant';

@Injectable({
    providedIn: 'root',
})
export class CookieFieldsLocalizeService {
    private fields: { [key: string]: string } = {};

    constructor (private translateService: TranslateService) {
        this.fields[COOKIE_FIELDS.id] = this.translateService.getString('Id');
        this.fields[COOKIE_FIELDS.platform] = this.translateService.getString('Platform');
        this.fields[COOKIE_FIELDS.name] = this.translateService.getString('Name');
        this.fields[COOKIE_FIELDS.domain] = this.translateService.getString('Domain');
        this.fields[COOKIE_FIELDS.source] = this.translateService.getString('Source');
        this.fields[COOKIE_FIELDS.expires] = this.translateService.getString('Duration');
        this.fields[COOKIE_FIELDS.type] = this.translateService.getString('Category');
        this.fields[COOKIE_FIELDS.description] = this.translateService.getString('Description');
    }

    getField (key: string): string {
        return this.fields[key];
    }
}
