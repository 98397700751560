import { Injectable } from '@angular/core';
import { FastTrackSessionService } from '@client/app/modules/fast-track/services/session.service';
import { SessionService } from '@monsido/core/session/session.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';


@Injectable({
    providedIn: 'root',
})
export class AccessibilityFastTrackService {
    private excludedChecks: { [domainId: string]: { [snippetId: string]: any[] } } = {};
    private closedCaptions: { [domainId: string]: { [snippetId: string]: { [checkId: string]: boolean } } } = {};

    constructor (
        private fastTrackSessionService: FastTrackSessionService,
        private sessionService: SessionService,
        private monEventService: MonEventService,
        private translateService: TranslateService,
    ) {}

    clear (): void {
        this.fastTrackSessionService.clear();
    }

    setupSession (): void {
        this.fastTrackSessionService.setSnippetSourceCodeProperty('source_code');
        this.fastTrackSessionService.setSnippetComplianceProperty('effect_on_compliance');
        this.fastTrackSessionService.setSnippetIssuesProperty('caused_issues');
    }

    getNonExcludedChecks (snippet: any, checks: any[]): any[] {
        const domainId = this.sessionService.getDomainId();
        if (!domainId) {
            return checks;
        }
        if (this.excludedChecks[domainId] && this.excludedChecks[domainId][snippet.id]) {
            const excludedChecks = this.excludedChecks[domainId][snippet.id];
            return checks.filter((check) => !excludedChecks.some((excludedCheck) => excludedCheck.id === check.id));
        }
        return checks;
    }

    getAffectedPagesCount (checks: any[]): number {
        return checks.reduce((num, content) => Math.max(content.pages_count, num), 0);
    }

    addExcludedCheck (snippet: any, check: any): void {
        const domainId = this.sessionService.getDomainId();
        if (!domainId) {
            return;
        }
        if (!this.excludedChecks[domainId]) {
            this.excludedChecks[domainId] = {};
        }
        if (snippet !== null) {
            if (!this.excludedChecks[domainId][snippet.id]) {
                this.excludedChecks[domainId][snippet.id] = [];
            }
            this.excludedChecks[domainId][snippet.id].push(check);
        } else {
            this.monEventService.run('newException', `Unable to add Excluded Check ${check.id}, on Domain ${domainId}`, 'error');
        }
    }

    getHelpCenterGuidelineDescription (check: any): string {
        return this.translateService.getString('Part of success criteria [[checkIssueNumber]]', {
            checkIssueNumber: check.issue_number,
        });
    }

    getAccessibilityGuideline (): any {
        return this.sessionService.domain?.features.accessibility;
    }

    isClosedCaption (snippet: any, check: any): boolean {
        const domainId = this.sessionService.getDomainId();
        if (!domainId || !this.closedCaptions[domainId] || !this.closedCaptions[domainId][snippet.id]) {
            return false;
        }
        return this.closedCaptions[domainId][snippet.id][check.id];
    }

    closeCaptionSuccess (snippet: any, check: any): void {
        const domainId = this.sessionService.getDomainId();
        if (!domainId) {
            return;
        }
        if (!this.closedCaptions[domainId]) {
            this.closedCaptions[domainId] = {};
        }
        if (snippet !== null) {
            if (!this.closedCaptions[domainId][snippet.id]) {
                this.closedCaptions[domainId][snippet.id] = {};
            }
            this.closedCaptions[domainId][snippet.id][check.id] = true;
        } else {
            this.monEventService.run('newException', `Unable to add Close Caption to ${check.id}, on Domain ${domainId}`, 'error');
        }
    }
}
