(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuPageSelectorDomainPanel', {
        templateUrl: 'app/layout/menu/side-menu-search/domain-panel/domain-panel.html',
        controller: SideMenuSearchDomainPanelController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            startSwitchDomain: '&',
            isFocused: '<',
        },
    });

    SideMenuSearchDomainPanelController.$inject = [
        'ng2SessionService',
        'ng2MonEventsService',
        'Lodash',
        'ng2CoreDomainService',
        '$deepmerge',
        '$stateParams',
        '$state',
        'ng2RedirectWhitelistHelperService',
        'ng2TotalDomainsService',
    ];
    function SideMenuSearchDomainPanelController (
        ng2SessionService,
        ng2MonEventsService,
        Lodash,
        ng2CoreDomainService,
        $deepmerge,
        $stateParams,
        $state,
        ng2RedirectWhitelistHelperService,
        ng2TotalDomainsService,
    ) {
        var vm = this;
        vm.goToDomain = goToDomain;

        function goToDomain () {
            var currentPath = $state.getCurrentPath();
            var pathName = currentPath[currentPath.length - 1] && currentPath[currentPath.length - 1].state.name;
            var domain = getDomain();
            var domainGroup = getDomainGroup(domain);
            var queryParams = $deepmerge($stateParams, {
                domainId: domain.id,
            });

            var to =
                ng2RedirectWhitelistHelperService.isPathWhitelisted(currentPath) && pathName
                    ? pathName : 'base.customer.domain.dashboard';
            var config = {
                reload: 'base.customer.domain',
                inherit: false,
            };
            delete queryParams.page_id;
            delete queryParams.target;
            delete queryParams.subComponent;

            if (typeof domainGroup !== 'undefined') {
                queryParams.domain_group = domainGroup.id;
            } else {
                delete queryParams.domain_group;
                ng2CoreDomainService.removeDomainGroup();
            }

            vm.startSwitchDomain();

            if ('base.customer.domain.uptimes.show' === to) {
                to = 'base.customer.domain.uptimes.index';
            }

            $state
                .go(to, queryParams, config)
                .then(angular.noop, angular.noop)
                .finally(function () {
                    return ng2MonEventsService.run('changed-domain');
                });
        }

        // PROTECTED

        function getDomain () {
            var domains = angular.copy(ng2TotalDomainsService.domainsEssential.getValue());
            if (vm.domain.type === 'Domain') {
                return Lodash.find(domains, { id: vm.domain.id });
            } else {
                return Lodash.find(domains, function (domain) {
                    return Lodash.some(domain.domainGroups, { id: vm.domain.id });
                });
            }
        }

        function getDomainGroup (domain) {
            var result;
            if (vm.domain.type === 'DomainGroup') {
                result = Lodash.find(domain.domainGroups, { id: vm.domain.id });
            }
            return result;
        }
    }
})();
