import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { merge, filter } from 'lodash';
import { SessionService } from '@monsido/core/session/session.service';

@Injectable({
    providedIn: 'root',
})
export class FormUptimeService {
    intervalOptions = [
        { name: this.translateService.getString('1 minute'), value: 60 },
        { name: this.translateService.getString('5 minutes'), value: 300 },
    ];

    constructor (
        private translateService: TranslateService,
        private sessionService: SessionService,
    ) {}

    getIntervalOptions (): any[] {
        if (this.hasCustomer()) {
            const customerIntervals = this.sessionService.customer?.plan_traits.uptime_intervals;
            if (Array.isArray(customerIntervals)) {
                if (customerIntervals.length === 1) {
                    return merge(
                        [],
                        filter(this.intervalOptions, (interval: any) => customerIntervals.indexOf(interval.value) > -1),
                    );
                } else if (customerIntervals.length > 1) {
                    return merge([], this.intervalOptions);
                }
            }
        }
        return [];
    }

    private hasCustomer (): boolean {
        return Boolean(this.sessionService.customer);
    }
}
