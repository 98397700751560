import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionModule } from '@monsido/core/session/session.module';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatar-2';
import {
    AngularSharedComponentsModule,
    TableSearchModule,
    TableModule,
} from '@monsido/angular-shared-components';
import { ScheduleReportFormComponent } from './schedule-report-form.component';
import { ReportCenterPipesModule } from '@monsido/modules/report-center/pipes/report-center-pipes.module';
import { GetUserNameModule } from 'ng2/shared/pipes/get-user-name/get-user-name.module';
import { OrderByModule } from '@monsido/ng2/shared/pipes/order-by/order-by.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [ScheduleReportFormComponent],
    imports: [
        CommonModule,
        AngularSharedComponentsModule,
        SessionModule,
        TranslateModule,
        FormsModule,
        NgbTooltipModule,
        AvatarModule,
        ReportCenterPipesModule,
        GetUserNameModule,
        OrderByModule,
        TableSearchModule,
        TableModule,
    ],
    exports: [ScheduleReportFormComponent],
})
export class ScheduleReportFormModule {}
