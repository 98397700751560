import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.data_privacy.dialogs').component('privacyDialogsFastTrack', {
        templateUrl: 'app/modules/data-privacy/dialogs/fast-track/fast-track.html',
        controller: DataPrivacyViolationPagesController,
        controllerAs: 'vm',
        bindings: {},
    });

    DataPrivacyViolationPagesController.$inject = [
        'ng2MonEventsService',
        '$controller',
        'ng2PrivacyDialogsFastTrackService',
        'DataPrivacyEntitiesFastTrackService',
        'ng2DataPrivacyContentRepoService',
        'ng2SessionService',
        'gettextCatalog',
        'ng2MonPromptService',
        'Lodash',
        '$deepmerge',
    ];

    /* @ngInject */
    function DataPrivacyViolationPagesController (
        ng2MonEventsService,
        $controller,
        ng2PrivacyDialogsFastTrackService,
        DataPrivacyEntitiesFastTrackService,
        ng2DataPrivacyContentRepoService,
        ng2SessionService,
        gettextCatalog,
        ng2MonPromptService,
        Lodash,
        $deepmerge,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.hasContent = hasContent;
        vm.setActiveContent = setActiveContent;
        vm.getInfoTypeTranslation = getInfoTypeTranslation;
        vm.openHelpCenter = openHelpCenter;
        vm.openPages = openPages;
        vm.updateInfoType = updateInfoType;
        vm.getPage = getPage;

        function activate () {
            ng2MonEventsService.run('openFasttrack');
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            ng2PrivacyDialogsFastTrackService.setupSession();
            vm.progress = null;
            vm.activeContent = null;
            vm.loadingInfoTypes = false;
            vm.chartData = [0, 1];
            vm.contents = [];
            getPage();
        }

        function onDestroy () {
            ng2PrivacyDialogsFastTrackService.clear();
        }

        function hasContent () {
            return vm.activeContent != null;
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: 'effect_on_compliance',
                sort_dir: 'desc',
            };

            vm.progress = ng2DataPrivacyContentRepoService.getAll(params).then(function (contents) {
                if (!hasContent()) {
                    setActiveContent(contents[0]);
                } else {
                    var index = Lodash.findIndex(contents, { id: vm.activeContent.id });
                    if (index === -1) {
                        index = 0;
                    }
                    setActiveContent(contents[index]);
                }
                vm.contents = contents;
            }, angular.noop);
        }

        function setActiveContent (content) {
            vm.activeContent = content;
            getInfoTypes();
        }

        function getInfoTypeTranslation (infoType) {
            return ng2PrivacyDialogsFastTrackService.getInfoTypeTranslation(infoType);
        }

        function openHelpCenter (infoType) {
            var params = {
                size: 'lg',
                body: 'privacyDialogsHelpCenter',
                data: {
                    infoType: infoType,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openPages (infoType) {
            var params = {
                size: 'lg',
                body: 'privacyViolationPages',
                data: {
                    infoType: infoType,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function updateInfoType (infoType) {
            var content = vm.activeContent;

            ng2MonPromptService.prompt(infoType.name, {
                parentSelector: '#common-dialog-wrapper',
                size: 'md',
                message: gettextCatalog.getString('Add reason (Optional)'),
            }).then(function (promptAnswer) {
                vm.loadingInfoTypes = true;
                var copy = $deepmerge({}, infoType);
                copy.comment = promptAnswer;
                vm.progress = DataPrivacyEntitiesFastTrackService.updateInfoType(content.id, copy).then(
                    function () {
                        if (vm.activeContent.id === content.id) {
                            getPage();
                            return vm.progress;
                        }
                        vm.loadingInfoTypes = false;
                    },
                    function () {
                        vm.loadingInfoTypes = false;
                    },
                );
            }, angular.noop);
        }

        // PROTECTED

        function getInfoTypes () {
            if (!hasContent()) {
                vm.infoTypes = [];
                return;
            }
            // Copy activeSnippet in current session, so that it doesn't change during async process
            var content = vm.activeContent;
            vm.loadingInfoTypes = true;
            DataPrivacyEntitiesFastTrackService.getInfoTypes(content.id)
                .then(
                    function (infoTypes) {
                        vm.infoTypes = infoTypes;
                        content.setIssueCount(vm.infoTypes);
                        updateDropdowns();
                    },
                    function () {
                        vm.infoTypes = [];
                    },
                )
                .finally(function () {
                    vm.loadingInfoTypes = false;
                    vm.affectedPagesCount = ng2PrivacyDialogsFastTrackService.getAffectedPagesCount(vm.infoTypes);
                    vm.chartData = [vm.affectedPagesCount, ng2SessionService.domain.crawled_pages - vm.affectedPagesCount];
                });
        }

        function updateDropdowns () {
            for (const infoType of vm.infoTypes) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Review'),
                        leftIcon: 'faEye',
                        action: ()=>updateInfoType(infoType),
                    },
                ];

                infoType.dropdown = dropdown;
            }
        }
    }
})();
