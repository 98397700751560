(function () {
    'use strict';
    /**
     * @memberof modules.data_privacy.services.api
     * @ngdoc factory
     * @name DataPrivacyEntitiesFastTrackService
     * @description Service to handle Fast Track endpoint data interaction
     */
    angular
        .module('modules.data_privacy.services.api')
        .factory('DataPrivacyEntitiesFastTrackService', DataPrivacyEntitiesFastTrackService);

    DataPrivacyEntitiesFastTrackService.$inject = ['ng2DataPrivacyFastTrackRepoService'];
    /* @ngInject*/
    function DataPrivacyEntitiesFastTrackService (ng2DataPrivacyFastTrackRepoService) {
        return {
            getAll: getAll,
            get: get,
            getInfoTypes: getInfoTypes,
            updateInfoType: updateInfoType,
        };

        /**
         * @memberOf DataPrivacyEntitiesFastTrackService
         * @desc Get Fast Track Content Data
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function getAll (params, config) {
            return ng2DataPrivacyFastTrackRepoService.getAll(params, config);
        }

        /**
         * @memberOf DataPrivacyEntitiesFastTrackService
         * @desc Get Fast Track Content Data
         * @param {Number} dpContentId - The Data Privacy Content ID
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function get (dpContentId, params, config) {
            return ng2DataPrivacyFastTrackRepoService.get(dpContentId, params, config);
        }

        /**
         * @memberOf DataPrivacyEntitiesFastTrackService
         * @desc Get Fast Track Content Data
         * @param {Number} dpContentId - The Data Privacy Content ID
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function getInfoTypes (dpContentId, params, config) {
            return ng2DataPrivacyFastTrackRepoService.getInfoTypes(dpContentId, params, config);
        }

        /**
         * @memberOf DataPrivacyEntitiesFastTrackService
         * @desc Update Fast Track Info Type
         * @param {Number} dpContentId - The Data Privacy Content ID
         * @param {Object} infoType - The Data Privacy Info Type
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function updateInfoType (dpContentId, infoType, params, config) {
            return ng2DataPrivacyFastTrackRepoService.updateInfoType(dpContentId, infoType, params, config);
        }
    }
})();
