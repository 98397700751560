import { Injectable } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

@Injectable({
    providedIn: 'root',
})
export class BiService {
    private config = {
        headers: {},
    };

    constructor (
        private monEventService: MonEventService,
    ) {}

    log (data: any): void {}

    action (action: string, ref: string, params: any): void {
        this.monEventService.run('trackEvent', action, 'Button', ref);
    }

    pageView (url: string, title: string, ref: string, type: boolean, params: any): void {
        this.callEventTrackPageView(url, title, ref, type, params);
    }

    private callEventTrackPageView (url: string, title: string, ref: string, virtual: boolean, params: any): void {
        if (virtual) {
            this.monEventService.run('trackEvent', url, 'Dialog', window.location.href);
        } else {
            this.monEventService.run('trackPageView', url, title, ref, virtual, params);
        }
    }
}
