import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnChanges, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Report } from '@monsido/modules/models/api/report';
import { TranslateReportPipe } from '@monsido/modules/report-center/pipes/translate-report/translate-report.pipe';
import { TranslateReportService } from '@monsido/modules/report-center/services/translate-report/translate-report.service';
import { TextSearchService } from '@monsido/services/text-search/text-search.service';
import { Filter, EnhancedReport } from '../types';
import { ReportCenterDashboardService } from '@monsido/modules/report-center/services/dashboard/report-center-dashboard.service';
import { ParamService } from '@monsido/ng2/core/param/param.service';
import { TransitionService } from '@uirouter/core';
import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'mon-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() reports: Report[] = [];
    filteredReports: EnhancedReport[] = [];
    model: EnhancedReport[] = [];

    filters$: BehaviorSubject<Filter>;
    filterIsOpen = false;
    @Output() fetchReceivedReports = new EventEmitter<void>();
    @Output() getPage = new EventEmitter<void>();

    mapOfReports: Array<[string, EnhancedReport[]]> = [];
    noneTranslatedKey: string;
    otherTranslatedKey: string;
    transitionUnsubscribe: unknown;
    search$ = new BehaviorSubject('');
    destroy$ = new Subject();

    constructor (
        private readonly translateReportPipe: TranslateReportPipe,
        private readonly translateService: TranslateService,
        private readonly translateReportService: TranslateReportService,
        private readonly textSearchService: TextSearchService,
        private paramService: ParamService,
        private dashboardService: ReportCenterDashboardService,
        private transitionService: TransitionService,
        private activeFeatureService: ActiveFeatureService,
    ) {
        this.noneTranslatedKey = this.translateService.getString('None');
        this.otherTranslatedKey = this.translateService.getString('Consolidated Reports');
        const dictionary = this.translateReportService.getDictionary();
        this.filters$ = new BehaviorSubject({
            modules: [
                {
                    name: dictionary.accessibility,
                    value: 'accessibility',
                },
                {
                    name: dictionary.qa,
                    value: 'qa',
                },
                {
                    name: dictionary.seo,
                    value: 'seo',
                },
                {
                    name: dictionary.policy,
                    value: 'policy',
                },
                {
                    name: dictionary.stats,
                    value: 'stats',
                },
                {
                    name: dictionary.inventory,
                    value: 'inventory',
                },
                {
                    name: dictionary.uptime,
                    value: 'uptime',
                },
                {
                    name: dictionary.heatmaps,
                    value: 'heatmaps',
                },
                {
                    name: dictionary.data_protection,
                    value: 'data_protection',
                },
                {
                    name: this.otherTranslatedKey,
                    value: 'other',
                },
            ],
            'Schedule status': [
                { name: this.translateService.getString('Scheduled'), value: true },
                { name: this.translateService.getString('Not scheduled'), value: false },
            ],
        });
    }

    ngOnInit (): void {
        const { search: term } = this.paramService.getParams();
        this.search$.next(term);
        combineLatest([this.filters$, this.search$]).pipe(
            tap(() => {
                this.updateURLParams();
                this.setMapOfReports();
            }),
            takeUntil(this.destroy$),
        )
            .subscribe();


        this.transitionUnsubscribe = this.transitionService.onSuccess(
            { retained: 'base.customer.global.reportcenter' },
            (transition) => {
                const { search } = transition.targetState().params();
                this.search$.next(search);
            },
        );
    }

    ngOnChanges (): void {
        this.model = cloneDeep(this.reports);
        this.setUsersForReportsModel();
        this.resetFilters();

        const { search: term } = this.paramService.getParams();
        if (term) {
            this.setMapOfReports();
        }
    }

    ngOnDestroy (): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    toggleFilter (): void {
        this.filterIsOpen = !this.filterIsOpen;
    }

    private buildMapOfReports (): Array<[string, EnhancedReport[]]> {
        const map = {};
        this.filteredReports.forEach(report => {
            let key = this.translateReportPipe.transform(report.template.name);
            if (key === this.noneTranslatedKey) {
                key = this.otherTranslatedKey;
            }
            if (!map[key]) {
                map[key] = [report];
            } else {
                map[key].push(report);
            }
        });
        return this.sortReportsByModule(Object.entries(map));
    }

    private sortReportsByModule (reports: Array<[string, EnhancedReport[]]>): Array<[string, EnhancedReport[]]> {
        const dictionary = this.translateReportService.getDictionary();
        const order = [
            dictionary.accessibility,
            dictionary.qa,
            dictionary.seo,
            dictionary.policy,
            dictionary.stats,
            dictionary.inventory,
            dictionary.uptime,
            dictionary.heatmaps,
            dictionary.data_protection,
            this.otherTranslatedKey,
        ];
        const sortedReports: Array<[string, Report[]]> = [];
        for (let i = 0; i < reports.length; i++) {
            const index = order.indexOf(reports[i][0]);
            sortedReports[index] = reports[i];
        }
        return sortedReports.filter(Boolean);
    }

    private resetFilters (): void {
        this.filteredReports = this.model;
        this.mapOfReports = this.buildMapOfReports();
    }

    private filterByModules (filters: Filter[string]): Array<[string, EnhancedReport[]]> {
        const reportsObject = Object.fromEntries(this.mapOfReports);
        const dictionary = this.translateReportService.getDictionary();
        const actualFilters = filters.filter(filter => filter.active);
        if (!actualFilters.length) {
            return this.mapOfReports;
        }

        const result: Array<[string, EnhancedReport[]]> = [];
        for (const filter of actualFilters) {
            const key: string = dictionary[filter.value as string] || this.otherTranslatedKey;
            if (reportsObject[key]?.length) {
                result.push([key, reportsObject[key]]);
            }
        }
        return result;
    }

    private filterByScheduleStatus (filters: Filter[string]): Array<[string, EnhancedReport[]]> {
        const actualFilters = filters.filter(filter => filter.active);
        if (!actualFilters.length) {
            return this.mapOfReports;
        }

        return this.mapOfReports.map<[string, EnhancedReport[]]>(([key, reports]) => {
            const filteredReports = reports.filter(report => {
                return actualFilters.some(filter => filter.value === report.scheduled);
            });
            return [key, filteredReports];
        }).filter(([, reports]) => reports.length);
    }

    private setMapOfReports (): void {
        const search = this.search$.value;
        const filters = this.filters$.value;
        this.resetFilters();
        this.mapOfReports = this.filterByModules(filters.modules);
        this.mapOfReports = this.filterByScheduleStatus(filters['Schedule status']);

        if (search) {
            this.mapOfReports = this.mapOfReports.map<[string, EnhancedReport[]]>(([key, reports]) => {
                const filteredReports = reports.filter(report => this.textSearchService.fuzzySearch(search, report.template.title));
                return [key, filteredReports];
            }).filter(([, reports]) => reports.length);
        }
    }

    private setUsersForReportsModel (): void {
        this.model = this.model.map(report => {
            const users = this.dashboardService.getUniqueUsersByIdFromReport(report);
            let scheduled = false;
            if (users) {
                scheduled = this.dashboardService.isReceivingReport(users);
            }

            const enhancedReport = cloneDeep(report);
            enhancedReport.users = users;
            enhancedReport.scheduled = scheduled;
            return enhancedReport;
        });
    }

    private updateURLParams (): void {
        this.paramService.setParams({
            search: this.search$.value,
        });
    }

}
