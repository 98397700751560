import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuide', {
        templateUrl: 'app/forms/domain-script-guide/domain-script-guide.html',
        controller: DomainScriptGuideController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    DomainScriptGuideController.$inject = [
        'ng2DomainService',
        'domainScriptUrl',
        'domainScriptConsentManagerUrl',
        'ng2FormDomainScriptGuideService',
        'ng2FormDomainScriptGuideHeatmapsService',
        'ng2FormDomainScriptGuidePageFixService',
        'FormDomainScriptGuideStatisticsService',
        'monDialog',
        'ng2FormDomainScriptPageAssistService',
        'ng2FormDomainScriptGuidePageAssistService',
        'FORM_CONSTANT',
        '$timeout',
        '$deepmerge',
        'ng2CookieBannerHelperService',
        'ng2FormDomainScriptCookieBannerService',
        'ng2SessionService',
        'ng2DemoHasAccessService',
        'ng2MonEventsService',
    ];

    /* @ngInject */
    function DomainScriptGuideController (
        ng2DomainService,
        domainScriptUrl,
        domainScriptConsentManagerUrl,
        ng2FormDomainScriptGuideService,
        ng2FormDomainScriptGuideHeatmapsService,
        ng2FormDomainScriptGuidePageFixService,
        FormDomainScriptGuideStatisticsService,
        monDialog,
        ng2FormDomainScriptPageAssistService,
        ng2FormDomainScriptGuidePageAssistService,
        FORM_CONSTANT,
        $timeout,
        $deepmerge,
        ng2CookieBannerHelperService,
        ng2FormDomainScriptCookieBannerService,
        SessionService,
        ng2DemoHasAccessService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.openLegacy = openLegacy;
        vm.getScript = getScript;
        vm.updatePageAssistSettings = updatePageAssistSettings;
        vm.setScriptUnsaved = setScriptUnsaved;
        vm.submit = submit;

        function activate () {
            ng2FormDomainScriptGuideService.showScriptObs.subscribe(function (val) {
                vm.showScript = val;
            });
            vm.isDemo = ng2DemoHasAccessService.isDemoConsentManager();
            vm.saving = false;
            vm.id = 'script' + Math.floor(Math.random() * 100);
            ng2FormDomainScriptGuideService.setDomain(vm.domain);
            var pageAssistSettings = vm.domain.settings ? vm.domain.settings.scripts.page_assist_v2 : {};
            var pageAssistLegacySettings = vm.domain.settings ? vm.domain.settings.scripts.page_assist : {};
            vm.pageAssistModel = ng2FormDomainScriptPageAssistService.formDomainScriptPageAssistModel(pageAssistSettings, pageAssistLegacySettings);
            var consentManagerInitObj = { enabled: false };
            if (vm.domain.settings.scripts.cookie_banner && vm.domain.settings.scripts.cookie_banner.enabled) {
                consentManagerInitObj = vm.domain.settings.scripts.cookie_banner;
            }
            vm.cookieBannerModel = ng2FormDomainScriptCookieBannerService.getFormDomainScriptCookieBannerModel(consentManagerInitObj);

            getDomain().then(function () {
                $timeout(function () {
                    if (vm.setForm) {
                        vm.setForm({ id: vm.monDialogId, form: vm.domainScriptSetupForm });
                    }
                });
                vm.canUseCB = SessionService.customer.plan_traits.cookie_banner;
                vm.saveButtonDisabled = !canSave();
            });
            vm.formUnsaved = false;

            addEventListener();
        }

        function openLegacy () {
            const params = {
                body: FORM_CONSTANT.FORM_DOMAIN_SCRIPT_GUIDE_LEGACY,
                size: 'sm',
                data: {
                    domain: vm.domain,
                    pageAssistModel: vm.pageAssistModel,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getScript () {
            var script = '&lt;script type="text/javascript"&gt;\n';
            script += ng2FormDomainScriptGuideService.tab() + 'window._monsido = window._monsido || {\n';
            script += ng2FormDomainScriptGuideService.getParameters(vm.domain, vm.pageAssistModel, {
                statistics: {
                    value: FormDomainScriptGuideStatisticsService.getValue(),
                    cookieLessTracking: FormDomainScriptGuideStatisticsService.getCookielessTracking(),
                    documentTracking: FormDomainScriptGuideStatisticsService.getDocumentTracking(),
                    documentClass: FormDomainScriptGuideStatisticsService.getDocumentClass(),
                    documentIgnoreClass: FormDomainScriptGuideStatisticsService.getDocumentIgnoreClass(),
                    filledDocumentExtensions: FormDomainScriptGuideStatisticsService.getFilledDocumentExtensions(),
                },
                heatmaps: {
                    value: ng2FormDomainScriptGuideHeatmapsService.getValue(),
                },
                pageAssist: {
                    accessToDomain: ng2FormDomainScriptGuidePageAssistService.getAccessToFeature(vm.domain),
                },
                pageFix: {
                    value: ng2FormDomainScriptGuidePageFixService.getValue(),
                },
            });
            script += ng2FormDomainScriptGuideService.tab() + '};\n';
            if (vm.cookieBannerModel.settings.enabled) {
                if (vm.isDemo) {
                    script +=
                        '<span class="blurred-line width-one"></span><br>' +
                        '<span class="blurred-line width-one indent-one"></span><br>' +
                        '<span class="blurred-line indent-two"></span><br>' +
                        '<span class="blurred-line width-two indent-one"></span>\n' +
                        '<span class="blurred-line indent-two"></span>\n' +
                        '<span class="blurred-line width-one indent-two"></span>\n' +
                        '<span class="blurred-line indent-one"></span>\n' +
                        '<span class="blurred-line width-one"></span>\n' +
                        '<span class="blurred-line width-two indent-one"></span>\n' +
                        '<span class="blurred-line indent-one"></span>\n' +
                        '<span class="blurred-line width-one indent-two"></span>\n' +
                        '<span class="blurred-line indent-one"></span>\n' +
                        '<span class="blurred-line indent-one"></span>\n' +
                        '<span class="blurred-line"></span';
                } else {
                    script +=
                        ng2FormDomainScriptGuideService.tab() +
                        'window._monsidoConsentManagerConfig = window._monsidoConsentManagerConfig || {\n';
                    script += ng2FormDomainScriptGuideService.getCookieBannerParameters(vm.domain, vm.cookieBannerModel, {
                        cookieBanner: {
                            accessToDomain: ng2CookieBannerHelperService.isAbleToSetupScript(vm.domain),
                        },
                    });
                }
            }
            script += '&lt;/script&gt;\n';
            script += getScriptSrc() + '\n';
            script += getConsentManagerScriptSrc();
            vm.saveButtonDisabled = !canSave();
            return script;
        }

        function submit () {
            ng2FormDomainScriptGuideService.setPageAssistEdit(vm.pageAssistModel); // Ensure that PageAssist settings is set on submit
            ng2FormDomainScriptGuideService.setCookieBannerEdit(vm.cookieBannerModel);
            vm.saving = true;
            var isAdmin = SessionService.hasRole('customer_admin');
            var isCMEnable = vm.cookieBannerModel.settings.enabled;
            var isFirstTimeUseCM = vm.cookieBannerModel.settings.firstTimeEnableCM;
            var linkForOverview = vm.cookieBannerModel.settings.cookieOverviewLinkEnabled;
            var pageHref = 'base.customer.domain.data_privacy.consent_overview.cookie_overview';
            ng2DomainService
                .update(vm.domain.id, {
                    settings: {
                        scripts: ng2FormDomainScriptGuideService.getDomainSettings(),
                    },
                })
                .then(function () {
                    return runGetDomain().then(setDomain, angular.noop);
                })
                .then(function () {
                    if (vm.domainScriptSetupForm) {
                        vm.domainScriptSetupForm.$setPristine();
                    }
                })
                .then(function () {
                    monDialog.close(vm.monDialogId, vm.pageAssistModel);
                })
                .finally(function () {
                    vm.saving = false;
                    if (isAdmin && isCMEnable && isFirstTimeUseCM && !linkForOverview) {
                        ng2FormDomainScriptGuideService.confirmOpenCookieOverviewPagePopup(vm.domain.id, pageHref, false);
                    }
                });
        }

        function updatePageAssistSettings (settings) {
            vm.pageAssistSettings = settings;
            ng2FormDomainScriptGuideService.setScriptSettingBlock('page_assist', settings, true);
        }

        // PROTECTED

        function getDomain () {
            vm.loading = true;

            return runGetDomain()
                .then(setDomain, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setFeatureDomainSettings () {
            ng2FormDomainScriptGuideHeatmapsService.setValue(vm.domain.settings.scripts.heatmap.enabled);

            ng2FormDomainScriptGuidePageFixService.setValue(vm.domain.settings.scripts.page_correct.enabled);

            FormDomainScriptGuideStatisticsService.setValue(vm.domain.settings.scripts.statistics.enabled);
            FormDomainScriptGuideStatisticsService.setCookielessTracking(vm.domain.settings.scripts.statistics.cookieless_tracking);
            FormDomainScriptGuideStatisticsService.setDocumentTracking(vm.domain.settings.scripts.statistics.document_tracking.enabled);
            FormDomainScriptGuideStatisticsService.setDocumentExtensions(
                vm.domain.settings.scripts.statistics.document_tracking.document_ext,
            );
            FormDomainScriptGuideStatisticsService.setDocumentClass(vm.domain.settings.scripts.statistics.document_tracking.document_cls);
            FormDomainScriptGuideStatisticsService.setDocumentIgnoreClass(
                vm.domain.settings.scripts.statistics.document_tracking.document_ignore_cls,
            );
        }

        function getScriptSrc () {
            return '&lt;script type="text/javascript" async src="' + domainScriptUrl + '"&gt;&lt;/script&gt;';
        }

        function getConsentManagerScriptSrc () {
            if (vm.cookieBannerModel.settings.enabled && ng2CookieBannerHelperService.isAbleToSetupScript(vm.domain)) {
                return '&lt;script type="text/javascript" src="' + domainScriptConsentManagerUrl + '"&gt;&lt;/script&gt;';
            }
            return '';
        }

        function runGetDomain () {
            return ng2DomainService.get(vm.domain.id);
        }

        function setDomain (domain) {
            vm.originalSettings = $deepmerge({}, domain.settings.scripts.page_assist_v2);
            vm.domain = domain;
            vm.pageAssistModel.setLegacySettings(domain.settings.scripts.page_assist);
            vm.pageAssistModel.setSettings(domain.settings.scripts.page_assist_v2);
            vm.cookieBannerModel.setSettings(domain.settings.scripts.cookie_banner);
            ng2FormDomainScriptGuideService.setDomain(domain);
            setFeatureDomainSettings();
        }

        function canSave () {
            return (
                !vm.saving &&
                (!vm.pageAssistModel.settings.enabled || vm.pageAssistModel.settings.canSavePaSetting) &&
                (!vm.cookieBannerModel.settings.enabled || vm.cookieBannerModel.canSaveModel())
            );
        }

        function addEventListener () {
            vm.eventId = ng2MonEventsService.addListener('pageAssistScriptSettingsUpdate', function () {
                vm.pageAssistModel.settings = ng2FormDomainScriptGuideService.getDomainSettings().page_assist_v2;
                vm.originalSettings = vm.pageAssistModel.settings;
            });
        }

        function setScriptUnsaved () {
            vm.domainScriptSetupForm.$setDirty();
            vm.formUnsaved = true;
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
