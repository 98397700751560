import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CopyModule, LayoutModule, TableModule, MonEventTriggerModule, IconsModule } from '@monsido/angular-shared-components';
import { IssueComponent } from './components/issue/issue.component';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [CommonModule, TranslateModule, LayoutModule, TableModule, CopyModule, MonEventTriggerModule, IconsModule],
    declarations: [IssueComponent],
    exports: [IssueComponent],
})
export class MonIssueModule {}
