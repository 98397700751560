import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { merge } from 'lodash';
import {
    PAGE_ASSIST_SCRIPT_CONSTANT,
} from '@client/app/forms/domain-script-guide/features/pa-script-settings/pa-script.constant';

@Injectable({
    providedIn: 'root',
})
export class FormDomainScriptGuideLegacyPageAssistService {
    private themes = [
        {
            name: this.translateService.getString('Light'),
            value: 'light',
        },
        {
            name: this.translateService.getString('Dark'),
            value: 'dark',
        },
    ];

    private directions = [
        {
            name: this.translateService.getString('Bottom left'),
            value: 'left',
        },
        {
            name: this.translateService.getString('Bottom right'),
            value: 'right',
        },
    ];

    constructor (
        private translateService: TranslateService,
    ) {}

    getThemes (): any[] {
        return merge([], this.themes);
    }

    getDirections (): any[] {
        return merge([], this.directions);
    }

    getTheme (theme: string) {
        if (!theme || theme === 'white') {
            return 'light';
        }
        return theme;
    }

    getDirection (direction: string) {
        if (
            PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.LEFT === direction ||
          PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.RIGHT === direction
        ) {
            return direction;
        }

        return direction && direction.indexOf('right') > -1 ? 'right' : 'left';
    }
}
