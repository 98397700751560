import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { DomainGroup } from '@monsido/ng2/modules/models/api/domain-group';
import { ErrorHandler } from 'app/core/services/error-handler/error-handler';
import { DomainGroupsEntity, DomainInterface } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

export type DomainRepoGetParamsType = RequestParamsType & BaseRepoRequestType;

export type DomainRepoGetAllParamsType = {
    page_size: number;
    page?: number;
    mini?: boolean;
    sort_by?: string;
    sort_dir?: string;
    search?: string;
};

export type DomainRepoRescanParamsType = {
    full_cookie_scan?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class DomainRepoService extends BaseRepo {
    constructor (private apiService: ApiService, private errorHandler: ErrorHandler, protected sessionService: SessionService) {
        super();
    }

    getAll (params: DomainRepoGetAllParamsType): Promise<CollectionInterface<Domain>> {
        return this.apiService.get<CollectionInterface<DomainInterface>>('domains', params).then(domains => {
            domains.forEach(domain => {
                if (domain?.domain_groups) {
                    domain.domain_groups = this.domainGroupToModels(domain.domain_groups);
                }
            });
            return this.domainToModels(domains);
        });
    }

    get (id: number, params: DomainRepoGetParamsType = {}): Promise<Domain> {
        params = this.setGroupId(params);
        return this.apiService.get<Domain>(`domains/${id}`, params).then(domain => {
            if (domain?.domain_groups) {
                this.domainGroupToModels(domain.domain_groups);
            }
            return new Domain(domain);
        });
    }

    create (params: unknown): Promise<Domain> {
        return this.apiService.post<Domain>('domains', params).then(
            domain => {
                if (domain?.domain_groups) {
                    this.domainGroupToModels(domain.domain_groups);
                }
                return new Domain(domain);
            },
            error => {
                this.errorHandler.noopError(error, 'info');
                return Promise.reject(error);
            },
        );
    }

    rescan (id: number, params: DomainRepoRescanParamsType = {}): Promise<void> {
        return this.apiService.post(`domains/${id}/rescan`, params);
    }

    update (id: number, data: Partial<DomainInterface>): Promise<Domain> {
        data = data || {};
        return this.apiService.patch<Domain>(`domains/${id}`, data).then(
            domain => {
                if (domain?.domain_groups) {
                    this.domainGroupToModels(domain.domain_groups);
                }
                return new Domain(domain);
            },
            error => {
                this.errorHandler.noopError(error, 'info');
                return Promise.reject(error);
            },
        );
    }

    updateDomainGroupRules (id: number, data?: unknown): Promise<Domain> {
        return this.apiService.post(`domains/${id}/domain_groups/update`, data);
    }

    updateSingleDomainGroupRules (id: number, groupId: number): Promise<unknown> {
        return this.apiService.post(`domains/${id}/domain_groups/${groupId}/update`);
    }

    getDomainGroup (domainId: number, groupId: number): Promise<DomainGroup> {
        return this.apiService.get<DomainGroup>(`domains/${domainId}/domain_groups/${groupId}`).then(data => {
            return new DomainGroup(data);
        },
        error => {
            this.errorHandler.noopError(error, 'info');
            return Promise.reject(error);
        });
    }

    destroy (id: number): Promise<void> {
        return this.apiService.delete(`domains/${id}`);
    }

    stopCrawl (id: number): Promise<void> {
        return this.apiService.delete(`domains/${id}/crawls/running`);
    }

    getExcludedIps (id: number): Promise<string[]> {
        return this.apiService.get(`domains/${id}/excluded_ips`);
    }

    private domainGroupToModels (models: DomainGroupsEntity[]): DomainGroup[] {
        return models.map(data => new DomainGroup(data));
    }

    private domainToModels (models: CollectionInterface<DomainInterface>): CollectionInterface<Domain> {
        const domains: CollectionInterface<Domain> = models.map(data => new Domain(data));
        domains.total = models.total;
        domains.currentPage = models.currentPage;
        domains.perPage = models.perPage;
        return domains;
    }
}
