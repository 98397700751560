import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { SessionService } from '@monsido/core/session/session.service';
import { DeviceDetectorBrowser, DeviceDetectorService } from '@client/app/modules/extension/services/device-detector.service';
import { LoginService } from '@client/app/core/services/login/login.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { chromeExtensionId, edgeExtensionId, firefoxExtensionId, firefoxExtensionURL } from '@monsido/core/constants/general.constant';
import { EXTENSION_DEEPLINK_MODULE } from '@monsido/modules/extension/constants/extension-deeplink.constant';
import { Chrome } from '@client/app/core/constants/helpers/chrome';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { JsonObject } from '@angular-devkit/core';
import { ExtensionParamsInterface } from '@monsido/modules/issue/services/issue.service';

export type ExtensionDeeplinkMethod = 'beacon' | 'query';
export interface ExtensionDeeplinkParams extends ExtensionParamsInterface {
    module?: string,
}
type ExtensionBeaconResponse = {
    installed: boolean
};
export type ExtensionInformation = {
    url: string,
    downloadLabel: string,
    label: string,
    image: string
};
export type DeeplinkMessageParams = {
    url: string,
    params: ExtensionDeeplinkParams
    isSudo?: boolean,
    sudoParams?: {
        accountId: number,
        token: string,
        refreshToken: string,
        baseURL: string
    }
};
export type DeeplinkMessagePayload = {
    type: string,
    action: string,
    params: DeeplinkMessageParams
};

@Injectable({
    providedIn: 'root',
})
export class ExtensionService {
    private hasV2 = false;
    private hasExtension: boolean | null = null;
    private currentBrowser: DeviceDetectorBrowser | null = null;
    readonly extensions: Record<DeviceDetectorBrowser, ExtensionInformation>;

    constructor (
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService,
        private sessionService: SessionService,
        private deviceDetectorService: DeviceDetectorService,
        private loginService: LoginService,
        private monEventsService: MonEventService,
    ) {
        this.extensions = {
            chrome: {
                url: 'https://chrome.google.com/webstore/detail/monsido-extension/' + chromeExtensionId,
                downloadLabel: this.translateService.getString('Download the Chrome extension'),
                label: this.translateService.getString('Chrome extension'),
                image: 'images/optimize-extension-chrome.png',
            },
            edge: {
                url: 'https://microsoftedge.microsoft.com/addons/detail/' + edgeExtensionId,
                downloadLabel: this.translateService.getString('Download the Edge add-on'),
                label: this.translateService.getString('Edge add-on'),
                image: 'images/optimize-extension-edge.png',
            },
            firefox: {
                url: firefoxExtensionURL,
                downloadLabel: this.translateService.getString('Download the Firefox add-on'),
                label: this.translateService.getString('Firefox add-on'),
                image: 'images/optimize-extension-firefox.png',
            },
            other: {
                url: '',
                downloadLabel: '',
                label: '',
                image: '',
            },
        };

        this.currentBrowser = this.deviceDetectorService.browser;
    }

    async isInstalled (): Promise<boolean> {
        if (this.hasExtension !== null) {
            return this.hasExtension;
        }

        switch (this.deviceDetectorService.browser) {
            case 'chrome':
                const res = await this.isChromeExtensionInstalled(chromeExtensionId);
                return res;
            case 'edge':
                return this.isChromeExtensionInstalled(edgeExtensionId);
            case 'firefox':
                return this.isFirefoxExtensionInstalled(firefoxExtensionId);
            default:
                this.hasExtension = false;
                return false;
        }
    }

    getExtensionDeepLink (params: ExtensionParamsInterface): string {
        const domainId = params.page?.domain_id;
        const accountId = this.sessionService.agreement?.account.id;
        let issueId: number | null = params.issueId;

        let res = '';
        let accType: string | null = null;
        let accCheck: number | null = null;
        let baseUrl = params.page?.url;
        if (!baseUrl) {
            return '';
        }
        if (baseUrl.indexOf('?') === -1) {
            baseUrl += '?';
        } else {
            baseUrl += '&';
        }

        if (!params.type) {
            return baseUrl + 'monsidoDomainId=' + domainId + '&monsidoAccountId=' + accountId;
        }
        if (!params.data) {
            return baseUrl + 'monsidoDomainId=' + domainId + '&monsidoAccountId=' + accountId + '&monsidoModule=' + params.type;
        }
        switch (params.type) {
            case EXTENSION_DEEPLINK_MODULE.BrokenImages.id:
            case EXTENSION_DEEPLINK_MODULE.BrokenLinks.id:
                issueId = issueId || params.data.link_id || null;
                break;
            case EXTENSION_DEEPLINK_MODULE.Misspelling.id:
            case EXTENSION_DEEPLINK_MODULE.PolicyIssue.id:
            case EXTENSION_DEEPLINK_MODULE.SeoIssue.id:
                issueId = issueId || params.data.id || null;
                break;
            case EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id:
                accType = params.data.check?.issue_abbr.toUpperCase() || null;
                accCheck = params.data.check?.id || null;
                issueId = issueId || (params.data.error ? params.data.error.id : null) || null;
                break;
            case EXTENSION_DEEPLINK_MODULE.Readability.id:
            case EXTENSION_DEEPLINK_MODULE.Heatmaps.id:
            default:
                issueId = null;
        }

        if (params.type === EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id) {
            res =
                baseUrl +
                'monsidoDomainId=' +
                domainId +
                '&monsidoAccountId=' +
                accountId +
                '&monsidoModule=' +
                params.type +
                '&monsidoAccType=' +
                accType +
                '&monsidoAccCheckId=' +
                accCheck;
            if (issueId) {
                res += '&monsidoIssueId=' + issueId;
            }
        } else {
            res = baseUrl + 'monsidoDomainId=' + domainId + '&monsidoAccountId=' + accountId + '&monsidoModule=' + params.type;
            if (issueId) {
                res += '&monsidoIssueId=' + issueId;
            }
        }

        return res;
    }

    getCurrentExtensionInfo (): ExtensionInformation | null {
        if (!this.currentBrowser) {
            return null;
        }
        return this.extensions[this.currentBrowser];
    }

    sendDeeplinkMessage (action: string, params: DeeplinkMessageParams): void {
        const message: DeeplinkMessagePayload = {
            type: 'deeplink',
            action: action,
            params: params || {},
        };

        if (this.sessionService.isSudo && this.sessionService.agreement) {
            message.params.isSudo = true;
            message.params.sudoParams = {
                accountId: this.sessionService.agreement.account.id,
                token: this.loginService.getAuthToken(),
                refreshToken: this.loginService.getRefreshToken(),
                baseURL: this.loginService.getApiPath() + '/api/',
            };
        }

        window.postMessage(this.sanitizePayload(message));
    }

    getExtensionDeeplinkParams (params: ExtensionParamsInterface): ExtensionDeeplinkParams {
        const domainId = params?.page?.domain_id;
        const accountId = this.sessionService.agreement?.account.id;
        const res: ExtensionDeeplinkParams = Object.assign(params, { accountId, domainId });
        let pageId: number | null = res.page?.id || null;
        delete res.page;

        Object.keys(res).forEach(key => {
            if (res[key] == null) {
                delete res[key];
            }
        });
        let issueId: number | null = params.issueId;
        let issueAbbr: string | null = null;
        let checkId: number | null = null;
        let pageLinkId: number | null = null;
        let word: string | null = null;

        if (pageId) {
            res.pageId = pageId;
        }
        if (!params.type) {
            return res;
        }
        if (!params.data) {
            res.module = params.type;
            return res;
        }

        switch (params.type) {
            case EXTENSION_DEEPLINK_MODULE.BrokenImages.id:
            case EXTENSION_DEEPLINK_MODULE.BrokenLinks.id:
                issueId = issueId || params.data.link_id || null;
                pageId = params.data.page_id || null;
                pageLinkId = params.data.link_id || null;
                break;
            case EXTENSION_DEEPLINK_MODULE.Misspelling.id:
            case EXTENSION_DEEPLINK_MODULE.PolicyIssue.id:
            case EXTENSION_DEEPLINK_MODULE.SeoIssue.id:
                issueId = issueId || params.data.id || null;
                word = params.data.word || null;
                break;
            case EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id:
                issueAbbr = params.data.check?.issue_abbr.toUpperCase() || null;
                checkId = params.data.check?.id || null;
                issueId = issueId || (params.data.error ? params.data.error.id : '') || null;
                break;
            case EXTENSION_DEEPLINK_MODULE.Readability.id:
            case EXTENSION_DEEPLINK_MODULE.Heatmaps.id:
            default:
                issueId = null;
        }
        if (params.type === EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id) {
            res.module = EXTENSION_DEEPLINK_MODULE.AccessibilityIssue.id;
            if (issueAbbr) {
                res.issueAbbr = issueAbbr;
            }
            if (checkId) {
                res.checkId = checkId;
            }
            if (issueId) {
                res.issueId = issueId;
            }
        } else {
            res.module = params.type;
            if (issueId) {
                res.issueId = issueId;
            }

            if (pageId) {
                res.pageId = pageId;
            }

            if (pageLinkId) {
                res.pageLinkId = pageLinkId;
            }

            if (word) {
                res.word = word;
            }
        }
        return res;
    }

    getDeeplinkMethod (): ExtensionDeeplinkMethod {
        return this.hasV2 ? 'beacon' : 'query';
    }

    async openExtensionDeeplink (payload): Promise<void> {
        const isExtensionInstalled = await this.isInstalled();

        if (!isExtensionInstalled) {
            this.openExtensionGuideDialog(payload.callback);
            return;
        }
        const url = payload.page.url;
        let deepLinkParams: ExtensionDeeplinkParams = {
            issueId: 0,
        };
        deepLinkParams = Object.assign(deepLinkParams, payload);
        delete deepLinkParams.callback;

        const params = this.getExtensionDeeplinkParams(deepLinkParams);

        if (this.getDeeplinkMethod() === 'beacon') {
            this.sendDeeplinkMessage('deeplink/openPage', {
                url: url,
                params: params,
            });
        }
    }

    sanitizePayload (payload: unknown): JsonObject {
        return JSON.parse(JSON.stringify(payload));
    }

    async isChromeExtensionInstalled (extensionId: string): Promise<boolean> {
        return new Promise(resolve => {
            if (Chrome !== null && typeof Chrome === 'object' && Chrome.runtime !== null && typeof Chrome.runtime === 'object') {
                Chrome.runtime.sendMessage(extensionId, { message: 'monsido-extension-installed' }, (reply: ExtensionBeaconResponse) => {
                    if (Chrome.runtime.lastError && Chrome.runtime.lastError.message) {
                        this.hasExtension = false;
                        resolve(false);
                    } else {
                        this.hasExtension = Boolean(reply && reply.installed);
                        this.isExtensionV2Installed(extensionId);
                        resolve(this.hasExtension);
                    }
                });
            } else {
                this.hasExtension = false;
                resolve(false);
            }
        });
    }

    async isFirefoxExtensionInstalled (extensionId: string): Promise<boolean> {
        const beacon = document.querySelectorAll('[data-monsido-extension-id=\'' + extensionId + '\']');
        this.isExtensionV2Installed(extensionId);
        return beacon.length > 0;
    }

    isExtensionV2Installed (extensionId: string): boolean {
        const beacon = document.querySelectorAll(
            '[data-monsido-extension-id=\'' + extensionId + '\'][monsido-extension-version=\'' + 2 + '\']',
        );
        this.hasV2 = beacon.length > 0;
        return this.hasV2;
    }

    openExtensionGuideDialog (cb: () => void): void {
        const params = {
            body: 'extensionGuideDialog',
            size: 'sm',
            classes: 'fade-animation middle center',
        };

        const callback = typeof cb === 'function' ? cb : (): void => {};

        this.monEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
    }

}
