import { Injectable } from '@angular/core';

import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ChartFactory } from '@client/app/blocks/service/charts/base-chart/chart-factory';
import { ChartsDataHelper } from '@client/app/blocks/helpers/charts/charts-data-helper';

@Injectable({
    providedIn: 'root',
})
export class PolicyLineChartService {
    constructor (private translateService: TranslateService) {}

    createPolicyLineChartService () {
        const policyLineChartService = new ChartFactory(new LineChart());
        policyLineChartService.setOptions([
            this.translateService.getString('Issues'),
            this.translateService.getString('Content'),
        ]);
        policyLineChartService.setColors([
            '#da5c5b', '#f49956', '#ff9d4e', '#a9dad6',
        ]);
        policyLineChartService.setOverrides([2, 2]);
        policyLineChartService.setSeries([
            this.translateService.getString('Unwanted'),
            this.translateService.getString('Required'),
            this.translateService.getString('Content with issues'),
            this.translateService.getString('Scanned content'),
        ]);
        policyLineChartService.setData([
            'policy_with_violations_count',
            'policy_with_required_count',
            'pages_with_policy_matches_count',
            ['documents_count', 'page_count'],
        ], new ChartsDataHelper(
            'time'));
        return policyLineChartService;
    }
}
