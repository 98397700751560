(function () {
    'use strict';

    angular.module('modules.business-intelligence').run(runDialog);

    runDialog.$inject = ['monDialog', 'ng2VirtualUrlService', 'ng2ErrorHandler', 'ng2BiService'];
    /* @ngInject */
    function runDialog (monDialog, virtualUrlService, ng2ErrorHandler, biService) {
        monDialog.init(function (params) {
            try {
                var virtualUrl = virtualUrlService.get(params.body, params);
                var newParams = virtualUrlService.getParams(params.body, params);
                var ref = virtualUrlService.getRef(params);
                var title = params.title || 'Undefined';
                biService.pageView(virtualUrl, title, ref, true, newParams);
            } catch (e) {
                ng2ErrorHandler.noopError(e, 'error');
            }
        });
    }
})();
