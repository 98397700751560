<img src="/images/stars.svg" alt="">

@if (showLoader()) {
    <div class="mt-2">
        <mon-bot-loading-spinner [message]="'Authenticating'"></mon-bot-loading-spinner>
    </div>
} @else {
    <h1>Welcome to the Policy Assistant!</h1>
    @if (!userAuthenticated()) {
        <p>By using the assistant, you are agreeing to Acquia’s <a target="_blank" class="opt-link" aria-describedby="linkOpensInNewTab" rel="noopener" href="https://www.acquia.com/legal/ai-user-guidelines">AI user guidelines <mon-icon [monIconDirect]="'faUpRightFromSquare'"></mon-icon></a> and understand that responses by the assistant are for informational purposes only.</p>
        <button class="btn opt-button opt-button-main"
            (click)="userAgreed.emit(true)"
            type="button">Agree</button>
    }
}
