import { Injectable } from '@angular/core';
import {
    PagePerformancePageDetailsService,
} from '@client/app/modules/page-performance/services/page-performance-page-details/page-performance-page-details.service';
import { PAGE_PERFORMANCE_CATEGORY } from '@client/app/modules/page-performance/constants/page-performance-category';

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceOpportunitiesService {
    constructor (
        private pagePerformancePageDetailsService: PagePerformancePageDetailsService,
    ) {}

    getAudits (): any[] {
        const report = this.getReport();
        if (report) {
            const auditRefIds = this.pagePerformancePageDetailsService.getUnassignedAuditIds(
                report.categories[PAGE_PERFORMANCE_CATEGORY.PERFORMANCE].auditRefs,
            );

            return Object.values(report.audits).filter((audit: any) => {
                return audit.details?.type === 'opportunity' && auditRefIds.indexOf(audit.id) > -1;
            });
        }

        return [];
    }

    private getReport (): any {
        return this.pagePerformancePageDetailsService.getReport();
    }
}
