import { Injectable } from '@angular/core';
import { IssueInterface } from '@client/app/modules/accessibility/interfaces/issue-interface';

export type ComplianceAccessibilityResultTypes = 'error' | 'warning' | 'review' | string;

export type ComplianceAccessibilityFilterParams = {
    only_errors?: boolean;
    abbreviations?: string[];
    result_types: Array<ComplianceAccessibilityResultTypes>;
};

@Injectable({
    providedIn: 'root',
})
export class ComplianceAccessibilityService {

    getPercentageFromResultType (resultType: ComplianceAccessibilityResultTypes, pageCountAttr: string, accessibilityChecks: IssueInterface[], summary: Record<string, number>): number {
        let resultTypeAr: string[] = [];
        switch (resultType) {
            case 'errors':
                resultTypeAr = ['error'];
                break;
            case 'errors_and_warnings':
                resultTypeAr = ['error', 'warning'];
                break;
            default:
                resultTypeAr = [];
        }

        const pageErrors = this.getTotalPercentageCountByResultType(resultTypeAr, pageCountAttr, accessibilityChecks, summary);
        const checks = this.getChecksCountByResultType(resultTypeAr, accessibilityChecks);
        const result = 100 - pageErrors / checks;

        if (isNaN(result)) {
            return 0;
        } else {
            return result;
        }
    }

    getChecksCountByResultType (resultTypes: ComplianceAccessibilityResultTypes[], accessibilityChecks: IssueInterface[]): number {
        const checks = this.filterAccessibilityChecksByResultTypes(accessibilityChecks, {
            result_types: resultTypes,
        });
        return checks.length;
    }

    getTotalPercentageCountByResultType (resultTypes: string[], pageCountAttr: string, accessibilityChecks: IssueInterface[], summary: Record<string, number>): number {
        pageCountAttr = pageCountAttr || 'pages';
        const params = {
            result_types: resultTypes,
            only_errors: true,
        };
        const checks = this.filterAccessibilityChecksByResultTypes(accessibilityChecks, params);
        return checks.reduce(function (num, check) {
            const pageCount = typeof check.pages_count === 'number' ? check.pages_count : parseInt(check.pages_count);
            return num + (pageCount / summary[pageCountAttr]) * 100;
        }, 0);
    }

    filterAccessibilityChecksByResultTypes (checks: IssueInterface[], params: ComplianceAccessibilityFilterParams): IssueInterface[] {
        if (!Array.isArray(checks)) {
            return [];
        }

        params = params || {};
        params.only_errors = Boolean(params.only_errors);
        params.abbreviations = params.abbreviations || [];
        params.abbreviations.map(function (abbr) {
            return abbr.toUpperCase();
        });

        if (!Array.isArray(params.result_types) || params.result_types.length === 0) {
            params.result_types = ['error', 'warning', 'review'];
        }

        return checks.filter(function (c) {
            if (c.ignored) {
                return false;
            }

            if (params.only_errors && c.pages_count === 0) {
                return false;
            }

            if (params.result_types.indexOf(c.result_type) === -1) {
                return false;
            }

            if (Array.isArray(params.abbreviations) && params.abbreviations.length > 0) {
                if (params.abbreviations.indexOf(c.issue_abbr) === -1) {
                    return false;
                }
            }

            return true;
        });
    }

}
