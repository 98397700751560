import { Injectable } from '@angular/core';
import { DataPrivacyViolationModel } from '@client/app/models/data-privacy/violation.model';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { DataPrivacyViolationType } from '@monsido/ng2/modules/data-privacy/interfaces/data-privacy-violation.type';

export type DataPrivacyViolationRequestType = {
    'group[]'?: string[],
    regulations?: string[],
    status?: string,
    info_type?: number,
} & BaseRepoRequestType;

@Injectable({
    providedIn: 'root',
})
export class DataPrivacyViolationRepoService extends BaseRepo {

    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    getAll (pageId: number, params: DataPrivacyViolationRequestType): Promise<CollectionInterface<DataPrivacyViolationModel>> {
        params = params || {};
        params = this.setGroupId(params);
        if (pageId) {
            return this.apiService.get<CollectionInterface<DataPrivacyViolationType>>(
                'domains/' + this.getDomainId() + '/pages/' + pageId + '/data_protection/violations',
                params,
            ).then((data) => this.toModels(data));
        } else {
            return this.apiService.get<CollectionInterface<DataPrivacyViolationType>>('domains/' + this.getDomainId() + '/data_protection/violations', params)
                .then((data) => this.toModels(data));
        }
    }

    update (violation: DataPrivacyViolationModel): Promise<DataPrivacyViolationModel> {
        const params: BaseRepoRequestType = this.setGroupId({});

        return this.apiService.patch<DataPrivacyViolationType>(
            'domains/' + this.getDomainId() + '/data_protection/violations/' + violation.id,
            violation,
            params,
        ).then((data) => this.toModel(data));
    }

    updatePageViolation (pageId: number, violation: DataPrivacyViolationModel): Promise<DataPrivacyViolationModel> {
        const params: BaseRepoRequestType = this.setGroupId({});

        return this.apiService.patch<DataPrivacyViolationType>(
            'domains/' + this.getDomainId() + '/pages/' + pageId + '/data_protection/violations/' + violation.id,
            violation,
            params,
        ).then((data) => this.toModel(data));
    }

    private toModels (models: CollectionInterface<DataPrivacyViolationType>): CollectionInterface<DataPrivacyViolationModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: DataPrivacyViolationType): DataPrivacyViolationModel {
        return new DataPrivacyViolationModel(model);
    }
}
