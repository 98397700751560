(function () {
    'use strict';

    angular.module('modules.business-intelligence').run(runRouter);

    runRouter.$inject = ['$transitions', 'ng2BiService', '$state'];
    /* @ngInject */
    function runRouter ($transitions, biService, $state) {
        $transitions.onSuccess({}, function (transition) {
            var ref; var title;
            if ($state.href(transition.from().name)) {
                ref = $state.href(transition.from().name, {}, { absolute: true }).split('?')[0];
            } else {
                ref = undefined;
            }
            title = transition.to().data.title || 'Undefined';
            biService.pageView(
                $state.href(transition.to().name, {}, { absolute: true }).split('?')[0],
                title,
                ref,
                false,
                clean(transition.params('to')),
            );
        });
    }

    function clean (obj) {
        var tempObj = Object.assign({}, obj);
        for (var propName in tempObj) {
            if (tempObj[propName] === null || tempObj[propName] === undefined) {
                delete tempObj[propName];
            }
        }
        return tempObj;
    }
})();
