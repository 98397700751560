import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type PageClarityRepoUpageCheckErrorRequestType = RequestParamsType & BaseRepoRequestType;
type PageClarityRepoGetCheckErrorsRequestType = RequestParamsType & {
    result_type: string,
};
type PageClarityRepoCheckType = {
    errors_count: number,
    id: number,
    name: string,
};

export type PageClarityRepoCheckErrorType = {
    id: string,
    result_type: string,
    ignored_reviewed: string,
    clarity_accessibility_check_id: string,
    page_id: string,
    page_number: string,
    element_tree_path: string,
    reason: string,
    clarity_comments: string,
    comments: {
        id: string,
        user_id: string,
        subject_id: string,
        subject_type: string,
        comment: string,
        created_at: string,
        updated_at: string
    }[],
    created_at: string,
    updated_at: string,
    latest_check_at: string
};

@Injectable({
    providedIn: 'root',
})
export class PageClarityRepoService extends BaseRepo {

    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    getAllChecks (pageId: number): Promise<PageClarityRepoCheckType[]> {
        return this.apiService.get('domains/' + this.getDomainId() + '/pages/' + pageId + '/clarity/checks');
    }

    getCheckErrors (pageId: number, checkId: number, params: PageClarityRepoGetCheckErrorsRequestType, config?):
    Promise<MonTableCollection<PageClarityRepoCheckErrorType>> {
        return this.apiService.get(
            'domains/' + this.getDomainId() + '/pages/' + pageId + '/clarity/checks/' + checkId + '/errors',
            params,
            config,
        );
    }

    updateCheckError (pageId: number, checkId: number, errorId: number, params: PageClarityRepoUpageCheckErrorRequestType): Promise<void> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.patch(
            'domains/' + this.getDomainId() + '/pages/' + pageId + '/clarity/checks/' + checkId + '/errors/' + errorId,
            params,
        );
    }
}
