import { Injectable } from '@angular/core';
import { ClarityRepoService } from '@client/app/services/api/clarity-repo/clarity-repo.service';
import { DemoHasAccessService } from '@client/app/modules/demodata/services/demo-has-access.service';
import {
    DemoPdfClarityRepoService,
} from '@client/app/modules/demodata/endpoints/demo-pdf-clarity-repo/demo-pdf-clarity-repo.service';


@Injectable({
    providedIn: 'root',
})
export class ClarityService {
    constructor (
        private clarityRepoService: ClarityRepoService,
        private demoHasAccessService: DemoHasAccessService,
        private demoPdfClarityRepoService: DemoPdfClarityRepoService,
    ) {}


    getSummary () {
        if (this.demoHasAccessService.canAccessAccessibilityPDF()) {
            return this.demoPdfClarityRepoService.getSummary();
        } else {
            return this.clarityRepoService.getSummary();
        }
    }
}
