import { Injectable } from '@angular/core';
import {
    PagePerformancePageDetailsService,
} from '@client/app/modules/page-performance/services/page-performance-page-details/page-performance-page-details.service';
import { PagePerformanceReportCategoryType } from '@client/app/services/api/page-performance/page-performance-report/page-performance-report-repo.service';


@Injectable({
    providedIn: 'root',
})
export class PagePerformanceCategoryService {
    constructor (
        private pagePerformancePageDetailsService: PagePerformancePageDetailsService,
    ) {}

    getAuditsFromCategory (categoryName: string) {
        const report = this.getReport();
        if (!report) {
            return null;
        }

        const auditIds = this.getCategory(categoryName)?.auditRefs.map((audit) => audit.id);
        return auditIds?.map((id) => {
            return Object.values(report.audits).find((audit) => audit.id === id);
        });
    }

    getAuditReference (categoryName: string, auditId: string) {
        const category = this.getCategory(categoryName);
        return category?.auditRefs.find((ref) => ref.id === auditId);
    }

    getCategoryGroup (categoryGroupId: string) {
        const report = this.getReport();
        return report?.categoryGroups[categoryGroupId];
    }

    getI18n () {
        const report = this.getReport();
        return report?.i18n;
    }

    getCategory (categoryName: string): PagePerformanceReportCategoryType | undefined {
        const report = this.getReport();
        return report?.categories[categoryName];
    }

    private getReport () {
        return this.pagePerformancePageDetailsService.getReport();
    }
}
