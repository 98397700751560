

export abstract class TrackingService {
    abstract trackEvent (url: string, type: string, ref: string): void;

    abstract trackPageView (url: string, title: string, ref: string, virtual: boolean, params: any): void;

    abstract setTrackingInfo (settings: any): void;

    cleanupUrl (url: string): string {
        return this.addOrigin(this.removeCustomAndDomainId(this.removeOrigin(url)));
    }

    addOrigin (url: string): string {
        return window.location.origin + url;
    }

    removeOrigin (url: string): string {
        return url.replace(window.location.origin, '');
    }

    removeCustomAndDomainId (url: string): string {
        return url
            .replace(/^\/(\d+)\//, '/{CustomerId}/')
            .replace(/^\/{CustomerId}\/domains\/(\d+)/, '/{CustomerId}/domains/{DomainId}');
    }
}
