import { Injectable } from '@angular/core';
import { MON_MODULES } from '@monsido/ng2/core/constants/mon-modules.constant';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { User } from '@monsido/ng2/modules/models/api/user';
import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';

@Injectable({
    providedIn: 'root',
})
export class HasAccessService {
    constructor (
        private sessionService: SessionService,
        private activeFeatureService: ActiveFeatureService,
    ) {}

    hasAccess (user: User | undefined, module: string): boolean {
        const canAccessModule = this.checkModuleAccess(module);

        if (user?.settings?.modules) {
            if (user.settings.modules.length > 0) {
                return canAccessModule && user.settings.modules.indexOf(module) === -1;
            }
        }

        return canAccessModule;
    }

    private checkModuleAccess (module: string): boolean {
        switch (module) {
            case MON_MODULES.accessibility:
                return this.customerHasAccessToAccessibility();
            case MON_MODULES.data_privacy:
                return this.activeFeatureService.isFeatureActive(MON_MODULES.data_privacy);
            case MON_MODULES.cookie_banner:
                return this.activeFeatureService.isFeatureActive(MON_MODULES.cookie_banner);
            case MON_MODULES.pagecorrect:
                return this.customerHasAccessToPageCorrect();
            default:
                return true;
        }
    }

    private customerHasAccessToAccessibility (): boolean {
        const customer = this.sessionService.customer;
        return Boolean(!customer || customer.plan_traits.accessibility);
    }

    private customerHasAccessToPageCorrect (): boolean {
        const customer = this.sessionService.customer;
        return Boolean(!customer || customer.plan_traits.page_fix);
    }
}
