(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardSummaryReadability', {
        templateUrl: 'app/pages/global/changes-history/dashboard/summary/readability/readability.html',
        controller: ReadabilityController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
            total: '<',
        },
    });

    ReadabilityController.$inject = ['$filter', 'ng2ChangesHistoryReadabilityService'];
    /* @ngInject */
    function ReadabilityController ($filter, ChangesHistoryReadabilityService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = update;

        function update () {
            vm.total = vm.total || 0;
            setDefaultMostCommonLevel();
            setMostCommonLevel();
        }

        function activate () {
            vm.total = vm.total || 0;
            setDefaultMostCommonLevel();
            setMostCommonLevel();
        }

        function setMostCommonLevel () {
            if (!vm.history || vm.history.length === 0 || !vm.domain) {
                return;
            }
            var latestEntry = vm.history[vm.history.length - 1];
            vm.mostCommonLevel = ChangesHistoryReadabilityService.getMostCommonReadabilityLevel(
                vm.domain.features.readabilityTest,
                latestEntry.readability_distribution,
            );

            if (vm.mostCommonLevel && vm.total > 0) {
                vm.mostCommonLevel.percentage =
                    latestEntry.readability_distribution[vm.mostCommonLevel.attr] / vm.total;
            } else {
                setDefaultMostCommonLevel();
            }
        }

        // PROTECTED

        function setDefaultMostCommonLevel () {
            vm.mostCommonLevel = {
                percentage: 0,
            };
        }
    }
})();
