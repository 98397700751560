(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideLegacyPageAssistSettings', {
        templateUrl: 'app/forms/domain-script-guide/legacy/page-assist/settings/settings.html',
        controller: DomainScriptGuideFeaturesPageAssistSettingsController,
        controllerAs: 'vm',
        bindings: {
            prefixName: '@?',
            pageAssistModel: '<',
        },
    });

    DomainScriptGuideFeaturesPageAssistSettingsController.$inject = [
        '$timeout',
        'ng2FormDomainScriptGuideLegacyPageAssistService',
        'ng2FormDomainScriptGuideService',
    ];
    /* @ngInject */
    function DomainScriptGuideFeaturesPageAssistSettingsController (
        $timeout,
        ng2FormDomainScriptGuideLegacyPageAssistService,
        ng2FormDomainScriptGuideService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDomainSettings = updateDomainSettings;

        function activate () {
            vm.prefixName = vm.prefixName || '';
            vm.settings = vm.pageAssistModel.legacySettings;
            vm.settings.theme = ng2FormDomainScriptGuideLegacyPageAssistService.getTheme(vm.settings.theme);
            vm.settings.direction = ng2FormDomainScriptGuideLegacyPageAssistService.getDirection(vm.settings.direction);
            vm.directions = ng2FormDomainScriptGuideLegacyPageAssistService.getDirections();
            vm.themes = ng2FormDomainScriptGuideLegacyPageAssistService.getThemes();
        }

        function updateDomainSettings () {
            $timeout(function () {
                ng2FormDomainScriptGuideService.setPageAssistEdit(vm.pageAssistModel);
            });
        }
    }
})();
