import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { BaseChartDirective, SortType, ChartDataset, TooltipModel, ChartConfiguration, ChartType } from '@monsido/angular-shared-components';
import { tap, takeUntil, switchMap, distinctUntilChanged } from 'rxjs/operators';
import moment from 'moment/moment';
import { SessionService } from '@monsido/core/session/session.service';
import { ParamService } from '@monsido/core/param/param.service';
import { MonTableCollection } from 'ng2/models/table-collection.interface';
import { KeywordsStatisticsResult } from 'ng2/angularjs-providers/stats-repo/stats-repo.types';
import { STATISTICS } from '@monsido/modules/statistics/statistics.constant';
import { DEFAULTS } from '@monsido/ng2/core/constants/defaults.constant';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { Domain } from '@monsido/modules/models/api/domain';
import { isEqual } from 'lodash';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { StatsRepoService } from '@client/app/services/api/stats-repo/stats-repo.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

@Component({
    selector: 'mon-site-search',
    templateUrl: './statistics-site-search.component.html',
    styleUrls: ['./statistics-site-search.component.scss'],
})
export class StatisticsSiteSearchComponent implements OnInit, OnDestroy {
    domain: Domain | null = null;
    monHref: string = 'https://help.monsido.com/en/articles/6816478-how-to-set-up-site-search';
    search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    selectedDateRange$: BehaviorSubject<Record<string, moment.Moment>> = new BehaviorSubject({});
    onDestroy$: Subject<null> = new Subject();
    loading = false;
    headers: Record<string, string>[] = [];
    collection: MonTableCollection<KeywordsStatisticsResult> = [];
    getSiteSearchParams$: BehaviorSubject<{
        page: number,
        limit: number,
        sortBy: string,
        sortDirection: SortType,
        search: string,
        from?: string,
        to?: string,
    }>;
    exports = [
        {
            name: this.translateService.getString('Search terms'),
            note: {
                message: this.translateService.getString('We export up to a maximum of 1000 rows'),
            },
            data: {
                category: 'site_search_keywords',
                date: this.selectedDateRange$.value,
                category_ref: '',
            },
        },
        {
            name: this.translateService.getString('Search terms with no results'),
            note: {
                message: this.translateService.getString('We export up to a maximum of 1000 rows'),
            },
            data: {
                category: 'site_search_no_result_keywords',
                date: this.selectedDateRange$.value,
                category_ref: '',
            },
        },
    ];

    chartData: ChartDataset<'bar'>[] = [
        { data: [] },
    ];
    chartLabels: string[] = [];
    chartOptions?: ChartConfiguration<'bar'>['options'];
    chartColors: string[] = [];
    chartOverride?: Partial<ChartDataset<'bar'>>[];
    chartSeries: string[] = [];
    chartRef: BaseChartDirective = {} as BaseChartDirective;
    tooltipModel: BehaviorSubject<unknown> = new BehaviorSubject({
        opacity: 0,
    });
    transitionUnsubscribe: unknown;

    dateFormat = DEFAULTS.DATE_FORMAT;

    constructor (
        private routerGlobals: UIRouterGlobals,
        private paramService: ParamService,
        private translateService: TranslateService,
        private transitionService: TransitionService,
        private sessionService: SessionService,
        private statsRepoService: StatsRepoService,
    ) {
        const { from, to, page, limit, search, sortBy, sortDirection } = this.paramService.getParams();
        this.search$.next(search);
        this.getSiteSearchParams$ = new BehaviorSubject({
            page: Number(page),
            limit: Number(limit),
            sortBy,
            sortDirection: sortDirection as SortType,
            search,
        });
        this.setDate(from, to);
    }

    ngOnInit (): void {
        this.headers = this.createHeaders();
        this.domain = this.sessionService.domain;
        this.setupChart();

        combineLatest([this.search$, this.getSiteSearchParams$, this.selectedDateRange$])
            .pipe(
                tap(([search, params, dateRange]) => {
                    this.paramService.setParams({
                        ...params,
                        search,
                        from: dateRange.startDate?.format(STATISTICS.DATE_FORMAT),
                        to: dateRange.endDate?.format(STATISTICS.DATE_FORMAT),
                    });
                }),
                distinctUntilChanged(isEqual),
                switchMap(() => this.getData()),
                tap((data) => {
                    this.collection = data;

                    this.chartLabels = this.collection.map(entry => entry.label);
                    this.chartData[0].data = this.collection.map(entry => entry.visits);

                    this.collection.total = this.collection.length;
                    this.loading = false;
                }),
                takeUntil(this.onDestroy$),
            )
            .subscribe();
        this.transitionUnsubscribe = this.transitionService.onSuccess(
            { retained: 'base.customer.domain.statistics.pages.siteSearch' },
            (transition) => {
                const { search, from, to, sortDirection, sortBy } = transition.targetState().params();
                this.setDate(from, to);
                this.search$.next(search);
                this.getSiteSearchParams$.next({
                    ...this.getSiteSearchParams$.value,
                    sortDirection,
                    sortBy,

                });
            },
        );
    }

    ngOnDestroy (): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        if (this.transitionUnsubscribe && typeof this.transitionUnsubscribe === 'function') {
            this.transitionUnsubscribe();
        }
    }

    onSelectedDateRange (date: Record<string, moment.Moment>): void {
        if (date.startDate !== undefined && date.endDate !== undefined) {
            this.selectedDateRange$.next(date);
            this.setExportCategoryRef(
                date.startDate.format(STATISTICS.DATE_FORMAT),
                date.endDate.format(STATISTICS.DATE_FORMAT),
            );
        }
    }

    onPageChange (page: number): void {
        this.getSiteSearchParams$.next({
            ...this.getSiteSearchParams$.value,
            page,
        });
    }

    onPageSizeChange (pageSize: number): void {
        this.getSiteSearchParams$.next({
            ...this.getSiteSearchParams$.value,
            page: 1,
            limit: pageSize,
        });
    }

    onSortEvents (sortPayload: Record<string, string>): void {
        const { by, direction } = sortPayload;
        this.getSiteSearchParams$.next({
            ...this.getSiteSearchParams$.value,
            page: 1,
            sortBy: by,
            sortDirection: direction as SortType,
        });
    }

    async getData (): Promise<MonTableCollection<KeywordsStatisticsResult>> {
        this.loading = true;
        const { page, limit, sortBy, sortDirection } = this.getSiteSearchParams$.value;

        const params: RequestParamsType = {
            domainId: Number(this.routerGlobals.params.domainId),
            from: this.selectedDateRange$.value.startDate?.format(STATISTICS.DATE_FORMAT),
            to: this.selectedDateRange$.value.endDate?.format(STATISTICS.DATE_FORMAT),
            limit,
            page,
            search: this.search$.value,
        };

        if (sortBy) {
            params.sort_by = sortBy;
        }

        if (sortDirection) {
            params.sort_dir = sortDirection;
        }

        this.setExportCategoryRef(
            this.selectedDateRange$.value.startDate?.format(STATISTICS.DATE_FORMAT),
            this.selectedDateRange$.value.endDate?.format(STATISTICS.DATE_FORMAT),
        );

        return this.statsRepoService.getSiteSearchKeywords(params) as Promise<MonTableCollection<KeywordsStatisticsResult>>;
    }

    onChartRefChange (chartRef: BaseChartDirective): void {
        if (chartRef) {
            this.chartRef = chartRef;
        }
    }

    private createHeaders (): Record<string, string>[] {
        return [
            {
                name: this.translateService.getString('Search term'),
                value: 'label',
            },
            {
                name: this.translateService.getString('Searches'),
                value: 'visits',
            },
            {
                name: this.translateService.getString('Average no. of results pages viewed'),
                value: 'pages_per_search',
            },
            {
                name: this.translateService.getString('% of visitors staying after a search'),
                value: 'exit_rate',
            },
        ];
    }

    private setDate (from: string, to: string): void {
        const start = moment(from, STATISTICS.DATE_FORMAT);
        const end = moment(to, STATISTICS.DATE_FORMAT);
        const today = moment();
        if (start.isValid() && end.isValid() && start <= today && end <= today) {
            this.selectedDateRange$.next({
                startDate: start,
                endDate: end,
            });
            this.setExportCategoryRef(
                start.format(STATISTICS.DATE_FORMAT),
                end.format(STATISTICS.DATE_FORMAT),
            );
        } else {
            this.selectedDateRange$.next({
                startDate: moment(),
                endDate: moment(),
            });
            this.setExportCategoryRef(
                this.selectedDateRange$.value.startDate?.format(STATISTICS.DATE_FORMAT),
                this.selectedDateRange$.value.endDate?.format(STATISTICS.DATE_FORMAT),
            );
        }
    }

    private setupChart (): void {
        this.chartColors = ['#239CF9'];
        this.chartSeries = [this.translateService.getString('Searches')];
        this.chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 0,
                        padding: 0,
                    },
                },
                tooltip: {
                    enabled: false,
                    external: ({ tooltip }): void => {
                        this.tooltipModel.next(tooltip as TooltipModel<ChartType>);
                    },
                },
            },
            scales: {
                x: {
                    beginAtZero: true,
                    suggestedMax: 4,
                    min: 0,
                    ticks: {
                        maxTicksLimit: 8,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                },
            },
        };

        this.chartOverride = [
            {
                label: this.translateService.getString('Searches'),
                borderWidth: 1,
                borderColor: [],
                backgroundColor: [],
            },
        ];

        if (this.chartOverride[0]) {
            this.chartOverride[0].borderColor = this.chartColors[0];
            this.chartOverride[0].backgroundColor = this.chartColors[0];
        }
    }

    private setExportCategoryRef (start: string, end: string): void {
        this.exports.forEach(siteSearchExport =>
            Object.assign(siteSearchExport, {
                data: {
                    category: siteSearchExport.data.category,
                    date: {
                        startDate: moment(start),
                        endDate: moment(end),
                    },
                },
            }),
        );
    }
}
