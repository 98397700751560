import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { SessionStorageService } from '@monsido/angular-shared-components';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { RollbarErrorHandlerService } from '../rollbar/rollbar-error-handler.service';
import { monsidoPath, oauthClientId, oauthClientSecret } from '@monsido/ng2/core/constants/general.constant';

type SuccessHandlerType = (callback: (...args: unknown[]) => unknown) => void;

function stringLooksGood (val: string, len: number = 2): boolean {
    return typeof val === 'string' && val.length >= len;
}

type RollbarResponseData = {
    params: {
        client_id_looks_good: boolean;
        client_secret_looks_good: boolean;
        code_looks_good: boolean;
        grant_type: string;
        redirect_uri: string;
    };
    response: {
        access_token_looks_good: boolean;
        token_type: string;
        expires_in: string;
        refresh_token_looks_good: boolean;
        scope: string;
        created_at: string;
        otp: string;
        api_url: string;
        accessible_mode: string;
    }
};

type OAuthData = {
    access_token: string;
    token_type: string;
    expires_in: string;
    refresh_token: string;
    scope: string;
    created_at: string;
    otp: string;
    api_url: string;
    accessible_mode: string;
};

export type OAuthValueName = keyof OAuthData;

export interface OAuthRequestParams {
    path: string;
    code: string;
    clientId: string;
    secret: string;
}

@Injectable()
export class OauthService {
    private mayRequestToken = false;
    private memoryStore = false;
    private logoutURL: string | null = null;
    private oauth: OAuthData | null = null;

    constructor (
        private sessionStorageService: SessionStorageService,
        private http: HttpClient,
        private rollbar: RollbarErrorHandlerService,
        private monEventService: MonEventService,
    ) {}

    getToken (): string {
        return this.getOAuthValue('access_token');
    }

    getRefreshToken (): string {
        return this.getOAuthValue('refresh_token');
    }

    getTokenHeaderValue (): string {
        return 'Bearer ' + this.getToken();
    }

    getApiPath (): string {
        return this.getOAuthValue('api_url');
    }

    getAccessToken (stateCode: string): Observable<OAuthData> {
        this.sessionStorageService.setItem('monStateLocationCode', stateCode);

        const requestParams = this.getRequestParams();

        if (!requestParams) {
            return throwError('No request params found');
        }
        const { clientId, secret, code, path } = this.getRequestParams() as OAuthRequestParams;


        if (!this.mayRequestToken) {
            return throwError('Cannot authenticate!');
        }

        const params = {
            client_id: clientId,
            client_secret: secret,
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: this.origin() + '/auth',
        };

        return this.http.post(path + 'oauth/token', null, { params })
            .pipe(
                tap(
                    (response: OAuthData): void => this.processOAuthResponse(params, response),
                ),
            );
    }

    clearParams (): void {
        this.sessionStorageService.removeItem('redirectState');
        this.sessionStorageService.removeItem('redirectParams');
    }

    getStateParams (): string {
        return this.sessionStorageService.getItem('redirectParams');
    }

    getState (): string {
        return this.sessionStorageService.getItem('redirectState');
    }

    setRedirectState (state: Record<string, unknown>, params: Record<string, unknown>): void {
        this.sessionStorageService.setItem('redirectState', state);
        this.sessionStorageService.setItem('redirectParams', params);
    }

    useMemoryStore (): void {
        this.memoryStore = true;
    }

    isAuthenticated (): boolean {
        return !!this.getToken();
    }

    clearLogoutUrl (): void {
        this.sessionStorageService.removeItem('logoutUrl');
        this.logoutURL = null;
    }

    setLogoutUrl (url: string): void {
        if (this.memoryStore) {
            this.logoutURL = url;
        } else {
            this.sessionStorageService.setItem('logoutUrl', url);
        }
    }

    getLogoutUrl (): string {
        if (this.memoryStore) {
            return this.logoutURL || '';
        }
        return this.sessionStorageService.getItem('logoutUrl');
    }

    clear (): void {
        if (this.memoryStore) {
            this.oauth = null;
        } else {
            this.sessionStorageService.removeItem('oauth');
        }
    }

    getAuthUrlAndSetStateParam (path: string, clientId: string, scope: string): string {
        const authStateParamValue = this.getStateValue();
        this.sessionStorageService.setItem('monAuthStateParamValue', authStateParamValue);

        return `${path}oauth/authorize?response_type=code&client_id=${clientId}&scope=${scope}&state=${authStateParamValue}&redirect_uri=${this.origin()}/auth`;
    }

    generateOTPUrl (path: string, action: string, returnPage: string): string {
        action = action || 'disable';
        returnPage = returnPage || '';
        return `${path}otp/${action}?redirect_uri=${this.origin()}/${returnPage}`;
    }

    processStateParam (authStateParamValue: string):{ onSuccess: SuccessHandlerType } {
        let onSuccess: SuccessHandlerType = (): void => {};
        const currentStateValue = this.sessionStorageService.getItem('monAuthStateParamValue');
        this.mayRequestToken = !authStateParamValue || (currentStateValue && currentStateValue === authStateParamValue);

        if (this.mayRequestToken) {
            onSuccess = (callback): void => {
                callback();
            };
        }
        return {
            onSuccess,
        };
    }

    refreshAccessToken (): Observable<OAuthData> {
        if (!this.mayRequestToken) {
            return throwError('Cannot authenticate!');
        }

        const requestParams = this.getRequestParams();

        if (!requestParams) {
            return throwError('No request params found. Authenticate first, please.');
        }

        const refreshToken = this.getOAuthValue('refresh_token');

        if (!refreshToken) {
            return throwError('Refresh token not found. Authenticate first, please.');
        }

        const params = {
            client_id: requestParams.clientId,
            client_secret: requestParams.secret,
            code: requestParams.code,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            redirect_uri: this.origin() + '/auth',
        };

        return this.http.post(requestParams.path + 'oauth/token', null, { params })
            .pipe(
                tap(
                    (response: OAuthData): void => this.processOAuthResponse(params, response),
                ),
            );
    }

    updateRequestToken (request: HttpRequest<unknown>): HttpRequest<unknown> {
        return request.clone({
            headers: request.headers.set('Authorization', this.getTokenHeaderValue()),
        });
    }

    requestUsesWrongToken (request: HttpRequest<unknown>): boolean {
        return request.headers.get('Authorization') !== this.getTokenHeaderValue();
    }

    private getRequestParams (): OAuthRequestParams | null {
        const clientId = oauthClientId;
        const secret = oauthClientSecret;
        const code = this.sessionStorageService.getItem('monStateLocationCode');
        const path = monsidoPath;

        if (code) {
            return {
                clientId,
                secret,
                code,
                path,
            };
        }
        return null;
    }

    private processOAuthResponse (params: Record<string, string>, response: OAuthData): void {
        const errorMessages: string[] = [];
        if (response) {
            this.setOAuthData(response);
        }
        if (!response.access_token) {
            errorMessages.push('The oauth response misses access_token.');
        }
        if (!response.api_url) {
            errorMessages.push('The oauth response misses api_url.');
        }
        if (errorMessages.length) {
            this.rollbar.log(
                errorMessages.join(' '),
                undefined,
                this.getResponseDataForRollbar(params, response),
            );
        }
    }

    // IE don't have `window.location.origin`
    private origin (): string {
        if (window.location.origin) {
            return window.location.origin;
        }

        return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    private getStateValue (): string {
        return Math.random().toString(14)
            .slice(2);
    }

    private getResponseDataForRollbar (params: Record<string, string>, response: OAuthData): RollbarResponseData {
        return {
            params: {
                client_id_looks_good: stringLooksGood(params.client_id, 64),
                client_secret_looks_good: stringLooksGood(params.client_secret, 64),
                code_looks_good: stringLooksGood(params.code, 40),
                grant_type: params.grant_type,
                redirect_uri: params.redirect_uri,
            },
            response: {
                access_token_looks_good: stringLooksGood(response.access_token, 645),
                token_type: response.token_type,
                expires_in: response.expires_in,
                refresh_token_looks_good: stringLooksGood(response.refresh_token, 40),
                scope: response.scope,
                created_at: response.created_at,
                otp: response.otp,
                api_url: response.api_url,
                accessible_mode: response.accessible_mode,
            },
        };
    }

    private setOAuthData (data: OAuthData): void {
        if (this.memoryStore) {
            this.oauth = data;
        } else {
            this.sessionStorageService.setItem('oauth', data);
        }

        this.monEventService.run('newAccessToken');
    }

    private getOAuthValue (name: OAuthValueName): string {
        const oauthData = this.memoryStore
            ? this.oauth
            : this.sessionStorageService.getItem('oauth') as OAuthData;

        return oauthData && oauthData[name]
            ? oauthData[name] as string
            : '';
    }
}
