(function () {
    'use strict';

    angular.module('modules.languages').filter('translateLanguage', TranslateLanguageController);

    TranslateLanguageController.$inject = ['ng2LanguagesService'];
    /* @ngInject */
    function TranslateLanguageController (ng2LanguagesService) {
        return result;

        // //////////

        /**
         *
         * @param locale - localization registered in the Backend
         */
        function result (locale) {
            return ng2LanguagesService.getLanguage(locale) || '';
        }
    }
})();
