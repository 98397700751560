import { COOKIE_BANNER_I18N } from '@monsido/modules/cookie-banner/constants/cookie-banner-i18n.constant';
import { CRAWL_STATE } from '../../ng2/core/constants/crawlState.constant';
import angular from 'angular';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { ISSUE_TYPES } from '@monsido/modules/deeplink/constants/deeplink.constant';
import { INFOTYPE_ICON } from '@monsido/modules/data-privacy/constants/into-type-icon.constant';
import { LIKELIHOODS } from '@monsido/modules/data-privacy/constants/data-privacy-likelihood.constant';
import { EXTENSION_DEEPLINK_MODULE } from '@monsido/modules/extension/constants/extension-deeplink.constant';
import { ROLLBAR_CONFIG } from '../../ng2/core/constants/rollbar-config.constant';
import { BI_API_CONSTANT } from '@monsido/core/constants/bi-api.constant';
import { PAGE_PERFORMANCE_VERSION } from '@monsido/core/constants/performance-version.constant';
import { PAGE_CORRECT_DOMAIN_SETTINGS_MODULE } from '@monsido/modules/page-fix/constants/domain-settings-modules.constant';
import { MON_MODULES } from '@monsido/core/constants/mon-modules.constant';
import { PAGE_FIX_SELECTOR_QUERIES } from 'app/modules/page-fix/constants/page-fix-selector-queries';
import { PAGE_FIX_FORM_VIEW_TYPE } from 'app/modules/page-fix/constants/page-fix-form-view-type';
import { PAGE_FIX_FORM_VIEW_NAME } from 'app/modules/page-fix/constants/page-fix-form-view-name';
import { PAGE_FIX_FORM_TAB_ICON } from 'app/modules/page-fix/constants/page-fix-form-tab-icon';
import { PAGE_FIX_FORM_FOOTER } from 'app/modules/page-fix/constants/page-fix-form-footer';
import { PAGE_FIX_FIXABLE_TYPES } from 'app/modules/page-fix/constants/page-fix-fixable-types';
import { PAGE_FIX_EVENT } from 'app/modules/page-fix/constants/page-fix-event';
import { PAGE_FIX_CHANGE_TYPES } from 'app/modules/page-fix/constants/page-fix-change-types';
import { PAGE_FIX_ACCESSIBILITY_CHECK_TAB } from 'app/modules/page-fix/constants/page-fix-accessibility-check-tab';
import { PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY } from 'app/modules/page-fix/constants/page-fix-accessibility-check-difficulity';
import { default as PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG } from '@client/app/modules/page-fix/constants/page-fix-accessibility-check-config.json';
import { SCE_MODULES } from 'app/modules/source-code-excludes/constants/sce_modules';
import { ROLES_ROLE_SECTIONS } from 'app/modules/roles/constants/role-selection';
import { POLICY_TYPES } from 'app/modules/policies/constants/policy-types';
import { POLICY_MODES } from 'app/modules/policies/constants/policy-modes';
import { PAGE_PERFORMANCE_METRICS } from 'app/modules/page-performance/constants/page-performance-metrics';
import { PAGE_PERFORMANCE_ICON } from '@client/app/modules/page-performance/constants/page-performance-icon';
import { PAGE_PERFORMANCE_DISPLAY_MODE } from '@client/app/modules/page-performance/constants/page-performance-display-mode';
import { PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE } from '@client/app/modules/page-performance/constants/page-performance-display-mode-detail-heading-type';
import { PAGE_PERFORMANCE_CONNECTION_PROFILES } from 'app/modules/page-performance/constants/page-performance-connection.-profiles';
import { PAGE_PERFORMANCE_CATEGORY } from 'app/modules/page-performance/constants/page-performance-category';
import { PAGE_PERFORMANCE_CATEGORY_GROUP } from 'app/modules/page-performance/constants/page-performance-category-group';
import { PAGE_PERFORMANCE_AUDIT_INFO } from 'app/modules/page-performance/constants/page-performance-audit-info';
import { PAGE_PERFORMANCE_AUDIT_IMPACT } from 'app/modules/page-performance/constants/page-performance-audit-impact';
import { PAGE_PERFORMANCE_AUDIT_ID } from 'app/modules/page-performance/constants/page-performance-audit-id';
import { REGULATIONS } from 'app/modules/data-privacy/constants/regulations.constant';
import { RISKS } from 'app/modules/data-privacy/constants/risks.constant';
import { POLICY_EXCHANGE_CENTER_DB } from '@client/app/modules/global-policy/constants/policy-exchange-center-db.data';
import { HEATMAPS_HEATMAP_TYPES } from 'app/modules/heatmaps/constants/heatmap-types.constant';
import { LOCALE_LANGUAGE_MAP } from 'app/modules/languages-ng2/constants/locale-language-map.constant';
import { LOCALE_MAP } from 'app/modules/languages-ng2/constants/locale-map.constant';
import { NOTIFICATION_CONSTANT } from 'app/modules/notifications/constant/notification.constant';
import { default as REGIONS } from 'app/modules/data-privacy/constants/regions.constant.json';
import { INFOTYPE_GROUPS } from 'app/modules/data-privacy/constants/infotype-groups.constant';
import { PAGE_DETAILS_SECTION_CONSTANT } from 'app/components/pagedetails/details/sections/page-details-section.constant';
import { API_CONSTANTS } from 'app/core/constants/api.constant';
import { ENTITY_CONSTANT } from 'app/core/constants/entity.constant';
import { default as TIME_ZONE } from '../../ng2/core/constants/timeZone.constant.json';
import { MON_CONSENT_MESSAGE_ENCODER } from '@monsido/ng2/core/constants/mon-consent-message-encoder.constant';
import { COOKIE_FIELDS } from '@monsido/ng2/core/constants/cookie-fields.constant';
import { COOKIE_SOURCE } from '@monsido/ng2/core/constants/cookie-source.constant';
import { default as PAGE_PERFORMANCE_LOCATIONS } from '@client/app/modules/page-performance/constants/page-performance-locations.json';
import { ACCESSIBILITY_HELP_CENTER_IMPACT } from 'app/modules/accessibility/constants/impact';
import { ACCESSIBILITY_HELP_CENTER_TAG } from 'app/modules/accessibility/constants/tag';
import { VIRTUAL_URLS } from 'app/modules/business-intelligence/constants/vitrual-urls';
import { TRAFFIC_LIGHT_COLORS } from 'app/modules/cookie-banner/constants/cookie-banner-traffic-light';
import { COOKIE_BANNER_SETTINGS } from 'app/modules/cookie-banner/constants/cookie-banner-settings';
import { DOMAIN_SCRIPT_GUIDE_EVENTS } from 'app/forms/constants/domain-script-guide';
import { FORM_CONSTANT } from 'app/forms/constants/form';
import { WIDGET_SETTINGS } from 'app/core/constants/widget-settings';
import { RESPONSE_TIME } from 'app/core/constants/response-time';
import { POLICY_FORM_HELPER_DB } from 'app/core/constants/policy-form-helper-db';
import { USER_ROLES } from 'app/core/constants/helpers/user-roles';
import { READABILITY_HELPER } from 'app/core/constants/helpers/readability-helper';
import { PATH_WHITELIST } from 'app/core/constants/helpers/path-whitelist';
import { MON_ICONS } from '@monsido/ng2/layout/icons/icons.constants';
import { MODULES_COLORS_AND_ICONS, MON_COLORS } from 'app/core/constants/helpers/monsido-colors';
import { MON_CMS_OPTIONS } from 'app/core/constants/helpers/monsido-cms-options';
import { CRAWL_STATUS } from 'app/core/constants/helpers/crawl-status';
import { Chrome } from 'app/core/constants/helpers/chrome';
import { AUTH_EVENTS } from 'app/core/constants/helpers/auth-events';
import { ACCESSIBILITY_HELPER } from '@monsido/ng2/core/constants/accessibility-helper.constant';
import { CHART_COLORS_CONSTANT } from 'app/constants/chart-colors';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from 'app/forms/domain-script-guide/features/pa-script-settings/pa-script.constant';
import { COOKIE_CATEGORIES } from '@monsido/ng2/core/constants/cookie-categories.constant';
import { DEFAULT_CHART_SETTINGS } from 'deprecate/frontend-components/src/js/components/charts/Chart.constant';

(function () {
    'use strict';
    angular.module('modules.cookie_banner').constant('COOKIE_BANNER_I18N', COOKIE_BANNER_I18N);
    angular.module('modules.cookie_banner').constant('MON_CONSENT_MESSAGE_ENCODER', MON_CONSENT_MESSAGE_ENCODER);
    angular.module('modules.cookie_banner').constant('COOKIE_FIELDS', COOKIE_FIELDS);
    angular.module('modules.cookie_banner').constant('COOKIE_SOURCE', COOKIE_SOURCE);
    angular.module('modules.cookie_banner').constant('COOKIE_CATEGORIES', COOKIE_CATEGORIES);
    angular.module('app.core').constant('CRAWL_STATE', CRAWL_STATE);
    angular.module('app.core').constant('MON_EVENTS', MON_EVENTS);
    angular.module('core.constant').constant('MON_MODULES', MON_MODULES);
    angular.module('core.constant').constant('API_CONSTANTS', API_CONSTANTS);
    angular.module('core.constant').constant('ENTITY_CONSTANT', ENTITY_CONSTANT);

    angular.module('modules.deeplink').constant('ISSUE_TYPES', ISSUE_TYPES);
    angular.module('modules.data_privacy.constants').constant('INFOTYPE_ICON', INFOTYPE_ICON);
    angular.module('modules.data_privacy.constants').constant('LIKELIHOODS', LIKELIHOODS);
    angular.module('modules.data_privacy.constants').constant('RISKS', RISKS);
    angular.module('modules.data_privacy.constants').constant('REGULATIONS', REGULATIONS);
    angular.module('modules.data_privacy.constants').constant('REGIONS', REGIONS);
    angular.module('modules.data_privacy.constants').constant('INFOTYPE_GROUPS', INFOTYPE_GROUPS);

    angular.module('modules.extension').constant('EXTENSION_DEEPLINK_MODULE', EXTENSION_DEEPLINK_MODULE);
    angular.module('modules.rollbar').constant('ROLLBAR_CONFIG', ROLLBAR_CONFIG);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_VERSION', PAGE_PERFORMANCE_VERSION);
    angular.module('modules.page_fix').constant('PAGE_CORRECT_DOMAIN_SETTINGS_MODULE', PAGE_CORRECT_DOMAIN_SETTINGS_MODULE);
    angular.module('modules.page_fix').constant('PAGE_FIX_SELECTOR_QUERIES', PAGE_FIX_SELECTOR_QUERIES);
    angular.module('modules.page_fix').constant('PAGE_FIX_FORM_VIEW_TYPE', PAGE_FIX_FORM_VIEW_TYPE);
    angular.module('modules.page_fix').constant('PAGE_FIX_FORM_VIEW_NAME', PAGE_FIX_FORM_VIEW_NAME);
    angular.module('modules.page_fix').constant('PAGE_FIX_FORM_TAB_ICON', PAGE_FIX_FORM_TAB_ICON);
    angular.module('modules.page_fix').constant('PAGE_FIX_FORM_FOOTER', PAGE_FIX_FORM_FOOTER);
    angular.module('modules.page_fix').constant('PAGE_FIX_FIXABLE_TYPES', PAGE_FIX_FIXABLE_TYPES);
    angular.module('modules.page_fix').constant('PAGE_FIX_EVENT', PAGE_FIX_EVENT);
    angular.module('modules.page_fix').constant('PAGE_FIX_CHANGE_TYPES', PAGE_FIX_CHANGE_TYPES);
    angular.module('modules.page_fix').constant('PAGE_FIX_ACCESSIBILITY_CHECK_TAB', PAGE_FIX_ACCESSIBILITY_CHECK_TAB);
    angular.module('modules.page_fix').constant('PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY', PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY);
    angular.module('modules.page_fix').constant('PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG', PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG);
    angular.module('modules.source-code-excludes').constant('SCE_MODULES', SCE_MODULES);
    angular.module('modules.page_fix').constant('ROLES_ROLE_SECTIONS', ROLES_ROLE_SECTIONS);
    angular.module('modules.policies').constant('POLICY_TYPES', POLICY_TYPES);
    angular.module('modules.policies').constant('POLICY_MODES', POLICY_MODES);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_METRICS', PAGE_PERFORMANCE_METRICS);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_ICON', PAGE_PERFORMANCE_ICON);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_DISPLAY_MODE', PAGE_PERFORMANCE_DISPLAY_MODE);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE', PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_CONNECTION_PROFILES', PAGE_PERFORMANCE_CONNECTION_PROFILES);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_CATEGORY', PAGE_PERFORMANCE_CATEGORY);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_CATEGORY_GROUP', PAGE_PERFORMANCE_CATEGORY_GROUP);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_AUDIT_INFO', PAGE_PERFORMANCE_AUDIT_INFO);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_AUDIT_IMPACT', PAGE_PERFORMANCE_AUDIT_IMPACT);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_AUDIT_ID', PAGE_PERFORMANCE_AUDIT_ID);
    angular.module('modules.page-performance').constant('PAGE_PERFORMANCE_LOCATIONS', PAGE_PERFORMANCE_LOCATIONS);
    angular.module('modules.cookie_banner').constant('COOKIE_BANNER_SETTINGS', COOKIE_BANNER_SETTINGS);
    angular.module('modules.cookie_banner.components').constant('TRAFFIC_LIGHT_COLORS', TRAFFIC_LIGHT_COLORS);
    angular.module('modules.accessibility').constant('ACCESSIBILITY_HELP_CENTER_TAG', ACCESSIBILITY_HELP_CENTER_TAG);
    angular.module('modules.accessibility').constant('ACCESSIBILITY_HELP_CENTER_IMPACT', ACCESSIBILITY_HELP_CENTER_IMPACT);
    angular.module('app.forms').constant('PAGE_ASSIST_SETTINGS', PAGE_ASSIST_SCRIPT_CONSTANT);
    angular.module('app.forms').constant('DOMAIN_SCRIPT_GUIDE_EVENTS', DOMAIN_SCRIPT_GUIDE_EVENTS);
    angular.module('app.forms').constant('FORM_CONSTANT', FORM_CONSTANT);
    angular.module('core.constant').constant('WIDGET_SETTINGS', WIDGET_SETTINGS);
    angular.module('core.constant').constant('RESPONSE_TIME', RESPONSE_TIME);
    angular.module('core.constant').constant('POLICY_FORM_HELPER_DB', POLICY_FORM_HELPER_DB);
    angular.module('core.constant').constant('USER_ROLES', USER_ROLES);
    angular.module('core.constant').constant('READABILITY_HELPER', READABILITY_HELPER);
    angular.module('core.constant').constant('PATH_WHITELIST', PATH_WHITELIST);
    angular.module('core.constant').constant('MON_ICONS', MON_ICONS);
    angular.module('core.constant').constant('MON_COLORS', MON_COLORS);
    angular.module('core.constant').constant('MODULES_COLORS_AND_ICONS', MODULES_COLORS_AND_ICONS);
    angular.module('core.constant').constant('MON_CMS_OPTIONS', MON_CMS_OPTIONS);
    angular.module('core.constant').constant('CRAWL_STATUS', CRAWL_STATUS);
    angular.module('core.constant').constant('Chrome', Chrome);
    angular.module('core.constant').constant('AUTH_EVENTS', AUTH_EVENTS);
    angular.module('core.constant').constant('ACCESSIBILITY_HELPER', ACCESSIBILITY_HELPER);
    angular.module('constants').constant('CHART_COLORS_CONSTANT', CHART_COLORS_CONSTANT);
    angular.module('modules.notifications').constant('NOTIFICATION_CONSTANT', NOTIFICATION_CONSTANT);
    angular.module('modules.languages').constant('LOCALE_MAP', LOCALE_MAP);
    angular.module('modules.languages').constant('LOCALE_LANGUAGE_MAP', LOCALE_LANGUAGE_MAP);
    angular.module('modules.heatmaps').constant('HEATMAPS_HEATMAP_TYPES', HEATMAPS_HEATMAP_TYPES);
    angular.module('app.components.pageDetails').constant('PAGE_DETAILS_SECTION_CONSTANT', PAGE_DETAILS_SECTION_CONSTANT);
    angular.module('modules.globalPolicy').constant('POLICY_EXCHANGE_CENTER_DB', POLICY_EXCHANGE_CENTER_DB);
    angular.module('msMomentCountries').constant('timeZone', TIME_ZONE);
    angular.module('constants').constant('DEFAULT_CHART_SETTINGS', DEFAULT_CHART_SETTINGS);
})();
