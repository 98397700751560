import { Injectable } from '@angular/core';
import { ApiService } from '../../api-service/api.service';
import { CreateProfileService } from '@client/app/models/page-performance/create-profile/create-profile.service';
import { PagePerformanceProfile, PageProfileDataInterface } from '@client/app/models/page-performance/profile.model';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { HttpContextToken } from '@angular/common/http';

export type ProfileRepoRequestParams = {
    page?: number;
    page_size?: number;
    archived?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class ProfilesRepoService {

    constructor (
        private apiService: ApiService,
        private createProfileService: CreateProfileService,
    ) { }

    getAll (params: ProfileRepoRequestParams, contextToken?: HttpContextToken<boolean>): Promise<CollectionInterface<PagePerformanceProfile>> {
        params = params || {};
        return this.apiService.get<CollectionInterface<PageProfileDataInterface>>('performance/profiles', params, {}, contextToken)
            .then((profilesCollection) => {
                const pageProfiles = profilesCollection.map((data: PageProfileDataInterface) => this.createProfileService.getPagePerformanceProfile(data));
                const { currentPage, perPage, total } = profilesCollection;
                const pageProfilesCollection: CollectionInterface<PagePerformanceProfile> = pageProfiles;
                pageProfilesCollection.currentPage = currentPage;
                pageProfilesCollection.perPage = perPage;
                pageProfilesCollection.total = total;

                return pageProfilesCollection;
            });
    }

    update (profile: PagePerformanceProfile): Promise<PagePerformanceProfile> {
        return this.apiService.patch<PageProfileDataInterface>('performance/profiles/' + profile.id, profile)
            .then(data => this.createProfileService.getPagePerformanceProfile(data));
    }

    create (profile: PagePerformanceProfile): Promise<PagePerformanceProfile> {
        return this.apiService.post<PageProfileDataInterface>('performance/profiles', profile)
            .then(data => this.createProfileService.getPagePerformanceProfile(data));
    }

    archiveProfile (profile: PagePerformanceProfile): Promise<PagePerformanceProfile> {
        return this.apiService.patch('/performance/profiles/' + profile.id + '/archived', profile);
    }
}
