(function () {
    'use strict';

    angular.module('pages.global.policy').component('globalPolicyMatches', {
        templateUrl: 'app/pages/global/policy/policy/matches/matches.html',
        controller: GlobalPolicyMatchesController,
        controllerAs: 'vm',
        bindings: {
            policies: '<',
            openPolicyForm: '&',
            getPolicies: '&',
        },
    });

    GlobalPolicyMatchesController.$inject = [
        'ng2PolicyRepoService',
        '$timeout',
        'activeFeatures',
        'ng2LabelRepo',
        'ng2PolicyHelperService',
        'ng2MonEventsService',
        'policyService',
        'ng2MonModalService',
        'gettextCatalog',
        'MON_EVENTS',
    ];
    /* @ngInject */
    function GlobalPolicyMatchesController (
        ng2PolicyRepoService,
        $timeout,
        activeFeatures,
        ng2LabelRepo,
        ng2PolicyHelperService,
        ng2MonEventsService,
        policyService,
        ng2MonModalService,
        gettextCatalog,
        MON_EVENTS,
    ) {
        var vm = this;
        vm.getPolicyTarget = getPolicyTarget;
        vm.getTooltipMessage = getTooltipMessage;
        vm.deletePolicy = deletePolicy;
        vm.runPolicy = runPolicy;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.updatePolicy = updatePolicy;
        vm.getTooltipForTag = getTooltipForTag;
        vm.openTargets = openTargets;
        vm.archivePolicy = archivePolicy;

        function activate () {
            vm.filteredPolicies = vm.policies.filter(function (policy, index) {
                return index < 4;
            }); // Maximum 4 policies should be shown
            updateDropdowns();
            vm.maxCount = vm.policies.length > 0 ? vm.policies[0].matches_count : 0;
            vm.allLabels = [];
            vm.showLabels = activeFeatures.labels;
            vm.currentSort = {
                by: 'matches_count',
                direction: 'desc',
            };
            getLabels();
        }

        function updateDropdowns () {
            for (const policy of vm.filteredPolicies) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Run policy again'),
                        leftIcon: 'faPlay',
                        action: ()=>runPolicy(policy),
                        eventParams: { action: 'policy-run', params: { name: policy.name } },
                        shouldShow: policy.state !== 'running' && policy.state !== 'queued',
                    },
                    {
                        label: gettextCatalog.getString('Policy in queue'),
                        leftIcon: 'faPlay',
                        disabled: true,
                        shouldShow: policy.state === 'queued',
                    },
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faEdit',
                        action: ()=>vm.openPolicyForm({ policy: policy }),
                        eventParams: { action: 'before-policy-edit', params: { name: policy.name } },
                    },
                    {
                        label: gettextCatalog.getString('Archive'),
                        leftIcon: 'faArchive',
                        action: ()=>archivePolicy(policy),
                        eventParams: { action: 'policy-archive', params: { name: policy.name, archiveStatus: policy.archived } },
                    },
                    {
                        label: gettextCatalog.getString('Delete'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>deletePolicy(policy),
                        eventParams: { action: 'policy-delete', params: { name: policy.name } },
                    },
                ];

                policy.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        disabled: item.disabled,
                        action: ()=>{
                            if (item.eventParams) {
                                ng2MonEventsService.run('actionTrigger', item.eventParams);
                            }

                            if (item.action) {
                                item.action();
                            }
                        },
                    };
                });
            }
        }

        function onDestroy () {
            if (typeof vm.timerPromise === 'object') {
                $timeout.cancel(vm.timerPromise);
            }
        }

        function deletePolicy (policy) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to delete the policy <strong>{{policyName}}</strong>', {
                        policyName: policy.name,
                    }),
                )
                .then(function () {
                    policyService.destroy(policy.id).then(function () {
                        vm.getPolicies();
                    }, angular.noop);
                }, angular.noop);
        }

        function runPolicy (policy) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to run the policy <strong>{{policyName}}</strong> again?', {
                        policyName: policy.name,
                    }),
                )
                .then(function () {
                    policyService.run(policy.id).then(function () {
                        refreshPolicies().then(function () {
                            startAutoReload();
                        }, angular.noop);
                    }, angular.noop);
                }, angular.noop);
        }

        function openTargets (policy) {
            var params = {
                body: 'globalPolicyDialogsTargets',
                size: 'md',
                data: { policy: policy },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getPolicyTarget (policy) {
            return ng2PolicyHelperService.getType(policy);
        }

        function getTooltipMessage (policy) {
            return ng2PolicyHelperService.getTooltipMessageForType(policy);
        }

        function refreshPolicies () {
            // Only for this component
            var params = {
                page_size: 0,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };
            vm.policies = false;
            return ng2PolicyRepoService.getAll(params).then(function (policies) {
                vm.policies = policies.sort(function (a, b) {
                    return a.matches_count > b.matches_count ? -1 : a.matches_count < b.matches_count ? 1 : 0;
                });
                activate();
            }, angular.noop);
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function updatePolicy (policy) {
            var cPolicy = {};
            cPolicy.id = policy.id;
            cPolicy.labels = policy.labels.map(function (label) {
                return label.id;
            });
            policyUpdate(cPolicy).then(angular.noop, angular.noop);
        }

        function archivePolicy (policy) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString(
                        'Are you sure you want to archive the policy <strong>{{policyName}}</strong>? We will no longer look for matches to this policy. You can always unarchive the policy again later on.',
                        {
                            policyName: policy.name,
                        },
                    ),
                )
                .then(function () {
                    var clone = {
                        id: policy.id,
                        archived: !policy.archived,
                    };
                    policyUpdate(clone).then(function () {
                        vm.getPolicies();
                    }, angular.noop);
                }, angular.noop);
        }

        function startAutoReload () {
            vm.timerPromise = $timeout(function () {
                refreshPolicies().then(function () {
                    var runningPolicies = vm.policies.filter(function (policy) {
                        return policy.state === 'running';
                    }).length;
                    if (runningPolicies > 0) {
                        startAutoReload();
                    } else {
                        vm.getPolicies(); // Refresh the entire dashboard, so that all information is refreshed after all policies are done scanning. //Might wanna consider a better method, but this is a quick and easy approach for now
                    }
                }, angular.noop);
            }, 30000);
        }

        function getTooltipForTag (tag) {
            return ng2PolicyHelperService.getTag(tag);
        }

        function policyUpdate (policy) {
            return policyService.update(policy);
        }
    }
})();
