import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@monsido/angular-shared-components';

@Component({
    selector: '[mon-favicon-wrapper-component]',
    standalone: true,
    imports: [CommonModule, IconsModule],
    templateUrl: './favicon-wrapper.component.html',
    styleUrls: ['./favicon-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaviconWrapperComponent {
    @Input() monSrc: string = '';
    @Input() monAlt: string = '';

    hasFailedFavicon = false;

    public onError (): void {
        this.hasFailedFavicon = true;
    }
}
