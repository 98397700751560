import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ExporterAllParserService } from '@client/app/modules/export/services/history/all.service';
import { ExporterPerformanceParserService } from '@client/app/modules/export/services/history/performance.service';
import { ExporterReadabilityParserService } from '@client/app/modules/export/services/history/readability.service';
import { ExporterDataPrivacyParserService } from '@client/app/modules/export/services/history/data-privacy.service';

@NgModule({
    providers: [
        DatePipe,
        DecimalPipe,
        ExporterAllParserService,
        ExporterPerformanceParserService,
        ExporterReadabilityParserService,
        ExporterDataPrivacyParserService,
    ],
})
export class ExportModule { }
