(function () {
    'use strict';

    angular.module('modules.fastTrack').component('fastTrackInformation', {
        templateUrl: 'app/modules/fast-track/information/information.html',
        controller: FastTrackInformationController,
        controllerAs: 'vm',
        bindings: {
            affectedPagesCount: '<',
            snippet: '<',
            chartData: '<', // [errorCount, successCount]
        },
    });

    FastTrackInformationController.$inject = ['ng2FastTrackInformationService'];

    function FastTrackInformationController (ng2FastTrackInformationService) {
        var vm = this;
        var snippetCharacterLimit = 200;
        vm.$onInit = activate;
        vm.getSnippetCompliance = getSnippetCompliance;
        vm.getSnippetIssues = getSnippetIssues;
        vm.getSnippetSourceCode = getSnippetSourceCode;
        vm.getAffectedPages = getAffectedPages;
        vm.toggleCharacterLimit = toggleCharacterLimit;

        function activate () {
            vm.maxCharacters = snippetCharacterLimit;
            vm.labels = ['', ''];
            vm.colors = ['#ce2e54', '#dcdcdc'];
            vm.options = {
                cutoutPercentage: 70,
                tooltips: {
                    enabled: false,
                },
            };
        }

        function getAffectedPages () {
            return ng2FastTrackInformationService.getAffectedPages(vm.affectedPagesCount);
        }

        function getSnippetCompliance () {
            return ng2FastTrackInformationService.getSnippetCompliance(vm.snippet);
        }

        function getSnippetIssues () {
            return ng2FastTrackInformationService.getSnippetIssues(vm.snippet);
        }

        function getSnippetSourceCode () {
            return ng2FastTrackInformationService.getSnippetSourceCode(vm.snippet);
        }

        function toggleCharacterLimit () {
            if (vm.maxCharacters > 0) {
                vm.maxCharacters = null;
            } else {
                vm.maxCharacters = snippetCharacterLimit;
            }
        }
    }
})();
