(function () {
    'use strict';

    angular.module('modules.roles').factory('RolesDemoDataService', RolesDemoDataService);

    RolesDemoDataService.$inject = ['$timeout', 'ng2DateTimeService', 'ng2TextSearchService', 'ROLES_ROLE_SECTIONS', 'ng2UserService'];

    /* @ngInject */
    function RolesDemoDataService ($timeout, ng2DateTimeService, ng2TextSearchService, ROLES_ROLE_SECTIONS, ng2UserService) {
        var vm = this;
        vm.roles = [];
        vm.roleSections = [];
        vm.RolesRoleSections = [];

        function init () {
            return {
                getData: getData,
                setupData: setupData,
                getRoleRoleSections: getRoleRoleSections,
                getUserRoleSections: getUserRoleSections,
            };
        }

        return init();

        // /////////////

        function getData (endpoint, params) {
            params = params || {
                page: 1,
                page_size: 10,
            };
            let ar = [];
            const end = params.page_size * params.page;
            const start = end - params.page_size;
            switch (endpoint) {
                case 'roles':
                    ar = vm.roles.filter(function (role) {
                        if (params.search) {
                            return (
                                ng2TextSearchService.fuzzySearch(params.search, role.name) ||
                                ng2TextSearchService.fuzzySearch(params.search, role.description)
                            );
                        }
                        return true;
                    });
                    ar.total = ar.length;
                    break;
                case 'role_sections':
                    ar = vm.roleSections.filter(function (role) {
                        if (params.search) {
                            return (
                                ng2TextSearchService.fuzzySearch(params.search, role.name) ||
                                ng2TextSearchService.fuzzySearch(params.search, role.description)
                            );
                        }
                        return true;
                    });
                    ar.total = ar.length;
                    break;
            }

            return getPromise(
                ar.filter(function (role, index) {
                    return params.page_size === 0 || (index >= start && index < end);
                }),
            );
        }

        function getRoleRoleSections (roleId, noPromise) {
            const sections = vm.RolesRoleSections.filter(function (pivot) {
                return pivot.role_id === roleId;
            }).map(function (pivot) {
                return pivot.role_section_id;
            });
            const result = vm.roleSections.filter(function (roleSection) {
                return sections.indexOf(roleSection.id) > -1;
            });
            result.total = result.length;
            if (noPromise) {
                return result;
            }
            return getPromise(result);
        }

        function getUserRoleSections (userId) {
            const sections = vm.usersRoleSections
                .filter(function (pivot) {
                    return pivot.user_id === userId;
                })
                .map(function (pivot) {
                    return pivot.role_section_id;
                });
            const result = vm.roleSections.filter(function (roleSection) {
                return sections.indexOf(roleSection.id) > -1;
            });
            result.total = result.length;
            return getPromise(result);
        }

        async function setupData () {
            const fetchedUsers = [];
            const userCache = await ng2UserService.tryGetUsersFromCache({ page: 1, page_size: 50 }, fetchedUsers);
            const users = Object.keys(userCache).map(function (i) {
                return userCache[i];
            });
            const createdAtDate = ng2DateTimeService.substractDuration(undefined, 1, 'weeks');
            const createdAtDateIsoString = ng2DateTimeService.toISOString(createdAtDate);
            vm.roles = [
                {
                    id: 1,
                    name: 'Developer',
                    description: 'Has access to QA, SEO and Accessibility',
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
                {
                    id: 2,
                    name: 'Content Writer',
                    description: 'Has access to Misspellings',
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
                {
                    id: 3,
                    name: 'All',
                    description: 'Has access to all Modules',
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
            ];

            vm.roleSections = [
                {
                    id: 1,
                    name: 'QA',
                    description: 'Gives access to the entire QA Module',
                    value: ROLES_ROLE_SECTIONS.qa,
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
                {
                    id: 2,
                    name: 'QA Misspellings',
                    description: 'Gives access to QA Misspellings only',
                    value: ROLES_ROLE_SECTIONS.misspellings,
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
                {
                    id: 3,
                    name: 'SEO',
                    description: 'Gives access to the entire SEO Module',
                    value: ROLES_ROLE_SECTIONS.seo,
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
                {
                    id: 4,
                    name: 'Accessibility',
                    description: 'Gives access to the entire Accessibility Module',
                    value: ROLES_ROLE_SECTIONS.accessibility,
                    updated_at: randomDate(createdAtDate, new Date()),
                    created_at: createdAtDateIsoString,
                },
            ];

            vm.RolesRoleSections = [
                {
                    role_id: 1,
                    role_section_id: 1,
                },
                {
                    role_id: 1,
                    role_section_id: 3,
                },
                {
                    role_id: 1,
                    role_section_id: 4,
                },
                {
                    role_id: 2,
                    role_section_id: 2,
                },
            ];

            let result = [];
            users.forEach(function (user) {
                if (user.roles) {
                    user.roles.forEach((roleId) => {
                        result = result.concat(
                            getRoleRoleSections(roleId, true).map(function (roleSection) {
                                return { role_section_id: roleSection.id, user_id: user.id };
                            }),
                        );
                    });
                }
            });
            vm.usersRoleSections = result;
        }

        // PROTECTED

        function randomDate (start, end) {
            return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString();
        }

        function getPromise (result) {
            return $timeout(function () {
                return result;
            }, Math.ceil(Math.random() * 3000));
        }
    }
})();
