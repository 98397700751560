import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { PAGE_FIX_FORM_VIEW_TYPE } from '@client/app/modules/page-fix/constants/page-fix-form-view-type';
import { PAGE_FIX_FORM_FOOTER } from '@client/app/modules/page-fix/constants/page-fix-form-footer';
import { PAGE_FIX_FORM_VIEW_NAME } from '@client/app/modules/page-fix/constants/page-fix-form-view-name';

@Injectable({
    providedIn: 'root',
})
export class WizardContainerViewModelService {
    constructor (
        private translateService: TranslateService,
    ) {}

    createViewModel (el: HTMLElement, params: any) {
        if (params.type === PAGE_FIX_FORM_VIEW_TYPE.INFO) {
            params.title = params.title || this.translateService.getString('Info');
            params.footer = params.footer || PAGE_FIX_FORM_FOOTER.GO_TO;
            params.viewName = params.viewName || PAGE_FIX_FORM_VIEW_NAME.INFO;
        }

        if (params.type === PAGE_FIX_FORM_VIEW_TYPE.TESTING) {
            params.title = params.title || this.translateService.getString('Testing');
            params.footer = params.footer || PAGE_FIX_FORM_FOOTER.GO_TO;
            params.viewName = params.viewName || PAGE_FIX_FORM_VIEW_NAME.TESTING;
        }

        if (params.type === PAGE_FIX_FORM_VIEW_TYPE.OPTIONS) {
            params.title = params.title || this.translateService.getString('Options');
            params.footer = params.footer || PAGE_FIX_FORM_FOOTER.SUBMIT;
            params.viewName = params.viewName || PAGE_FIX_FORM_VIEW_NAME.OPTIONS;
        }
        return {
            type: params.type,
            viewName: params.viewName,
            order: params.order,
            footer: params.footer,
            title: params.title,
            selected: !!params.selected,
            el: el,
        };
    }
}
