(function () {
    'use strict';

    angular.module('modules.page-performance.page-details').component('pagePerformancePageDetailsDashboard', {
        templateUrl: 'app/modules/page-performance/page-details/dashboard/dashboard.html',
        controller: PagePerformancePageDetailsDashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onResultChange: '&',
            goToSection: '&',
            report: '<',
            profile: '<',
        },
    });

    PagePerformancePageDetailsDashboardController.$inject = [
        'ng2PagePerformancePageDetailsService',
        'ng2PagePerformanceCategoryService',
        'PAGE_PERFORMANCE_CATEGORY',
        'ng2MonEventsService',
        'ng2PagePerformanceResultsRepoService',
        'gettextCatalog',
        'ng2PagePerformanceVersionService',
    ];
    /* @ngInject */
    function PagePerformancePageDetailsDashboardController (
        ng2PagePerformancePageDetailsService,
        ng2PagePerformanceCategoryService,
        PAGE_PERFORMANCE_CATEGORY,
        ng2MonEventsService,
        ng2PagePerformanceResultsRepoService,
        gettextCatalog,
        ng2PagePerformanceVersionService,
    ) {
        const vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.onOpportunityClick = onOpportunityClick;

        function activate () {
            vm.audits = [];
            vm.timelineMilestones = [];
            vm.pagePerformanceScore = null;
            vm.getLoadingStatus = ng2PagePerformancePageDetailsService.getLoadingReportStatus;
            setExports();

            if (!vm.getLoadingStatus()) {
                setAuditsCollection();
            }

            if (vm.profile) {
                getPerformanceResults(vm.profile);
            }

            vm.setReportEventId = ng2MonEventsService.addListener('pagePerformancePDAfterSetReport', function () {
                setAuditsCollection();
            });

            vm.setProfileEventnId = ng2MonEventsService.addListener('pagePerformanceSelectProfile', function (profile) {
                getPerformanceResults(profile);
            });
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.setReportEventId);
            ng2MonEventsService.remove(vm.setProfileEventnId);
        }
        // PROTECTED

        function setAuditsCollection () {
            vm.audits = ng2PagePerformanceCategoryService.getAuditsFromCategory(PAGE_PERFORMANCE_CATEGORY.PERFORMANCE);
        }

        function getPerformanceResults (profile) {
            const params = {
                page_id: vm.page.id,
                profile_id: profile.id,
                limit: 8, //  The "Timeline" and "Load Times Trend" will be shown a clean design on a screen resolution of 1366x768 or above.
            };
            ng2PagePerformanceResultsRepoService.getAll(params).then(function (res) {
                vm.history = res;
                vm.performanceResults = res.reverse();
                vm.performanceScoreLineData = vm.performanceResults.map(function (entry) {
                    return {
                        score: entry.performance || 0,
                        time: entry.scanned_at,
                    };
                });
                vm.timelineMilestones = ng2PagePerformanceVersionService.getEntriesWithVersionChanged(vm.performanceResults, 'version').map(
                    function (entry) {
                        const versionInfo = ng2PagePerformanceVersionService.getVersionInfo(entry.version);
                        return {
                            text: versionInfo.label,
                            link: versionInfo.link,
                            date: entry.scanned_at,
                        };
                    },
                );
                vm.lastCheck = vm.performanceResults[vm.performanceResults.length - 1] || null;
                vm.pagePerformanceScore = vm.lastCheck ? vm.lastCheck.performance * 100 : 0;
                vm.onResultChange({ lastCheck: vm.lastCheck });
            }, angular.noop);
        }

        function setExports () {
            const categoryRef = [vm.page.id];

            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'page_performance_results',
                        category_ref: categoryRef.join(),
                    },
                },
            ];
        }

        function onOpportunityClick (section) {
            vm.goToSection({ active: section });
        }
    }
})();
