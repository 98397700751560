import { Component, OnDestroy, OnInit } from '@angular/core';
import { MonPromptService, SortType } from '@monsido/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { UIRouter, UrlService } from '@uirouter/core';
import { DataPrivacyViolationStatus } from '../../interfaces/data-privacy-issue-repo.interface';
import { DataPrivacyViolationType } from '../../interfaces/data-privacy-violation.type';
import { DataPrivacyIssueRepoService } from '../../repos/data-privacy-issue.repo';
import { DataPrivacyContentInterface } from '../../interfaces/data-privacy-content.interface';
import { DataPrivacyLikelihoodService } from '../../services/data-privacy-likelihood.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BaseComponent } from '@monsido/core/components/base.component';
import { IssueService } from '@monsido/modules/issue/services/issue.service';
import { GetPagesParamsInterface } from 'app/modules/qa/repos/qa-issue-repo.interface';
import { Page } from '@monsido/modules/page-details/models';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import type { ActionMenuItemType } from '@monsido/ng2/shared/components/action-menu-panel/action-menu-panel.type';
import { DateTimeService } from 'app/services/date-time/date-time.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';


@Component({
    selector: 'mon-data-privacy-issue',
    templateUrl: './data-privacy-issue.component.html',
    styleUrls: ['./data-privacy-issue.component.scss'],
})
export class DataPrivacyComponent extends BaseComponent implements OnInit, OnDestroy {
    DataPrivacyViolationStatus = DataPrivacyViolationStatus;

    loading: boolean = false;
    issueId: number = -1;
    contentId: number = -1;
    pageId: number = -1;
    issueTitle: string = '';
    texts: { [key: string]: string };
    effectOnCompliance: number = -1;
    currentPage: Page = {} as Page;
    issueData?: DataPrivacyContentInterface;
    violationData?: DataPrivacyViolationType;
    loadingPages: boolean = false;
    violations: MonTableCollection<DataPrivacyViolationType & { groupText: string, likelihoodText: string }> = [];
    openingHelpCenter: boolean = false;
    violation?: DataPrivacyViolationType & { groupText: string, likelihoodText: string };

    pages: MonTableCollection<Page> = [];
    subscription?: Subscription;
    getPagesParams: BehaviorSubject<GetPagesParamsInterface> = new BehaviorSubject({
        page: 1,
        page_size: 10,
        sort_by: 'title',
        sort_dir: 'desc',
    });
    dropdownOptions: ActionMenuItemType[] = [];

    openingPageDetails = false;

    viewsColumIsAvailable: boolean = true;

    private pageDetailsOpeningMs = 1000;
    private pageDetailsOpeningTimeout: number | null = null;
    private confirmationRequiredText: string = '';

    constructor (
        private uiRouter: UIRouter,
        private translateService: TranslateService,
        private eventsService: MonEventService,
        private monPromptService: MonPromptService,
        private dataPrivacyIssueRepo: DataPrivacyIssueRepoService,
        private dataPrivacyLikelihoodService: DataPrivacyLikelihoodService,
        private issueService: IssueService,
        private activeFeatureService: ActiveFeatureService,
        private dateTimeService: DateTimeService,
        $location: UrlService,
    ) {
        super($location);
        this.confirmationRequiredText = this.translateService.getString('Confirmation required');
        this.texts = {
            fix: this.translateService.getString('Are you sure you want to mark this issue as fixed?'),
            unfix: this.translateService.getString('Are you sure you want to mark this issue as not fixed?'),
            ignore: this.translateService.getString('Are you sure you want to mark this issue as ignored?'),
            unignore: this.translateService.getString('Are you sure you want to unignore this issue?'),
            checkFix: this.translateService.getString('Are you sure you want to mark this check as fixed?'),
            checkIgnore: this.translateService.getString('Are you sure you want to mark this check as ignored?'),
        };
    }

    async ngOnInit (): Promise<void> {
        ({
            issueOverlayIssueId: this.issueId,
            issueOverlayPageId: this.pageId,
            issueOverlayContentId: this.contentId,
        } = this.uiRouter.urlService.search());

        this.issueTitle = this.translateService.getString('Data privacy issue');
        this.getPage();
        this.viewsColumIsAvailable = this.activeFeatureService.isFeatureActive('script_setup_guide');
    }

    ngOnDestroy (): void {
        this.subscription?.unsubscribe();
    }

    async getPage (): Promise<void> {
        this.loading = true;
        if (this.issueId && this.pageId && this.contentId) {
            await this.getPageDetails();
            await this.getIssueData();
            await this.getViolations();
            this.subscription = this.getPagesParams.subscribe(params => {
                this.getPages(params);
            });
            await this.getIssueData();
        }
        this.loading = false;
    }

    async markPageViolationAs (status: string): Promise<void> {
        switch (status) {
            case DataPrivacyViolationStatus.fixed: {
                if (this.pageId && this.violationData?.id) {
                    try {
                        await this.monPromptService.confirm(this.texts.fix, {
                            parentSelector: '#common-dialog-wrapper',
                            size: 'md',
                            title: this.confirmationRequiredText,
                        });
                        this.loading = true;
                        await this.dataPrivacyIssueRepo.markPageViolationAsFixed(this.pageId, this.violationData.id);
                        await this.getIssueData(true);
                        this.loading = false;
                        this.getViolations();
                    } catch (_e) {}
                }
                break;
            }
            case DataPrivacyViolationStatus.ignored: {
                try {
                    await this.monPromptService.confirm(this.texts.ignore, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });
                    this.loading = true;
                    if (this.pageId && this.violationData?.id) {
                        await this.dataPrivacyIssueRepo.markPageViolationAsIgnored(this.pageId, this.violationData.id);
                    }
                    await this.getIssueData(true);
                    this.loading = false;
                    this.getViolations();
                } catch (_e) {}
                break;
            }
            case 'unignored': {
                try {
                    await this.monPromptService.confirm(this.texts.unignore, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });
                    this.loading = true;
                    if (this.pageId && this.violationData?.id) {
                        await this.dataPrivacyIssueRepo.markPageViolationAsActive(this.pageId, this.violationData.id);
                    }
                    await this.getIssueData(true);
                    this.loading = false;
                    this.getViolations();
                } catch (_e) {}
                break;
            }
            case 'unfixed': {
                try {
                    await this.monPromptService.confirm(this.texts.unfix, {
                        parentSelector: '#common-dialog-wrapper',
                        size: 'md',
                        title: this.confirmationRequiredText,
                    });
                    this.loading = true;
                    if (this.pageId && this.violationData?.id) {
                        await this.dataPrivacyIssueRepo.markPageViolationAsActive(this.pageId, this.violationData.id);
                    }
                    await this.getIssueData(true);
                    this.loading = false;
                    this.getViolations();
                } catch (_e) {}
                break;
            }
        }
    }

    openHelpCenter (infoType: DataPrivacyViolationType['info_type']): void {
        this.openingHelpCenter = true;
        this.eventsService.run(MON_EVENTS.LOAD_DIALOG, {
            params: {
                size: 'lg',
                body: 'privacyDialogsHelpCenter',
                data: {
                    infoType: infoType,
                },
            },
            callback: () => {
                this.openingHelpCenter = false;
            },
        });
    }

    onSortContent (sortPayload: Record<string, string>, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        const { direction, by } = sortPayload;
        const params: GetPagesParamsInterface = {
            ...subject.value,
            sort_dir: direction as SortType,
            sort_by: by,
        };

        if (!direction) {
            params.sort_dir = 'asc';
        }

        if (!by) {
            params.sort_by = '';
        }

        subject.next(params);
    }

    onContentPageChange (page: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page,
        });
    }

    onContentPerPageChange (perPage: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page: 1,
            page_size: perPage,
        });
    }

    goToPageDetails (page: Page): void {
        if (!this.pageDetailsOpeningTimeout) {
            this.openingPageDetails = true;
            const target = 'page-details-section-data-privacy';
            const callback = (): void => {
                this.openingPageDetails = false;
            };
            this.issueService.goToPageDetails(page, target, Number(this.issueId), false, callback);
            this.pageDetailsOpeningTimeout = setTimeout(() => {
                this.pageDetailsOpeningTimeout = null;
            }, this.pageDetailsOpeningMs) as unknown as number;
        }
    }

    openInExtension (page: Page): void {
        const params = {
            type: 'dpriv',
            contentId: this.contentId,
            pageId: this.pageId,
            issueId: this.issueId,
        };
        this.issueService.openInExtension(page, params);
    }

    private updateDropdown () : void {
        if (!this.violationData) {
            this.dropdownOptions = [];
            return;
        }

        const dropdown: Array<ActionMenuItemType & { shouldShow?: boolean }> = [
            {
                label: this.translateService.getString('Mark as fixed'),
                leftIcon: 'faCheck',
                action: ()=>this.markPageViolationAs('fixed'),
                shouldShow: this.violationData.status !== DataPrivacyViolationStatus.fixed,
            },
            {
                label: this.translateService.getString('Mark as not fixed'),
                leftIcon: 'faBan',
                action: ()=>this.markPageViolationAs('unfixed'),
                shouldShow: this.violationData.status === DataPrivacyViolationStatus.fixed,
            },
            {
                label: this.translateService.getString('Mark as ignored'),
                leftIcon: 'faEyeSlash',
                action: ()=>this.markPageViolationAs('ignored'),
                shouldShow: this.violationData.status !== DataPrivacyViolationStatus.ignored,
            },
            {
                label: this.translateService.getString('Unignore'),
                leftIcon: 'faEye',
                action: ()=>this.markPageViolationAs('unignored'),
                shouldShow: this.violationData.status === DataPrivacyViolationStatus.ignored,
            },
        ];

        this.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
            return {
                label: item.label,
                leftIcon: item.leftIcon,
                action: item.action,
            };
        });
    }

    private async getIssueData (statusChanged = false): Promise<void> {
        const data = await this.dataPrivacyIssueRepo.getContent(this.contentId);
        const violationData = await this.dataPrivacyIssueRepo.getViolation(this.pageId, this.issueId);

        if (data) {
            this.issueData = data;
            this.issueData.created_at = this.dateTimeService.format(this.issueData.created_at, 'DD MMM. YYYY');
            this.effectOnCompliance = Number(this.issueData.effect_on_compliance) || 0;
        }

        if (violationData) {
            this.violationData = violationData;
            this.updateDropdown();
        }

        if (statusChanged) {
            this.eventsService.run(MON_EVENTS.RELOAD_DATA_PRIVACY);
        }

    }

    private async getViolations (): Promise<void> {
        this.loadingPages = true;
        try {
            const violations = await this.dataPrivacyIssueRepo.getViolationsFromContent(this.contentId);
            const formatGroupText = (text: string): string => {
                if (!text) {
                    return '';
                }
                return (
                    text.charAt(0).toUpperCase() +
                    text
                        .substring(1, text.length)
                        .toLowerCase()
                        .replace(/_/g, ' ')
                );
            };

            if (violations) {
                this.violations = violations.map(page => {
                    return {
                        ...page,
                        groupText: formatGroupText(page.info_type.group),
                        likelihoodText: this.dataPrivacyLikelihoodService.getViolationLikelihood(page),
                    };
                });
                for (let i = 0; i < this.violations.length; i++) {
                    if (Number(this.issueId) === this.violations[i].id) {
                        this.violation = this.violations[i];
                    }
                }
                this.violations = this.violations.filter((value) => {
                    return value.id !== Number(this.issueId);
                });

                if (this.violations?.length === 0) {
                    this.violations.total = 0;
                }
            }
        } finally {
            this.loadingPages = false;
        }
    }

    private async getPageDetails (): Promise<void> {
        this.issueService.getPage(this.pageId).then((page: Page) => {
            this.currentPage = page;
        });
    }

    private async getPages (params: GetPagesParamsInterface): Promise<void> {

        const parameters: Record<string, string | number | boolean> = { ...params };
        if (this.issueId) {
            parameters.data_protection_violation_id = Number(this.issueId);
        }

        return this.dataPrivacyIssueRepo.getDataPrivacyIssuePages(parameters).then((data: Page[]) => {
            this.pages = data;
            this.pages = this.pages.filter((page) => {
                return page.id !== Number(this.pageId);
            });
            if (this.pages?.length === 0) {
                this.pages.total = 0;
            }
        }, () => {});
    }
}
