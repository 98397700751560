import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonActionMenuPanelComponent } from './action-menu-panel.component';
import { MonButtonNextModule } from '../button/button-next.module';
import { TemplateReferenceDirective } from './template-reference.directive';
import { IconsModule } from '@monsido/angular-shared-components';


@NgModule({
    declarations: [
        MonActionMenuPanelComponent,
        TemplateReferenceDirective,
    ],
    imports: [
        CommonModule,
        MonButtonNextModule,
        IconsModule,
    ],
    exports: [MonActionMenuPanelComponent, TemplateReferenceDirective],
})
class ActionMenuPanelModule { }

export { ActionMenuPanelModule as MonActionMenuPanelModule };
