import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { ChartsDataHelper } from '../../helpers/charts/charts-data-helper';
import MonColors from '@monsido/colors/dist/es6/colors';

(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name ChangeHistoryLineChartService
     * @description
     * Create the necessary objects to shape the change history line chart
     */

    angular.module('blocks.service').factory('ChangeHistoryLineChartService', ChangeHistoryLineChartService);

    ChangeHistoryLineChartService.$inject = [
        '$filter',
        'gettextCatalog',
        'MON_MODULES',
        'ng2SessionService',
        'ng2DateTimeService',
        'ng2ActiveFeatureService',
        'ng2LegendCursorService',
    ];

    function ChangeHistoryLineChartService (
        $filter,
        gettextCatalog,
        MON_MODULES,
        ng2SessionService,
        ng2DateTimeService,
        ng2ActiveFeatureService,
        ng2LegendCursorService,
    ) {
        const vm = this;
        /**
         * @memberOf ChangeHistoryLineChartService
         * @description Initialize the ChangeHistoryLineChartService factory
         */
        function init (domain) {
            const user = ng2SessionService.me;
            vm.lineChartSettings = new LineChart();
            vm.domain = domain;
            vm.showDocuments = domain ? domain.scan.scanDocuments : false;
            vm.showAccessibility =
                $filter('hasAccess')(user, MON_MODULES.accessibility) && Boolean(domain && domain.features.accessibility);
            vm.showSEO = $filter('hasAccess')(user, MON_MODULES.seo) && ng2ActiveFeatureService.isFeatureActivePure('seo_all');
            vm.showPolicy = $filter('hasAccess')(user, MON_MODULES.policies) && ng2ActiveFeatureService.isFeatureActivePure('policies_all');
            vm.showQA = $filter('hasAccess')(user, MON_MODULES.qa) && ng2ActiveFeatureService.isFeatureActivePure('qa_module');

            return {
                getOption: getOption,
                getColors: getColors,
                getSeries: getSeries,
                getOverride: getOverride,
                getLabels: getLabels,
                getData: getData,
            };
        }

        return init;

        /**
         * @memberOf ChangeHistoryLineChartService
         * @description The option object for the change history line chart
         */
        function getOption (history) {
            vm.lineChartSettings.setTooltipSettings({
                intersect: false,
                mode: 'x-axis',
                callbacks: {
                    label: function (tooltipItem, data) {
                        return (
                            data.datasets[tooltipItem.datasetIndex].label +
                            ': ' +
                            $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y)
                        );
                    },
                    title: function (tooltipItem) {
                        return ng2DateTimeService.format(tooltipItem[0].xLabel, 'MMM DD');
                    },
                },
            });
            vm.lineChartSettings.setLegendSettingsOverride({
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 13,
                },
            });
            vm.lineChartSettings.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Pages'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        display: Boolean(vm.showDocuments),
                        position: 'right',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Documents'),
                            fontSize: 11,
                        },
                    },
                ],
                xAxes: [
                    {
                        id: 'x-axis-0',
                        type: 'time',
                        gridLines: {
                            offsetGridLines: true,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            maxTicksLimit: 30,
                            callback: function (val) {
                                return val;
                            },
                        },
                        time: {
                            minUnit: 'day',
                            unit: 'day',
                            displayFormats: {
                                // Since the scans always only appear once or twice a week, there's no need for any other date formats to be displayed
                                millisecond: 'MMM D',
                                second: 'MMM D',
                                minute: 'MMM D',
                                hour: 'MMM D',
                                day: 'MMM D',
                                week: 'MMM D',
                                month: 'MMM D',
                                quarter: 'MMM D',
                                year: 'MMM D',
                            },
                        },
                    },
                ],
            });

            const verticalLines = [];
            for (let i = 0; i < history.length; i++) {
                const entry = history[i];
                if (entry.browserServiceChanged) {
                    const browserServiceTranslation = {
                        'browser_service': gettextCatalog.getString('Legacy Browser Service changed'),
                        'puppeteer_service': gettextCatalog.getString('Puppeteer Browser Service changed'),
                    };
                    verticalLines.push({
                        xAxisID: 'x-axis-0',
                        x: entry.date,
                        style: {
                            lineDash: [5, 5],
                            color: MonColors['secondary-15'],
                            width: 3,
                        },
                        label: {
                            text: gettextCatalog.getString(browserServiceTranslation[entry.js_rendering_service]),
                            color: MonColors['secondary-15'],
                            position: 'top',
                        },
                        data: {
                            link: entry.browserServiceChanged.infoLink,
                        },
                    });
                }

                if (entry.isScanWithV20) {
                    const v20Text = gettextCatalog.getString('Puppeteer 20');
                    verticalLines.push({
                        xAxisID: 'x-axis-0',
                        x: entry.date,
                        style: {
                            lineDash: [5, 5],
                            color: MonColors['secondary-15'],
                            width: 3,
                        },
                        label: {
                            text: v20Text,
                            color: MonColors['secondary-15'],
                            position: 'top',
                        },
                        data: {
                            link: entry.isScanWithV20.infoLink,
                        },
                    });
                }
            }

            vm.lineChartSettings.options.verticalLines = {
                lines: verticalLines,
            };

            vm.lineChartSettings.options = ng2LegendCursorService.setHoverState(vm.lineChartSettings.options);

            return vm.lineChartSettings.options;
        }

        /**
         * @memberOf ChangeHistoryLineChartService
         * @description The colors for the change history line chart
         */
        function getColors () {
            if (vm.showAccessibility) {
                vm.lineChartSettings.addColor('#cb8ce2');
            }

            if (vm.showSEO) {
                vm.lineChartSettings.addColor('#f5c069');
            }

            if (vm.showQA) {
                vm.lineChartSettings.addColor('#76cdcc');
            }

            if (vm.showPolicy) {
                vm.lineChartSettings.addColor('#e091b0');
            }

            vm.lineChartSettings.addColor('#8ae2ef');

            if (vm.showDocuments) {
                vm.lineChartSettings.addColor('#57b1b6');
            }
            return vm.lineChartSettings.colors;
        }

        /**
         * @memberOf ChangeHistoryLineChartService
         * @description The series for the change history line chart
         */
        function getSeries () {
            if (vm.showAccessibility) {
                vm.lineChartSettings.addSerie(gettextCatalog.getString('Pages with accessibility issues'));
            }

            if (vm.showSEO) {
                vm.lineChartSettings.addSerie(gettextCatalog.getString('Pages with SEO opportunities'));
            }

            if (vm.showQA) {
                vm.lineChartSettings.addSerie(gettextCatalog.getString('Pages with QA issues'));
            }

            if (vm.showPolicy) {
                vm.lineChartSettings.addSerie(gettextCatalog.getString('Pages with policy issues'));
            }

            vm.lineChartSettings.addSerie(gettextCatalog.getString('Pages crawled'));

            if (vm.showDocuments) {
                vm.lineChartSettings.addSerie(gettextCatalog.getString('Documents crawled'));
            }
            return vm.lineChartSettings.series;
        }

        /**
         * @memberOf ChangeHistoryLineChartService
         * @description The override object for the change history line chart
         */
        function getOverride () {
            let linesToOverride = 4;
            if (!vm.showAccessibility) {
                linesToOverride = linesToOverride - 1;
            }

            if (!vm.showSEO) {
                linesToOverride = linesToOverride - 1;
            }

            if (!vm.showPolicy) {
                linesToOverride = linesToOverride - 1;
            }

            if (!vm.showQA) {
                linesToOverride = linesToOverride - 1;
            }

            for (let i = 0; i < linesToOverride; i++) {
                vm.lineChartSettings.addOverride({
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                });
            }

            vm.lineChartSettings.addOverride({
                yAxisID: 'y-axis-0',
                xAxisID: 'x-axis-0',
                type: 'line',
                fill: false,
                lineTension: 0,
                radius: 0,
                borderWidth: 1,
                pointRadius: 3,
                pointHitRadius: 34,
            });

            if (vm.showDocuments) {
                vm.lineChartSettings.addOverride({
                    yAxisID: 'y-axis-1',
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                });
            }

            return vm.lineChartSettings.override;
        }

        /**
         * @memberOf ChangeHistoryLineChartService
         * @description The labels for the change history line chart
         */
        function getLabels (crawls) {
            return crawls.map(function (crawl) {
                return crawl.date;
            });
        }

        /**
         * @memberOf ChangeHistoryLineChartService
         * @description The data object for the change history line chart
         */
        function getData (crawls) {
            const helper = new ChartsDataHelper('time');
            const data = [];
            const copiedCrawls = [].slice.call(crawls).map(function (crawl) {
                const newCrawl = Object.assign({}, crawl);
                if (newCrawl.queued_at) {
                    newCrawl.date = newCrawl.queued_at;
                }
                return newCrawl;
            });
            if (vm.showAccessibility) {
                data.push(
                    copiedCrawls.map(function (crawl) {
                        return helper.setData(crawl, 'pages_with_accessibility_errors_count');
                    }),
                );
            }
            if (vm.showSEO) {
                data.push(
                    copiedCrawls.map(function (crawl) {
                        return helper.setData(crawl, 'pages_with_seo_errors_count');
                    }), // All classifications except Technical
                );
            }
            if (vm.showQA) {
                data.push(
                    copiedCrawls.map(function (crawl) {
                        return helper.setData(crawl, 'pages_with_notifications_count');
                    }),
                );
            }
            if (vm.showPolicy) {
                data.push(
                    copiedCrawls.map(function (crawl) {
                        return helper.setData(crawl, 'pages_with_policy_matches_count');
                    }),
                );
            }
            data.push(
                copiedCrawls.map(function (crawl) {
                    return helper.setData(crawl, 'page_count');
                }),
            );

            if (vm.showDocuments) {
                data.push(
                    copiedCrawls.map(function (crawl) {
                        return helper.setData(crawl, 'documents_count');
                    }),
                );
            }
            return data;
        }
    }
})();

