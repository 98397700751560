import { Injectable } from '@angular/core';
import { PAGE_PERFORMANCE_DISPLAY_MODE } from '../../constants/page-performance-display-mode';
import { PagePerformanceAuditScoreUtil } from '../page-performance-audit-score.util';
import { PagePerformanceReportAudit } from '@client/app/services/api/page-performance/page-performance-report/page-performance-report-repo.service';

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceAuditService {

    constructor () { }

    scoreDisplayModeIsNumeric (scoreDisplayMode: string): boolean {
        return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.NUMERIC;
    }

    scoreDisplayModeIsBinary (scoreDisplayMode: string): boolean {
        return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.BINARY;
    }

    scoreDisplayModeIsManual (scoreDisplayMode: string): boolean {
        return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.MANUAL;
    }

    scoreDisplayModeIsInformative (scoreDisplayMode: string): boolean {
        return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.INFORMATIVE;
    }

    scoreDisplayModeIsNotApplicable (scoreDisplayMode: string): boolean {
        return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.NOT_APPLICABLE;
    }

    scoreDisplayModeIsMetricSavings (scoreDisplayMode: string): boolean {
        return scoreDisplayMode === PAGE_PERFORMANCE_DISPLAY_MODE.METRIC_SAVINGS;
    }

    getColorByScore (audit: PagePerformanceReportAudit): string {
        if (audit.scoreDisplayMode !== PAGE_PERFORMANCE_DISPLAY_MODE.MANUAL && audit.score !== null) {
            const val = PagePerformanceAuditScoreUtil.getProperScoreNo(audit.score) * 100;
            if (PagePerformanceAuditScoreUtil.hasScoreFailed(val)) {
                return 'text-danger';
            } else if (PagePerformanceAuditScoreUtil.hasScoreAverage(val)) {
                return 'text-warning';
            } else if (PagePerformanceAuditScoreUtil.hasScorePassed(val)) {
                return 'text-success';
            }
        }
        return '';
    }
}
