import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    ('use strict');

    angular.module('pages.global.policy').component('globalPolicyDashboard', {
        templateUrl: 'app/pages/global/policy/policy.html',
        controller: GlobalPolicyDashboardController,
        controllerAs: 'vm',
    });

    GlobalPolicyDashboardController.$inject = [
        'ng2PolicyRepoService',
        'ng2MonEventsService',
        '$state',
        'FORM_CONSTANT',
        'ng2MonUIRouterTransitionService',
        'ng2SessionService',
    ];
    /* @ngInject */
    function GlobalPolicyDashboardController (
        ng2PolicyRepoService,
        ng2MonEventsService,
        $state, FORM_CONSTANT,
        ng2MonUIRouterTransitionService,
        ng2SessionService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.openPolicyForm = openPolicyForm;
        vm.getPolicies = getPolicies;
        vm.goToPolicyAIBot = goToPolicyAIBot;

        function activate () {
            vm.currentSort = {
                by: 'matches_count',
                direction: 'desc',
            };
            vm.policies = [];
            vm.showPolicyBotButton = ng2SessionService.customer.plan_traits.policy_bot;
            getPolicies();
        }

        function openPolicyForm (policy) {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
                data: {
                    policy: policy,
                },
            };
            const callback = function (currentPolicy) {
                if (angular.isObject(currentPolicy)) {
                    ng2MonUIRouterTransitionService.onTransitionFinished(() => $state.go('base.customer.global.policy.policies'));
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function goToPolicyAIBot () {
            $state.go('base.customer.global.policy.bot');
        }

        function getPolicies () {
            var params = {
                page_size: 0,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                archived: false,
            };

            vm.progress = ng2PolicyRepoService.getAll(params).then(function (policies) {
                vm.policies = policies.sort(function (a, b) {
                    return a.matches_count > b.matches_count ? -1 : a.matches_count < b.matches_count ? 1 : 0;
                });
            }, angular.noop);
        }
    }
})();
