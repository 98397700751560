import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { SessionService } from '@monsido/core/session/session.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Domain } from '@monsido/modules/models/api/domain';
import { MON_MODULES } from '@monsido/core/constants/mon-modules.constant';
import { DomainsService } from '@client/app/services/domains/domains.service';
import { TotalDomainsService } from '@client/app/services/total-domains/total-domains.service';
import {
    EssentialDomainDataModel as BaseEssentialDomainDataModel,
} from '@client/app/services/total-domains/essential-domain-data.model';
import { User } from '@monsido/modules/models/api/user';
import { HasAccessService } from '@client/app/pipe/has-access/has-access.service';
import { IconsService } from '@layout/icons/icons.service';
import { MON_ICONS } from '@layout/icons/icons.constants';

interface EssentialDomainDataModel extends BaseEssentialDomainDataModel {
    selectorId: string;
}

interface Module {
    name: string;
    value: string;
    icon?: string | typeof MON_ICONS;
    canAccess: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ChangesHistoryService {
    private modules: Module[] = [];
    private activeModule: string | undefined;
    private domains: EssentialDomainDataModel[] | null = null;
    private domain: EssentialDomainDataModel | undefined = undefined;
    private domainGroup: any | null = null;

    public readonly domainsObs = new BehaviorSubject<EssentialDomainDataModel[] | null>(null);
    public readonly pagesStackBehaviorSubject = new BehaviorSubject<string[]>([]);

    constructor (
        private translateService: TranslateService,
        private sessionService: SessionService,
        private monEventService: MonEventService,
        private activeFeatureService: ActiveFeatureService,
        private domainsService: DomainsService,
        private totalDomainsService: TotalDomainsService,
        private hasAccessService: HasAccessService,
        private iconsService: IconsService,
    ) {
        this.init();
    }

    private init (): void {
        this.setModule('all');
        this.totalDomainsService.domainsEssential.pipe(
            delay(750),
        ).subscribe((domains: EssentialDomainDataModel[]) => {
            const setupDomainsCollection = this.setupDomains(domains);
            this.domains = setupDomainsCollection;
            this.domainsObs.next(setupDomainsCollection);
        });
    }

    setDate (date: Date): void {
        this.monEventService.run('hcSetDate', date);
    }

    setDomain (domain: EssentialDomainDataModel, domainGroup: any): void {
        this.domain = domain;
        this.domainGroup = domainGroup;
        this.monEventService.run('hcSetDomain', domain, domainGroup);
        this.setupModules();
    }

    setModule (module: string): void {
        this.activeModule = module || 'all';
        this.monEventService.run('hcSetModule', this.activeModule);
        this.setPagesStack();
    }

    setHistory (history: any): void {
        this.monEventService.run('hcSetHistory', history);
    }

    getModules (): Module[] {
        return this.modules.filter(m => m.canAccess);
    }

    getModule (): string {
        const canUseModule = this.modules.find(m => m.value === this.activeModule && m.canAccess);
        return canUseModule ? this.activeModule || 'all' : 'all';
    }

    getModuleTranslation (): string | undefined {
        const module = this.modules.find(m => m.value === this.activeModule);
        return module?.name;
    }

    getDomainFromSelectorId (selectorId: string | null): EssentialDomainDataModel | null {
        if (this.domains?.length === 1) {
            return this.domains[0];
        }
        if (selectorId !== null && this.domains) {
            return this.domains.find(d => d.selectorId === selectorId) || null;
        }
        return null;
    }

    getHistoryCenterParameters (module: string): Record<string, any> {
        const params: Record<string, any> = {
            module: module,
        };

        const domainId = this.sessionService.getDomainId();
        const domainGroup = this.sessionService.domainGroup;

        if (Number.isInteger(domainId)) {
            params.domain_id = domainId;
        }

        if (domainGroup !== null) {
            params.group_id = domainGroup.id;
        }

        return params;
    }

    private setupModules (): void {
        const user = this.sessionService.me;
        const hasAccessibility = Boolean(this.domain?.features.accessibility);
        const hasReadability = Boolean(this.domain?.features.readabilityTest);
        const hasDataPrivacy = Boolean(this.domain?.features.dataProtection);
        const hasPerformance = this.activeFeatureService.isFeatureActive('page_performance', this.domain as unknown as Domain);

        this.modules = [
            {
                name: this.translateService.getString('All modules - Content with Issues'),
                value: 'all',
                canAccess: true,
            },
            {
                name: this.translateService.getString('SEO'),
                value: 'seo',
                icon: this.iconsService.getIcon(['MENUES', 'SEO']),
                canAccess: this.hasAccess(user, MON_MODULES.seo) && this.activeFeatureService.isFeatureActivePure('seo_all'),
            },{
                name: this.translateService.getString('Quality Assurance'),
                value: 'qa',
                icon: this.iconsService.getIcon(['MENUES','QA']),
                canAccess: this.hasAccess(user, MON_MODULES.qa) && this.activeFeatureService.isFeatureActivePure('qa_module'),
            },
            {
                name: this.translateService.getString('Policies'),
                value: 'policy',
                icon: this.iconsService.getIcon(['MENUES','POLICIES']),
                canAccess: this.hasAccess(user, MON_MODULES.policies) && this.activeFeatureService.isFeatureActivePure('policies_all'),
            },
            {
                name: this.translateService.getString('Accessibility'),
                value: 'accessibility',
                icon: this.iconsService.getIcon(['MENUES','ACCESSIBILITY']),
                canAccess: hasAccessibility && this.hasAccess(user, MON_MODULES.accessibility),
            },
            {
                name: this.translateService.getString('Readability'),
                value: 'readability',
                icon: this.iconsService.getIcon(['QA','READABILITY']),
                canAccess: hasReadability && this.activeFeatureService.isFeatureActivePure('admin_readability'),
            },
            {
                name: this.translateService.getString('Data Privacy'),
                value: 'data-privacy',
                icon: this.iconsService.getIcon(['MENUES','DATA_PRIVACY']),
                canAccess: hasDataPrivacy && this.activeFeatureService.isFeatureActive(MON_MODULES.data_privacy, this.domain as unknown as Domain),
            },
            {
                name: this.translateService.getString('Performance'),
                value: 'performance',
                icon: this.iconsService.getIcon(['MENUES','PAGE_PERFORMANCE']),
                canAccess: hasPerformance && this.hasAccess(user, MON_MODULES.performance),
            },

        ];
    }

    private setupDomains (domains: EssentialDomainDataModel[]): any[] {
        if (Array.isArray(domains) && domains.length > 0) {
            return this.domainsService.flattenEssentialDomainData(domains).map(domain => ({
                ...domain,
                selectorId: domain.type === 'DomainGroup' ? `domain_group_${domain.id}` : `domain_${domain.id}`,
            }));
        }
        return [];
    }

    private setPagesStack (): void {
        let stack = ['page_count'];

        if (this.activeModule !== 'seo' && this.activeModule !== 'accessibility') {
            stack = ['page_count', 'documents_count'];
        }

        if (this.activeModule === 'performance') {
            stack = ['performance_page_count'];
        }

        this.pagesStackBehaviorSubject.next(stack);
    }

    private hasAccess (user: User | undefined | null, module: string): boolean {
        return user ? this.hasAccessService.hasAccess(user, module) : false;
    }
}
