import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardWcag22', {
        templateUrl: 'app/pages/accessibility/dashboard/wcag22/wcag22.html',
        controller: AccessibilityDashboardWCAG22Controller,
        controllerAs: 'vm',
    });

    AccessibilityDashboardWCAG22Controller.$inject = [
        'ng2SessionService',
        'ng2AccessibilityRepoService',
        'ng2CrawlsRepoService',
        'ng2AccessibilityChecksRepoService',
        '$q',
        'rx',
        '$timeout',
        'AccessibilityResultTypeService',
        'ng2ChangesHistoryService',
        'ng2ActiveFeatureService',
        'ng2MonEventsService',
        '$filter',
        'ng2CrawlHistoryInfoService',
    ];
    /* @ngInject */
    function AccessibilityDashboardWCAG22Controller (
        ng2SessionService,
        ng2AccessibilityRepoService,
        ng2CrawlsRepoService,
        ng2AccessibilityChecksRepoService,
        $q,
        rx,
        $timeout,
        AccessibilityResultTypeService,
        ng2ChangesHistoryService,
        ng2ActiveFeatureService,
        ng2MonEventsService,
        $filter,
        ng2CrawlHistoryInfoService,
    ) {
        var vm = this;
        vm.destroyed$ = new Subject();
        vm.accessibilityErrorsOnly = true;
        vm.historyCenterParameters = {};
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.selectItem = selectItem;
        vm.onToggleAction = onToggleAction;

        function activate () {
            ng2CrawlHistoryInfoService.accessibilityErrorsOnly$
                .pipe(takeUntil(vm.destroyed$))
                .subscribe((accessibilityErrorsOnly) => {
                    vm.accessibilityErrorsOnly = accessibilityErrorsOnly;
                });
            vm.pdfscan = ng2SessionService.domain.scan.scan_documents;
            vm.summary = null;
            vm.history = [];
            vm.accessibilityChecks = [];
            vm.total = 0;
            vm.historyCenterParameters = ng2ChangesHistoryService.getHistoryCenterParameters('accessibility');
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.dropdownOptions = AccessibilityResultTypeService.getResultTypeOptions().map(option => {
                return {
                    ...option,
                    action: () => {
                        vm.selectItem(option);
                        ng2MonEventsService.run('actionTrigger',
                            {
                                action: 'accessibility-diagnostic-show-filter-select',
                                params: { selected: option.value },
                            });
                    },
                };
            });
            vm.selectedObservable = new rx.BehaviorSubject(null);

            var promises = [getHistory(), getAccessibilityChecks(), getSummary()];
            vm.progress = $q.all(promises).then(function (values) {
                vm.summary = values[2];
                vm.accessibilityChecks = values[1];
                vm.history = values[0]
                    .filter(function (crawl) {
                        return angular.isObject(crawl.accessibility_checks) && crawl.accessibility_checks.hasOwnProperty('A');
                    })
                    .reverse();
                selectItem(vm.dropdownOptions[0]);
                getAccessibilityChecksExcludeIgnored();
            }, angular.noop);
            hasLevelComplianceCheck();
        }

        function onDestroy () {
            vm.destroyed$.next();
            vm.destroyed$.complete();
        }

        function onToggleAction () {
            ng2MonEventsService.run('actionTrigger', { action: 'accessibility-diagnostic-show-filter' });
        }

        function getSummary () {
            return ng2AccessibilityRepoService.getSummary();
        }

        function getHistory () {
            var params = {
                page_size: 5,
            };
            return ng2CrawlsRepoService.getAll(params);
        }

        function getAccessibilityChecks () {
            return ng2AccessibilityChecksRepoService.getAll();
        }

        function selectItem (item) {
            vm.selected = item;
            $timeout(function () {
                vm.selectedObservable.onNext(vm.selected.value);
            });
        }

        function getAccessibilityChecksExcludeIgnored () {
            vm.total = AccessibilityResultTypeService.getAccessibilityChecksExcludeIgnored(vm.accessibilityChecks);
        }

        function hasLevelComplianceCheck () {
            vm.hasLevelACompliance = $filter(
                'accessibilityHasGuidelineId')('wcag22-a') || $filter('accessibilityHasGuidelineId')('wcag22-a_mobile') || $filter(
                'accessibilityHasGuidelineId')('qualweb-a');
            vm.hasLevelAACompliance = $filter(
                'accessibilityHasGuidelineId')('wcag22-aa') || $filter('accessibilityHasGuidelineId')('wcag22-aa_mobile') || $filter(
                'accessibilityHasGuidelineId')('qualweb-aa');
            vm.hasLevelAAACompliance = $filter(
                'accessibilityHasGuidelineId')('wcag22-aaa') || $filter('accessibilityHasGuidelineId')('wcag22-aaa_mobile');
        }
    }
})();
