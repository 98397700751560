(function () {
    'use strict';

    angular.module('pages.qa').component('lastScan', {
        templateUrl: 'app/pages/qa/summary/last-scan/last-scan.html',
        controller: LastScanController,
        controllerAs: 'vm',
        bindings: {
            qaSummary: '<',
            history: '<',
            progress: '<',
        },
    });

    LastScanController.$inject = [
        '$filter',
        'gettextCatalog',
        'ng2SessionService',
        'ng2QALineChartService',
        '$timeout',
        'ng2ChangesHistoryService',
        'ng2ActiveFeatureService',
        'ng2ScoresService',
        'ng2DemoHasAccessService',
        'ng2PropertiesSumService',
    ];

    function LastScanController (
        $filter,
        gettextCatalog,
        ng2SessionService,
        ng2QALineChartService,
        $timeout,
        ng2ChangesHistoryService,
        ng2ActiveFeatureService,
        ng2ScoresService,
        ng2DemoHasAccessService,
        ng2PropertiesSumService,
    ) {
        var vm = this;
        vm.historyCenterParameters = {};
        vm.$onInit = activate;
        vm.allowVerticalAverage = false;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.cardsList = [];
            vm.historyCenterParameters = ng2ChangesHistoryService.getHistoryCenterParameters('qa');
            vm.hideCharts =
                !ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck') || !ng2ActiveFeatureService.isFeatureActivePure('qa_links');
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.doughnutOptions = {};
            vm.doughnutData = [];
            vm.doughnutLabels = [];
            vm.doughnutColors = [];

            vm.lineOverride = [];
            vm.lineChartSeries = [];
            vm.lineColors = [];
            vm.lineOptions = [];
            vm.lineChartLabels = [];
            vm.lineChartData = [];

            vm.progress.then(function () {
                $timeout(function () {
                    vm.latestCrawl = getLatestHistory();
                    setupDoughnutChart();
                    setupLineChart();
                    setupNotifications();

                    var isDemoAccount = ng2DemoHasAccessService.isDemoAccount();
                    if (isDemoAccount) {
                        vm.allowVerticalAverage = true;
                        setVerticalData(ng2ScoresService.getQADemoData());
                    } else {
                        vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
                        if (vm.allowVerticalAverage) {
                            ng2ScoresService.getAverageData('qa').then(setVerticalData);
                        }
                    }
                });
            }, angular.noop);
        }

        function getLatestHistory () {
            if (vm.history.length > 0) {
                return vm.history.sort(function (a, b) {
                    // Get the latest history id
                    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                })[0];
            } else {
                return {};
            }
        }

        function setupLineChart () {
            var lineChartService = ng2QALineChartService.createQaLineChartService();
            vm.lineOverride = lineChartService.getOverride();
            vm.lineChartSeries = lineChartService.getSeries();
            vm.lineColors = lineChartService.getColors();
            vm.lineOptions = lineChartService.getOption();
            vm.lineChartLabels = lineChartService.getLabels(vm.history);
            vm.lineChartData = lineChartService.getData(vm.history);
        }

        function setupDoughnutChart () {
            var pagesStack = ['pages_with_quality_notifications', 'pages_without_quality_notifications'];
            var documentsStack = ['documents_with_quality_notifications', 'documents_without_quality_notifications'];

            var pagesCount = vm.qaSummary.pages_with_quality_notifications || 0;
            var documentsCount = vm.qaSummary.documents_with_quality_notifications || 0;

            var totalPagesCount = ng2PropertiesSumService.sum(vm.qaSummary, pagesStack);
            var totalDocumentsCount = ng2PropertiesSumService.sum(vm.qaSummary, documentsStack);
            var totalContent = totalPagesCount + totalDocumentsCount;
            var totalErrors = pagesCount + documentsCount;

            var percentage = (totalErrors / totalContent) * 100;

            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: $filter('monNumber')((100 - percentage) / 100, '0.[00] %'),
                    totalLabel: gettextCatalog.getString('Quality assurance'),
                    subLabel: gettextCatalog.getString('Compliance'),
                    subFontSize: 170,
                    totalFontSize: 170,
                },
                cutoutPercentage: 80,
                tooltips: {
                    callbacks: {
                        beforeLabel: function () {},
                        label: function (tooltipItem) {
                            var pagesCount = vm.qaSummary.pages_with_quality_notifications || 0;
                            var documentsCount = vm.qaSummary.documents_with_quality_notifications || 0;
                            if (tooltipItem.index === 0) {
                                // First entry is expected to be entries without errors
                                pagesCount = (vm.latestCrawl.page_count || 0) - pagesCount;
                                documentsCount = (vm.latestCrawl.documents_count || 0) - documentsCount;
                            }

                            return getTooltipLabelText(pagesCount, documentsCount);
                        },
                    },
                },
            };
            vm.doughnutData = [totalContent - totalErrors, totalErrors];
            vm.doughnutLabels = [gettextCatalog.getString('Content in compliance'), gettextCatalog.getString('Content with issues')];
            vm.doughnutColors = ['#4dc0bd', '#DCDCDC'];
        }

        function setupNotifications () {
            var summaryIssues = ng2PropertiesSumService.sum(vm.qaSummary, ['confirmed_misspellings', 'broken_images', 'broken_links']);
            var summaryPageIssues = ng2PropertiesSumService.sum(vm.qaSummary, [
                'pages_with_quality_notifications',
                'documents_with_quality_notifications',
            ]);
            var historyIssues = ng2PropertiesSumService.sum(vm.history[vm.history.length - 1], [
                'spelling_errors_confirmed_count',
                'dead_count',
            ]);
            var historyPageIssues = ng2PropertiesSumService.sum(vm.history[vm.history.length - 1], ['pages_with_notifications_count']);

            vm.cardsList = [
                {
                    title: gettextCatalog.getString('Total QA issues'),
                    tooltipTitle: gettextCatalog.getString('Contains confirmed misspellings, broken links & images'),
                    issues: summaryIssues,
                    change: vm.history.length > 1 ? summaryIssues - historyIssues : 0,
                    changePercentage: getPercentageChange(summaryIssues, historyIssues),
                },
                {
                    title: gettextCatalog.getString('Content with issues'),
                    issues: summaryPageIssues,
                    change: vm.history.length > 1 ? summaryPageIssues - historyPageIssues : 0,
                    changePercentage: getPercentageChange(summaryPageIssues, historyPageIssues),
                },
            ];
        }

        function getTooltipLabelText (pages, documents) {
            pages = pages || 0;
            documents = documents || 0;
            var scope = {
                pages: $filter('numeraljs')(pages),
                documents: $filter('numeraljs')(documents),
            };
            var single = '1 page';
            var multi = '{{pages}} pages';
            if (vm.domain.scan.scan_documents) {
                single += ' and {{documents}} document(s)';
                multi += ' and {{documents}} document(s)';
            }
            return $filter('translateWithPlural')(single, pages, multi, scope);
        }

        function getPercentageChange (a, b) {
            var difference = Math.abs(a - b);
            var average = (a + b) / 2;

            if (difference >= average) {
                return 100;
            }
            return (difference / average) * 100;
        }

        function setVerticalData (data) {
            vm.verticalData = {
                verticalAverage: data.score,
                averageTitle: ng2ScoresService.getDefaultTitle(),
                tooltipText: ng2ScoresService.getDefaultTooltip() + ' ' + ng2ScoresService.getVerticalName(data.name),
            };
        }
    }
})();
