(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsPoliciesTable', {
        templateUrl: 'app/components/pagedetails/details/sections/policies/table/table.html',
        controller: SectionPoliciesTableController,
        controllerAs: 'vm',
        bindings: {
            selectPolicy: '&',
            policies: '<',
            getPolicies: '&',
            policy: '<',
            progress: '<',
            onTabChange: '&',
            onFilterChange: '&',
        },
    });

    SectionPoliciesTableController.$inject = ['gettextCatalog', 'ng2PolicyHelperService', 'activeFeatures'];

    function SectionPoliciesTableController (gettextCatalog, ng2PolicyHelperService, activeFeatures) {
        var vm = this;
        vm.$onInit = activate;
        vm.filterPolicies = filterPolicies;
        vm.getPolicyTarget = getPolicyTarget;
        vm.getTooltipMessage = getTooltipMessage;
        vm.customTabChange = customTabChange;
        vm.onFiltersChange = onFiltersChange;

        function activate () {
            vm.showFilters = activeFeatures.table_filters;
            vm.isFiltersOpen = false;
            setupFilters();
            setupTabs();
        }

        function setupFilters () {
            vm.availableFilters = {};
            vm.availableFilters.scheduled = [
                {
                    name: gettextCatalog.getString('Scheduled'),
                    value: true,
                },
                {
                    name: gettextCatalog.getString('Not scheduled'),
                    value: false,
                },
            ];
            vm.filters = {};
            vm.filters.scheduled = [];
        }

        function setupTabs () {
            vm.tabOptions = [
                {
                    name: gettextCatalog.getString('All'),
                    value: null,
                },
                {
                    name: gettextCatalog.getString('Unwanted'),
                    value: 'unwanted',
                },
                {
                    name: gettextCatalog.getString('Required'),
                    value: 'required',
                },
                {
                    name: gettextCatalog.getString('Matches'),
                    value: 'search',
                },
            ];

            vm.tab = vm.tabOptions[0].value;
        }

        function customTabChange (tab) {
            vm.tab = tab;
            vm.onTabChange({ tab: tab });
        }

        function filterPolicies () {
            if (!vm.policies) {
                return [];
            }
            vm.policies = moveNotPassedPoliciesToTheTop(vm.policies);
            if (vm.tab === 'ignored') {
                return onlyIgnored(vm.policies);
            } else if (vm.tab === 'passed') {
                return onlyPassedChecks(vm.policies);
            } else {
                return vm.policies;
            }
        }

        function onlyIgnored (policies) {
            return policies.filter(function (policy) {
                return policy.ignored;
            });
        }

        function onlyPassedChecks (policies) {
            return policies.filter(function (policy) {
                return !policy.ignored && policy.matches_count === 0 && policy.state !== 'running';
            });
        }

        function getPolicyTarget (policy) {
            return ng2PolicyHelperService.getType(policy);
        }

        function getTooltipMessage (policy) {
            return ng2PolicyHelperService.getTooltipMessageForType(policy);
        }

        function onFiltersChange (changes) {
            vm.onFilterChange({ changes: changes });
            if (changes.scheduled && changes.scheduled.length > 0) {
                var schedule = changes.scheduled[changes.scheduled.length - 1];
                vm.filters.scheduled = [schedule];
            }
        }

        function moveNotPassedPoliciesToTheTop (policies) {
            var notPassedPolicies = policies.filter(function (policy) {
                if (policy.matches_count > 0) {
                    return policy;
                }
            });
            var passedPolicies = policies.filter(function (policy) {
                if (policy.matches_count === 0) {
                    return policy;
                }
            });
            return notPassedPolicies.concat(passedPolicies);
        }
    }
})();
