import { Injectable } from '@angular/core';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import {
    CookieCategoriesLocalizeService,
} from '@client/app/modules/cookie-banner/components/cookie-overview/categories-localize/cookie-categories-localize.service';
import { DemoHasAccessService } from '@client/app/modules/demodata/services/demo-has-access.service';


@Injectable({
    providedIn: 'root',
})
export class CookieBannerHelperService {
    constructor (
        private activeFeatureService: ActiveFeatureService,
        private cookieCategoriesLocalizeService: CookieCategoriesLocalizeService,
        private demoHasAccessService: DemoHasAccessService,
    ) {}

    isAbleToSetupScript (domain: any): boolean {
        return (
            this.demoHasAccessService.isDemoConsentManager() ||
      Boolean(
          domain &&
          domain.settings.scripts.cookie_banner &&
          this.activeFeatureService.isFeatureActive('cookie_banner', domain),
      )
        );
    }

    consentPayloadDecoder (input: string): any {
        try {
            let bytesLeft = input;
            let result = '';
            while (bytesLeft.length) {
                const byte = bytesLeft.slice(0, 8);
                bytesLeft = bytesLeft.slice(8);
                result += String.fromCharCode(parseInt(byte, 2));
            }
            return JSON.parse(result);
        } catch (e) {
            return {};
        }
    }

    getCookiePreferenceLabel (value: string): string {
        const preference = this.cookieCategoriesLocalizeService[value];
        if (preference) {
            return preference;
        }
        return '';
    }
}
