import { Injectable } from '@angular/core';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { DomainGroupsEntity } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { cloneDeep } from 'lodash';
import { EssentialDomainDataModel } from '../total-domains/essential-domain-data.model';
import { EssentialDomainGroupDataModel } from '../total-domains/essential-domain-group-data.model';

export enum DomainPickerValueType {
    'Domain' = 'Domain',
    'DomainGroup' = 'DomainGroup',
}

export interface DomainValue extends Domain {
    type: DomainPickerValueType.Domain
}
export interface DomainGroupValue extends DomainGroupsEntity {
    type: DomainPickerValueType.DomainGroup;
    domainId: number;
}
export interface EssentialDomainDataValue extends EssentialDomainDataModel {
    type: DomainPickerValueType.Domain
}
export interface EssentialDomainGroupDataValue extends EssentialDomainGroupDataModel {
    type: DomainPickerValueType.DomainGroup;
    domainId: number;
}

@Injectable({
    providedIn: 'root',
})
export class DomainsService {

    constructor () { }

    flattenDomains (domains: Domain[], includeDomainGroups = true): (DomainValue | DomainGroupValue)[] {
        const result: (DomainValue | DomainGroupValue)[] = [];
        for (const domain of domains) {
            const domainValue: DomainValue = cloneDeep(domain) as DomainValue;
            domainValue.type = DomainPickerValueType.Domain;
            result.push(domainValue);

            if (domainValue.domain_groups && includeDomainGroups) {
                for (const group of domainValue.domain_groups) {
                    const domainGroupValue = cloneDeep(group) as DomainGroupValue;
                    domainGroupValue.type = DomainPickerValueType.DomainGroup;
                    domainGroupValue.domainId = domain.id;
                    result.push(domainGroupValue);
                }
            }
        }

        return result;
    }

    flattenEssentialDomainData (domains: EssentialDomainDataModel[], includeDomainGroups = true): (EssentialDomainDataValue | EssentialDomainGroupDataValue)[] {
        const result: (EssentialDomainDataValue | EssentialDomainGroupDataValue)[] = [];
        for (const domain of domains) {
            const domainValue: EssentialDomainDataValue = cloneDeep(domain) as EssentialDomainDataValue;
            domainValue.type = DomainPickerValueType.Domain;
            result.push(domainValue);

            if (domainValue.domainGroups && includeDomainGroups) {
                for (const group of domainValue.domainGroups) {
                    const domainGroupValue = cloneDeep(group) as EssentialDomainGroupDataValue;
                    domainGroupValue.type = DomainPickerValueType.DomainGroup;
                    domainGroupValue.domainId = domain.id;
                    result.push(domainGroupValue);
                }
            }
        }

        return result;
    }
}
