(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideFeaturesHeatmaps', {
        templateUrl: 'app/forms/domain-script-guide/features/heatmaps/heatmaps.html',
        controller: DomainScriptGuideFeaturesHeatmapsController,
        controllerAs: 'vm',
        bindings: {
            prefixName: '@?',
        },
    });

    DomainScriptGuideFeaturesHeatmapsController.$inject = ['ng2FormDomainScriptGuideHeatmapsService', '$timeout'];
    /* @ngInject */
    function DomainScriptGuideFeaturesHeatmapsController (ng2FormDomainScriptGuideHeatmapsService, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.onChangeValue = onChangeValue;

        function activate () {
            vm.prefixName = vm.prefixName || '';
            vm.show = ng2FormDomainScriptGuideHeatmapsService.hasAccessToFeature();
            vm.canUse = ng2FormDomainScriptGuideHeatmapsService.getValue();
            setupWatcher();
        }

        function onChangeValue () {
            $timeout(function () {
                setValue(vm.canUse);
            });
        }

        // PROTECTED

        function setupWatcher () {
            ng2FormDomainScriptGuideHeatmapsService.settingChangeObs.subscribe(function () {
                vm.canUse = ng2FormDomainScriptGuideHeatmapsService.getValue();
            });
        }

        function setValue (value) {
            ng2FormDomainScriptGuideHeatmapsService.setValue(value, true);
        }
    }
})();
