import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistory', {
        templateUrl: 'app/pages/global/changes-history/changes-history.html',
        controller: DashboardController,
        controllerAs: 'vm',
    });

    DashboardController.$inject = [
        'ng2ActiveFeatureService',
        'moment',
        'ng2CrawlsRepoService',
        'gettextCatalog',
        'ParamService',
        '$location',
        '$state',
        '$window',
        'exporter',
        'ng2ChangesHistoryService',
        'Lodash',
        'ng2MonEventsService',
        '$timeout',
        'ng2DemoHasAccessService',
        'ng2MonUIRouterTransitionService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function DashboardController (
        ng2ActiveFeatureService,
        moment,
        ng2CrawlsRepoService,
        gettextCatalog,
        ParamService,
        $location,
        $state,
        $window,
        exporter,
        ng2ChangesHistoryService,
        Lodash,
        ng2MonEventsService,
        $timeout,
        ng2DemoHasAccessService,
        ng2MonUIRouterTransitionService,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.showScoresDropdown = false;
        vm.$onInit = activate;
        vm.exportCompoundCsv = exportCompoundCSV;
        vm.print = print;
        vm.goToDomain = goToDomain;
        vm.moduleTranslation;
        vm.onChangeDomain = onChangeDomain;
        vm.exportCsv = exportCsv;
        vm.setModule = setModule;
        vm.setChartType = setChartType;
        vm.setDate = setDate;

        function activate () {
            vm.settingModule = false;
            vm.showExport =
                ng2ActiveFeatureService.isFeatureActivePure('data_export');
            vm.loading = false;
            vm.datePickerLoading = false;
            vm.domain = null;
            vm.domainGroup = null;
            vm.domainSelector = null;
            vm.domains = [];
            vm.domainPickerPlaceholder = gettextCatalog.getString(
                'Loading domains, please wait',
            );
            vm.chartTypesOptions = [
                {
                    name: gettextCatalog.getString('Issues'),
                    value: 'issues',
                },
            ];
            var isDemoAccount = ng2DemoHasAccessService.isDemoAccount();
            if (!isDemoAccount) {
                vm.chartTypesOptions.push({
                    name: gettextCatalog.getString('Scores'),
                    value: 'scores',
                });
            }
            vm.selectedChart = getSelectedChart(vm.showScoresDropdown);
            vm.datepickerOptions = { opens: 'center' };
            vm.dateFormat = 'YYYY-MM-DD';
            vm.modules = [];
            vm.date = {
                startDate: moment().subtract(3, 'months'),
                endDate: moment(),
            };
            vm.displayDateFormat = DATE_FORMAT;
            vm.selectedModule = 'all';
            vm.history = null;
            setupWatchers();
        }

        function exportCsv () {
            if (
                ['seo', 'qa', 'accessibility'].indexOf(vm.selectedModule) > -1
            ) {
                return exporter.requestExportFromModule(vm.selectedModule);
            }

            if (vm.history) {
                return exporter.parseHistory(vm.selectedModule, vm.history);
            } else {
                return null;
            }
        }

        function goToDomain () {
            var params = {
                domainId: vm.domain.id,
            };
            var url;

            if (vm.domainGroup !== null) {
                params.domain_group = vm.domainGroup.id;
            }

            switch (vm.selectedModule) {
                case 'seo':
                    url = 'base.customer.domain.seo.summary';
                    break;
                case 'qa':
                    url = 'base.customer.domain.qa.summary';
                    break;
                case 'policy':
                    url = 'base.customer.domain.policy.dashboard';
                    break;
                case 'accessibility':
                    url = 'base.customer.domain.accessibility.summary';
                    break;
                case 'readability':
                    url = 'base.customer.domain.qa.readability.dashboard';
                    break;
                case 'data-privacy':
                    url = 'base.customer.domain.data_privacy.summary';
                    break;
                case 'performance':
                    url = 'base.customer.domain.page_performance.pages';
                    break;
                default:
                    url = 'base.customer.domain.dashboard';
            }

            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(url, params),
            );
        }

        function print () {
            $window.print();
        }

        function onChangeDomain (newValue) {
            if (newValue === null || typeof newValue !== 'object') {
                vm.domainSelector = null;
                vm.domain = null;
                vm.domainGroup = null;
                return;
            }

            // Do not update if the selected domain has not changed.
            if (
                (vm.domainGroup === null &&
                    vm.domain !== null &&
                    newValue.selectorId === vm.domain.selectorId) ||
                (vm.domainGroup !== null &&
                    newValue.selectorId === vm.domainGroup.selectorId)
            ) {
                return;
            }

            vm.domainSelector = newValue.selectorId;
            var domain = newValue;
            var domainGroup = null;
            if (newValue.type === 'DomainGroup') {
                domainGroup = newValue;
                domain = Lodash.find(vm.domains, {
                    id: newValue.domainId,
                    type: 'Domain',
                });
            }

            setDomain(domain, domainGroup);
            setModule(vm.selectedModule);
            getCrawlHistory();
        }

        function exportCompoundCSV () {
            var params = {
                body: 'csvExportCompoundDialog',
                size: 'sm',
                classes: 'fade-animation middle center',
                data: {
                    exports: [
                        {
                            name: gettextCatalog.getString(
                                'Start compound export',
                            ),
                            data: {
                                category: 'scan_history_overview',
                            },
                        },
                    ],
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function setModule (module, shouldSetParams) {
            if (
                typeof module !== 'string' ||
                module === ng2ChangesHistoryService.getModule()
            ) {
                return;
            }

            vm.showScoresDropdown = getShowScores(vm.selectedModule);
            vm.selectedChart = getSelectedChart(vm.showScoresDropdown);

            $timeout(function () {
                ng2ChangesHistoryService.setModule(module);
                vm.selectedModule = ng2ChangesHistoryService.getModule();

                if (shouldSetParams) {
                    setParams();
                }
                vm.moduleTranslation =
                  ng2ChangesHistoryService.getModuleTranslation();
                removeFocusFromSelect();
            });
        }

        function setChartType () {
            removeFocusFromSelect();
            setParams();
        }

        function setDate (date) {
            updateDate(date);
            getCrawlHistory();
        }

        // PROTECTED

        function loadLocations () {
            var params = $location.search();
            if (params.from != null) {
                vm.date.startDate = moment(params.from, vm.dateFormat);
            }

            if (params.to != null) {
                vm.date.endDate = moment(params.to, vm.dateFormat);
            }

            if (params.group_id != null) {
                vm.domainSelector = 'domain_group_' + params.group_id;
            } else if (params.domain_id != null) {
                vm.domainSelector = 'domain_' + params.domain_id;
            }

            if (params.module != null) {
                vm.selectedModule = params.module;
                vm.showScoresDropdown = getShowScores(vm.selectedModule);
            }

            if (params.chart != null) {
                var knownChart = vm.chartTypesOptions.some(function (
                    chartOptions,
                ) {
                    return chartOptions.value === params.chart;
                });

                if (knownChart) {
                    vm.selectedChart = params.chart;
                }
            }
        }

        function setupWatchers () {
            ng2ChangesHistoryService.domainsObs.subscribe(function (domains) {
                if (Array.isArray(domains)) {
                    vm.domainPickerPlaceholder =
                        gettextCatalog.getString('Select domain');
                    vm.domains = domains;
                    loadLocations();
                    updateDate(vm.date);
                    if (
                        vm.domain === null &&
                        vm.domainGroup === null &&
                        vm.domainSelector !== null
                    ) {
                        onChangeDomain(
                            ng2ChangesHistoryService.getDomainFromSelectorId(
                                vm.domainSelector,
                            ),
                        );
                    }
                }
            });
        }

        function getCrawlHistory () {
            if (!vm.domain) {
                return;
            }

            setParams();
            var params = {
                from: vm.date.startDate.format(vm.dateFormat),
                to: vm.date.endDate.format(vm.dateFormat),
                domain_id: vm.domain.id,
                group_id:
                    vm.domainGroup != null ? vm.domainGroup.id : undefined,
                module: vm.selectedModule,
            };

            vm.loading = true;
            vm.progress = ng2CrawlsRepoService.getAll(params)
                .then(function (history) {
                    vm.history = history;
                    ng2ChangesHistoryService.setHistory(history.reverse());
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setParams () {
            var params = {
                from: vm.date.startDate.format(vm.dateFormat),
                to: vm.date.endDate.format(vm.dateFormat),
                domain_id: vm.domain.id,
                group_id:
                    vm.domainGroup !== null ? vm.domainGroup.id : undefined,
                module: vm.selectedModule,
            };

            if (vm.showScoresDropdown) {
                params.chart = vm.selectedChart;
            } else {
                params.chart = null;
            }

            ParamService.setParams(params);
        }

        function updateDate (date) {
            if (vm.date === date) {
                return;
            }

            vm.date = date;
            ng2ChangesHistoryService.setDate(date);
            vm.datePickerLoading = true;
            $timeout(function () {
                vm.datePickerLoading = false;
            });
        }

        function setDomain (domain, domainGroup) {
            vm.domain = domain;
            vm.domainGroup = domainGroup;
            ng2ChangesHistoryService.setDomain(domain, domainGroup);
            var modules = ng2ChangesHistoryService.getModules();

            if (!isSelectedModuleAvailable(vm.selectedModule, modules)) {
                vm.selectedModule = 'all';
            }

            vm.modules = modules;
            vm.showScoresDropdown = getShowScores(vm.selectedModule);
            vm.selectedChart = getSelectedChart(vm.showScoresDropdown);
            removeFocusFromSelect();
        }

        function isSelectedModuleAvailable (selectedModule, availableModules) {
            return Array.isArray(availableModules) && availableModules.some(function (module) {
                return module.value === selectedModule;
            });
        }

        function removeFocusFromSelect () {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }

        function getSelectedChart (showScoresDropdown) {
            if (showScoresDropdown) {
                return vm.selectedChart || vm.chartTypesOptions[0].value;
            } else {
                return vm.chartTypesOptions[0].value;
            }
        }

        function getShowScores (selectedModule) {
            return (
                ng2ActiveFeatureService.isFeatureActive('monsido_score') &&
                vm.domain != null &&
                ['seo', 'qa', 'accessibility'].indexOf(selectedModule) > -1
            );
        }
    }
})();
