import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ToastService } from '@monsido/angular-shared-components';
import { CookieModelService } from '@client/app/modules/cookie-banner/components/cookie-overview/cookie-model.service';
import { MonUIRouterTransitionService } from '@monsido/services/ui-router-transition-service/ui-router-transition-service';
import demoData from './../../../../demo/cookie-overview.json';
import { MonModalService } from '@client/app/modules/modals/services/mon-modal/mon-modal.service';
import { COOKIE_FIELDS } from '@monsido/core/constants/cookie-fields.constant';
import { CookiesRepoService } from '@client/app/services/api/cookies-repo/cookies-repo.service';
import { DemoHasAccessService } from '@client/app/modules/demodata/services/demo-has-access.service';
import { CookieEntity } from '@client/app/modules/cookie-banner/interfaces/cookie.interface';
import { StateService } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class CookieOverviewService {
    constructor (
        private cookiesRepoService: CookiesRepoService,
        private demoHasAccessService: DemoHasAccessService,
        private monModalService: MonModalService,
        private translateService: TranslateService,
        private state: StateService,
        private toastService: ToastService,
        private cookieModelService: CookieModelService,
        private routerTransitionService: MonUIRouterTransitionService,
    ) {}

    getCookies (domainId: number): Promise<any> {
        if (this.hasDemoAccess()) {
            return Promise.resolve(this.getDemoCookies());
        }
        return this.cookiesRepoService.getAll({ domain_id: domainId });
    }

    async getCookiesFromCentralDb (): Promise<any> {
        if (this.hasDemoAccess()) {
            return Promise.resolve(this.getDemoCookies());
        }
        return await this.cookiesRepoService.getCookiesFromCentralDb();
    }

    createMultipleCookies (cookies: any[], domainId: number): Promise<any> {
        if (this.hasDemoAccess()) {
            return Promise.resolve(true);
        }

        const promises = cookies.map(cookie => {
            const cookieOptions = {
                [COOKIE_FIELDS.name]: cookie.name,
                [COOKIE_FIELDS.domain]: cookie.domain,
                [COOKIE_FIELDS.platform]: cookie.platform,
                [COOKIE_FIELDS.source]: cookie.source,
                [COOKIE_FIELDS.type]: cookie.type,
                [COOKIE_FIELDS.descriptions]: cookie.descriptions,
                [COOKIE_FIELDS.expirations]: cookie.expirations,
            } as unknown as CookieEntity;
            const newCookie = this.cookieModelService.createCookie(cookieOptions);
            return newCookie ? this.cookiesRepoService.create(domainId, newCookie) : Promise.resolve();
        });

        return Promise.all(promises);
    }

    updateMultipleCookies (cookies: any[], domainId: number): Promise<any> {
        if (this.hasDemoAccess()) {
            return Promise.resolve(true);
        }

        const promises = cookies.map(cookie => {
            const cookieOptions = {
                [COOKIE_FIELDS.id]: cookie.id,
                [COOKIE_FIELDS.name]: cookie.name,
                [COOKIE_FIELDS.domain]: cookie.domain,
                [COOKIE_FIELDS.platform]: cookie.platform,
                [COOKIE_FIELDS.source]: cookie.source,
                [COOKIE_FIELDS.type]: cookie.type,
                [COOKIE_FIELDS.descriptions]: cookie.descriptions,
                [COOKIE_FIELDS.expirations]: cookie.expirations,
            } as unknown as CookieEntity;
            const newCookie = this.cookieModelService.createCookie(cookieOptions);
            return newCookie ? this.cookiesRepoService.update(domainId, newCookie) : Promise.resolve();
        });

        return Promise.all(promises);
    }

    removeMultipleCookies (cookies: any[], domainId: number): Promise<any> {
        if (this.hasDemoAccess()) {
            return Promise.resolve(true);
        }

        const promises = cookies.map(cookie =>
            this.cookiesRepoService.destroy(domainId, cookie.id),
        );

        return Promise.all(promises);
    }

    confirmSavePopup (params: any): void {
        this.monModalService.customDialog({
            title: this.translateService.getString('Save existing cookies'),
            message: this.translateService.getString('Save your page before leaving to keep your progress and publish the completed cookies.'),
            buttons: [
                {
                    label: this.translateService.getString('Leave without saving'),
                    className: 'btn btn-secondary',
                    callback: () => {
                        params.options.listenerRef();
                        this.routerTransitionService.onTransitionFinished(
                            () => {
                                this.state.go(params.transition.to().name, params.transition.params());
                            },
                        );
                    },
                    value: 'leave',
                },
                {
                    label: this.translateService.getString('Save cookies'),
                    className: 'btn btn-primary',
                    callback: () => {
                        if (params.options.form.$valid) {
                            params.callback().then(() => {
                                params.options.listenerRef();
                                this.routerTransitionService.onTransitionFinished(
                                    () =>{
                                        this.state.go(params.transition.to().name, params.transition.params());
                                    },
                                );
                            });
                        } else {
                            this.toastService.warning(this.translateService.getString('Could not save invalid form'));
                        }
                    },
                    value: 'save',
                },
            ],
        }, {});
    }

    private hasDemoAccess (): boolean {
        return this.demoHasAccessService.isDemoConsentManager();
    }

    private getDemoCookies (): any {
        return demoData;
    }

    successToast (): void {
        this.toastService.success(this.translateService.getString('Cookie Overview saved'));
    }

    errorToast (msg: string): void {
        this.toastService.error(this.translateService.getString(msg));
    }
}
