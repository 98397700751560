import { Injectable } from '@angular/core';
import { ReadabilityGradesLixType, ReadabilityGradesType } from './qa-readability.type';
import { getGradeSubTitles, getGradeTooltips, getGradeTranslations } from './qa-readability.data';
import { READABILITY_HELPER } from '@client/app/core/constants/helpers/readability-helper';
import { TranslateService } from '@client/app/services/translate/translate.service';
import MonColors from '@monsido/colors/dist/es6/colors';

type ReadabilityListType = { name: string, page_count: number };

@Injectable({
    providedIn: 'root',
})
export class QAReadabilityService {
    translations: ReadabilityGradesType;
    readabilityColorPallete: string[];
    omitLevels = ['na'];
    tooltips: ReadabilityGradesType;
    subTitle: ReadabilityGradesLixType;


    constructor (private translateService: TranslateService) {
        this.translations = getGradeTranslations(this.translateService);
        this.tooltips = getGradeTooltips(this.translateService);
        this.subTitle = getGradeSubTitles(this.translateService);
        this.readabilityColorPallete = [
            MonColors['main-1'],
            MonColors['secondary-7'],
            MonColors['secondary-8'],
            '#5E3EA8',
            MonColors['branding-6'],
            MonColors['secondary-16'],
            '#FDC50D',
            MonColors['branding-1'],
        ];
    }

    getTranslationScore (score: string, readabilityType: string): string {
        if (!readabilityType) {
            return (this.translations[score] && this.translations[score].test_name) || this.translateService.getString('Unknown Score');
        }
        return this.translations[readabilityType][score];
    }

    getTooltipScore (score: string, readabilityType: string): string {
        if (!readabilityType) {
            return (this.tooltips[score] && this.tooltips[score].test_name) || '';
        }
        return this.tooltips[readabilityType][score];
    }

    hasTooltipScore (score: string, readabilityType: string): boolean {
        if (!readabilityType || !this.tooltips[readabilityType]) {
            return false;
        }
        const keys = Object.keys(this.tooltips[readabilityType]);
        return keys.indexOf(score) > -1;
    }

    getReaderLevelName (score: string): string {
        let readerLevelName;
        switch (score) {
            case '1':
                readerLevelName = 'below_5th';
                break;
            case '5':
                readerLevelName = '5th';
                break;
            case '6':
                readerLevelName = '6th';
                break;
            case '7':
                readerLevelName = '7th';
                break;
            case '8':
                readerLevelName = '8th_to_9th';
                break;
            case '10':
                readerLevelName = '10th_to_12th';
                break;
            case '15':
                readerLevelName = 'college';
                break;
            case '17':
                readerLevelName = 'college_graduate';
                break;
            case 'language_not_supported':
                readerLevelName = 'language_not_supported';
                break;
            case 'other':
                readerLevelName = 'other';
                break;
            case 'na':
                readerLevelName = 'no_score';
                break;
        }
        return readerLevelName;
    }

    getColorPallete (): string[] {
        return this.readabilityColorPallete;
    }

    getReadabilityLevels (readabilityTest: string, omitNA?: boolean): string[] {
        if (!READABILITY_HELPER[readabilityTest]) {
            return [];
        }
        return READABILITY_HELPER[readabilityTest].filter((level) => {
            if (omitNA) {
                return true;
            } else {
                return this.omitLevels.indexOf(level) === -1;
            }
        });
    }

    getReadabilityLevelSubTitle (score: string, readabilityType: string): string {
        if (!this.subTitle[readabilityType] || !this.subTitle[readabilityType][score]) {
            return '';
        }
        return this.subTitle[readabilityType][score];
    }

    sortReadability (test: string, list: ReadabilityListType[]): ReadabilityListType[] {
        list = list || [];
        return list.slice().sort((a, b) => READABILITY_HELPER.weights[test][a.name] - READABILITY_HELPER.weights[test][b.name]);
    }
}
