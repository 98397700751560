import { Injectable } from '@angular/core';
import { QAReadabilityService } from '@client/app/blocks/service/qa-readability/qareadability.service';

@Injectable({
    providedIn: 'root',
})

export class ChangesHistoryReadabilityService {
    constructor (private qaReadabilityService: QAReadabilityService) {}

    getReadabilityLevels (readabilityTest: any): any[] {
        const readabilityLevels = this.qaReadabilityService.getReadabilityLevels(readabilityTest, false);
        if (!readabilityLevels) {
            return [];
        }
        return readabilityLevels.map(level => this.getReadabilityLevelObject(level, readabilityTest));
    }

    getMostCommonReadabilityLevel (readabilityTest: any, readabilityDistribution: any): any {
        const readabilityLevels = this.qaReadabilityService.getReadabilityLevels(readabilityTest, false);
        if (!readabilityTest || !readabilityDistribution || !readabilityLevels || readabilityLevels.length === 0) {
            return null;
        }

        let maxPagesValue = 0;
        let mostCommonLevel = readabilityLevels[0];
        readabilityLevels.forEach(level => {
            if (readabilityDistribution[level] >= maxPagesValue) {
                maxPagesValue = readabilityDistribution[level];
                mostCommonLevel = level;
            }
        });

        return this.getReadabilityLevelObject(mostCommonLevel, readabilityTest);
    }

    private getReadabilityLevelObject (level: any, readabilityTest: any): any {
        const readabilityLevels = this.qaReadabilityService.getReadabilityLevels(readabilityTest, false);
        return {
            attr: level,
            label: this.qaReadabilityService.getTranslationScore(level, readabilityTest),
            color: this.qaReadabilityService.getColorPallete()[readabilityLevels.indexOf(level)] || '#000000',
        };
    }
}
