(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('ignoredMisspellings', {
        templateUrl: 'app/pages/qa/spellcheck/ignoredMisspellings/ignoredMisspellings.html',
        controller: IgnoredMisspellings,
        controllerAs: 'vm',
    });

    IgnoredMisspellings.$inject = [
        'ng2SpellingRepoService',
        'gettextCatalog',
        '$stateParams',
        '$filter',
        '$controller',
        'spellService',
    ];
    /* @ngInject */
    function IgnoredMisspellings (ng2SpellingRepoService, gettextCatalog, $stateParams, $filter, $controller, spellService) {
        var vm = this;

        vm.removeWord = removeWord;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = null;
            vm.type = null;
            vm.domainId = $stateParams.domainId;
            vm.misspellings = [];
            vm.tabs = [{ name: gettextCatalog.getString('All'), faIcon: 'fas fa-language', value: 'all' }];
            vm.activeTab = vm.tabs[0].value;
            vm.subheaderTranslation = '';
            vm.exports = [
                {
                    name: gettextCatalog.getString('Ignored misspellings on account'),
                    data: {
                        category: 'account_dictionary_ignored',
                    },
                },
            ];

            ng2SpellingRepoService.getAllLanguages({ domainId: $stateParams.domainId }).then(function (data) {
                angular.forEach(data, function (item) {
                    vm.tabs.push({
                        name: $filter('translateLanguage')(item.lang),
                        faIcon: 'fas fa-language',
                        value: item.lang,
                    });
                });
            }, angular.noop);

            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                language: vm.activeTab === 'all' ? null : vm.activeTab,
                search: vm.search,
                type: vm.type,
                domainId: vm.domainId,
            };
            vm.promise = ng2SpellingRepoService.getAllIgnoredSpellingErrors(params).then(function (data) {
                vm.misspellings = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.misspellings.total, '0,0'),
                '1 misspelling ignored across all domains',
                '{{$count}} misspellings added across all domains',
                {},
            );
        }

        function removeWord (word) {
            spellService.destroyWord(word.id).then(function () {
                getPage();
            }, angular.noop);
        }
    }
})();
