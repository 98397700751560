import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { TrackingService } from '@client/app/modules/tracking/services/tracking.service';
import { gainsight } from '@monsido/core/constants/general.constant';

declare global {
    interface Window {
        aptrinsic: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class GainsightService extends TrackingService {
    private activateTracking = false;

    constructor (private sessionService: SessionService) {
        super();
    }

    trackEvent (url: string, type: string, ref: string) {
        if (this.activateTracking) {
            window.aptrinsic('track', type + ' ' + url, { 'type': type,'url': url ,'ref': ref });
        }
    }

    trackPageView (url: string, title: string, ref: string, virtual: boolean, params: any) {}

    setTrackingInfo (settings: any) {
        if (!this.sessionService.isSudo) {
            // Script from Gainsight - converted names.
            (function (window, document, path, key, co) {
                const aptrinsic = 'aptrinsic';
                window[aptrinsic] = window[aptrinsic] || function () {
                    // eslint-disable-next-line prefer-rest-params
                    (window[aptrinsic].q = window[aptrinsic].q || []).push(arguments);
                };
                window[aptrinsic].p = key;
                window[aptrinsic].c = co;
                const newScriptElement = document.createElement('script');
                newScriptElement.async = true;
                newScriptElement.src = path + '?a=' + key;
                const firstScriptElement = document.getElementsByTagName('script')[0];
                firstScriptElement.parentNode?.insertBefore(newScriptElement, firstScriptElement);
            })(window, document, gainsight.path, gainsight.apiKey);
            this.activateTracking = true;
        }
    }
}
