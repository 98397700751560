import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import {
    PagePerformanceRescanRepoService,
} from '@client/app/services/api/page-performance/page-performance-rescan-repo/page-performance-rescan-repo.service';
import { MonModalService } from '@client/app/modules/modals/services/mon-modal/mon-modal.service';

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceScanService {
    constructor (
        private rescanRepoService: PagePerformanceRescanRepoService,
        private modalService: MonModalService,
        private translateService: TranslateService,
    ) {}

    performanceRescan (page: any, profile: any): Promise<any> {
        const translation = this.translateService.getString(
            'You are about to perform a scan of <span class=\'text-link\'>[[pageTitle]]</span> using the profile <span class=\'text-link\'>[[profileName]]</span>.<br>The scan can take a few minutes to finalise, depending on the size of the page.<br><br>Do you want to continue?',
            {
                pageTitle: page.title,
                profileName: profile.name,
            },
        );
        return this.modalService.confirm({ message: translation }).then(() => {
            const params = {
                page_id: page.id,
                profile_id: profile.id,
            };
            return this.rescanRepoService.create(params);
        });
    }
}
