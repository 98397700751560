import { Injectable } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { BiService } from '@client/app/modules/business-intelligence/services/bi.service';

@Injectable({
    providedIn: 'root',
})
export class ActionRunService {
    constructor (
        private monEventService: MonEventService,
        private biService: BiService,
    ) {}

    initializeApp () {
        this.monEventService.addListener('actionTrigger', (payload: any) => {
            if (!payload.ref) {
                payload.ref = window.location.href;
            }
            if (!payload.params) {
                payload.params = {};
            }

            this.biService.action(payload.action, payload.ref, payload.params);
        });
    }
}
