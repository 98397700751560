(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRuleMetaHeader', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/blocks/meta-header/meta-header.html',
        controller: MetaHeaderController,
        controllerAs: 'vm',
        bindings: {
            cRule: '<',
            monDialogId: '<',
            policyRuleForm: '=',
        },
    });

    MetaHeaderController.$inject = ['ng2PolicyFormHelperService', 'gettextCatalog', 'monDialog'];

    /* @ngInject */
    function MetaHeaderController (ng2PolicyFormHelperService, gettextCatalog, monDialog) {
        var vm = this;
        vm.$onInit = activate;
        vm.chooseOperator = chooseOperator;
        vm.chooseSubEntryParameter = chooseSubEntryParameter;
        vm.close = close;
        vm.getQueryType = getQueryType;
        vm.isDateOperator = isDateOperator;

        function activate () {
            if (Array.isArray(vm.cRule.sub_entry)) {
                vm.cRule.sub_entry = vm.cRule.sub_entry.join('');
            }
            vm.cRule.inverse = false;
            vm.cRule.query = vm.cRule.query || '';
            ng2PolicyFormHelperService.setRuleType(vm.cRule.type);
            setupTypeOptions();
            setupSubEntryOptions();
            if (!vm.cRule.name) {
                chooseOperator(vm.typeOptions.length > 0 ? vm.typeOptions[0] : {});
            } else {
                chooseOperator(
                    vm.typeOptions.find(function (type) {
                        return type.type === vm.cRule.operator;
                    }),
                );
            }
        }

        function setupTypeOptions () {
            vm.typeOptions = ng2PolicyFormHelperService.getOptionsByRuleType();
            vm.dropdownOptions = ng2PolicyFormHelperService.getMenuDropdownOptionsByRuleType(vm.chooseOperator);
        }

        function chooseOperator (option) {
            vm.selectedOperator = option;
            vm.cRule.operator = option.type;
            if (isDateOperator() > -1 && !vm.selectedSubEntry) {
                chooseSubEntryParameter(vm.dateAgeOptions[0]);
            }
        }

        function chooseSubEntryParameter (option) {
            vm.selectedSubEntry = option;
        }

        function getQueryType () {
            if (isDateOperator()) {
                return 'number';
            }

            return 'text';
        }

        function setupSubEntryOptions () {
            vm.dateAgeOptions = [
                {
                    name: gettextCatalog.getString('Seconds'),
                    value: 'second',
                },
                {
                    name: gettextCatalog.getString('Minutes'),
                    value: 'minute',
                },
                {
                    name: gettextCatalog.getString('Hours'),
                    value: 'hour',
                },
                {
                    name: gettextCatalog.getString('Days'),
                    value: 'day',
                },
                {
                    name: gettextCatalog.getString('Months'),
                    value: 'month',
                },
                {
                    name: gettextCatalog.getString('Years'),
                    value: 'year',
                },
            ];

            vm.dropdownDateAgeOptions = vm.dateAgeOptions.map(option => {
                return {
                    ...option,
                    action: () => vm.chooseSubEntryParameter(option),
                    label: option.name,
                };
            });

            if (isDateOperator()) {
                var queryStr = vm.cRule.query.replace(/(second|minute|hour|day|month|year)/, '');

                if (queryStr === vm.cRule.query) {
                    chooseSubEntryParameter(vm.dateAgeOptions[0]);
                } else {
                    var size = vm.cRule.query.substring(queryStr.length, vm.cRule.query.length);
                    chooseSubEntryParameter(
                        vm.dateAgeOptions.find(function (option) {
                            return option.value === size;
                        }),
                    );
                }

                vm.cRule.query = parseInt(queryStr);
            }
        }

        function close () {
            var newRule = angular.copy(vm.cRule);
            if (isDateOperator()) {
                newRule.query = isNaN(parseInt(newRule.query)) ? 0 : vm.cRule.query;
                newRule.query = newRule.query + vm.selectedSubEntry.value;
            }
            if (vm.policyRuleForm) {
                vm.policyRuleForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, newRule);
        }

        function isDateOperator () {
            return ['date_age_lt', 'date_age_gt'].indexOf(vm.cRule.operator) > -1;
        }
    }
})();
