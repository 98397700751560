(function () {
    'use strict';

    angular.module('pages.uptimes').component('uptime', {
        templateUrl: 'app/forms/uptime/uptime.html',
        controller: UptimeController,
        controllerAs: 'vm',
        bindings: {
            uptime: '<?',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    UptimeController.$inject = ['monDialog', 'gettextCatalog', 'ng2ToastService', '$timeout', 'uptimeService', 'ng2FormUptimeService'];
    /* @ngInject */
    function UptimeController (monDialog, gettextCatalog, toastService, $timeout, uptimeService, ng2FormUptimeService) {
        var vm = this;
        vm.submit = submit;
        vm.$onInit = activate;
        vm.addOneMinuteOptionIfThePingTimeIsOneMinute = addOneMinuteOptionIfThePingTimeIsOneMinute;

        function activate () {
            vm.ngModel = angular.copy(vm.uptime, { pingtime_requests: 5, answertime_requests: 3000 });
            vm.emailFails = [];
            vm.notiFails = [];
            vm.saving = false;
            vm.pingIntervals = ng2FormUptimeService.getIntervalOptions();
            setupAnswerTimeRequests();
            setupPingTimeRequests();
            setupAnswerTime();
            addOneMinuteOptionIfThePingTimeIsOneMinute();

            if (typeof vm.ngModel.email_fail === 'string') {
                vm.emailFails = vm.ngModel.email_fail.split(',').filter(Boolean);
            }

            if (typeof vm.ngModel.email_long === 'string') {
                vm.notiFails = vm.ngModel.email_long.split(',').filter(Boolean);
            }

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.uptimeForm });
                }
            });
        }

        function setupAnswerTime () {
            vm.uptimeAnswerTimeOptions = [
                {
                    name: gettextCatalog.getString('Over 200 ms.'),
                    value: 200,
                },
                {
                    name: gettextCatalog.getString('Over 500 ms.'),
                    value: 500,
                },
                {
                    name: gettextCatalog.getString('Over 1000 ms.'),
                    value: 1000,
                },
                {
                    name: gettextCatalog.getString('Over 2000 ms.'),
                    value: 2000,
                },
                {
                    name: gettextCatalog.getString('Over 3000 ms.'),
                    value: 3000,
                },
                {
                    name: gettextCatalog.getString('Over 4000 ms.'),
                    value: 4000,
                },
                {
                    name: gettextCatalog.getString('Over 5000 ms.'),
                    value: 5000,
                },
                {
                    name: gettextCatalog.getString('Over 6000 ms.'),
                    value: 6000,
                },
                {
                    name: gettextCatalog.getString('Over 7000 ms.'),
                    value: 7000,
                },
                {
                    name: gettextCatalog.getString('Over 8000 ms.'),
                    value: 8000,
                },
                {
                    name: gettextCatalog.getString('Over 9000 ms.'),
                    value: 9000,
                },
                {
                    name: gettextCatalog.getString('Over 10000 ms.'),
                    value: 10000,
                },
            ];
            if (typeof vm.ngModel.answertime === 'undefined') {
                vm.ngModel.answertime = vm.uptimeAnswerTimeOptions[0].value;
            }
        }
        function setupAnswerTimeRequests () {
            vm.uptimeAnswerTimeRequestOptions = [
                {
                    name: gettextCatalog.getString('5 min.'),
                    value: 5,
                },
                {
                    name: gettextCatalog.getString('10 min.'),
                    value: 10,
                },
                {
                    name: gettextCatalog.getString('15 min.'),
                    value: 15,
                },
                {
                    name: gettextCatalog.getString('30 min.'),
                    value: 30,
                },
            ];
            if (typeof vm.ngModel.answertime_requests === 'undefined') {
                vm.ngModel.answertime_requests = vm.uptimeAnswerTimeRequestOptions[0].value;
            }
        }
        function setupPingTimeRequests () {
            vm.uptimePingTimeRequestOptions = [
                {
                    name: gettextCatalog.getString('5 min.'),
                    value: 5,
                },
                {
                    name: gettextCatalog.getString('10 min.'),
                    value: 10,
                },
                {
                    name: gettextCatalog.getString('15 min.'),
                    value: 15,
                },
                {
                    name: gettextCatalog.getString('30 min.'),
                    value: 30,
                },
                {
                    name: gettextCatalog.getString('60 min.'),
                    value: 60,
                },
                {
                    name: gettextCatalog.getString('120 min.'),
                    value: 120,
                },
            ];
            if (typeof vm.ngModel.pingtime_requests === 'undefined') {
                vm.ngModel.pingtime_requests = vm.uptimePingTimeRequestOptions[0].value;
            }
        }

        function close (uptime) {
            monDialog.close(vm.monDialogId, uptime);
        }

        function submit () {
            var promise;
            vm.saving = true;

            if (vm.ngModel.notification_fail) {
                vm.ngModel.email_fail = vm.emailFails
                    .map(function (email) {
                        return email.text;
                    })
                    .join(',');
            }
            if (vm.ngModel.notification_long) {
                vm.ngModel.email_long = vm.notiFails
                    .map(function (email) {
                        return email.text;
                    })
                    .join(',');
            }

            if (vm.ngModel.id) {
                promise = uptimeService.update(vm.ngModel);
            } else {
                promise = uptimeService.create(vm.ngModel);
            }

            promise
                .then(function (uptime) {
                    toastService.success(gettextCatalog.getString('Heartbeat status saved'));
                    if (vm.uptimeForm) {
                        vm.uptimeForm.$setPristine();
                    }
                    close(uptime);
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }

        function addOneMinuteOptionIfThePingTimeIsOneMinute () {
            if (vm.ngModel.pingtime === 60) {
                if (vm.pingIntervals[0]) {
                    if (
                        vm.pingIntervals[0].value === 60 &&
                        vm.uptimeAnswerTimeRequestOptions[0].value !== 1 &&
                        vm.uptimePingTimeRequestOptions[0].value !== 1
                    ) {
                        vm.uptimeAnswerTimeRequestOptions.unshift({
                            name: gettextCatalog.getString('1 min.'),
                            value: 1,
                        });
                        vm.uptimePingTimeRequestOptions.unshift({
                            name: gettextCatalog.getString('1 min.'),
                            value: 1,
                        });
                    }
                }
            } else {
                if (vm.uptimeAnswerTimeRequestOptions[0].value === 1 && vm.uptimePingTimeRequestOptions[0].value === 1) {
                    vm.uptimeAnswerTimeRequestOptions.shift();
                    vm.uptimePingTimeRequestOptions.shift();
                }
            }
        }
    }
})();
