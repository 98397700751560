import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.admin.user').component('adminUsersTable', {
        templateUrl: 'app/pages/admin/users/table/table.html',
        controller: UsersController,
        controllerAs: 'vm',
        bindings: {
            isAdmin: '<?',
            getPage: '&',
            users: '<',
            onPageSort: '&',
            currentSort: '<?',
        },
    });

    UsersController.$inject = [
        'ng2UserService',
        'ng2LabelRepo',
        'hotkeys',
        'ng2LoadingService',
        'activeFeatures',
        'ng2MonEventsService',
        '$scope',
        'behaviorService',
    ];
    /* @ngInject */
    function UsersController (
        ng2UserService,
        ng2LabelRepo,
        hotkeys,
        ng2LoadingService,
        activeFeatures,
        ng2MonEventsService,
        $scope,
        behaviorService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.user = user;
        vm.onConfirmDelete = onConfirmDelete;
        vm.updateUser = updateUser;
        vm.getLabels = getLabels;
        vm.extendedUsers = [];

        function activate () {
            vm.isAdmin = vm.isAdmin || false;
            vm.users = [];
            vm.showLabels = activeFeatures.labels;
            vm.currentSort = vm.currentSort || {
                by: '',
                direction: '',
            };
            hotkeysInit();
            getLabels();
        }

        function onChanges (changes) {
            if (changes.users) {
                vm.extendedUsers = vm.users.map(function (user) {
                    return Object.assign(user, { avatarInitials: user.getAvatarInitials(),
                    });
                });
            }
        }

        function hotkeysInit () {
            hotkeys.bindTo($scope).add({
                combo: 'n',
                description: 'Create user',
                callback: function (event) {
                    event.preventDefault();
                    user();
                },
            });
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function user (user) {
            user = user || {};
            var customer_admin;
            if (user.id) {
                customer_admin = user.customer_admin;
            }
            const params = { body: 'form-user', size: 'md', data: { user: user } };
            const callback = function (user) {
                if (user) {
                    if (customer_admin !== undefined && user.customer_admin !== customer_admin) {
                        behaviorService.userChangeAdmin(user.email, user.customer_admin);
                    }
                    vm.getPage();
                }
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function onConfirmDelete (input, user) {
            if ('delete' !== input.toLowerCase()) {
                return;
            }

            vm.deleteDialog = ng2LoadingService.service();
            ng2UserService
                .destroy(user)
                .then(function () {
                    vm.getPage();
                }, angular.noop)
                .finally(function () {
                    vm.deleteDialog.close();
                });
        }

        function updateUser (user) {
            var params = {};
            params.first_name = user.first_name;
            params.email = user.email;
            params.labels = user.labels.map(function (label) {
                return label.id;
            });
            params.id = user.id;

            ng2UserService.update(params).then(angular.noop, angular.noop);
        }
    }
})();
