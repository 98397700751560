import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ACCESSIBILITY_HELP_CENTER_TAG } from '@client/app/modules/accessibility/constants/tag';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityTagsService {
    constructor (private translateService: TranslateService) {}

    getTranslation (tagKey: string): string {
        const tag = ACCESSIBILITY_HELP_CENTER_TAG[tagKey];
        if (tag) {
            return this.translateService.getString(tag.translation);
        }
        let result = tagKey.replace(/_/g, ' ');
        result = result[0].toUpperCase() + result.substring(1);
        return result;
    }

    getClass (tagKey: string): string {
        const tag = ACCESSIBILITY_HELP_CENTER_TAG[tagKey];
        if (tag && tag.styleClass.length > 0) {
            return tag.styleClass;
        }
        return 'tag-info';
    }
}
