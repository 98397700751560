(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyContent', {
        templateUrl: 'app/forms/global-policy/steps/content/content.html',
        controller: PolicySettingsController,
        controllerAs: 'vm',
        bindings: {
            policy: '=',
            wizNext: '&',
        },
    });

    PolicySettingsController.$inject = ['ng2PolicyFormHelperService', 'ng2MonModalService', 'gettextCatalog'];

    /* @ngInject */
    function PolicySettingsController (ng2PolicyFormHelperService, ng2MonModalService, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.checkChange = checkChange;

        function activate () {
            vm.currentSubject = vm.policy.subject || '';
            vm.policy.subject = vm.currentSubject;
        }

        function checkChange (subject) {
            vm.currentSubject = subject;
            if (vm.policy.subject === '') {
                vm.policy.subject = vm.currentSubject;
                vm.wizNext();
            } else {
                checkAffections();
            }
        }

        function checkAffections () {
            ng2PolicyFormHelperService.setPolicySubject(vm.currentSubject);

            var ruleOptions = ng2PolicyFormHelperService.getSubjectTypeOptions();
            var rulesToBeRemoved = vm.policy.rules.filter(function (rule) {
                return !ruleOptions.find(function (option) {
                    return option.type === rule.type;
                });
            });

            if (rulesToBeRemoved.length > 0) {
                ng2PolicyFormHelperService.setPolicySubject(vm.policy.subject);
                ng2MonModalService.confirm(generateConfirmMessage(ng2PolicyFormHelperService.getSubjectTypeOptions(), rulesToBeRemoved)).then(
                    function () {
                        vm.policy.subject = vm.currentSubject;
                        vm.wizNext();
                    },
                    function () {
                        ng2PolicyFormHelperService.setPolicySubject(vm.policy.subject);
                    },
                );
            } else {
                vm.policy.subject = vm.currentSubject;
                vm.wizNext();
            }
        }

        function generateConfirmMessage (rules, rulesToBeRemoved) {
            var message =
                '<strong>' +
                gettextCatalog.getString('The following rules will be removed, are you sure you want to continue with this change?') +
                '</strong>\n<br/><br/>';

            rulesToBeRemoved.forEach(function (rule) {
                message +=
                    '<div class="text-link policy-form-content">' +
                    '<div class="d-flex align-items-center my-3 rules-fixed-height-container mb-2">' +
                    '<div class="pr-3 icon-container">';

                getRule(rules, rule.type).icons.forEach(function (icon) {
                    message += '<span class="' + icon + ' fa-2x outer-r-mini inline-block"></span>';
                });

                message +=
                    '</div>' +
                    '<div class="text-left">' +
                    gettextCatalog.getString('Rule') +
                    ': ' +
                    (rule.name || gettextCatalog.getString('No name defined')) +
                    '</div>' +
                    '</div>' +
                    '</div>';
            });
            return message;
        }

        function getRule (rules, ruleType) {
            return (
                rules.find(function (rule) {
                    return rule.type === ruleType;
                }) || { icons: [] }
            );
        }
    }
})();
