import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSharedComponentsModule, MonChartsModule, MonDatePickerModule } from '@monsido/angular-shared-components';
import { ParamModule } from '@monsido/core/param/param.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LinkModule } from 'ng2/shared/components/link/link.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'ng2/core/pipes/pipes.module';
import { StatisticsPipesModule } from '../../pipes/statistics-pipes.module';
import { StatisticsEventsComponent } from './events/statistics-events.component';
import { STATISTICS_CONTENT_STATES } from './statistics-content.state';
import { StatisticsSiteSearchComponent } from './site-search/statistics-site-search.component';
import { SharedModule } from 'ng2/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        AngularSharedComponentsModule,
        MonChartsModule,
        MonDatePickerModule,
        UIRouterUpgradeModule.forChild({ states: STATISTICS_CONTENT_STATES }),
        ParamModule,
        NgbTooltipModule,
        LinkModule,
        PipesModule,
        StatisticsPipesModule,
        SharedModule,
    ],
    exports: [],
    declarations: [
        StatisticsEventsComponent,
        StatisticsSiteSearchComponent,
    ],
})
export class StatisticsContentModule {}
