import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleReportAdminNonUsersComponent } from './schedule-report-admin-non-users.component';
import { ConfirmModule, FormBuilderInputModule, FormBuilderValidationModule, LayoutModule } from '@monsido/angular-shared-components';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@layout/icons/icons.module';
import { TranslateModule } from '@client/app/modules/translate.module';


@NgModule({
    declarations: [ScheduleReportAdminNonUsersComponent],
    imports: [
        CommonModule,
        LayoutModule,
        ConfirmModule,
        TranslateModule,
        FormsModule,
        FormBuilderInputModule,
        FormBuilderValidationModule,
        IconsModule,
    ],
    exports: [ScheduleReportAdminNonUsersComponent],
})
export class ScheduleReportAdminNonUsersModule { }
