@for (rule of rules(); track $index; let first = $first; let last = $last) {
    <div class="policy-card d-flex p1">
        <span class="p-2">
            <span class="{{rule.icon}} fa-2x outer-r-mini"></span>
        </span>
        <span class="d-flex flex-column align-self-center policy-card__content">
            <div>
                Rule: {{ rule.name }}
            </div>
            <div class="text-dark-grey">
                @if (isRuleTextType(rule.type)) {
                    <span class="policy-card--wrap">
                        <span class="policy-card__icon fas" [ngClass]="{'fa-toggle-on': !rule.inverse, 'fa-toggle-off': rule.inverse}"></span>
                        <span translate>Contains</span>
                    </span>
                    <span class="policy-card--wrap">
                        <span class="policy-card__icon fas fa-text-height"></span>
                        @if (rule.case_sensitive) {
                            <span translate>Case sensitive</span>
                        }
                        @else {
                            <span translate>Not case sensitive</span>
                        }
                    </span>
                }
                @if (rule.operator) {
                    <span class="policy-card--wrap">
                        <span class="policy-card__icon fas fa-info-circle"></span>
                        <span>{{ getOperatorTranslation(rule) }}: {{rule.query}}</span>
                    </span>
                }
            </div>
        </span>
    </div>
    @if (!last) {
        <div class="text-grey py-2">{{ policyRulesOperator }}</div>
    }
    @if (last) {
        <button type="button"
            class="btn opt-button opt-button-main mt-3"
            aria-label="View policy" (click)="onViewPolicy()">
            <span class="fa-lg far fa-sparkles"></span>
            View Policy
        </button>
    }
}
