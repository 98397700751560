import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';

type ModuleAccessType = {
    value: string;
    name: string;
    hasAccess: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class AuditlogModulesService {

    private moduleList: ModuleAccessType[] = [
        {
            value: 'accessibility',
            name: 'Accessibility',
            hasAccess: this.activeFeatureService.isFeatureActive('admin_accessibility'),
        },
        {
            value: 'settings',
            name: 'Settings',
            hasAccess: true,
        },
        {
            value: 'qa',
            name: 'Quality Assurance',
            hasAccess: true,
        },
        {
            value: 'policies',
            name: 'Policies',
            hasAccess: true,
        },
        {
            value: 'seo',
            name: 'SEO',
            hasAccess: true,
        },
        {
            value: 'page_correct',
            name: 'PageCorrect',
            hasAccess: this.activeFeatureService.isFeatureActive('admin_page_fix'),
        },
        {
            value: 'data_protection',
            name: 'Data privacy',
            hasAccess: this.sessionService.customer !== null &&
                this.sessionService.customer.plan_traits &&
                this.sessionService.customer.plan_traits.data_protection,
        },
        {
            value: 'report',
            name: 'Report',
            hasAccess: true,
        },
        {
            value: 'uptime',
            name: 'Heartbeat',
            hasAccess: this.sessionService.customer !== null &&
                this.sessionService.customer.plan_traits &&
                this.sessionService.customer.plan_traits.heartbeat,
        },
        {
            value: 'user',
            name: 'User',
            hasAccess: true,
        },
        {
            value: 'api_users',
            name: 'API Users',
            hasAccess: this.activeFeatureService.isFeatureActive('api_users'),
        },
        {
            value: 'single_page_scan',
            name: 'Single Page Scan',
            hasAccess: true,
        },
        {
            value: 'core',
            name: 'Core',
            hasAccess: true,
        },
    ].filter(function (module) {
        return module.hasAccess;
    });

    constructor (
        private activeFeatureService: ActiveFeatureService,
        private sessionService: SessionService,
    ) {}


    getList (): ModuleAccessType[] {
        return this.moduleList.sort((a, b) => {
            const aShort = a.name.toLowerCase();
            const bShort = b.name.toLowerCase();
            return aShort < bShort ? -1 : bShort < aShort ? 1 : 0;
        });
    }

    getModule (name: string): ModuleAccessType | Record<string, never> {
        const module = this.moduleList.find((m) => {
            return m.value === name;
        });
        return module ? module : {};
    }

    getModuleNameFromValue (value: string): string {
        const modul = this.moduleList.find((module) => {
            return module.value === value;
        });
        return modul ? modul.name : '';
    }

}
