import { Injectable } from '@angular/core';
import { ApiService } from '../../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { AccessibilityCheckModel } from '@client/app/models/accessibility/accessibility-check.model';
import { PageAccessibilityRepoCheckType } from '../../page-accessibility/page-accessibility-repo.service';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { BaseRepo, BaseRepoRequestType } from '../../base-repo/base-repo';
import { AccessibilityHelpType } from '@client/app/models/types/accessibility-help.type';
import { PageModel } from '@client/app/models/page-model-factory.service';

type AccessibilityChecksParams = {
    'labels[]'?: string;
    abbr?: string;
    responsibility?: string;
    group_id?: number;
};

@Injectable({
    providedIn: 'root',
})
export class AccessibilityChecksRepoService extends BaseRepo {

    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    getAll (params: AccessibilityChecksParams = {}): Promise<CollectionInterface<AccessibilityCheckModel>> {
        params = this.setGroupId(params);
        const domainId = this.getDomainId();

        return this.apiService.get<CollectionInterface<PageAccessibilityRepoCheckType>>(
            `domains/${domainId}/accessibility/checks`,
            params,
        ).then(data => this.toModels(data));
    }

    update (checkId: number, payload: Partial<AccessibilityCheckModel>): Promise<AccessibilityCheckModel> {
        const domainId = this.getDomainId();

        return this.apiService.patch<PageAccessibilityRepoCheckType>(
            `domains/${domainId}/accessibility/checks/${checkId}`,
            payload,
        ).then((data) => this.toModel(data));
    }

    getHelp (checkId: number): Promise<AccessibilityHelpType> {
        const domainId = this.getDomainId();
        const params = this.setGroupId();

        return this.apiService.get(
            `domains/${domainId}/accessibility/checks/${checkId}/help`,
            params,
        );
    }

    getAllPages (checkId: number, params: BaseRepoRequestType = {}): Promise<CollectionInterface<PageModel>> {
        const domainId = this.getDomainId();
        params = this.setGroupId(params);

        return this.apiService.get(
            `domains/${domainId}/accessibility/checks/${checkId}/pages`,
            params,
        );
    }

    private toModels (models: CollectionInterface<PageAccessibilityRepoCheckType>): CollectionInterface<AccessibilityCheckModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models as CollectionInterface<AccessibilityCheckModel>;
    }

    private toModel (model: PageAccessibilityRepoCheckType): AccessibilityCheckModel {
        return new AccessibilityCheckModel(model);
    }
}
