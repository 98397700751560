(function () {
    'use strict';

    angular.module('app.components.pageLinkDropdown').component('pageLinkDropdownAll', {
        templateUrl: 'app/components/page-link-dropdown/all/all.html',
        controller: PageLinkDropdownController,
        controllerAs: 'vm',
        bindings: {
            selected: '=',
            callback: '&',
            status: '<?',
            pageId: '<?',
        },
    });

    PageLinkDropdownController.$inject = ['ng2LoadingService', '$q', 'linkService', 'pageService', 'gettextCatalog', 'ng2MonUtilsService'];
    /* @ngInject */
    function PageLinkDropdownController (ng2LoadingService, $q, linkService, pageService, gettextCatalog, ng2MonUtilsService) {
        var vm = this;
        vm.dropdownId = 'pageLinkDropdownAllDropdown-' + ng2MonUtilsService.getRandomString();
        vm.update = update;
        vm.clearSelected = clearSelected;

        function update (status) {
            var loadingTexts = {
                fixed: gettextCatalog.getString('Marking broken link as fixed'),
                ignored: gettextCatalog.getString('Ignoring broken link'),
                dead: gettextCatalog.getString('Reverting state'),
            };

            var chain = $q.when();
            vm.dialog = ng2LoadingService.service(gettextCatalog.getString('Please wait') + ' - ' + loadingTexts[status]);
            angular.forEach(vm.selected, function (value) {
                if (vm.pageId) {
                    chain = chain.then(function () {
                        return patchPage(value, status);
                    }, angular.noop);
                } else {
                    chain = chain.then(function () {
                        return patch(value, status);
                    }, angular.noop);
                }
            });
            chain
                .then(function () {
                    vm.dialog.close();
                    if (vm.callback) {
                        vm.callback();
                    }
                }, angular.noop)
                .finally(function () {
                    vm.dialog.close();
                });
        }

        function clearSelected () {
            vm.selected = [];
        }

        function patch (link, status) {
            var params = {};
            params.status = status;
            params.id = link.id;
            return linkService.update(params);
        }

        function patchPage (link, status) {
            var params = {
                status: status,
            };

            return pageService.updateLink(vm.pageId, link.id, params);
        }
    }
})();
