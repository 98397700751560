(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRuleImageSize', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/blocks/image-size/image-size.html',
        controller: ImageSizeController,
        controllerAs: 'vm',
        bindings: {
            cRule: '=',
            monDialogId: '<',
            policyRuleForm: '=',
        },
    });

    ImageSizeController.$inject = ['ng2PolicyFormHelperService', 'gettextCatalog', 'monDialog'];

    /* @ngInject */
    function ImageSizeController (ng2PolicyFormHelperService, gettextCatalog, monDialog) {
        var vm = this;
        vm.$onInit = activate;
        vm.chooseOperator = chooseOperator;
        vm.chooseImageSize = chooseImageSize;
        vm.close = close;

        function activate () {
            vm.cRule.inverse = false;
            vm.cRule.query = vm.cRule.query || '';
            ng2PolicyFormHelperService.setRuleType(vm.cRule.type);
            setupTypeOptions();
            setupSizeOptions();
            if (!vm.cRule.name) {
                chooseOperator(vm.typeOptions.length > 0 ? vm.typeOptions[0] : {});
            } else {
                chooseOperator(
                    vm.typeOptions.find(function (type) {
                        return type.type === vm.cRule.operator;
                    }),
                );
            }
        }

        function setupTypeOptions () {
            vm.typeOptions = ng2PolicyFormHelperService.getOptionsByRuleType();
            vm.imageSizeTypeDropdownOptions = ng2PolicyFormHelperService.getMenuDropdownOptionsByRuleType(vm.chooseOperator);
        }

        function chooseOperator (option) {
            vm.selectedOperator = option;
            vm.cRule.operator = option.type;
        }

        function chooseImageSize (option) {
            vm.selectedSize = option;
        }

        function setupSizeOptions () {
            vm.sizeOptions = ng2PolicyFormHelperService.getOptionsByRuleSize();

            vm.imageSizeDropdownSizeOptions = ng2PolicyFormHelperService.getMenuDropdownOptionsByRuleSize(vm.chooseImageSize);

            var queryStr = vm.cRule.query.replace(/(kb|mb)/, '');
            if (queryStr === vm.cRule.query) {
                chooseImageSize(vm.sizeOptions[0]);
            } else {
                var size = vm.cRule.query.substring(queryStr.length, vm.cRule.query.length);
                chooseImageSize(
                    vm.sizeOptions.find(function (option) {
                        return option.value === size;
                    }),
                );
            }

            vm.cRule.query = parseInt(queryStr);
        }

        function close () {
            var newRule = angular.copy(vm.cRule);
            newRule.sub_entry = newRule.sub_entry.join(',');
            newRule.query = newRule.query === '' ? 0 : newRule.query;
            newRule.query = newRule.query + vm.selectedSize.value;
            if (vm.policyRuleForm) {
                vm.policyRuleForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, newRule);
        }
    }
})();
