import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { CollectionInterface } from '@monsido/angular-shared-components';

export type HeatmapType = {
    id: number;
    domain_id: number;
    page_id: number;
    heatmap_type: Array<'scroll' | 'movement' | 'click'>;
    page: {
        id: number;
        url: string;
        title: string;
        domain_id: number;
        type: string
    };
    created_at: string;
    updated_at: string
};

export class HeatmapModel implements HeatmapType {
    id: number;
    domain_id: number;
    page_id: number;
    heatmap_type: Array<'scroll' | 'movement' | 'click'>;
    page: {
        id: number;
        url: string;
        title: string;
        domain_id: number;
        type: string
    };
    created_at: string;
    updated_at: string;

    constructor (data: HeatmapType) {
        this.id = data.id;
        this.domain_id = data.domain_id;
        this.page_id = data.page_id;
        this.heatmap_type = [...data.heatmap_type];
        this.page = { ...data.page };
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
    }
}

@Injectable({
    providedIn: 'root',
})
export class HeatmapsRepoService {

    constructor (
        private apiService: ApiService,
    ) { }

    create (domainId: number, params): Promise<HeatmapType> {
        params = params || {};
        return this.apiService.post('domains/' + domainId + '/heatmaps_rules/', params);
    }

    destroy (domainId: number, ruleId: number): Promise<unknown> {
        return this.apiService.delete('domains/' + domainId + '/heatmaps_rules/' + ruleId);
    }

    getAllFromCustomer (params: RequestParamsType): Promise<CollectionInterface<HeatmapModel>> {
        params = params || {};
        return this.apiService.get<CollectionInterface<HeatmapType>>('heatmaps_rules/', params)
            .then((dataArray) => {
                for (let i = 0; i < dataArray.length; i += 1) {
                    dataArray[i] = new HeatmapModel(dataArray[i]);
                }
                return dataArray;
            });
    }
}
