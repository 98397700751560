import { NgModule } from '@angular/core';
import { AngularSharedComponentsModule, TableFiltersModule, FilterButtonModule } from '@monsido/angular-shared-components';
import { CommonModule } from '@angular/common';
import { ReportModule } from '@monsido/modules/report-center/components/dashboard/report/report.module';
import { ReportListComponent } from '@monsido/modules/report-center/components/dashboard/report-list/report-list.component';
import { ReportCenterPipesModule } from '@monsido/modules/report-center/pipes/report-center-pipes.module';
import { TextSearchService } from '@monsido/services/text-search/text-search.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        ReportListComponent,
    ],
    imports: [
        CommonModule,
        ReportModule,
        AngularSharedComponentsModule,
        TranslateModule,
        ReportCenterPipesModule,

        FormsModule,
        TableFiltersModule,
        FilterButtonModule,
    ],
    providers: [
        TextSearchService,
    ],
    exports: [
        ReportListComponent,
    ],
})
export class ReportListModule {}
