import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService, MonPromptService, SortType } from '@monsido/angular-shared-components';
import { UIRouter } from '@uirouter/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QaMisspellingInterface } from '../../interfaces/qa-misspelling.interface';
import { QaIssueRepoService } from '../../repos/qa-issue.repo';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { QaPageInterface } from '../../interfaces/qa-page.interface';
import { LanguagesService } from 'app/modules/languages-ng2/languages.service';
import { GetPagesParamsInterface } from '../../repos/qa-issue-repo.interface';
import { LOCALE_MAP_TYPE } from 'app/modules/languages-ng2/constants/locale-map.constant';
import { LOCALE_LANGUAGE_MAP_TYPE } from 'app/modules/languages-ng2/constants/locale-language-map.constant';
import { ISSUE_TYPES } from '@monsido/modules/deeplink/constants/deeplink.constant';
import { IssueStatusEnum } from 'app/modules/common/enum/issue-status.enum';
import { IssueService } from '@monsido/modules/issue/services/issue.service';
import { Page } from '@monsido/modules/page-details/models';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import type { ActionMenuItemType } from '@monsido/ng2/shared/components/action-menu-panel/action-menu-panel.type';
import { DateTimeService } from 'app/services/date-time/date-time.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';

enum Actions {
    addToDictionary,
    addToAllDictionaries,
    confirm,
    ignore,
    fix,
}

@Component({
    selector: 'mon-misspelling-issue',
    templateUrl: './qa-misspelling-issue.component.html',
    styleUrls: ['./qa-misspelling-issue.component.scss'],
})
export class QaMisspellingIssueComponent implements OnInit, OnDestroy {
    issueId: number = -1;
    issueTitle: string = '';
    issueLanguage?: string;
    issueData: QaMisspellingInterface | null = null;
    page: Page = {} as Page;
    pages: MonTableCollection<QaPageInterface> = [];
    texts: Record<string, string> = {};
    loadingPages: boolean = false;
    getPagesParams: BehaviorSubject<GetPagesParamsInterface> = new BehaviorSubject({
        page: 1,
        page_size: 10,
        sort_by: 'title',
        sort_dir: 'desc',
    });

    translationKeys: Record<string, Record<string,string>> = {
        misspellings: {
            contentTableText: 'All pages with this Misspelling',
        },
        potentialMisspellings: {
            contentTableText: 'All pages with this Potential Misspelling',
        },
    };

    subscription?: Subscription;
    dialog?: LoadingService;
    wordChangedFromTheDB: boolean = false;
    status: string = '';
    issueType: string = '';
    contentTableName: string = '';
    openingPageDetails = false;
    loadingIssueData: boolean = false;
    isPermittedForChanges: boolean = true;
    viewsColumIsAvailable: boolean = true;
    dropdownOptions: ActionMenuItemType[] = [];

    private word: string = '';
    private pageId: number = -1;
    private pageDetailsOpeningMs = 1000;
    private pageDetailsOpeningTimeout: number | null = null;
    private confirmationRequiredText: string;

    constructor (
        private uiRouter: UIRouter,
        private translateService: TranslateService,
        private qaIssueRepoService: QaIssueRepoService,
        private eventsService: MonEventService,
        private monPromptService: MonPromptService,
        public languagesService: LanguagesService,
        private loadingService: LoadingService,
        private issueService: IssueService,
        private activeFeatureService: ActiveFeatureService,
        private dateTimeService: DateTimeService,
    ) {
        ({
            issueOverlayIssueId: this.issueId,
            word: this.word,
            issueOverlayIssueType: this.issueType,
            issueOverlayPageId: this.pageId,
        } = this.uiRouter.urlService.search());

        this.confirmationRequiredText = this.translateService.getString('Confirmation required');
        this.pages.total = 0;
    }

    async ngOnInit (): Promise<void> {
        if (this.issueId) {
            await this.getIssueData();
            await this.getPageDetails();

            this.subscription = this.getPagesParams.subscribe(params => {
                this.getPages(params);
            });
        }
        this.viewsColumIsAvailable = this.activeFeatureService.isFeatureActive('script_setup_guide');
    }

    ngOnDestroy (): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    searchWordInGoogle (word: string): void {
        this.issueService.searchWordInGoogle(word);
    }

    onSortPages (sortPayload: Record<string, string>, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        const { direction, by } = sortPayload;
        const params: GetPagesParamsInterface = {
            ...subject.value,
            sort_dir: direction as SortType,
            sort_by: by,
        };

        if (!direction) {
            params.sort_dir = 'asc';
        }

        if (!by) {
            params.sort_by = '';
        }

        subject.next(params);
    }

    onPagesPageChange (page: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page,
        });
    }

    onPagesPerPageChange (perPage: number, subject: BehaviorSubject<GetPagesParamsInterface>): void {
        subject.next({
            ...subject.value,
            page: 1,
            page_size: perPage,
        });
    }

    goToPageDetails (page: QaPageInterface): void {
        if (!this.pageDetailsOpeningTimeout) {
            this.openingPageDetails = true;
            const target = 'page-details-section-qa';
            const subTarget = this.isMisspellings() ? 'misspellings' : 'potential';
            const callback = (): void => {
                this.openingPageDetails = false;
            };
            this.issueService.goToPageDetails(page, target, subTarget, false, callback);
            this.pageDetailsOpeningTimeout = setTimeout(() => {
                this.pageDetailsOpeningTimeout = null;
            }, this.pageDetailsOpeningMs) as unknown as number;
        }
    }

    openInExtension (page: QaPageInterface | Page): void {
        const params = {
            type: this.isMisspellings() ? ISSUE_TYPES.Misspelling.id : ISSUE_TYPES.PotentialMisspelling.id,
            pageId: this.pageId,
            issueId: this.issueId,
            word: this.issueData?.word,
        };
        this.issueService.openInExtension(page, params);
    }

    private updateDropdown (): void {
        const dropdown: Array<ActionMenuItemType & { shouldShow?: boolean }> = [
            {
                label: this.translateService.getString('Confirm as misspelling'),
                leftIcon: 'faCheck',
                action: ()=>this.spellingActions(Actions.confirm),
                shouldShow: this.issueData?.classification !== 'confirmed',
            },
            {
                label: this.translateService.getString('Add to dictionary'),
                leftIcon: 'faBook',
                action: ()=>this.spellingActions(Actions.addToDictionary),
            },
            {
                label: this.translateService.getString('Add to dictionary for all languages'),
                leftIcon: 'faBook',
                action: ()=>this.spellingActions(Actions.addToAllDictionaries),

            },
            {
                label: this.translateService.getString('Ignore misspelling'),
                leftIcon: 'faEyeSlash',
                action: ()=>this.spellingActions(Actions.ignore),
            },
            {
                label: this.translateService.getString('Mark as fixed'),
                leftIcon: 'faCheck',
                action: ()=>this.spellingActions(Actions.fix),
            },
        ];

        this.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
            return {
                label: item.label,
                leftIcon: item.leftIcon,
                action: item.action,
            };
        });
    }

    private async getIssueData (): Promise<void> {
        this.loadingIssueData = true;
        const data = await this.qaIssueRepoService.getMisspellingIssueContent(this.pageId, this.issueId).catch((response) => {
            if (response.status === 404) {
                this.issueData = null;
            }
        });
        if (data) {
            this.issueData = data;
            const title = this.isMisspellings() ? ISSUE_TYPES.Misspelling.name : ISSUE_TYPES.PotentialMisspelling.name;
            this.issueTitle = this.translateService.getString(`${title}: ` + this.issueData?.word);
            this.issueLanguage = this.languagesService.getLanguage(this.issueData?.language);
            this.issueData.created_at = this.dateTimeService.format(this.issueData.created_at, 'DD MMM. YYYY');

            this.texts = {
                confirm: this.translateService.getString('Are you sure you want to confirm the word [[word]] as misspelling?',
                    { word: this.issueData.word }),
                fix: this.translateService.getString('Are you sure you want to mark the misspelling [[word]] as fixed?',
                    { word: this.issueData.word }),
                addToDictionary: this.translateService.getString('Are you sure you want to add word [[word]] to dictionary?',
                    { word: this.issueData.word }),
                addToAllDictionaries: this.translateService.getString('Are you sure you want to add word [[word]] to all dictionaries',
                    { word: this.issueData.word }),
                ignoreMisspelling: this.translateService.getString('Are you sure you want to ignore the misspelling [[word]] for all languages?',
                    { word: this.issueData.word }),
            };
            this.issueType = this.issueData.classification === 'confirmed' ? 'misspellings' : 'potentialMisspellings';
            this.contentTableName = this.translateService.getString(this.translationKeys[this.issueType].contentTableText);
        } else {
            this.issueData = null;
        }
        this.loadingIssueData = false;
        this.updateDropdown();
    }

    private async getPages (params: GetPagesParamsInterface): Promise<void> {
        if (!this.issueData) {
            return;
        }
        this.loadingPages = true;
        const pages = await this.qaIssueRepoService.getMisspellingIssuePages(this.issueId, params);
        if (pages) {
            this.pages = pages;
            for (let i = 0; i < this.pages.length; i++) {

                this.pages[i].language = this.languagesService.getLanguage((this.pages[i].language as LOCALE_LANGUAGE_MAP_TYPE | LOCALE_MAP_TYPE));
            }
            if (pages.length === 0) {
                this.pages.total = 0;
            }
        }
        this.loadingPages = false;
    }

    async spellingActions (status: Actions): Promise<void> {
        switch (status) {
            case Actions.confirm: {
                this.setPrompt(this.texts.confirm, () => {
                    this.loadingService.service(this.translateService.getString('Please wait - Confirming misspelling'));
                    const params = {
                        classification: 'confirmed',
                    };
                    return this.qaIssueRepoService.confirmWord(this.issueId, params)
                        .then(()=> {
                            this.loadingService.close();
                            this.getIssueData();
                            this.reloadBackgroundPageEvent();
                        });
                });
                break;
            }
            case Actions.addToDictionary: {
                this.setPrompt(this.texts.addToDictionary, () => {
                    this.loadingService.service(this.translateService.getString('Please wait - Adding words to dictionary'));
                    const params = {
                        word: this.issueData?.word,
                        language: this.issueData?.language,
                        classification: 'dictionary',
                    };

                    return this.qaIssueRepoService.addWordToDictionary(params)
                        .then(()=> {
                            this.loadingService.close();
                            this.status = 'in-dictionary';
                            this.reloadBackgroundPageEvent();
                        });
                });
                break;
            }
            case Actions.addToAllDictionaries: {
                this.setPrompt(this.texts.addToAllDictionaries, () => {
                    this.loadingService.service(this.translateService.getString('Please wait - Adding word to all dictionaries'));
                    const params = {
                        word: this.issueData?.word,
                        classification: 'dictionary',
                    };
                    return this.qaIssueRepoService.addWordToDictionary(params)
                        .then(()=> {
                            this.loadingService.close();
                            this.status = 'in-dictionary';
                            this.reloadBackgroundPageEvent();
                        });
                });
                break;
            }
            case Actions.ignore: {
                this.setPrompt(this.texts.ignoreMisspelling, () => {
                    this.loadingService.service(this.translateService.getString('Please wait - Adding words to ignored list'));
                    return this.qaIssueRepoService.ignoreSpellingError(this.pageId, this.issueId)
                        .then(()=> {
                            this.loadingService.close();
                            this.status = IssueStatusEnum.ignored;
                            this.reloadBackgroundPageEvent();
                        });
                });
                break;
            }
            case Actions.fix: {
                this.setPrompt(this.texts.fix, () => {
                    this.loadingService.service(this.translateService.getString('Please wait - Marking word as fixed'));
                    return this.qaIssueRepoService.destroySpellingError(this.pageId, this.issueId)
                        .then(()=> {
                            this.loadingService.close();
                            this.status = IssueStatusEnum.fixed;
                            this.reloadBackgroundPageEvent();
                        });
                });
                break;
            }
        }

        this.isPermittedForChanges = this.status !== IssueStatusEnum.fixed &&
            this.status !== IssueStatusEnum.ignored &&
            this.status !== 'in-dictionary';
    }

    private async setPrompt (message: string, callback: () => Promise<void>): Promise<void> {
        this.monPromptService.confirm(message, {
            parentSelector: '#common-dialog-wrapper',
            size: 'md',
            title: this.confirmationRequiredText,
        }).then(callback);
    }

    private reloadBackgroundPageEvent (): void {
        this.eventsService.run(MON_EVENTS.RELOAD_MISSPELLING);
    }

    private isMisspellings () : boolean {
        return Boolean(this.issueData?.classification === 'confirmed');
    }

    private async getPageDetails (): Promise<void> {
        this.issueService.getPage(this.pageId).then((page: Page) => {
            this.page = page;
        });
    }
}
