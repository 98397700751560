import { Injectable } from '@angular/core';
import { BaseRepo } from '../base-repo/base-repo';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { map } from 'rxjs/operators';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { DomainGroupModel } from './domain-group-model';
import { CrawlModel } from './crawl.model';
import { DomainGroupModelType } from './domain-group-model.type';
import { CrawlModelType } from './crawl-model.type';
import { CancelerType } from '@monsido/ng2/external/http/http-client.service';
import { Observable } from 'rxjs';
import { CollectionInterface } from '@monsido/angular-shared-components';

export type DomainGroupRequestType = {
    page_size?: number,
    page?: number,
    domain_id?: number,
    group_id?: number
};


@Injectable({
    providedIn: 'root',
})
export class DomainGroupRepoService extends BaseRepo {

    constructor (private apiService: ApiService, protected sessionService: SessionService, private monEventsService: MonEventService) {
        super();
    }

    getAll (domainId: number, params: DomainGroupRequestType): Promise<CollectionInterface<DomainGroupModel>> {
        params = params || {};
        const currentDomainId = this.getDomainId(domainId);
        return this.apiService
            .get(`domains/${currentDomainId}/domain_groups`, params)
            .then((res: CollectionInterface<DomainGroupModelType>) => this.toModels(res));
    }

    get (domainId: number, groupId: number): Promise<DomainGroupModel> {
        const currentDomainId = this.getDomainId(domainId);
        return this.apiService
            .get(`domains/${currentDomainId}/domain_groups/${groupId}`)
            .then((res: DomainGroupModelType) => this.toModel(res));
    }

    create (domain: Domain, groupAttributes: Pick<DomainGroupModelType, 'title' | 'rules' | 'domain_group_members'>): Promise<DomainGroupModel> {
        const domainId = this.getDomainId(domain.id);
        return this.apiService
            .post(`domains/${domainId}/domain_groups`, groupAttributes)
            .then((group: DomainGroupModelType) => {
                this.monEventsService.run('domainGroupAdded', { domain, group });
                return group;
            })
            .then((res: DomainGroupModelType) => this.toModel(res));
    }

    update (domain: Domain, group: DomainGroupModel): Promise<DomainGroupModel> {
        const domainId = this.getDomainId(domain.id);
        return this.apiService
            .patch(`domains/${domainId}/domain_groups/${group.id}`, group)
            .then((res: DomainGroupModelType) => {
                this.monEventsService.run('domainGroupUpdated', { domain, group: res });
                return res;
            })
            .then((res: DomainGroupModelType) => this.toModel(res));
    }

    destroy (domainId: number, groupId: number): Promise<void> {
        const currentDomainId = this.getDomainId(domainId);
        return this.apiService.delete(`domains/${currentDomainId}/domain_groups/${groupId}`);
    }

    getHistory (params: DomainGroupRequestType): Promise<CollectionInterface<CrawlModel>> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        const domainGroupId = params.group_id ?? this.sessionService.getGroupId();
        const route = `domains/${domainId}/domain_groups/${domainGroupId}/history`;

        return this.apiService
            .get(route, params)
            .then((res: CollectionInterface<CrawlModelType>) => this.toCrawlModels(res));
    }

    getHistoryObservable (params: DomainGroupRequestType, canceler$?: CancelerType): Observable<CollectionInterface<CrawlModel>> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        const domainGroupId = params.group_id ?? this.sessionService.getGroupId();
        const route = `domains/${domainId}/domain_groups/${domainGroupId}/history`;
        return this.apiService
            .getObservable(route, params, {}, canceler$)
            .pipe(map((res: CollectionInterface<CrawlModelType>) => this.toCrawlModels(res)));
    }

    // PROTECTED

    private toModels (models: CollectionInterface<DomainGroupModelType>): CollectionInterface<DomainGroupModel> {
        models.forEach(model => new DomainGroupModel(model));
        return models;
    }

    private toModel (model: DomainGroupModelType): DomainGroupModel {
        return new DomainGroupModel(model);
    }

    private toCrawlModels (models: CollectionInterface<CrawlModelType>): CollectionInterface<CrawlModel> {
        models.forEach(model => new CrawlModel(model));
        return models;
    }
}
