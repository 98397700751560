import { Injectable } from '@angular/core';
import { SupportRequestRepoService } from '@client/app/services/api/support-request-repo/support-request-repo.service';
import { TranslateService } from '@monsido/angular-shared-components';
import { DataPrivacyInfoType, DataPrivacyViolationType } from '@monsido/ng2/modules/data-privacy/interfaces/data-privacy-violation.type';
import { DataPrivacyLikelihoodService } from '@monsido/ng2/modules/data-privacy/services/data-privacy-likelihood.service';

@Injectable({
    providedIn: 'root',
})
export class DataPrivacyService {

    constructor (
        private translateService: TranslateService,
        private supportRequestRepoService: SupportRequestRepoService,
        private dataPrivacylikelihoodService: DataPrivacyLikelihoodService,
    ) { }

    formatText (text: string): string {
        if (text) {
            return (
                text.charAt(0).toUpperCase() +
                text
                    .substring(1, text.length)
                    .toLowerCase()
                    .replace(/_/g, ' ')
            );
        } else {
            return '';
        }
    }

    formatTextArray (textArray: string[], regulations: boolean): string {
        let text = '';
        if (textArray.length > 0 && !regulations) {
            for (let i = 0; i < textArray.length; i++) {
                if (i == textArray.length - 1) {
                    text += this.formatText(textArray[i]);
                } else {
                    text += this.formatText(textArray[i]) + ', ';
                }
            }
        }
        if (textArray.length > 0 && regulations) {
            text = textArray.join(', ');
        }
        return text;
    }

    getViolationName (violation: DataPrivacyViolationType): string {
        if (violation) {
            return this.translateService.getString(violation.info_type.name);
        } else {
            return this.translateService.getString('Violation\'s info_type name not set');
        }
    }
    requestDataPrivacySupport (msg: string): Promise<void> {
        const params = {
            code: 'data-privacy',
            message: msg,
        };
        return this.supportRequestRepoService.create(params);
    }

    getViolationLikelihood (violation: DataPrivacyViolationType): string {
        return this.dataPrivacylikelihoodService.getViolationLikelihood(violation);
    }

    getPagesWithActiveViolationCount (infoType: DataPrivacyInfoType): number {
        if (!infoType || !infoType.info_type_domain_summary || !(infoType.info_type_domain_summary.length > 0)) {
            return 0;
        }
        return infoType.info_type_domain_summary[0].pages_with_active_violations_count || 0;
    }

    getTotalPageCount (infoType: DataPrivacyInfoType): number {
        if (!infoType || !infoType.info_type_domain_summary || !(infoType.info_type_domain_summary.length > 0)) {
            return 0;
        }
        return (infoType.info_type_domain_summary[0].pages_with_active_violations_count || 0) +
            (infoType.info_type_domain_summary[0].pages_without_active_violations_count || 0);
    }
}
