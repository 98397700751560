import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleReportAdminComponent } from './schedule-report-admin.component';
import {
    IconsModule,
    LayoutModule,
    TableModule,
    TableSearchModule,
} from '@monsido/angular-shared-components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AvatarModule } from 'ngx-avatar-2';
import { OrderByModule } from 'ng2/shared/pipes/order-by/order-by.module';
import { ReportCenterPipesModule } from '@monsido/modules/report-center/pipes/report-center-pipes.module';
import { SharedModule } from 'ng2/shared/shared.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        ScheduleReportAdminComponent,
    ],
    exports: [ScheduleReportAdminComponent],
    imports: [
        CommonModule,
        SharedModule,
        OrderByModule,
        LayoutModule,
        TranslateModule,
        NgbTooltipModule,
        FormsModule,
        IconsModule,
        ReportCenterPipesModule,
        AvatarModule,
        TableModule,
        TableSearchModule,
    ],
})
export class ScheduleReportAdminModule {}
