import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { FastTrackSessionService } from '@client/app/modules/fast-track/services/session.service';

@Injectable({
    providedIn: 'root',
})
export class FastTrackInformationService {
    private snippetComplianceProperty = '';
    private snippetIssuesProperty = '';
    private snippetSourceCodeProperty = '';

    constructor (
        private translateService: TranslateService,
        private fastTrackSessionService: FastTrackSessionService,
    ) {
        this.initializeSubscriptions();
    }

    private initializeSubscriptions (): void {
        this.fastTrackSessionService.obsSnippetComplianceProperty.subscribe((property) => {
            this.snippetComplianceProperty = property;
        });

        this.fastTrackSessionService.obsSnippetIssuesProperty.subscribe((property) => {
            this.snippetIssuesProperty = property;
        });

        this.fastTrackSessionService.obsSnippetSourceCodeProperty.subscribe((property) => {
            this.snippetSourceCodeProperty = property;
        });
    }

    getSnippetCompliance (snippet: any): any {
        return snippet[this.snippetComplianceProperty];
    }

    getSnippetIssues (snippet: any): any {
        return snippet[this.snippetIssuesProperty];
    }

    getSnippetSourceCode (snippet: any): any {
        return snippet[this.snippetSourceCodeProperty];
    }

    getAffectedPages (count: number): string {
        count = count || 0;
        if (count < 10) {
            return this.translateService.getString('Less than 10');
        } else if (count < 50) {
            return this.translateService.getString('Less than 50');
        } else if (count < 100) {
            return this.translateService.getString('Less than 100');
        } else if (count < 1000) {
            return this.translateService.getString('More than {{count}}', {
                count: (count / 1000).toFixed(1) + 'k',
            });
        } else {
            const formatted = count >= 1000 ? Math.round(count / 1000) + 'K' : count.toString();
            return this.translateService.getString('More than {{count}}', {
                count: formatted,
            });
        }
    }
}
