(function () {
    'use strict';

    angular.module('services', [
        'services.customer',
        'services.entities',
        'services.api',
        'services.client',
    ]);
})();
