import MonColors from '@monsido/colors/dist/es6/colors';


(function () {
    'use strict';

    angular
        .module('pages.accessibility')
        .component('accessibilityDashboardComplianceBar', {
            templateUrl: 'app/pages/accessibility/dashboard/compliance/bar/bar.html',
            controller: AccessibilityDashboardComplianceBarController,
            controllerAs: 'vm',
            bindings: {
                history: '<',
                type: '<',
                checks: '<',
                selectedObs: '<',
            },
        });

    AccessibilityDashboardComplianceBarController.$inject = [
        '$filter',
        'gettextCatalog',
        'AccessibilityResultTypeService',
        'ng2DateTimeService',
        'ng2AccessibilityGuidelineVersionService',
        '$window',
    ];
    /* @ngInject */
    function AccessibilityDashboardComplianceBarController (
        $filter,
        gettextCatalog,
        AccessibilityResultTypeService,
        ng2DateTimeService,
        ng2AccessibilityGuidelineVersionService,
        $window,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getEmptyDataMessage = getEmptyDataMessage;

        function activate () {
            vm.showChangeCount = false;
            vm.changeInPercentage = 0;
            vm.changeCount = 0;

            vm.barChartOptions = {};
            vm.barChartSeries = [];
            vm.barOverrides = [];
            vm.barChartLabels = [];
            vm.barChart = [];

            vm.selectedObs.subscribe(function (resultType) {
                setupBarChart(resultType);
                historyChanges(resultType);
                vm.historyScans = vm.history.length;
            });
        }

        function setupBarChart (resultType) {
            var history = vm.history.filter(function (crawl) {
                return crawl.accessibility_checks.hasOwnProperty(vm.type);
            });
            var complianceSize = AccessibilityResultTypeService.getChecksCount(
                vm.checks,
                resultType,
                [vm.type],
            );
            vm.barChartSeries = [
                gettextCatalog.getString('In compliance'),
                gettextCatalog.getString('Failing checks'),
            ];
            vm.barOverrides = [
                {
                    type: 'bar',
                    borderWidth: 0,
                    backgroundColor: '#57b2b7',
                    borderColor: '#57b2b7',
                },
                {
                    type: 'bar',
                    borderWidth: 0,
                    backgroundColor: '#eee',
                    borderColor: '#eee',
                },
            ];
            vm.barChartLabels = history.map(function (crawl) {
                return ng2DateTimeService.format(crawl.date, 'MMM DD');
            });
            vm.barChart = [
                history.map(function (crawl) {
                    var result =
            complianceSize -
            AccessibilityResultTypeService.getCrawlIssueCount(
                crawl,
                vm.type,
                resultType,
            );
                    if (result > 0) {
                        return result;
                    } else {
                        return 0;
                    }
                }),
                history.map(function (crawl) {
                    var result = AccessibilityResultTypeService.getCrawlIssueCount(
                        crawl,
                        vm.type,
                        resultType,
                    );
                    if (result > 0) {
                        return result;
                    } else {
                        return 0;
                    }
                }),
            ];
            vm.barChartOptions = {
                scales: {
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            barPercentage: 1,
                            categoryPercentage: 0.5,
                            type: 'category',
                            offset: true,
                            gridLines: {
                                offsetGridLines: true,
                                display: false,
                            },
                            stacked: true,
                        },
                    ],
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            ticks: {
                                min: 0,
                                max: Math.ceil(complianceSize / 4) * 4,
                                maxTicksLimit: 4,
                                beginAtZero: true,
                                fixedStepSize: Math.ceil(complianceSize / 4),
                            },
                            stacked: true,
                        },
                        {
                            id: 'y-axis-1',
                            display: false,
                            position: 'right',
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                max: Math.ceil(complianceSize / 4) * 4,
                                fixedStepSize: Math.ceil(complianceSize / 4),
                                maxTicksLimit: 4,
                            },
                            stacked: true,
                        },
                    ],
                },
                legend: {
                    display: false,
                    position: 'bottom',
                },
                tooltips: {
                    intersect: false,
                    itemSort: function (a, b) {
                        return b.datasetIndex - a.datasetIndex;
                    },
                },
            };
            ng2AccessibilityGuidelineVersionService.getVersions(history).then(function (
                versions,
            ) {
                vm.barChartOptions.verticalLines = {
                    lines: versions.map(function (version) {
                        return {
                            xAxisID: 'x-axis-0',
                            x: version.time,
                            style: {
                                lineDash: [5, 5],
                                color: MonColors['secondary-15'],
                                width: 3,
                            },
                            label: {
                                text: version.title,
                                color: MonColors['secondary-15'],
                                position: 'top',
                            },
                            data: {
                                link: version.infoLink,
                            },
                        };
                    }),
                    timeScale: history.map(function (crawl) {
                        return crawl.post_processing_done_at;
                    }),
                };
            });
        }

        function historyChanges (resultType) {
            // Changes between the first scan and live counter
            if (vm.history.length > 1) {
                var latestError = AccessibilityResultTypeService.getChecksCount(
                    vm.checks,
                    resultType,
                    [vm.type],
                    true,
                );
                var firstError = vm.history[0].accessibility_checks[vm.type] || 0;
                var change = latestError - firstError;
                var changeInPlus = Math.abs(change);
                var changeInPercentage = neverExceedHundredPercentage(
                    (changeInPlus / firstError) * 100 || 0,
                ); // If division is NaN, set 0

                vm.changeCount = change;
                vm.changeInPercentage = $filter('monNumber')(
                    changeInPercentage / 100,
                    '0.[0] %',
                );
            }
        }

        function neverExceedHundredPercentage (percentage) {
            if (percentage > 100) {
                return 100;
            }
            return percentage;
        }

        function getEmptyDataMessage () {
            var params = {
                lvl: vm.type,
            };
            return gettextCatalog.getString(
                'No Historic data available for Accessibility Level {{lvl}}',
                params,
            );
        }
    }
})();
