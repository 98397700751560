import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule, TableModule, TableSearchModule } from '@monsido/angular-shared-components';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng2/shared/shared.module';
import { ReceivedReportsContainerComponent } from './received-reports-container.component';
import { ReceivedReportsTableModule } from '../../received-reports-table/received-reports-table.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        ReceivedReportsContainerComponent,
    ],
    exports: [ReceivedReportsContainerComponent],
    imports: [
        CommonModule,
        SharedModule,
        LayoutModule,
        TranslateModule,
        FormsModule,
        TableSearchModule,
        TableModule,
        ReceivedReportsTableModule,
    ],
})
export class ReceivedReportsContainerModule {}
