import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import { FastTrackSessionService } from '@client/app/modules/fast-track/services/session.service';
import { DataPrivacyService } from '@client/app/modules/data-privacy/services/data-privacy/data-privacy.service';

@Injectable({
    providedIn: 'root',
})
export class PrivacyDialogsFastTrackService {
    constructor (
        private fastTrackSessionService: FastTrackSessionService,
        private dataPrivacyService: DataPrivacyService,
    ) {}

    setupSession (): void {
        this.fastTrackSessionService.setSnippetSourceCodeProperty('content');
        this.fastTrackSessionService.setSnippetComplianceProperty('effect_on_compliance');
        this.fastTrackSessionService.setSnippetIssuesProperty('issueCount');
    }

    clear (): void {
        this.fastTrackSessionService.clear();
    }

    getAffectedPagesCount (infoTypes: any[]): number {
        return reduce(infoTypes, (num, infoType) => Math.max(infoType.pages_count, num), 0);
    }

    getInfoTypeTranslation (infoType: any): string {
        return this.dataPrivacyService.formatText(infoType.group || 'other');
    }
}
