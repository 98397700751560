(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideFeaturesPageFix', {
        templateUrl: 'app/forms/domain-script-guide/features/page-fix/page-fix.html',
        controller: DomainScriptGuideFeaturesPageFixController,
        controllerAs: 'vm',
        bindings: {
            prefixName: '@?',
        },
    });

    DomainScriptGuideFeaturesPageFixController.$inject = ['ng2FormDomainScriptGuidePageFixService', '$timeout'];
    /* @ngInject */
    function DomainScriptGuideFeaturesPageFixController (ng2FormDomainScriptGuidePageFixService, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.onChangeValue = onChangeValue;

        function activate () {
            vm.prefixName = vm.prefixName || '';
            vm.show = ng2FormDomainScriptGuidePageFixService.hasAccessToFeature();
            vm.canUse = ng2FormDomainScriptGuidePageFixService.getValue();
            setupWatcher();
        }

        function onChangeValue () {
            $timeout(function () {
                setValue(vm.canUse);
            });
        }

        // PROTECTED
        function setupWatcher () {
            ng2FormDomainScriptGuidePageFixService.settingChangeObs.subscribe(function () {
                vm.canUse = ng2FormDomainScriptGuidePageFixService.getValue();
            });
        }

        function setValue (value) {
            ng2FormDomainScriptGuidePageFixService.setValue(value, true);
        }
    }
})();
