import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ExporterPerformanceParserService {
    constructor (
        private datePipe: DatePipe,
        private translateService: TranslateService,
    ) {}

    private getHeadlines (): string[] {
        return [
            this.translateService.getString('Crawled at'),
            this.translateService.getString('Performance Pages'),
            this.translateService.getString('Average Performance Score'),
            this.translateService.getString('Average First Contentful Paint'),
            this.translateService.getString('Average First Meaningful Paint'),
            this.translateService.getString('Average Time to Interactive'),
        ];
    }

    parse (data: any[]): string[][] {
        if (!data?.length) {
            return [];
        }

        const csv: string[][] = [];
        csv.push(this.getHeadlines());

        for (const item of data) {
            const entry: (string | number)[] = [
                this.datePipe.transform(item.post_processing_done_at || item.date, 'MMM d, y, h:mm a') || '',
                item.performance_score_average * 100,
                item.performance_first_contentful_paint_average,
                item.performance_first_meaningful_paint_average,
                item.performance_time_to_interactive_average || '',
            ];

            csv.push(entry.map(String));
        }

        return csv;
    }
}
