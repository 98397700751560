import { Injectable } from '@angular/core';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { SessionService } from '@monsido/ng2/core/session/session.service';

export type DemoPdfParamsType = {
    clarity?: string;
    reviewed?: boolean;
    page?: number;
    page_size?: number;
};

export type DemoPdfDocumentType = {
    id: number,
    domain_id: number,
    title: string,
    url: string,
    reviewed: boolean,
    content_length: number,
    pages_count: number,
    word_count: number,
    clarity_accessibility_errors_count: number,
    clarity_accessibility_checks_with_errors_count: number,
    clarity_tagged: boolean | null,
    clarity_queued_at: string | null,
    clarity_checked_at: string | null,
    created_at: string,
};


export function isDemoPdfDocumentType (docType: any): docType is DemoPdfDocumentType {
    if (!docType) {
        return false;
    }
    return 'clarity_accessibility_errors_count' in docType &&
        'clarity_accessibility_checks_with_errors_count' in docType;
}

@Injectable({
    providedIn: 'root',
})
export class DemoPdfRepoService {

    private hostNameRegex = /^https?:\/\/(?:w{3}\.)?([a-z0-9\.-]+)\.(?:[a-z\.]{2,10})(?:[a-z\.-])/;
    private documents: DemoPdfDocumentType[] = [
        {
            id: 1,
            domain_id: 1,
            title: 'Lorem ipsum',
            url: 'https://sample.tld/path_to_file.pdf',
            reviewed: false,
            content_length: 1666796,
            pages_count: 27,
            word_count: 1281,
            clarity_accessibility_errors_count: 0,
            clarity_accessibility_checks_with_errors_count: 0,
            clarity_tagged: null,
            clarity_queued_at: null,
            clarity_checked_at: null,
            created_at: '2018-05-24T11:30:09+02:00',
        },
        {
            id: 2,
            domain_id: 1,
            title: 'Ipsum dolor',
            url: 'https://sample.tld/files/dolor.pdf',
            reviewed: false,
            content_length: 110464,
            pages_count: 4,
            word_count: 1040,
            clarity_accessibility_errors_count: 0,
            clarity_accessibility_checks_with_errors_count: 0,
            clarity_tagged: null,
            clarity_queued_at: null,
            clarity_checked_at: null,
            created_at: '2016-06-24T12:53:21+02:00',
        },
        {
            id: 3,
            domain_id: 1,
            title: 'Etiam feugiat vehicula dolor et rutrum. Aenean at tellus',
            url: 'https://sample.tld/sites/default/files/Etiam_.pdf',
            word_count: 4443,
            clarity_accessibility_checks_with_errors_count: 0,
            clarity_accessibility_errors_count: 0,
            clarity_checked_at: null,
            clarity_queued_at: '2018-09-19T09:00:29Z',
            clarity_tagged: null,
            content_length: 1044068,
            pages_count: 17,
            reviewed: false,
            created_at: '2017-10-25T10:26:00+02:00',
        },
        {
            id: 4,
            domain_id: 1,
            title: 'Etiam nec quam egestas',
            url: 'https://sample.tld/Etiam_nec_quam_egestas.pdf',
            reviewed: false,
            content_length: 31922,
            pages_count: 5,
            word_count: 1272,
            clarity_accessibility_checks_with_errors_count: 0,
            clarity_accessibility_errors_count: 0,
            clarity_checked_at: null,
            clarity_queued_at: '2019-01-18T06:55:01Z',
            clarity_tagged: null,
            created_at: '2016-11-08T14:52:00+01:00',
        },
        {
            id: 5,
            domain_id: 1,
            clarity_accessibility_checks_with_errors_count: 1,
            clarity_accessibility_errors_count: 8,
            clarity_checked_at: '2019-01-23T15:23:35Z',
            clarity_queued_at: null,
            clarity_tagged: true,
            content_length: 16039,
            created_at: '2015-09-22T16:13:53+02:00',
            pages_count: 1,
            reviewed: false,
            title: '',
            url: 'https://sample.tld/files/dolor_as.pdf',
            word_count: 35,
        },
    ];

    constructor (
        private sessionService: SessionService,
    ) { }

    get (params: DemoPdfParamsType): Promise<DemoPdfDocumentType[]> {
        return this.getData('documents', params).then((documents) => {
            if (params.clarity === 'pending') {
                return documents.filter((doc) => {
                    return doc.reviewed === false && doc.clarity_queued_at === null && doc.clarity_checked_at === null;
                });
            } else if (params.clarity === 'reviewed' || params.reviewed) {
                return documents.filter((doc) => {
                    return doc.reviewed === true && doc.clarity_queued_at === null;
                });
            } else if (params.clarity === 'queued') {
                return documents.filter((doc) => {
                    return doc.clarity_queued_at !== null;
                });
            } else if (params.clarity === 'failed') {
                return documents.filter((doc) => {
                    return (
                        doc.clarity_tagged === false ||
                        (doc.clarity_accessibility_checks_with_errors_count > 0 && doc.clarity_accessibility_errors_count > 0)
                    );
                });
            }
            return this.getData();
        });
    }

    update (pdf: DemoPdfDocumentType, params: DemoPdfParamsType): Promise<DemoPdfDocumentType> {
        const index = this.getIndex(pdf);
        return this.getData('update', params).then(() => {
            if (index > -1) {
                this.documents[index].reviewed = Boolean(params.reviewed);
                return this.documents[index];
            }

            return this.documents[0];
        });
    }

    isDemo (document: DemoPdfDocumentType): boolean {
        const docs = this.documents;
        return docs.some((mockDocument) => {
            return mockDocument.id === document.id;
        });
    }

    private getData (): Promise<DemoPdfDocumentType[]>;
    private getData (fnName: 'update', params?: DemoPdfParamsType): Promise<boolean>;
    private getData (fnName: 'documents', params?: DemoPdfParamsType): Promise<DemoPdfDocumentType[]>;
    private getData (fnName?: string, params?: DemoPdfParamsType): Promise<DemoPdfDocumentType[] | boolean> {
        return new Promise((resolve) => {
            const timer = Math.round(1000 * Math.random());
            setTimeout(() => {
                switch (fnName) {
                    case 'documents':
                        resolve(this.setPageCounters(this.parseDocuments(this.documents), params));
                        break;
                    case 'update':
                        resolve(true);
                        break;
                    default:
                        resolve(this.setPageCounters([], params));
                }
            }, timer);
        });
    }

    private parseDocuments (documents): typeof this.documents {
        return [...documents].map((document) => {
            document.url = document.url.replace(this.hostNameRegex, this.getDomainUrl());
            return document;
        });
    }

    private getIndex (document: DemoPdfDocumentType): number {
        for (let i = 0; i < this.documents.length; i++) {
            if (this.documents[i].id === document.id) {
                return i;
            }
        }
        return -1;
    }

    private setPageCounters<T> (collection: CollectionInterface<T>, params?: DemoPdfParamsType): CollectionInterface<T> {
        params = params || {};
        if (collection.length < 10) {
            collection.total = collection.length;
        } else {
            collection.total = 10;
        }
        collection.currentPage = params.page || 1;
        collection.perPage = params.page_size || 10;

        return collection;
    }

    private getDomainUrl (): string {
        if (this.sessionService.domain) {
            const domain = this.sessionService.domain;
            if (domain.url[domain.url.length - 1] === '/') {
                return domain.url.substring(0, domain.url.length - 1);
            }
            return domain.url;
        }
        return '';
    }
}
