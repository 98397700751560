import { Injectable } from '@angular/core';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ChartFactory } from '@client/app/blocks/service/charts/base-chart/chart-factory';
import { ChartsDataHelper } from '@client/app/blocks/helpers/charts/charts-data-helper';

@Injectable({
    providedIn: 'root',
})

export class QALineChartService {
    constructor (private translateService: TranslateService) {}

    createQaLineChartService () {
        const qaLineChartService = new ChartFactory(new LineChart());
        qaLineChartService.setOptions([
            this.translateService.getString('Issues'),
            this.translateService.getString('Content'),
        ]);
        qaLineChartService.setColors([
            '#da5c5b', '#b960e2', '#ea9a5c', '#ff9d4e', '#a9dad6',
        ]);
        qaLineChartService.setOverrides([3, 2]);
        qaLineChartService.setSeries([
            this.translateService.getString('Broken links'),
            this.translateService.getString('Broken images'),
            this.translateService.getString('Misspellings'),
            this.translateService.getString('Content with issues'),
            this.translateService.getString('Scanned content'),
        ]);
        qaLineChartService.setData([
            'dead_links_count',
            'dead_images_count',
            'spelling_errors_confirmed_count',
            'pages_with_notifications_count',
            ['documents_count', 'page_count'],
        ], new ChartsDataHelper('time'));
        return qaLineChartService;
    }
}
