import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { PrivacyFastTrackModelFactoryService } from '@client/app/modules/data-privacy/services/privacy-fast-track-model-factory/privacy-fast-track-model-factory.service';
import { PrivacyFastTrackInfoTypeModel } from '@client/app/models/data-privacy/privacy-fast-track-info-type.model';

import { BaseRepo } from '@client/app/services/api/base-repo/base-repo';

@Injectable({
    providedIn: 'root',
})
export class DataPrivacyFastTrackRepoService extends BaseRepo {
    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
        private privacyFastTrackModelFactoryService: PrivacyFastTrackModelFactoryService,
    ) {
        super();
    }


    getAll (params: any = {}): Promise<any[]> {
        params = this.setGroupId(params);
        return this.apiService.get<any[]>(`domains/${this.getDomainId()}/data_protection/fast_track`, params)
            .then(res => this.toModels(res));
    }

    get (dpContentId: string, params: any = {}): Promise<any> {
        params = this.setGroupId(params);
        return this.apiService.get<any>(`domains/${this.getDomainId()}/data_protection/fast_track/${dpContentId}`, params)
            .then(res => this.toModel(res));
    }

    getInfoTypes (dpContentId: string, params: any = {}): Promise<any[]> {
        params = this.setGroupId(params);
        return this.apiService.get<any[]>(`domains/${this.getDomainId()}/data_protection/fast_track/${dpContentId}/info_types`, params)
            .then(res => this.infoTypesToModels(res));
    }

    updateInfoType (dpContentId: string, infoType: any, params: any = {}): Promise<any> {
        params = this.setGroupId(params);
        return this.apiService.patch<any>(`domains/${this.getDomainId()}/data_protection/fast_track/${dpContentId}/info_types/${infoType.id}`, infoType, params)
            .then(res => this.toModel(res));
    }

    private toModels (models: any[]): any[] {
        return models.map(model => this.toModel(model));
    }

    private toModel (model: any): any {
        return this.privacyFastTrackModelFactoryService.getModel(model);
    }

    private infoTypesToModels (models: any[]): any[] {
        return models.map(model => this.infoTypesToModel(model));
    }

    private infoTypesToModel (model: any): PrivacyFastTrackInfoTypeModel {
        return new PrivacyFastTrackInfoTypeModel(model);
    }
}
