import { Injectable } from '@angular/core';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ChartsDataHelper } from '@client/app/blocks/helpers/charts/charts-data-helper';
import { ChartFactory } from '@client/app/blocks/service/charts/base-chart/chart-factory';


@Injectable({
    providedIn: 'root',
})
export class DataPrivacyLineChartService {

    constructor (private translateService: TranslateService) {}

    createPrivacyLineChartService () {
        const dataPrivacyLineChartService = new ChartFactory(new LineChart());
        dataPrivacyLineChartService.setOptions([
            this.translateService.getString('Issues'),
            this.translateService.getString('Pages'),
        ]);
        dataPrivacyLineChartService.setColors([
            '#A94442', '#F0AD4E', '#31708F', '#ff9d4e', '#a9dad6',
        ]);
        dataPrivacyLineChartService.setOverrides([3, 2]);
        dataPrivacyLineChartService.setSeries([
            this.translateService.getString('High'),
            this.translateService.getString('Medium'),
            this.translateService.getString('Low'),
            this.translateService.getString('Pages with issues'),
            this.translateService.getString('Scanned pages'),
        ]);
        dataPrivacyLineChartService.setData([
            'data_protection_high_priority_page_violations_count',
            'data_protection_medium_priority_page_violations_count',
            'data_protection_low_priority_page_violations_count',
            'data_protection_pages_with_violations_count',
            'page_count',
        ], new ChartsDataHelper('time'));
        return dataPrivacyLineChartService;
    }
}
