import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { TrackingService } from '@client/app/modules/tracking/services/tracking.service';
import { MonsidoConfigType } from '@client/types/monsido-config.constant.type';

declare global {
    interface Window {
        _paq: any[];
    }
}

@Injectable({
    providedIn: 'root',
})
export class MatomoService extends TrackingService {
    private activateTracking = false;

    constructor (private sessionService: SessionService) {
        super();
    }

    trackEvent (url: string, type: string, ref: string) {
        if (this.activateTracking) {
            url = this.removeCustomAndDomainId(this.removeOrigin(url));
            ref = this.removeCustomAndDomainId(this.removeOrigin(ref));
            if (this.sessionService.customer?.name) {
                if (this.sessionService.me?.email) {
                    window._paq.push(['setCustomDimension', 2, this.sessionService.customer.id]);
                    window._paq.push(['setCustomDimension', 1, this.sessionService.me.id]);
                    window._paq.push(['setUserId', `${this.sessionService.customer.id}_${this.sessionService.me.id}`]);
                }
            }
            window._paq.push(['trackEvent', type, url, ref]);
        }
    }

    trackPageView (url: string, title: string, ref: string, virtual: boolean, params: any) {
        if (this.activateTracking) {
            url = this.cleanupUrl(url);
            window._paq.push(['setCustomUrl', url]);
            window._paq.push(['setDocumentTitle', title]);

            if (this.sessionService.customer?.name) {
                if (this.sessionService.me?.email) {
                    window._paq.push(['setCustomDimension', 2, this.sessionService.customer.id]);
                    window._paq.push(['setCustomDimension', 1, this.sessionService.me.id]);
                    window._paq.push(['setUserId', `${this.sessionService.customer.id}_${this.sessionService.me.id}`]);
                }
            }
            if (ref) {
                ref = this.cleanupUrl(ref);
                window._paq.push(['setReferrerUrl', ref]);
            }
            window._paq.push(['trackPageView']);
        }
    }

    setTrackingInfo (settings: MonsidoConfigType['matomoSettings']) {
        if (!this.sessionService.isSudo) {
            window._paq = window._paq || [];
            window._paq.push(['setTrackerUrl', settings.matomoUrl]);
            window._paq.push(['setSiteId', settings.matomoSiteId]);
            window._paq.push(['enableLinkTracking']);
            window._paq.push(['disableCookies']);
            const newScriptElement = document.createElement('script');
            const firstScriptElement = document.getElementsByTagName('script')[0];
            newScriptElement.nonce = '87b65f78ab';
            newScriptElement.async = true;
            newScriptElement.src = settings.matomoScriptUrl;
            firstScriptElement.parentNode?.insertBefore(newScriptElement, firstScriptElement);
            this.activateTracking = true;
        }
    }
}
