import { Injectable } from '@angular/core';
import { PagePerformanceReportAudit, PagePerformanceReportType } from '@client/app/services/api/page-performance/page-performance-report/page-performance-report-repo.service';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { PAGE_PERFORMANCE_AUDIT_INFO } from '../../constants/page-performance-audit-info';
import { PAGE_PERFORMANCE_ICON } from '../../constants/page-performance-icon';
import { PAGE_PERFORMANCE_METRICS } from '../../constants/page-performance-metrics';
import { PagePerformanceAuditScoreUtil } from '../page-performance-audit-score.util';

type PagePerformanceAuditFilterType = { audits: { value: string, name: string }[] };
export type PagePerformanceReportAuditRefType = {
    id: string,
    weight: number,
    group: string,
    acronym: string,
    relevantAudits: string[]
};

@Injectable({
    providedIn: 'root',
})
export class PagePerformancePageDetailsService {
    loadingReport = false;
    report: PagePerformanceReportType | null = null;

    constructor (private monEventService: MonEventService) {
        this.monEventService.addListener('pagePerformancePDBeforeSetReport', () => {
            this.loadingReport = true;
        });

        this.monEventService.addListener('pagePerformancePDOnSetReport', (report: PagePerformanceReportType) => {
            this.report = report;
        });

        this.monEventService.addListener('pagePerformancePDAfterSetReport', () => {
            this.loadingReport = false;
        });
    }

    getLoadingReportStatus (): boolean {
        return this.loadingReport;
    }

    getChevronDirection (open: boolean): string {
        if (open) {
            return PAGE_PERFORMANCE_ICON.OPEN;
        }

        return PAGE_PERFORMANCE_ICON.CLOSE;
    }


    getReport (): PagePerformanceReportType | null {
        if (this.report) {
            return Object.assign({}, this.report);
        } else {
            return null;
        }
    }

    initFilters (): { filters: PagePerformanceAuditFilterType, availableFilters: PagePerformanceAuditFilterType } {
        return {
            filters: { audits: [] },
            availableFilters: {
                audits: [
                    {
                        value: PAGE_PERFORMANCE_METRICS.FCP,
                        name: PAGE_PERFORMANCE_METRICS.FCP,
                    },
                    {
                        value: PAGE_PERFORMANCE_METRICS.LCP,
                        name: PAGE_PERFORMANCE_METRICS.LCP,
                    },
                    {
                        value: PAGE_PERFORMANCE_METRICS.TBT,
                        name: PAGE_PERFORMANCE_METRICS.TBT,
                    },
                    {
                        value: PAGE_PERFORMANCE_METRICS.CLS,
                        name: PAGE_PERFORMANCE_METRICS.CLS,
                    },
                ],
            },
        };
    }


    filterAudits (filters: PagePerformanceAuditFilterType, audits: (Required<Pick<PagePerformanceReportAudit, 'metrics'>> & PagePerformanceReportAudit)[], tab: string, searchKeyword: string): PagePerformanceReportAudit[] {
        const tabFiltered = audits.filter((audit) => {
            if (tab === 'errors') {
                return typeof audit.score !== 'number' || !PagePerformanceAuditScoreUtil.hasScorePassed(audit.score * 100);
            }
            return typeof audit.score === 'number' && PagePerformanceAuditScoreUtil.hasScorePassed(audit.score * 100);
        });

        const searchFilter = tabFiltered.filter((audit)=>{
            if (searchKeyword) {
                searchKeyword = searchKeyword.toLowerCase();
                return (
                    audit.title.toLowerCase().includes(searchKeyword) ||
                        audit.displayValue?.toLowerCase().includes(searchKeyword) ||
                        audit.description?.toLowerCase().includes(searchKeyword)
                );
            }
            return true;
        });

        const auditFilters = filters.audits || [];
        if (auditFilters.length) {
            return searchFilter.filter(function (audit) {
                let matches = true;
                auditFilters.forEach(function (filterAudit) {
                    matches = matches && audit.metrics.indexOf(filterAudit.value) >= 0;
                });
                return matches;
            });
        }
        return searchFilter;
    }


    getUnassignedAuditIds (auditRefs: PagePerformanceReportAuditRefType[]): string[] {
        const auditRefIds = auditRefs.filter((ref) => {
            return Object.values(PAGE_PERFORMANCE_AUDIT_INFO).some(auditInfo => auditInfo.id === ref.id);
        }).map((ref) => ref.id);

        return auditRefIds;
    }
}
