(function () {
    'use strict';

    angular.module('app.components.pageLinkDropdown').component('pageLinkDropdown', {
        templateUrl: 'app/components/page-link-dropdown/page-link-dropdown.html',
        controller: PageLinkDropdownController,
        controllerAs: 'vm',
        bindings: {
            link: '<',
            getPage: '&',
            pageId: '<?',
            showUnignore: '<',
            ignoredValue: '@?',
        },
    });

    PageLinkDropdownController.$inject = [
        'ng2LoadingService',
        'linkService',
        'gettextCatalog',
        'pageService',
        'ng2MonEventsService',
        'ng2MonModalService',
    ];

    /* @ngInject */
    function PageLinkDropdownController (
        ng2LoadingService,
        linkService,
        gettextCatalog,
        pageService,
        ng2MonEventsService,
        ng2MonModalService,
    ) {
        const vm = this;
        vm.update = update;
        vm.$onInit = activate;

        function activate () {
            vm.showUnignore = vm.showUnignore || false;
            vm.pageId = vm.pageId || false;
            vm.ignoredValue = vm.ignoredValue || 'ignored';

            const dropdown = [
                {
                    label: gettextCatalog.getString('Set to fixed'),
                    icon: 'faCheck',
                    action: ()=>update('fixed'),
                    eventParams: { action: 'broken-links-set-to-fixed' },
                    shouldShow: vm.link.status !== 'fixed' && vm.link.status !== 'ignored' && vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Mark as ignored'),
                    icon: 'faEyeSlash',
                    action: ()=>confirmUpdate(vm.ignoredValue, gettextCatalog.getString('Are you sure you want to mark this link as ignored?')),
                    eventParams: { action: 'broken-links-mark-as-ignored' },
                    shouldShow: vm.link.status !== 'ignored' && vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Unignore'),
                    icon: 'faEye',
                    action: ()=>confirmUpdate('dead', gettextCatalog.getString('Are you sure you want to Mark this link as unignored?')),
                    eventParams: { action: 'broken-links-unignore' },
                    shouldShow: vm.link.status === 'ignored' && vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Mark as not fixed'),
                    icon: 'faTimes',
                    action: ()=>confirmUpdate('dead', gettextCatalog.getString('Are you sure you want to mark this link as not fixed?')),
                    eventParams: { action: 'broken-links-mark-as-not-fixed' },
                    shouldShow: vm.link.status === 'fixed' && vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Mark as fixed'),
                    icon: 'faCheck',
                    action: ()=>confirmUpdate('fixed', gettextCatalog.getString('Are you sure you want to mark this link as fixed?')),
                    eventParams: { action: 'broken-links-mark-as-fixed' },
                    shouldShow: vm.link.status !== 'fixed' && vm.link.status !== 'ignored' && !vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Mark as ignored'),
                    icon: 'faEyeSlash',
                    action: ()=>confirmUpdate(vm.ignoredValue, gettextCatalog.getString('Are you sure you want to mark this link as ignored?')),
                    eventParams: { action: 'broken-links-mark-as-ignored' },
                    shouldShow: vm.link.status !== 'ignored' && !vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Unignore'),
                    icon: 'faEye',
                    action: ()=>confirmUpdate('dead',gettextCatalog.getString('Are you sure you want to unignore this link?')),
                    eventParams: { action: 'broken-links-unignore' },
                    shouldShow: vm.link.status === 'ignored' && !vm.pageId,
                },
                {
                    label: gettextCatalog.getString('Mark as not fixed'),
                    icon: 'faTimes',
                    action: ()=>confirmUpdate('dead',gettextCatalog.getString('Are you sure you want to mark this link as not fixed?')),
                    eventParams: { action: 'broken-links-mark-as-not-fixed' },
                    shouldShow: vm.link.status === 'fixed' && !vm.pageId,
                },
            ];

            vm.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                return {
                    label: item.label,
                    leftIcon: item.icon,
                    action: ()=>{
                        ng2MonEventsService.run('actionTrigger', item.eventParams);
                        item.action();
                    },
                };
            });
        }

        function confirmUpdate (loadingTextKey, confirmMessage) {
            ng2MonModalService.confirm(confirmMessage).then(()=>{
                update(loadingTextKey);
            });
        }

        function update (loadingTextKey) {
            var loadingTexts = {
                fixed: gettextCatalog.getString('Marking broken link as fixed'),
                ignored: gettextCatalog.getString('Ignoring broken link'),
                dead: gettextCatalog.getString('Reverting state'),
            };

            var promise;
            vm.deleteDialog = ng2LoadingService.service(gettextCatalog.getString('Please wait') + ' - ' + loadingTexts[loadingTextKey]);

            if (angular.isNumber(vm.pageId)) {
                promise = updateForPage(loadingTextKey);
            } else {
                promise = updateForDomain(loadingTextKey);
            }

            promise
                .then(function () {
                    vm.getPage();
                }, angular.noop)
                .finally(function () {
                    vm.deleteDialog.close();
                });
        }

        function updateForPage (param) {
            var params = {};
            params.status = param;

            return pageService.updateLink(vm.pageId, vm.link.id, params);
        }

        function updateForDomain (param) {
            var params = {};
            params.status = param;
            params.id = vm.link.id;

            return linkService.update(params);
        }
    }
})();
