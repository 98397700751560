import { environment } from '@monsido/core/constants/general.constant.ts';
import { showVersion } from '@monsido/core/constants/general.constant.ts';
import { devMode } from '@monsido/core/constants/general.constant.ts';
import { testMode } from '@monsido/core/constants/general.constant.ts';
import { BI } from '@monsido/core/constants/general.constant.ts';
import { performance } from '@monsido/core/constants/general.constant.ts';
import { terms } from '@monsido/core/constants/general.constant.ts';
import { supportPath } from '@monsido/core/constants/general.constant.ts';
import { rollBarId } from '@monsido/core/constants/general.constant.ts';
import { rollBarPost } from '@monsido/core/constants/general.constant.ts';
import { helpCenterPath } from '@monsido/core/constants/general.constant.ts';
import { monsidoPath } from '@monsido/core/constants/general.constant.ts';
import { monsidoShortPath } from '@monsido/core/constants/general.constant.ts';
import { oauthClientId } from '@monsido/core/constants/general.constant.ts';
import { oauthClientSecret } from '@monsido/core/constants/general.constant.ts';
import { oauthScopes } from '@monsido/core/constants/general.constant.ts';
import { activeFeatures } from '@monsido/core/constants/general.constant.ts';
import { chatSystems } from '@monsido/core/constants/general.constant.ts';
import { matomoSettings } from '@monsido/core/constants/general.constant.ts';
import { gainsight } from '@monsido/core/constants/general.constant.ts';
import { chromeExtensionId } from '@monsido/core/constants/general.constant.ts';
import { edgeExtensionId } from '@monsido/core/constants/general.constant.ts';
import { firefoxExtensionId } from '@monsido/core/constants/general.constant.ts';
import { firefoxExtensionURL } from '@monsido/core/constants/general.constant.ts';
import { maxDomainGroupVisible } from '@monsido/core/constants/general.constant.ts';
import { page_fix_settings } from '@monsido/core/constants/general.constant.ts';
import { domainScriptUrl } from '@monsido/core/constants/general.constant.ts';
import { domainScriptPageAssistV2Url } from '@monsido/core/constants/general.constant.ts';
import { domainScriptConsentManagerUrl } from '@monsido/core/constants/general.constant.ts';
import { version } from '@monsido/core/constants/general.constant.ts';
import { privacy_terms } from '@monsido/core/constants/general.constant.ts';

(function (angular) {
    angular.module('core.constant')

        .constant('environment', environment)
        .constant('showVersion', showVersion)
        .constant('devMode', devMode)
        .constant('testMode', testMode)
        .constant('BI', BI)
        .constant('performance', performance)
        .constant('terms', terms)
        .constant('supportPath', supportPath)
        .constant('rollBarId', rollBarId)
        .constant('rollBarPost', rollBarPost)
        .constant('helpCenterPath', helpCenterPath)
        .constant('monsidoPath', monsidoPath)
        .constant('monsidoShortPath', monsidoShortPath)
        .constant('oauthClientId', oauthClientId)
        .constant('oauthClientSecret', oauthClientSecret)
        .constant('oauthScopes', oauthScopes)
        .constant('activeFeatures', activeFeatures)
        .constant('chatSystems', chatSystems)
        .constant('matomoSettings', matomoSettings)
        .constant('gainsight', gainsight)
        .constant('chromeExtensionId', chromeExtensionId)
        .constant('edgeExtensionId', edgeExtensionId)
        .constant('firefoxExtensionId', firefoxExtensionId)
        .constant('firefoxExtensionURL', firefoxExtensionURL)
        .constant('maxDomainGroupVisible', maxDomainGroupVisible)
        .constant('page_fix_settings', page_fix_settings)
        .constant('domainScriptUrl', domainScriptUrl)
        .constant('domainScriptPageAssistV2Url', domainScriptPageAssistV2Url)
        .constant('domainScriptConsentManagerUrl', domainScriptConsentManagerUrl)
        .constant('version', version)
        .constant('privacy_terms', privacy_terms);

})(angular);
